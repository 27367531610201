import { Alert, Typography } from 'antd'
import React from 'react'
import Loading from '../../components/Loading'

import PageListHeader from '../../components/layout/PageListHeader/PageListHeader'
import { useAppState } from '../../context/AppContext'
import { IStream, StreamForm } from './components/stream-form'
import showNotification from '../../helpers/showNotification'
import { useNavigate } from 'react-router-dom'

const { Text } = Typography

export const StreamsEditPage: React.FC<{ params: { id: number } }> = ({ params }) => {
  const { apiClient, user } = useAppState()
  const { mutation } = apiClient.stream.updateStream
  const navigate = useNavigate()

  const { isLoading, error, data } = apiClient.stream.getStream.useQuery(['stream', params.id], {
    query: {
      id: params.id,
    },
  })

  const onSubmit = async (val: IStream) => {
    const { status, body } = await mutation({ body: val })

    if (status === 200) {
      showNotification({
        type: 'success',
        message: 'Stream successfully updated!',
      })
      navigate(`/streams/${body.id}`)
    }
    if (status === 500) {
      showNotification({ type: 'error', message: body.message })
    }
  }

  if (error?.status === 500) {
    return <Alert type="error" message={error.body.message} />
  }

  if (isLoading || !user) return <Loading />

  return (
    <div className="StreamsEditPage">
      <PageListHeader
        title={`Stream ${data?.body.id}`}
        extra={
          <>
            uid:
            <Text code copyable>
              {data?.body.uid}
            </Text>
          </>
        }
      />
      <StreamForm stream={data?.body} onSubmit={onSubmit} user={user} />
    </div>
  )
}
