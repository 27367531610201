import React, { Button, Col, Divider, Form, Input, Modal, Select, Typography, message } from 'antd'
import { FC, useState } from 'react'
import { useCreateRuleFromTemplate, useCreateTemplateCampaignRule, useRuleTemplateList } from '../../hooks'
import { CampaignRuleWithData } from '../../../../types/campaign-rule'
import { useAppState } from '../../../../context/AppContext'
import Loading from '../../../../components/Loading'
import { useQueryClient } from '@tanstack/react-query'
import showNotification from '../../../../helpers/showNotification'

export const RuleTemplateModal: FC<{ ruleInfo: CampaignRuleWithData }> = ({ ruleInfo }) => {
  const { mutateAsync: createTemplateRule } = useCreateTemplateCampaignRule()
  const queryClient = useQueryClient()
  const { data: templates } = useRuleTemplateList()
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [title, setTitle] = useState('')
  return (
    <>
      <Button
        onClick={() => {
          setShowTemplateModal(true)
        }}
      >
        Create template from rule
      </Button>
      <Modal
        open={showTemplateModal}
        onOk={() => {
          setShowTemplateModal(false)
        }}
        onCancel={() => {
          setShowTemplateModal(false)
        }}
        title="Create template from rule"
        footer={[]}
      >
        <Input
          value={title}
          onChange={e => {
            setTitle(e.target.value)
          }}
          placeholder="Template title"
          style={{ marginBottom: 10 }}
        />
        <Button
          type="primary"
          onClick={() => {
            if (!title) {
              showNotification({
                type: 'error',
                message: 'Error creating rule template',
                description: 'Template title is required',
              })
              return
            }
            createTemplateRule({ title, data: ruleInfo.data })
              .then(async () => {
                showNotification({
                  type: 'success',
                  message: 'Rule template created',
                  description: 'Rule template created successfully',
                })
                await queryClient.invalidateQueries({ queryKey: ['campaign', 'ruleTemplates'] })
              })
              .catch((e: any) => {
                console.error('Error during creating template from rule:', e)
                showNotification({
                  type: 'error',
                  message: 'Error creating rule template',
                  description: e.message,
                })
              })
          }}
        >
          Create template rule
        </Button>
        <Divider />

        <Col>
          <Typography.Title level={4}>Templates</Typography.Title>
          {templates &&
            templates.map(template => (
              <div key={template.id}>
                {template.id}&nbsp;{template.title}
              </div>
            ))}
        </Col>
      </Modal>
    </>
  )
}

export const CreateRuleFromTemplateModal: FC<{ campaignId: number }> = ({ campaignId }) => {
  const { apiClient } = useAppState()
  const { mutateAsync: createRuleFromTemplate } = useCreateRuleFromTemplate()
  const { data: templates } = useRuleTemplateList()

  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const { data } = apiClient.campaign.getCampaignListPaginated.useQuery(['campaigns'], {
    query: {
      pagination: {
        pageSize: 1000,
        pageNumber: 0,
      },
    },
  })
  const [form] = Form.useForm()
  const initialValues = {
    campaignIds: [+campaignId],
    title: '',
    templateId: templates?.[0]?.id,
  }
  const campaignIds = Form.useWatch('campaignIds', form)
  const title = Form.useWatch('title', form)
  const templateId = Form.useWatch('templateId', form)
  if (!data) return <Loading />
  const campaigns = data.body?.items || []
  return (
    <>
      <Button
        type="dashed"
        onClick={() => {
          setShowTemplateModal(true)
        }}
      >
        Create rule from template
      </Button>
      <Modal
        open={showTemplateModal}
        onOk={() => {
          setShowTemplateModal(false)
        }}
        onCancel={() => {
          setShowTemplateModal(false)
        }}
        footer={[]}
        title="Create rule from template"
      >
        {templates && templates?.length > 0 ? (
          <Form
            form={form}
            initialValues={initialValues}
            onSubmitCapture={e => {
              e.preventDefault()
              createRuleFromTemplate({
                campaignIds,
                title,
                templateId,
              })
                .then(() => {
                  message.success('Rule created')
                  setShowTemplateModal(false)
                })
                .catch(() => {
                  message.error('Error creating rule')
                })
            }}
          >
            <Form.Item name="title" label="Title">
              <Input placeholder="Rule title" />
            </Form.Item>
            <Form.Item name="campaignIds" label={'Apply to campaigns:'}>
              <Select
                showSearch
                mode="multiple"
                style={{ width: '100%' }}
                options={((campaigns as Array<{ id: number; title: string }>) || []).map(c => ({
                  value: c.id,
                  label: `${c.id} | ${c.title}`,
                }))}
              />
            </Form.Item>
            <Form.Item name="templateId" label="Rule template to apply for selected campaigns">
              <Select
                showSearch
                style={{ width: '100%' }}
                options={templates?.map(t => ({
                  value: t.id,
                  label: `${t.id} | ${t.title}`,
                }))}
              />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Create rule
            </Button>
          </Form>
        ) : (
          <>No rule templates found</>
        )}
      </Modal>
    </>
  )
}
