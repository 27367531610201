import React from 'react'
import { Form } from 'antd'

import './Submit.sass'
import Button from '../../buttons/Button/Button'

const Submit = ({ label, id, ...props }) => {
  return (
    <Form.Item {...props}>
      <Button type="primary" htmlType="submit" id={id}>
        {label ? label : 'Submit'}
      </Button>
    </Form.Item>
  )
}

export default Submit
