import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createDomain, deleteDomain, fetchDomain, fetchDomains, updateDomain } from './fetchers'

export const useDspDomains = ({ region, trafficType, limit, offset }) => {
  return useQuery({
    queryKey: ['domains'],
    queryFn: () => fetchDomains({ region, trafficType, limit, offset }),
  })
}

export const useDspDomain = ({ id }) => {
  return useQuery({
    queryKey: ['domains', id],
    queryFn: () => fetchDomain({ id }),
  })
}

export const useCreateDspDomainMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createDomain,
    onSettled: () => {
      queryClient.invalidateQueries(['domains'])
    },
  })
}

export const useUpdateDspDomainMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateDomain,
    onSettled: () => {
      queryClient.invalidateQueries(['domains'])
    },
  })
}

export const useDeleteDspDomainMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteDomain,
    onSettled: () => {
      queryClient.invalidateQueries(['domains'])
    },
  })
}
