import { MenuOutlined, MinusCircleOutlined, PlusCircleOutlined, PlusOutlined, WarningFilled } from '@ant-design/icons'
import { Button, Divider, Input, PageHeader, Select, Space, Spin, Typography } from 'antd'
import { createApiRequest } from '../../../api/http-client'
import { useMutation, useQuery } from '@tanstack/react-query'
import React, { CSSProperties, FC, useEffect } from 'react'
import { DndContext, DragEndEvent, closestCenter } from '@dnd-kit/core'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { TargetsData, useTargets } from './useTargets'

const { Title } = Typography

export interface VersionFormProps {
  parent: any
  onChange: any
  targetKey: string
}

const VersionForm: FC<VersionFormProps> = ({ parent, onChange, targetKey }) => {
  const { values, add, update, swap, remove } = useTargets(parent.versions)

  const [showRegexpField, setShowRegexpField] = React.useState(
    values.reduce((acc, val) => {
      if (val.regexp) {
        acc[val.id] = true
      }

      return acc
    }, {} as Record<string, boolean>)
  )

  const onDragEnd = (p: DragEndEvent) => {
    const { active, over } = p
    if (over && active.id != over.id) {
      swap(active.id, over.id)
    }
  }

  useEffect(() => {
    onChange(values)
  }, [values])

  const haveOther = values.some(val => val.label === 'Other')

  return (
    <div style={{ paddingLeft: 15, display: 'flex', flexDirection: 'column', gap: 10 }}>
      <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
        <SortableContext items={values} strategy={verticalListSortingStrategy}>
          {values.map(value => (
            <div key={value.id} style={{ display: 'flex', flexDirection: 'row' }}>
              <Target
                key={value.id}
                value={value}
                onLabelChange={(label: string) => update(value.id, el => ({ ...el, label }))}
                onTargetsChange={(targets: string[]) => update(value.id, el => ({ ...el, targets }))}
                targetOptionsKey={targetKey}
                targetOptionsFilters={parent.targets}
                remove={() => remove(value.id)}
              />
              {!showRegexpField[value.id] ? (
                <Button
                  onClick={() => {
                    setShowRegexpField(prev => ({ ...prev, [value.id]: !prev[value.id] }))
                  }}
                  style={{ marginLeft: 10, maxWidth: '100px' }}
                >
                  Add regexp
                </Button>
              ) : (
                <></>
              )}
              {showRegexpField[value.id] ? (
                <Input
                  prefix="/"
                  suffix="/"
                  placeholder="Regexp"
                  value={value.regexp || ''}
                  style={{ maxWidth: '100px', marginLeft: '10px' }}
                  onChange={e => {
                    update(value.id, el => ({ ...el, regexp: e.target.value }))
                  }}
                />
              ) : (
                <> </>
              )}
            </div>
          ))}
        </SortableContext>
      </DndContext>

      <div style={{ marginLeft: 22, display: 'flex', gap: 10 }}>
        <Button
          type="dashed"
          onClick={() => add({ id: Math.random(), label: '', targets: [], versions: [], regexp: null })}
          style={{ width: 150 }}
          icon={<PlusOutlined />}
        >
          Add version
        </Button>

        {!haveOther ? (
          <Button
            type="dashed"
            onClick={() => add({ id: Math.random(), label: 'Other', targets: [], versions: [], regexp: null })}
            style={{ width: 150, background: '#fffbe6', borderColor: '#ffe58f' }}
            icon={<WarningFilled />}
          >
            Add Other
          </Button>
        ) : null}
      </div>
    </div>
  )
}

const updateTargets = (key: string) => async (data: TargetsData[]) => {
  return createApiRequest('POST', `/targets/settings/${key}`, { targets: data })
}

interface TargetsWithVersionsFormProps {
  label: string
  initialData: TargetsData[]
  targetKey: string
  targetVersionKey: string
}

export const TargetsWithVersionsForm: FC<TargetsWithVersionsFormProps> = ({
  label,
  initialData,
  targetKey,
  targetVersionKey,
}) => {
  const mutation = useMutation({
    mutationFn: updateTargets(targetKey),
  })

  const { values, add, update, swap, remove } = useTargets(initialData)

  const onDragEnd = (e: DragEndEvent) => {
    const { active, over } = e
    if (over && active.id != over.id) {
      swap(active.id, over.id)
    }
  }

  const onFinish = () => {
    mutation.mutate(values)
  }

  if (mutation.isPending) {
    return <Spin />
  }

  if (mutation.error) {
    return <div>Error</div>
  }

  const haveOther = values.some(val => val.label === 'Other')

  return (
    <div>
      <Title level={3}>{label}</Title>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
          <SortableContext items={values} strategy={verticalListSortingStrategy}>
            {values.map(value => (
              <Target
                key={value.id}
                value={value}
                onLabelChange={(label: string) => update(value.id, el => ({ ...el, label }))}
                onTargetsChange={(targets: string[]) => update(value.id, el => ({ ...el, targets }))}
                targetOptionsKey={targetKey}
                targetOptionsFilters={[]}
                remove={() => remove(value.id)}
              >
                <VersionForm
                  parent={value}
                  onChange={(versions: TargetsData[]) => update(value.id, el => ({ ...el, versions }))}
                  targetKey={targetVersionKey}
                />
              </Target>
            ))}
          </SortableContext>
        </DndContext>

        <div style={{ marginLeft: 22, display: 'flex', gap: 10 }}>
          <Button
            type="dashed"
            onClick={() => add({ id: Math.random(), label: '', targets: [], versions: [], regexp: null })}
            style={{ width: 150 }}
            icon={<PlusOutlined />}
          >
            Add {label}
          </Button>

          {!haveOther ? (
            <Button
              type="dashed"
              onClick={() => add({ id: Math.random(), label: 'Other', targets: [], versions: [], regexp: null })}
              style={{ width: 150, background: '#fffbe6', borderColor: '#ffe58f' }}
              icon={<WarningFilled />}
            >
              Add Other
            </Button>
          ) : null}
        </div>
      </div>

      <Button onClick={onFinish} style={{ marginTop: 10 }} type="primary">
        Save
      </Button>
    </div>
  )
}

export const DevicesForm: FC<{ initialData: TargetsData[] }> = ({ initialData }) => {
  const mutation = useMutation({
    mutationFn: updateTargets('device'),
  })

  const { values, add, update, swap, remove } = useTargets(initialData)

  const onDragEnd = (p: DragEndEvent) => {
    const { active, over } = p
    if (over && active.id != over.id) {
      swap(active.id, over.id)
    }
  }

  const onFinish = () => {
    mutation.mutate(values)
  }

  if (mutation.isPending) {
    return <Spin />
  }

  if (mutation.error) {
    return <div>Error</div>
  }

  const haveOther = values.some(val => val.label === 'Other')

  return (
    <div>
      <Title level={3}>Devices</Title>

      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
          <SortableContext items={values} strategy={verticalListSortingStrategy}>
            {values.map(value => (
              <Target
                key={value.id}
                value={value}
                onLabelChange={(label: string) => update(value.id, el => ({ ...el, label }))}
                onTargetsChange={(targets: string[]) => update(value.id, el => ({ ...el, targets }))}
                targetOptionsKey="device"
                targetOptionsFilters={[]}
                remove={() => remove(value.id)}
              />
            ))}
          </SortableContext>
        </DndContext>

        <div style={{ marginLeft: 22, display: 'flex', gap: 10 }}>
          <Button
            type="dashed"
            onClick={() => add({ id: Math.random(), label: '', targets: [], regexp: null })}
            style={{ width: 150 }}
            icon={<PlusOutlined />}
          >
            Add device
          </Button>

          {!haveOther ? (
            <Button
              type="dashed"
              onClick={() => add({ id: Math.random(), label: 'Other', targets: [], versions: [], regexp: null })}
              style={{ width: 150, background: '#fffbe6', borderColor: '#ffe58f' }}
              icon={<WarningFilled />}
            >
              Add Other
            </Button>
          ) : null}
        </div>
      </div>

      <Button onClick={onFinish} style={{ marginTop: 10 }} type="primary">
        Save
      </Button>
    </div>
  )
}

const fetchTargetOptions = async (p: { queryKey: string[] }) => {
  const { queryKey } = p
  const filters = queryKey[queryKey.length - 1]
  const key = queryKey[queryKey.length - 2]

  return createApiRequest('POST', `/targets/settings/options/${key}`, {
    filters,
  })
}

interface TargetProps {
  value: any
  onLabelChange: any
  onTargetsChange: any
  targetOptionsKey: string
  targetOptionsFilters: any
  remove: any
  children?: any
}

const Target: FC<TargetProps> = ({
  value,
  onLabelChange,
  onTargetsChange,
  targetOptionsKey,
  targetOptionsFilters,
  remove,
  children,
}) => {
  const query = useQuery({
    queryKey: ['targets', 'settings', 'options', targetOptionsKey, targetOptionsFilters],
    queryFn: fetchTargetOptions,
    refetchOnWindowFocus: false,
    select: data =>
      data.map((p: { value: string; percent: number }) => {
        const { value, percent } = p
        return {
          value: value,
          label: `${value} (${percent}%)`,
        }
      }),
  })

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: value.id })

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  }

  return (
    <div ref={setNodeRef} style={style}>
      <Space align="baseline">
        <MenuOutlined style={{ opacity: 0.5 }} {...attributes} {...listeners} />

        <Input placeholder="Label" value={value.label} onChange={e => onLabelChange(e.target.value)} />

        <Select
          mode="multiple"
          placeholder="Targets"
          style={{
            maxWidth: 750,
            minWidth: 550,
          }}
          maxTagCount={10}
          autoClearSearchValue={false}
          onChange={value => onTargetsChange(value)}
          defaultValue={value.targets}
          options={query.data ?? []}
          dropdownRender={menu => {
            if (query.isFetching) {
              return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                  <Spin />
                </div>
              )
            }

            return menu
          }}
        />

        <PlusCircleOutlined onClick={() => remove()} />

        <MinusCircleOutlined onClick={() => remove()} />
      </Space>

      {children}
    </div>
  )
}

const fetchSelectedTargets = (): Promise<{
  device: TargetsData[]
  os: TargetsData[]
  browser: TargetsData[]
}> => {
  return createApiRequest('GET', '/targets/settings')
}

const TargetsEditPage = () => {
  const query = useQuery({
    queryKey: ['targets', 'settings'],
    refetchOnWindowFocus: false,
    queryFn: fetchSelectedTargets,
  })

  if (query.isLoading || !query.data) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Spin />
      </div>
    )
  }

  return (
    <>
      <PageHeader title={<Title level={2}>Targets</Title>} />

      <DevicesForm initialData={query.data.device} />

      <Divider />

      <TargetsWithVersionsForm label="Os" targetKey="os" targetVersionKey="os_version" initialData={query.data.os} />

      <Divider />

      <TargetsWithVersionsForm
        label="Browser"
        targetKey="browser"
        targetVersionKey="browser_version"
        initialData={query.data.browser}
      />
    </>
  )
}

export default TargetsEditPage
