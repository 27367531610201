import React, { FC, useState } from 'react'
import { Modal, Button, Input, Form } from 'antd'
import showNotification from '../../../helpers/showNotification'
import { useAppState } from '../../../context/AppContext'
import { useQueryClient } from '@tanstack/react-query'
import { validate as uuidValidate } from 'uuid'

const validateStreamUserId = (streamUserId: string): boolean => {
  if (!streamUserId) {
    showNotification({
      message: `Please enter stream:userId`,
      type: 'error',
    })
    return false
  }
  const userId = streamUserId.split(':')[1]
  const stream = streamUserId.split(':')[0]
  const isStreamUuid = uuidValidate(stream)
  if (!isStreamUuid) {
    showNotification({
      message: `Stream is not a valid uuid`,
      type: 'error',
    })
    return false
  }
  if (!userId || !stream) {
    showNotification({
      message: `Please enter stream:userId`,
      type: 'error',
    })
    return false
  }
  return true
}

export const UserAuditoryActions: FC<{
  auditoryId: number
  onChange: () => void
}> = ({ auditoryId, onChange }) => {
  const { apiClient } = useAppState()
  const queryClient = useQueryClient()
  const [streamUserId, setStreamUserId] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isChecking, setIsChecking] = useState(false)

  const { mutateAsync: checkIfInList } = apiClient.userAuditory.checkIsUserInAuditory.useMutation()

  const { mutateAsync: addUserToList } = apiClient.userAuditory.addUserToAuditory.useMutation({
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['userAuditory'],
      })
    },
  })
  const { mutateAsync: deleteUserFromList } = apiClient.userAuditory.removeUserFromAuditory.useMutation({
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['userAuditory'],
      })
    },
  })

  const handleCheck = async () => {
    setIsChecking(true)
    if (!validateStreamUserId(streamUserId)) {
      setIsChecking(false)
      return
    }
    try {
      const res = await checkIfInList({
        body: {
          userId: streamUserId.split(':')[1] || '0',
          stream: streamUserId.split(':')[0] || '',
          auditoryId,
        },
      })
      if (res?.body.isUserInAuditory === true) {
        showNotification({
          message: `User is in the list`,
          type: 'success',
        })
      }
      if (streamUserId && res?.body.isUserInAuditory === false) {
        showNotification({
          message: `User is not in the list`,
          type: 'error',
        })
      }
    } catch (e) {
      console.error('Error during check if user in auditory', e)
      showNotification({
        message: 'Something went wrong during this check',
        type: 'error',
      })
    }
    setIsChecking(false)
  }

  const handleAdd = () => {
    const userId = streamUserId.split(':')[1]
    const stream = streamUserId.split(':')[0]
    if (!validateStreamUserId(streamUserId)) {
      return
    }
    addUserToList({
      body: {
        stream,
        auditoryId,
        userId,
      },
    })
      .then(() => {
        showNotification({
          message: `User added to the list`,
          type: 'success',
        })
      })
      .catch(e => {
        console.error('error during add to list', e)
      })

    onChange()
  }

  const handleDelete = () => {
    setIsModalVisible(true)
  }

  const handleOkOnDeleteConfirm = () => {
    const userId = streamUserId.split(':')[1]
    const stream = streamUserId.split(':')[0]
    if (!validateStreamUserId(streamUserId)) {
      return
    }
    deleteUserFromList({
      body: {
        stream,
        auditoryId,
        userId,
      },
    })
      .then(r => {
        if (r.body && r.body.ok) {
          showNotification({
            message: `User deleted from the list`,
            type: 'success',
          })
        }
      })
      .catch(e => {
        console.error('error during delete user from auditory', e)
      })
    setIsModalVisible(false)
    onChange()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <div
      className="UserAuditoryActions"
      // style={{ 'margin-top': '25px', 'margin-bottom': '25px' }}
    >
      <Form layout="inline">
        <Form.Item>
          <Input placeholder="stream:userId" value={streamUserId} onChange={e => setStreamUserId(e.target.value)} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleCheck} loading={isChecking === true}>
            Check if in list
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleAdd}>
            Add to current list
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" danger onClick={handleDelete}>
            Delete from list
          </Button>
        </Form.Item>
      </Form>
      <Modal title="Confirmation" visible={isModalVisible} onOk={handleOkOnDeleteConfirm} onCancel={handleCancel}>
        Are you sure you want to delete this user from the list?
      </Modal>
    </div>
  )
}
