import { Descriptions } from 'antd'
import moment from 'moment'
import React, { FC } from 'react'
import { UserRole, UserStatus } from '@pushflow/backend-http-contract'

interface UserInfo {
  user: {
    id: number
    email: string
    name: string | null
    isEmailConfirmed: boolean
    postback: string | null
    roles: UserRole[]
    status: UserStatus
    createdAt: string
    updatedAt: string | null
  }
}

export const UserCard: FC<UserInfo> = ({ user }) => {
  const isPublisher = user.roles.includes(UserRole.PUBLISHER)
  const isAdmin = user.roles.includes(UserRole.ADMIN)
  const isInternalAdvertiser = user.roles.includes(UserRole.INTERNAL_ADVERTISER)
  const isExternalAdvertiser = user.roles.includes(UserRole.EXTERNAL_ADVERTISER)

  return (
    <Descriptions>
      <Descriptions.Item label="ID">{user.id}</Descriptions.Item>
      <Descriptions.Item label="E-mail" span={3}>
        {user.email}
      </Descriptions.Item>
      <Descriptions.Item label="Name">{user.name}</Descriptions.Item>
      <Descriptions.Item label="Active">{user.status === UserStatus.ACTIVE ? '✅' : '❌'}</Descriptions.Item>
      <Descriptions.Item label="Email confirmed">{user.isEmailConfirmed ? '✅' : '❌'}</Descriptions.Item>
      <Descriptions.Item label="Postback">{user.postback || '—'}</Descriptions.Item>
      <Descriptions.Item label="Admin">{isAdmin ? '✅' : '❌'}</Descriptions.Item>
      <Descriptions.Item label="Publisher">{isPublisher ? '✅' : '❌'}</Descriptions.Item>
      <Descriptions.Item label="Team Advertiser">{isInternalAdvertiser ? '✅' : '❌'}</Descriptions.Item>
      <Descriptions.Item label="External Advertiser">{isExternalAdvertiser ? '✅' : '❌'}</Descriptions.Item>
      <Descriptions.Item label="Updated At">
        {user.updatedAt ? moment(user.updatedAt).format('DD.MM.YYYY') : '-'}
      </Descriptions.Item>
      <Descriptions.Item label="Created At">{moment(user.createdAt).format('DD.MM.YYYY')}</Descriptions.Item>
    </Descriptions>
  )
}
