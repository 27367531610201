import { BugOutlined } from '@ant-design/icons'

import { Button, Modal, Typography, Spin, Result } from 'antd'
import { useCampaignDebugLink } from '../../../containers/campaign/hooks'
import { useState } from 'react'
import Link from 'antd/lib/typography/Link'

const { Text } = Typography

const CampaignDebugLinkModal = ({ campaignId }) => {
  const { data, isLoading, isError, error } = useCampaignDebugLink(campaignId)

  if (isLoading) {
    return <Spin />
  }

  if (isError) {
    return <Result status="error" title="Request failed" subTitle={error.message} />
  }

  if (data === null) {
    return <Text>Similar request not found</Text>
  }

  return (
    <Link href={data} target="_blank" copyable={{ text: data }}>
      {data}
    </Link>
  )
}

const CampaignDebugView = ({ campaignId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <Button icon={<BugOutlined />} onClick={() => setIsModalOpen(true)} />
      <Modal
        title="Debug request"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
      >
        <CampaignDebugLinkModal campaignId={Number(campaignId)} />
      </Modal>
    </>
  )
}

export default CampaignDebugView
