// import { useCreateOrUpdateUserAuditoryMutation } from '@pushflow/server/src/graphql/new/userAuditories/createOrUpdateUserAuditory/operations'
import * as Sentry from '@sentry/react'
import { Alert, Button, Form } from 'antd'
import pick from 'lodash/pick'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import showNotification from '../../../helpers/showNotification'
import { defaultFormLayout } from '../../../helpers/formLayouts'
import Input from '../../../components/forms/Input/Input'
import { useAppState } from '../../../context/AppContext'
import { useQueryClient } from '@tanstack/react-query'

export interface IUserAuditory {
  id: number
  title: string
}

export const UserAuditoryForm: FC<{
  userAuditory?: IUserAuditory
}> = ({ userAuditory }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { apiClient } = useAppState()
  const { mutateAsync: createOrUpdateUserAuditory } = userAuditory
    ? apiClient.userAuditory.updateUserAuditory.useMutation({
        onSettled: async () => {
          await queryClient.invalidateQueries({
            queryKey: ['userAuditory'],
          })
          await queryClient.invalidateQueries({
            queryKey: ['userAuditories'],
          })
        },
      })
    : apiClient.userAuditory.createUserAuditory.useMutation({
        onSettled: async () => {
          await queryClient.invalidateQueries({
            queryKey: ['userAuditory'],
          })
          await queryClient.invalidateQueries({
            queryKey: ['userAuditories'],
          })
        },
      })
  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()

  const [isFormValid, setIsFormValid] = useState(false)
  const [isFormTouched, setIsFormTouched] = useState(false)

  const validateForm = () => {
    setIsFormValid(
      form.getFieldsError().every(item => {
        return item.errors.length === 0
      })
    )
    setIsFormTouched(true)
  }

  const neededUserAuditoryFeilds = pick(userAuditory, ['title'])
  const initialValues = {
    ...neededUserAuditoryFeilds,
  }

  const submit = (values: IUserAuditory) => {
    createOrUpdateUserAuditory({
      body: {
        // @ts-ignore
        id: userAuditory && userAuditory.id,
        ...values,
      },
    })
      .then(result => {
        if (result.status === 201) {
          showNotification({
            type: 'success',
            message: userAuditory && userAuditory.id ? 'List succesfully updated!' : 'List succesfully created!',
          })
          navigate(`/userauditory/${result.body.id}`)
        }
      })
      .catch(err => {
        showNotification({ type: 'error', message: err.message })
        if (process.env.NODE_ENV !== 'development') Sentry.captureException(err)
        setSubmitting(false)
      })
  }

  return (
    <Form
      form={form}
      onFinish={submit}
      onFinishFailed={validateForm}
      onFieldsChange={validateForm}
      initialValues={initialValues}
      layout="horizontal"
      disabled={submitting}
      {...defaultFormLayout}
    >
      {/* @ts-ignore */}
      <Input isRequired name="title" label="Title" {...defaultFormLayout} />

      {!isFormValid && isFormTouched && (
        <Form.Item {...defaultFormLayout}>
          <Alert message="Some fields are invalid" type="error" />
        </Form.Item>
      )}

      <Form.Item {...defaultFormLayout}>
        <Button type="primary" htmlType="submit" loading={submitting} disabled={!isFormValid && isFormTouched}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}
