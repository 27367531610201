import React, { FC } from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import './LabelWithTooltip.sass'

const LabelWithTooltip: FC<{
  label: string
  labelTooltip: string
}> = ({ label, labelTooltip }) => {
  return (
    <span>
      <Tooltip title={labelTooltip}>
        {label}
        <QuestionCircleOutlined style={{ opacity: 0.6, marginLeft: 4 }} />
      </Tooltip>
    </span>
  )
}

export default LabelWithTooltip
