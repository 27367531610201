import {
  ClearOutlined,
  CloudDownloadOutlined,
  DownOutlined,
  FileTextOutlined,
  LoadingOutlined,
  UpOutlined,
} from '@ant-design/icons'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { Button, Col, DatePicker, Form, Row, Select, Tag } from 'antd'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import TimezoneSelect from '../../components/forms/TimezoneSelect/TimezoneSelect'
import { useAppState } from '../../context/AppContext'
import { createLabel } from '../../helpers/createAutocompleteLabel'
import config from '../../config'
import { currencyFormatter, floatFormatter, numberFormatter } from '../../helpers/agGridTable'
import { Link } from 'react-router-dom'
import { useGetCampaignReport } from './hooks'
const { Option } = Select
const { RangePicker } = DatePicker

LicenseManager.setLicenseKey(config.agGridLicense)

const targetNames = [
  'country',
  'language_short',
  'device',
  'os_name',
  'os_version',
  'browser_name',
  'browser_version',
  'connection_type',
  'domain',
  'isp',
]

const toGroupHeaderName = (t, groupBy) => {
  if (targetNames.includes(groupBy)) {
    return t(`Report.CampaignReport.Controls.FieldLabel_${groupBy}`)
  }
  return t(`Report.CampaignReport.${groupBy}`)
}

const defaultState = {
  reportParams: {
    groupBy: 'DATE',
    groupBy1: '',
    filterByUserId: [],
    filterByCampaignId: [],
    filterByCreativeId: [],
    filterByCampaignTags: [],
    filterByCreativeTags: [],
    filtersByTargets: targetNames.map(targetName => ({ type: targetName, values: [] })),
    timezone: '+0',
    dateRange: [moment().subtract(14, 'd'), moment().endOf()],
    dateFrom: moment().subtract(14, 'd').format('YYYY-MM-DD'),
    dateTo: moment().add('1d').format('YYYY-MM-DD'),
  },
  filteredInfo: {},
  sortedInfo: {},
  pagination: {
    pageSizeOptions: [10, 20, 50, 100, 400],
    showSizeChanger: true,
    pageSize: 50,
  },
  showFilters: false,
}

const CampaignsReport = () => {
  const { user } = useAppState()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [reloadReport, setReloadReport] = useState(false)
  const [creatives, setCreatives] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [campaignsTags, setCampaignsTags] = useState([])
  const [creativesTags, setCreativesTags] = useState([])
  const [users, setUsers] = useState([])
  const [reportCSVData, setReportCSVData] = useState([])
  const [reportParams, setReportParams] = useState(defaultState.reportParams)
  const { refetch, data, isError: isGetReportError, error: getReportError } = useGetCampaignReport(reportParams)
  const [cache, setCache] = useState({})
  const [showFilters, setShowFilters] = useState(defaultState.showFilters)
  const { apiClient } = useAppState()

  const reportFilterValues = apiClient.report.getCampaignReportFilterValues.useQuery(
    'campaign-report-filter-values',
    {}
  )

  const timezone = Form.useWatch('timezone', form)

  useEffect(() => {
    if (reloadReport) {
      refetch(reportParams).then(() => {
        setReloadReport(false)
      })
    }

    if (data && data.reportCampaign) {
      setReportCSVData(
        data.reportCampaign.map(obj => {
          const item = { ...obj }

          if (reportParams.groupBy === 'WEEKDAY') {
            const day = moment().day(item.group).format('dddd')

            item.weekDayName = t('Report.Weekdays.' + day)
          } else if (reportParams.groupBy === 'MONTH') {
            const day = moment()
              .month(item.group - 1)
              .format('YYYY-MM')

            item.monthName = t('Report.Months.' + day)
          }

          if (item.campaign) {
            item.campaignTitle = item.campaign.title
            item.campaignId = item.campaign.id
          }

          if (item.creative) {
            item.creativeTitle = item.creative.title
            item.creativeId = item.creative.id
          }

          if (item.stream) {
            item.streamTitle = item.stream.title
            item.streamId = item.stream.uid
          }

          delete item.campaign
          delete item.stream
          delete item.creative

          return item
        })
      )
    }
    if (reportFilterValues.data?.body) {
      const { creatives, campaigns, users = [], campaignTags, creativeTags } = reportFilterValues.data?.body

      const creativesList = creatives.map(e => {
        return {
          ...e,
          value: e.id,
          key: 'creative-' + e.id,
          label: createLabel(user, e),
        }
      })

      const campaignsList = campaigns.map(e => {
        return {
          ...e,
          value: e.id,
          key: 'campaign-' + e.id,
          label: createLabel(user, e),
        }
      })
      const usersList = users.map(e => {
        return {
          ...e,
          value: e.id,
          key: 'user-' + e.id,
          label: `${e.id} : ${e.email}`,
        }
      })
      const campaignsTagsList = campaignTags.map(e => {
        return {
          ...e,
          value: e,
          key: `campaignTag-${e}`,
          label: `${e}`,
        }
      })
      const creativesTagsList = creativeTags.map(e => {
        return {
          ...e,
          value: e,
          key: `creativeTag-${e}`,
          label: `${e}`,
        }
      })

      !cache.creatives && setCreatives(creativesList)
      !cache.campaigns && setCampaigns(campaignsList)
      !cache.users && setUsers(usersList)
      !cache.campaignsTags && setCampaignsTags(campaignsTagsList)
      !cache.creativesTags && setCreativesTags(creativesTagsList)

      setCache({ creatives: creativesList, campaigns: campaignsList, users: usersList })
    }
  }, [reportFilterValues.data?.body, reloadReport, data])

  const onFinish = event => {
    event.preventDefault()
    const values = form.getFieldsValue()
    setReportParams({
      ...reportParams,
      ...values,
      dateFrom: values.dateRange ? values.dateRange[0].format('YYYY-MM-DD') : null,
      dateTo: values.dateRange ? values.dateRange[1].format('YYYY-MM-DD') : null,
      filterByCampaignId: values.filterByCampaignId ? values.filterByCampaignId.map(Number) : null,
      filterByCreativeId: values.filterByCreativeId ? values.filterByCreativeId.map(Number) : null,
      filterByUserId: values.filterByUserId ? values.filterByUserId.map(Number) : null,
      filtersByTargets: reportParams.filtersByTargets,
      filterByCampaignTags: values.filterByCampaignTags ? values.filterByCampaignTags.map(String) : null,
      filterByCreativeTags: values.filterByCreativeTags ? values.filterByCreativeTags.map(String) : null,
    })

    setReloadReport(true)
  }

  const handleChangeCretiveId = value => {
    if (value.length) {
      const creatives = cache.creatives.filter(e => value.includes(e.id))
      const campaigns = cache.campaigns.filter(e => creatives.map(({ campaignId }) => campaignId).includes(e.id))

      setCampaigns(campaigns)
    } else {
      setCampaigns(cache.campaigns)
    }

    setReportParams({ ...reportParams, creativeId: value })
  }

  const handleChangeUserId = value => {
    if (value.length) {
      const campaigns = cache.campaigns.filter(c => value.includes(c.userId))
      const creatives = cache.creatives.filter(cr => value.includes(cr.userId))
      const users = cache.users.filter(e => value.includes(e.id))
      setCampaigns(campaigns)
      setCreatives(creatives)
      setUsers(users)
    } else {
      setUsers(cache.users)
    }

    setReportParams({ ...reportParams, userId: value })
  }

  const handleChangeCreativeTags = value => {
    if (value.length === 0) {
      return
    }
    setReportParams({ ...reportParams, filterByCreativeTags: value })
  }

  const handleChangeCampaignTags = value => {
    if (value.length === 0) {
      return
    }
    setReportParams({ ...reportParams, filterByCampaignTags: value })
  }

  const handleChangeCampaignId = value => {
    const creatives = value.length
      ? cache.creatives.filter(creative => value.includes(creative.campaignId))
      : cache.creatives

    setCreatives(creatives)
    setReportParams({ ...reportParams, campaignId: value })
  }

  const filtersByTargets = Form.useWatch('filtersByTargets', form)

  useEffect(() => {
    if (filtersByTargets) {
      setReportParams(reportParams => {
        return {
          ...reportParams,
          filtersByTargets: reportParams.filtersByTargets.map(({ type }, index) => {
            return { type, values: filtersByTargets[index].values }
          }),
        }
      })
    }
  }, [filtersByTargets])

  const clearAll = () => {
    form.resetFields()
    form.setFieldsValue(defaultState.reportParams)
    setReportParams(defaultState.reportParams)
    setReportParams(defaultState.reportParams)
    setCreatives(cache.creatives)
    setCampaigns(cache.campaigns)
    setReloadReport(true)
  }

  const gridRef = useRef()

  const [rowData, setRowData] = useState([])

  const columnDefs = useMemo(
    () => [
      {
        field: 'group',
        width: 130,
        pinned: 'left',
        headerName: toGroupHeaderName(t, reportParams.groupBy),
        cellRenderer: val => {
          if (reportParams.groupBy === 'CAMPAIGN_ID') {
            return val.data.campaign ? (
              <Link
                to={`/campaign/${val.data.campaign.id}`}
                target="_blank"
              >{`(${val.data.campaign.id}) ${val.data.campaign.title}`}</Link>
            ) : (
              ''
            )
          }
          if (reportParams.groupBy === 'CREATIVE_ID') {
            return val.data.creative ? `(${val.data.creative.id}) ${val.data.creative.title}` : ''
          }
          if (reportParams.groupBy === 'STREAM') {
            return val.data.stream ? `${val.data.stream.title}` : ''
          }
          return val.data.group
        },
        ...(reportParams.groupBy === 'HOUR' ? { comparator: (a, b) => a - b } : {}),
      },
      ...(reportParams.groupBy1
        ? [
            {
              field: 'group1',
              width: 130,
              pinned: 'left',
              headerName: toGroupHeaderName(t, reportParams.groupBy1),
              valueGetter: val => {
                if (reportParams.groupBy1 === 'CAMPAIGN_ID') {
                  return val.data.campaign ? `(${val.data.campaign.id}) ${val.data.campaign.title}` : ''
                }
                if (reportParams.groupBy1 === 'CREATIVE_ID') {
                  return val.data.creative ? `(${val.data.creative.id}) ${val.data.creative.title}` : ''
                }
                if (reportParams.groupBy1 === 'STREAM') {
                  return val.data.stream ? `${val.data.stream.title}` : ''
                }
                return val.data.group1
              },
              ...(reportParams.groupBy1 === 'HOUR' ? { comparator: (a, b) => a - b } : {}),
            },
          ]
        : []),
      { field: 'bids', width: 100, type: 'rightAligned', valueFormatter: numberFormatter },
      { field: 'wonBids', width: 120, type: 'rightAligned', valueFormatter: numberFormatter },
      { field: 'clicks', width: 100, type: 'rightAligned', valueFormatter: numberFormatter },
      { field: 'winRate', width: 120, valueFormatter: floatFormatter, type: 'rightAligned' },
      {
        field: 'cpc',
        width: 100,
        valueFormatter: params => currencyFormatter(Math.ceil((params.data.cpc || 0) * 10000) / 10000, '$', 3),
        type: 'rightAligned',
      },
      {
        field: 'cost',
        width: 100,
        valueFormatter: params => currencyFormatter(Math.ceil((params.data.cost || 0) * 1000) / 1000),
        type: 'rightAligned',
      },
      {
        field: 'conversionRate',
        width: 120,
        type: 'rightAligned',
        valueFormatter: params => `${floatFormatter(params) * 100}%`,
      },
      { field: 'conv1', width: 100, type: 'rightAligned', valueFormatter: numberFormatter },
      { field: 'conv2', width: 100, type: 'rightAligned', valueFormatter: numberFormatter },
      { field: 'conv3', width: 100, type: 'rightAligned', valueFormatter: numberFormatter },
      { field: 'revenue', width: 100, type: 'rightAligned', valueFormatter: numberFormatter },
      {
        field: 'profit',
        width: 100,
        cellStyle: params => {
          if (params.data.profit <= 0) {
            return { color: 'red' }
          } else {
            return { color: 'green' }
          }
        },
        type: 'rightAligned',
        valueFormatter: params => currencyFormatter(params.value),
      },
      {
        field: 'roi',
        width: 120,
        headerName: 'ROI',
        cellStyle: params => {
          if (params.data.roi <= 0) {
            return { color: 'red' }
          } else {
            return { color: 'green' }
          }
        },
        valueFormatter: params => params.data.roi.toFixed(2) + '%',
        type: 'rightAligned',
      },
      {
        field: 'cpl1',
        width: 100,
        valueFormatter: params => currencyFormatter(Math.ceil((params.data.cpc || 0) * 1000) / 1000),
        type: 'rightAligned',
      },
      {
        field: 'cpl2',
        width: 100,
        valueFormatter: params => currencyFormatter(Math.ceil((params.data.cpc || 0) * 1000) / 1000),
        type: 'rightAligned',
      },
      {
        field: 'cpl3',
        width: 100,
        valueFormatter: params => currencyFormatter(Math.ceil((params.data.cpc || 0) * 1000) / 1000),
        type: 'rightAligned',
      },
      {
        field: 'ctr',
        width: 100,
        valueFormatter: params => (params.data.ctr * 100).toFixed(2) + '%',
        type: 'rightAligned',
      },
    ],
    [reportParams.groupBy, reportParams.groupBy1]
  )
  const defaultColDef = useMemo(() => ({
    filter: true,
    sortable: true,
    resizable: true,
  }))
  const getFooterData = () => {
    const gridApi = gridRef.current?.api
    if (!gridApi) {
      return
    }
    const result = []
    const model = gridApi.getModel()
    const visibleRows = model.rowsToDisplay
    function sum(values, col) {
      let sum = 0
      values.forEach(function (value) {
        sum += value.data[col]
      })
      return sum
    }
    function avg(values, col) {
      if (!values.length) {
        return 0
      }
      let sum = 0
      values.forEach(function (value) {
        sum += value.data[col]
      })
      return sum / values.length
    }
    result.push({
      group: '',
      group1: '',
      campaign: '',
      creative: '',
      bids: sum(visibleRows, 'bids'),
      wonBids: sum(visibleRows, 'wonBids'),
      clicks: sum(visibleRows, 'clicks'),
      winRate: avg(visibleRows, 'winRate'),
      cpc: avg(visibleRows, 'cpc'),
      cost: sum(visibleRows, 'cost'),
      conversionRate: avg(visibleRows, 'conversionRate'),
      conv1: avg(visibleRows, 'conv1'),
      conv2: avg(visibleRows, 'conv2'),
      conv3: avg(visibleRows, 'conv3'),
      revenue: sum(visibleRows, 'revenue'),
      profit: sum(visibleRows, 'profit'),
      roi: parseFloat(((sum(visibleRows, 'profit') / sum(visibleRows, 'cost')) * 100).toFixed(2)),
      cpl1: avg(visibleRows, 'cpl1'),
      cpl2: avg(visibleRows, 'cpl2'),
      cpl3: avg(visibleRows, 'cpl3'),
      ctr: avg(visibleRows, 'ctr'),
    })
    return result
  }
  const [pinnedBottomRowData, setPinnedBottomRowData] = useState(getFooterData())
  useEffect(() => {
    if (data) {
      setRowData(data.reportCampaign)
      setTimeout(() => {
        setPinnedBottomRowData(getFooterData())
      }, 100)
    }
  }, [data])

  const filterOption = (input, { label }) => (label ?? '').toLowerCase().includes(input.toLowerCase())

  if (isGetReportError) {
    return <div>{getReportError.message}</div>
  }

  return (
    <>
      <Form
        form={form}
        id="reportForm"
        name="reportForm"
        onSubmit={onFinish}
        initialValues={reportParams}
        style={{ marginBottom: 24 }}
      >
        <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
          {['', '1'].map(suffix => (
            <Col span={{ xs: 8, sm: 8, md: 8, lg: 8 }} key={'group' + suffix}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name={`groupBy${suffix}`}
                key={`groupBy${suffix}`}
                label={t(`Report.CampaignReport.Controls.GroupBy${suffix}`)}
                rules={
                  suffix === '1'
                    ? []
                    : [
                        {
                          required: true,
                          message: t('Report.CampaignReport.Controls.GroupByErrorMsg'),
                        },
                      ]
                }
              >
                <Select
                  placeholder={t('Report.CampaignReport.Controls.GroupByPlaceholder')}
                  allowClear={suffix === '1'}
                >
                  <Option value="DATE">{t('Report.CampaignReport.DATE')}</Option>
                  <Option value="CAMPAIGN_ID">{t('Report.CampaignReport.CAMPAIGN_ID')}</Option>
                  <Option value="CREATIVE_ID">{t('Report.CampaignReport.CREATIVE_ID')}</Option>
                  <Option value="HOUR">{t('Report.CampaignReport.HOUR')}</Option>
                  <Option value="WEEKDAY">{t('Report.CampaignReport.WEEKDAY')}</Option>
                  <Option value="MONTH">{t('Report.CampaignReport.MONTH')}</Option>
                  <Option value="STREAM">{t('Report.CampaignReport.STREAM')}</Option>
                  <Option value="SUBSTREAM">{t('Report.CampaignReport.SUBSTREAM')}</Option>
                  {reportParams.filtersByTargets.map(({ type }) => (
                    <Option value={type} key={type}>
                      {t(`Report.CampaignReport.Controls.FieldLabel_${type}`)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ))}
          <Col span={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={
                <>
                  {t('Report.CampaignReport.Controls.DateRangeLabel')}{' '}
                  <Tag style={{ verticalAlign: 2, marginLeft: 4 }}>UTC{timezone}</Tag>
                </>
              }
              name="dateRange"
              key="dateRange"
              rules={[
                {
                  required: false,
                  message: t('Report.CampaignReport.Controls.DateRangeMsg'),
                },
              ]}
            >
              <RangePicker
                style={{ width: '100%' }}
                ranges={{
                  Today: [moment().startOf(), moment().endOf()],
                  Yesterday: [moment().subtract(1, 'days').startOf(), moment().subtract(1, 'days').endOf()],
                  'This Week': [moment().startOf('week').startOf(), moment().endOf('week').endOf()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'Last 7 days': [moment().subtract(7, 'days').startOf(), moment().subtract(1, 'days').endOf()],
                  'Last 30 days': [moment().subtract(30, 'days').startOf(), moment().subtract(1, 'days').endOf()],
                }}
              />
            </Form.Item>
          </Col>
          <Col span={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            <TimezoneSelect title={t('Report.Controls.Timezone')} name={'timezone'} />
          </Col>

          <Col span={{ xs: 12, lg: 3 }} className="gutter-row">
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="&nbsp;">
              <Button
                type="text"
                form="reportForm"
                onClick={() => setShowFilters(!showFilters)}
                icon={!showFilters ? <DownOutlined /> : <UpOutlined />}
              >
                {!showFilters ? t('Report.Controls.Filters') : t('Report.Controls.Filters')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row style={!showFilters && { display: 'none' }} gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              key="filterByCampaignId"
              label={t('Report.CampaignReport.Controls.CampaignsFieldLabel')}
              name="filterByCampaignId"
            >
              <Select
                mode="multiple"
                filterOption={filterOption}
                placeholder={t('Report.CampaignReport.Controls.CampaignsFieldPlaceholder')}
                onChange={handleChangeCampaignId}
                value={reportParams.filterByCampaignId}
                options={campaigns}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              key="filterByCreativeId"
              label={t('Report.CampaignReport.Controls.CreativesFieldLabel')}
              name="filterByCreativeId"
            >
              <Select
                mode="multiple"
                placeholder={t('Report.CampaignReport.Controls.CreativesFieldPlaceholder')}
                onChange={handleChangeCretiveId}
                filterOption={filterOption}
                value={reportParams.filterByCreativeId}
                options={creatives}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            {user && user.isAdmin ? (
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                key="filterByUserId"
                name="filterByUserId"
                label={t('Report.CampaignReport.Controls.UsersFieldLabel')}
              >
                <Select
                  mode="multiple"
                  placeholder={t('Report.CampaignReport.Controls.UsersFieldPlaceholder')}
                  onChange={handleChangeUserId}
                  value={reportParams.filterByUserId}
                  options={users}
                />
              </Form.Item>
            ) : (
              <></>
            )}
          </Col>
          <Col span={8}>
            {user ? (
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                key="filterByCampaignTags"
                name="filterByCampaignTags"
                label={t('Report.CampaignReport.Controls.CampaignTagsFieldLabel')}
              >
                <Select
                  mode="multiple"
                  placeholder={t('Report.CampaignReport.Controls.CampaignTagsFieldPlaceholder')}
                  onChange={handleChangeCampaignTags}
                  value={reportParams.filterByCampaignTags}
                  options={campaignsTags}
                />
              </Form.Item>
            ) : (
              <></>
            )}
          </Col>
          <Col span={8}>
            {user ? (
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                key="filterByCreativeTags"
                name="filterByCreativeTags"
                label={t('Report.CampaignReport.Controls.CreativeTagsFieldLabel')}
              >
                <Select
                  mode="multiple"
                  placeholder={t('Report.CampaignReport.Controls.CreativeTagsFieldPlaceholder')}
                  onChange={handleChangeCreativeTags}
                  value={reportParams.filterByCreativeTags}
                  options={creativesTags}
                />
              </Form.Item>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        {/* select for each campaignAutocomplete.data?.reportCampaignAutoComplete.targets */}
        <Row style={!showFilters && { display: 'none' }} gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
          {reportParams.filtersByTargets.map((target, index) => {
            const values = reportFilterValues.data?.body.targets.find(el => el.type === target.type)?.values

            return (
              <Col span={8} key={index}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  key={target.type}
                  label={t(`Report.CampaignReport.Controls.FieldLabel_${target.type}`)}
                  name={['filtersByTargets', index, 'values']}
                >
                  <Select
                    mode="multiple"
                    placeholder={t(`Report.CampaignReport.Controls.FieldLabel_${target.type}`)}
                    onChange={handleChangeUserId}
                    value={target.values}
                    options={values?.map(value => ({
                      value,
                      key: value,
                      label: value,
                    }))}
                  />
                </Form.Item>
              </Col>
            )
          })}
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={{ xs: 12, lg: 3 }} className="gutter-row">
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} key="submitBtn">
              <Button
                type="primary"
                form="reportForm"
                key="submit"
                htmlType="submit"
                onClick={onFinish}
                icon={reloadReport ? <LoadingOutlined /> : <FileTextOutlined />}
              >
                {reloadReport ? t('Report.Loading') : t('Report.Controls.CreateReport')}
              </Button>
            </Form.Item>
          </Col>
          <Col span={{ xs: 12, lg: 3 }} className="gutter-row">
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} key="submitBtn">
              <Button type="" form="reportForm" onClick={clearAll} icon={<ClearOutlined />}>
                {t('Report.Controls.ClearAll')}
              </Button>
            </Form.Item>
          </Col>
          <Col span={{ xs: 12, lg: 3 }} className="gutter-row">
            {reportCSVData && (
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ float: 'right' }}
                key="dowloadReportBtn"
              >
                <Button type="dashed" icon={<CloudDownloadOutlined />}>
                  <CSVLink
                    key="csvData"
                    data={reportCSVData}
                    filename={`pushflow_report_${moment().format('YYYY.MM.DD-hh.mm')}.csv`}
                    target="_blank"
                  >
                    {' '}
                    {t('Report.Controls.DownloadReport')}
                  </CSVLink>
                </Button>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
          domLayout="autoHeight"
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
              },
            ],
          }}
          pinnedBottomRowData={pinnedBottomRowData}
          suppressColumnFilter={false}
        />
      </div>
    </>
  )
}
export default CampaignsReport
