import { useQuery } from '@tanstack/react-query'
import { fetchCampaignReport, fetchDiscrepancyReport, fetchStreamReport } from './fetchers'

export const useQueryDiscrepancyReport = filter => {
  const query = useQuery({
    enabled: Boolean(filter?.fromDate && filter?.toDate),
    queryKey: ['report', 'campaign-discrepancy', `${filter?.fromDate}${filter?.toDate}`],
    queryFn: () => fetchDiscrepancyReport(filter?.fromDate, filter?.toDate),
  })

  return {
    ...query,
    isLoading: query.isLoading && query.fetchStatus !== 'idle',
  }
}

export const useGetCampaignReport = ({
  groupBy,
  groupBy1,
  filterByUserId,
  filterByCampaignId,
  filterByCreativeId,
  filterByCampaignTags,
  filterByCreativeTags,
  filtersByTargets,
  timezone,
  dateRange,
  dateFrom,
  dateTo,
}) => {
  const query = useQuery({
    queryKey: ['report', 'campaignReport'],
    queryFn: () =>
      fetchCampaignReport({
        groupBy,
        groupBy1,
        filterByUserId,
        filterByCampaignId,
        filterByCreativeId,
        filterByCampaignTags,
        filterByCreativeTags,
        filtersByTargets,
        timezone,
        dateRange,
        dateFrom,
        dateTo,
      }),
  })

  return query
}

export const useGetStreamReport = ({
  groupBy,
  groupBy1,
  showDeleted,
  filterByUserId,
  filterByStreamId,
  filtersByTargets,
  timezone,
  dateRange,
  dateFrom,
  dateTo,
}) => {
  const query = useQuery({
    queryKey: ['report', 'streamReport'],
    queryFn: () =>
      fetchStreamReport({
        groupBy,
        groupBy1,
        showDeleted,
        filterByUserId,
        filterByStreamId,
        filtersByTargets,
        timezone,
        dateRange,
        dateFrom,
        dateTo,
      }),
  })

  return query
}
