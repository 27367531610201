import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchServiceStatus, startService, stopService } from './fetchers'

export const useServiceStatusQuery = () => {
  return useQuery({
    queryKey: ['service', 'status'],
    queryFn: fetchServiceStatus,
  })
}

export const useStopServiceMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: stopService,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['service', 'status'] })
    },
  })
}

export const useStartServiceMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: startService,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['service', 'status'] })
    },
  })
}
