import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, Radio, Select, Space } from 'antd'
import React from 'react'

export const getProfessionalColumnSearchProps = (
  setValue: (value: any) => void,
  params: {
    type: 'text' | 'boolean' | 'multiselect'
    placeholder: string
    options?: any
    selectInputEl: any
    searchInputEl: any
  }
) => {
  const { type, placeholder, options, selectInputEl, searchInputEl } = params
  return {
    filterDropdown: (p: {
      setSelectedKeys: (val: any) => void
      selectedKeys: any
      confirm: any
      clearFilters?: any
    }): any => {
      const { setSelectedKeys, selectedKeys, confirm, clearFilters } = p
      if (type === 'text') {
        return (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInputEl}
              placeholder={placeholder}
              value={selectedKeys[0]}
              onChange={({ target }) => setSelectedKeys(target.value ? [target.value] : [])}
              onPressEnter={() => {
                confirm()
                setValue(selectedKeys[0])
              }}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  confirm()
                  setValue(selectedKeys[0])
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters?.()
                  confirm()
                  setValue('')
                }}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </Space>
          </div>
        )
      }
      if (type === 'boolean') {
        return (
          <div style={{ padding: 8 }}>
            <div style={{ marginBottom: 8 }}>
              <Radio.Group
                onChange={({ target }) => {
                  setSelectedKeys([target.value])
                  // confirm({
                  //   closeDropdown: false,
                  // })
                  // setValue(target.value)
                }}
                value={selectedKeys[0]}
              >
                <Radio value={true}>true</Radio>
                <Radio value={false}>false</Radio>
              </Radio.Group>
            </div>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  confirm()
                  setValue(selectedKeys[0])
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters?.()
                  confirm()
                  setValue(null)
                }}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </Space>
          </div>
        )
      }
      if (type === 'multiselect') {
        return (
          <div style={{ padding: 8 }}>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  confirm()
                  setValue(selectedKeys)
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters?.()
                  confirm()
                  setValue([])
                }}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </Space>
            <Select
              ref={selectInputEl}
              mode="multiple"
              allowClear
              style={{ marginTop: 8, display: 'block', maxWidth: 500 }}
              placeholder={placeholder}
              value={selectedKeys}
              onChange={ids => setSelectedKeys(ids)}
              options={options}
            />
          </div>
        )
      }
      return <></>
    },
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInputEl.current?.select(), 100)
      }
    },
  }
}
