import { Input, Popconfirm } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import React, { FC, useState } from 'react'

import showNotification from '../../../helpers/showNotification'
import { useAppState } from '../../../context/AppContext'

export const EditCampaignDailyBudget: FC<{
  dailyBudget: number
  campaignId: number
  refetch: () => void
  queryClient: any
}> = ({ dailyBudget, campaignId, refetch, queryClient }) => {
  const { apiClient } = useAppState()
  const { mutateAsync: updateDailyBudget } = apiClient.campaign.updateCampaignDailyBudget.useMutation({
    onSuccess: () => {
      refetch()
      queryClient.invalidateQueries({ queryKey: ['campaigns'] })
      showNotification({
        type: 'success',
        message: `Daily budget changed for campaign ${campaignId}`,
      })
    },
  })
  const onConfirm = async (value: number) => {
    updateDailyBudget({
      body: { dailyBudget: value, campaignId: Number(campaignId) },
    }).catch(e => {
      console.error('error during update of daily budget', e)
    })
  }

  const [priceValue, setPriceValue] = useState<string | number>(dailyBudget)

  return (
    <Popconfirm
      title={
        <div>
          <span>Change daily budget for campaign {campaignId}</span>
          <Input defaultValue={priceValue} onChange={e => setPriceValue(e.target.value)} />
        </div>
      }
      onConfirm={() => onConfirm(parseFloat(`${priceValue}`))}
      okText="Change Budget"
      cancelText="Cancel"
      placement="bottom"
    >
      <EditTwoTone />
    </Popconfirm>
  )
}
