import { gql } from 'graphql-tag'

export const USER_DATA = gql`
  fragment UserData on User {
    __typename
    id
    email
    name
    postback
    isAdmin
    isPublisher
    isAdvertiser
    isActive
    featureFlags
    telegram
    isEmailConfirmed
    updatedAt
    createdAt
  }
`
