import { gql } from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const BULK_EDIT_URLS_CAMPAIGNS_MUTATION = gql`
  mutation BulkEditUrlsCampaigns($input: BulkEditUrlsCampaignsInput!) {
    bulkEditUrlsCampaigns(input: $input) {
      campaigns {
        id
      }
    }
  }
`

export const useBulkEditUrlsCampaignsMutation = (options = {}) => {
  const [mutate, { data, error }] = useMutation(BULK_EDIT_URLS_CAMPAIGNS_MUTATION, {
    ...options,
  })
  return { mutate, data, error }
}
