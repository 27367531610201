import { useQuery as useQueryGQ } from '@apollo/react-hooks'
// import { useQuery } from '@tanstack/react-query'
import { gql } from '@apollo/client'
import React, { FC } from 'react'
//@ts-ignore
import { USER_DATA } from '@pushflow/server/src/graphql/new/user/User/operations'
import config from '../../config'

import { NotificationSettings } from './components/notification-settings'
import { PostbackUrl } from './components/postback-url'
import { ChangePassword } from './components/change-password'

import { Options } from './components/options'
import { ServiceStatus } from './components/service-status'
import { useAppState } from '../../context/AppContext'

const USER_QUERY = gql`
  query MeQuery {
    me {
      ...UserData
    }
  }
  ${USER_DATA}
`

export const OptionsPage: FC<{
  user: {
    isAdmin: boolean
  }
}> = props => {
  const { user } = props
  const { apiClient } = useAppState()
  const { loading, data = {} } = useQueryGQ(USER_QUERY)

  const userSettingsQuery = apiClient.user.getNotificationSettings.useQuery(['notificationSettings'])

  if (!userSettingsQuery.data || loading || userSettingsQuery.isLoading) return <div>Loading...</div>

  return (
    <>
      {user.isAdmin && <ServiceStatus />}

      <Options values={data.me} />
      <NotificationSettings
        data={{
          userId: data.me.id,
          isEnableTelegram: userSettingsQuery.data.body.telegramNotificationEnabled,
        }}
      />
      <ChangePassword />
      <PostbackUrl dspUrl={config.dspUrl} />
    </>
  )
}
