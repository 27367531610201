import showNotification from '../../../helpers/showNotification'
import { DeleteTwoTone, WarningTwoTone } from '@ant-design/icons'
import React from 'react'
import { useAppState } from '../../../context/AppContext'
import { Popconfirm } from 'antd'

export const DeleteStreamButton = (props: { id: number; onDeleted: () => void }) => {
  const { apiClient } = useAppState()

  const { mutation } = apiClient.stream.deleteStream

  return (
    <Popconfirm
      title="Are you sure you want to delete?"
      onConfirm={() => {
        mutation({ body: { id: props.id } }).then(() => {
          props.onDeleted()
          showNotification({
            type: 'success',
            message: 'Stream successfully deleted',
          })
        })
      }}
      icon={<WarningTwoTone twoToneColor={'red'} />}
      okText="Delete"
      cancelText="Cancel"
      placement="bottom"
    >
      <DeleteTwoTone />
    </Popconfirm>
  )
}
