import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm, Tooltip } from 'antd'
import React, { FC } from 'react'
import Button from '../Button/Button'
import './DeleteButton.sass'
import { useTranslation } from 'react-i18next'

const DeleteButton: FC<{
  onClick?: () => void
  isOnlyIcon?: boolean
  [key: string]: any
}> = ({ children, onClick, isOnlyIcon, ...props }) => {
  const { t } = useTranslation()

  function renderButton() {
    return (
      <Popconfirm
        title="Are you sure you want to delete?"
        onConfirm={() => onClick && onClick()}
        okText="Delete"
        cancelText="Cancel"
        placement="bottom"
      >
        {' '}
        {children || (
          <Button icon={<DeleteOutlined />} {...props}>
            {!isOnlyIcon && t('Actions.Delete')}
          </Button>
        )}
      </Popconfirm>
    )
  }

  if (isOnlyIcon) {
    return (
      <Tooltip placement="bottom" title={t('Actions.Delete')}>
        {renderButton()}
      </Tooltip>
    )
  }

  return renderButton()
}

export default DeleteButton
