import { z } from 'zod';
import { initContract } from '@ts-rest/core';
const c = initContract();

export const videoCreative = c.router({
  uploadVideoCreative: {
    method: 'POST',
    path: '/video-creative/uploadVideo',
    contentType: 'multipart/form-data',
    body: c.type<{ file: FormData }>(),
    responses: {
      201: z.object({
        fileId: z.number(),
      }),
    },
  },

  createVideoCreative: {
    method: 'POST',
    path: '/video-creative/createVideoCreative',
    body: z.object({
      title: z.string(),
      fileId: z.string(),
      tags: z.string().array().default([]),
    }),
    responses: {
      201: z.object({
        creativeId: z.string(),
      }),
    },
  },
});
