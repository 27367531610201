import { gql } from 'graphql-tag'

export const CAMPAIGN_STATISTIC_QUERY = gql`
  query CampaignStatisticQuery($campaignId: String, $days: Int, $now: String) {
    campaignStatistic(campaignId: $campaignId, days: $days, now: $now) {
      bids
      wonBids
      clicks
      cost
      chart {
        bids
        wonBids
        clicks
        cost
        date
      }
    }
  }
`
