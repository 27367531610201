import { z } from 'zod';
import { initContract } from '@ts-rest/core';

import { ErrorCode } from './common/error.enum';

const c = initContract();

export const auth = c.router({
  login: {
    method: 'POST',
    path: '/auth',
    responses: {
      201: z.object({
        jwtToken: z.string(),
      }),
      400: z.object({
        errorCode: z.enum([
          ErrorCode.PASSWORD_INVALID,
          ErrorCode.EMAIL_NOT_CONFIRMED,
          ErrorCode.USER_NOT_ACTIVATED,
        ]),
        message: z.string().optional(),
      }),
    },
    body: z.object({
      email: z.string().email(),
      password: z.string(),
    }),
  },

  register: {
    method: 'POST',
    path: '/auth.register',
    responses: {
      201: z.object({
        jwtToken: z.string(),
      }),
      400: z.object({
        errorCode: z.enum([ErrorCode.USER_IS_REGISTERED]),
        message: z.string().optional(),
      }),
    },
    body: z.object({
      email: z.string().email(),
      password: z.string(),
      preferredContact: z.string().optional(),
    }),
  },

  changePassword: {
    method: 'POST',
    path: '/changePassword',
    responses: {},
    body: z.object({
      currentPassword: z.string(),
      newPassword: z.string(),
    }),
  },
});
