import cidr from 'cidr-regex'
import ipRegex from 'ip-regex'

export const parseIpAndCIDR = value => {
  if (!value) return []
  if (Array.isArray(value)) return value.map(parseIpAndCIDR).flat()
  const cidrVals = value.match(cidr()) || []
  const ipVals =
    value
      .split(/\r?\n/)
      .map(s => s.match(ipRegex({ exact: true })))
      .filter(s => s)
      .map(s => s[0]) || []
  if (cidrVals.length || ipVals.length) return [...cidrVals, ...ipVals]
  else return []
}
