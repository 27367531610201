import { z } from 'zod';

export const StreamType = {
  PUSH: 'PUSH',
  LINK: 'LINK',
  VIDEO_PRE_ROLL: 'VIDEO_PRE_ROLL',
} as const;

export type StreamType = (typeof StreamType)[keyof typeof StreamType];

export const StreamTypeEnum = z.enum([
  StreamType.PUSH,
  StreamType.LINK,
  StreamType.VIDEO_PRE_ROLL,
]);
