import { Form } from 'antd'

export const IncludeOrExclude = ({ form, name }) => {
  const value = Form.useWatch(name, form)
  const defaultStyle = {
    fontSize: '12px',
    color: '#ddd',
    backgroundColor: '#333',
    border: 'none',
    borderRadius: '4px',
    padding: '2px 8px',
    marginRight: '3px',
    cursor: 'pointer',
  }
  const includeSelectedStyle = {
    ...defaultStyle,
    color: '#fff',
    backgroundColor: '#52c41a',
  }
  const excludeSelectedStyle = {
    ...defaultStyle,
    color: '#fff',
    backgroundColor: '#f5222d',
  }
  return (
    <Form.Item style={{ margin: 0 }} name={name}>
      <div>
        <button
          type="button"
          onClick={() => {
            form.setFieldsValue({ [name]: true })
          }}
          style={value ? includeSelectedStyle : defaultStyle}
        >
          Include
        </button>
        <button
          type="button"
          onClick={() => {
            form.setFieldsValue({ [name]: false })
          }}
          style={!value ? excludeSelectedStyle : defaultStyle}
        >
          Exclude
        </button>
      </div>
    </Form.Item>
  )
}
