import { useQuery } from '@tanstack/react-query'
import { fetchCampaignPrices } from './fetchers'

export interface CampaignStreamSubstreamInfo {
  bid: number
  status: 'WORKING' | 'PAUSED'
}
export interface CampaignPrices {
  price: number
  streamBids: Record<string, CampaignStreamSubstreamInfo>
  substreamBids: Record<string, CampaignStreamSubstreamInfo>
}

export const useCampaignPrices = () => {
  return useQuery<Record<string, CampaignPrices>>({
    queryKey: ['campaignPrices'],
    queryFn: () => fetchCampaignPrices(),
  })
}
