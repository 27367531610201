import { z } from 'zod';

export const UserRole = {
  ADMIN: 'ADMIN',
  INTERNAL_ADVERTISER: 'INTERNAL_ADVERTISER',
  EXTERNAL_ADVERTISER: 'EXTERNAL_ADVERTISER',
  PUBLISHER: 'PUBLISHER',
} as const;

export type UserRole = (typeof UserRole)[keyof typeof UserRole];

export const UserRoleEnum = z.enum([
  UserRole.ADMIN,
  UserRole.INTERNAL_ADVERTISER,
  UserRole.EXTERNAL_ADVERTISER,
  UserRole.PUBLISHER,
]);
