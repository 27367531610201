import { useMutation } from '@tanstack/react-query'
import React, { FC, useState } from 'react'
import { createApiRequest } from '../../../api/http-client'
import { Checkbox, Tooltip } from 'antd'
import { useAppState } from '../../../context/AppContext'
import { UserNotificationType } from '@pushflow/backend-http-contract'

export const DailyStatNotificationCheckbox: FC<{
  campaignId: number
  enabledDailyStatsNotification: boolean
}> = ({ campaignId, enabledDailyStatsNotification }) => {
  const [isChecked, setIsChecked] = useState(enabledDailyStatsNotification)
  const { user } = useAppState()

  const setEnabledNotification = useMutation({
    mutationFn: isEnabled => {
      return createApiRequest('PATCH', `/campaign/notification/daily-stats`, {
        enabled: isEnabled,
        campaignId: Number(campaignId),
      })
    },
  })

  const onChange = async (e: any) => {
    setIsChecked(e.target.checked)
    await setEnabledNotification.mutateAsync(e.target.checked)
  }

  const telegramNotificationsEnabled = user && user.activeNotificationTypes.includes(UserNotificationType.TELEGRAM)

  return (
    <Tooltip
      title={
        telegramNotificationsEnabled
          ? 'Send a daily report in Telegram'
          : 'Enable Telegram bot notifications in the settings'
      }
      placement="left"
    >
      <Checkbox checked={isChecked} onChange={onChange} disabled={!telegramNotificationsEnabled}></Checkbox>
    </Tooltip>
  )
}
