import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBulkArchiveCampaignsMutation } from '../../../containers/campaign/ArchivedCampaignsList/hooks'
import showNotification from '../../../helpers/showNotification'
import * as Sentry from '@sentry/react'
import { Button, Modal, Table } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

export const BulkArchiveButton: FC<{
  selectedCampaignIds: number[]
  allCampaigns: any[]
  setSelectedCampaignIds: (ids: number[]) => void
  refetch: () => void
}> = ({ selectedCampaignIds, allCampaigns, setSelectedCampaignIds, refetch }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleCancel = () => {
    setOpen(false)
  }
  const { mutate: bulkArchiveCampaigns } = useBulkArchiveCampaignsMutation()
  const handle = () => {
    setLoading(true)
    bulkArchiveCampaigns(selectedCampaignIds, {
      onSuccess: result => {
        const archivedCampaignsIds = result.archivedCampaignsIds || []
        // @ts-ignore
        const newSelectedCampaignIds = selectedCampaignIds.filter(id => !archivedCampaignsIds.includes(+id))
        setSelectedCampaignIds(newSelectedCampaignIds)
        if (archivedCampaignsIds.length) {
          showNotification({
            type: 'success',
            message: `${t('CampaignsListPage.Success Archive Count')} (${archivedCampaignsIds.length})`,
          })
        }
      },
      onError: err => {
        showNotification({ type: 'error', message: err.message })
        if (process.env.NODE_ENV !== 'development') {
          Sentry.captureException(err)
        }
      },
      onSettled: () => {
        refetch()
        setLoading(false)
        setOpen(false)
      },
    })
  }
  return (
    <>
      <Button disabled={!selectedCampaignIds.length} icon={<DeleteOutlined />} onClick={() => setOpen(true)} />
      <Modal
        open={open}
        title={t('CampaignsListPage.Bulk Archive Title')}
        onOk={() => handle()}
        onCancel={() => handleCancel()}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            {t('CampaignsListPage.Cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            disabled={!selectedCampaignIds.length || loading}
            onClick={() => handle()}
          >
            {t('CampaignsListPage.Bulk Archive Button')}
          </Button>,
        ]}
      >
        <Table
          rowKey={'id'}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
            },
            {
              title: 'Title',
              dataIndex: 'title',
              key: 'title',
            },
          ]}
          // @ts-ignore
          dataSource={allCampaigns.filter(campaign => selectedCampaignIds.includes(campaign.id))}
          pagination={false}
        />
      </Modal>
    </>
  )
}
