import React from 'react'
import './Title.sass'

const Title = ({ level = 1, children, isSeparator, ...props }) => {
  const Header = `h${level}`

  return (
    <Header className={`Title ${isSeparator ? 'Title--isSeparator' : ''}`} {...props}>
      {children}
    </Header>
  )
}

export default Title
