import { gql } from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { BLACK_WHITE_LIST_DATA } from '../BlackWhiteList/operations.js'

export const GET_BLACK_WHITE_LISTS_QUERY = gql`
  query GetBlackWhiteLists($input: GetBlackWhiteListsInput!) {
    getBlackWhiteLists(input: $input) {
      blackWhiteLists {
        ...BlackWhiteListData
      }
      total
    }
  }
  ${BLACK_WHITE_LIST_DATA}
`

export const useGetBlackWhiteListsQuery = (input = {}, options = {}) => {
  return useQuery(GET_BLACK_WHITE_LISTS_QUERY, {
    variables: { input },
    ...options,
  })
}
