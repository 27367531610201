import React, { Alert, Spin } from 'antd'
import PageHeader from '../../../components/layout/PageHeader/PageHeader'
import { UserEditForm } from './components/user-edit-form'
import { useAppState } from '../../../context/AppContext'
import { FC } from 'react'

export const AdminUserEditPage: FC<{ params: { id: string } }> = props => {
  const { apiClient } = useAppState()
  const { isLoading, data, error } = apiClient.user.getUserById.useQuery(['user', props.params.id], {
    query: {
      userId: parseInt(props.params.id, 10),
    },
  })

  if (isLoading) return <Spin size="large" />
  if (error || data?.status !== 200) {
    console.error(error)
    return <Alert type="error" />
  }

  const user = data.body

  return (
    <>
      <PageHeader
        breadcrumbs={[
          { title: 'Users', link: '/admin/users' },
          { title: user.name || user.email, link: `/admin/users/${user.id}` },
          { title: 'Edit' },
        ]}
        title={user.name || user.email}
      />
      <UserEditForm user={user} />
    </>
  )
}
