import BarChart from '../../components/BarChart'
import Statistic from '../../components/Statistic'
import calcCtr from '../../helpers/calcCtr'

import { useQuery } from '@apollo/client'
import { CAMPAIGN_STATISTIC_QUERY } from '@pushflow/server/src/graphql/new/campaigns/campaignStatistic/operations'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useAppState } from '../../context/AppContext'

const CampaignStatsContainer = ({ campaignId }) => {
  const { statisticDays } = useAppState()
  const { data = { campaignStatistic: { bids: 0, wonBids: 0, clicks: 0, cost: 0, chart: [] } }, error } = useQuery(
    CAMPAIGN_STATISTIC_QUERY,
    {
      variables: { campaignId, days: statisticDays },
    }
  )

  const { t } = useTranslation()

  let campaignStatistic = data.campaignStatistic ?? { bids: 0, wonBids: 0, clicks: 0, cost: 0, chart: [] }

  if (error) {
    return <p>{t('Common.Unavailable')}</p>
  }

  return (
    <>
      <Row>
        <Col span={8}>
          <Statistic
            title={t('CampaignStatsContainer.Bids in 14 days', { count: statisticDays })}
            value={campaignStatistic.bids}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title={t('CampaignStatsContainer.WonBids in 14 days', { count: statisticDays })}
            value={campaignStatistic.wonBids}
            suffix={calcCtr(campaignStatistic.wonBids, campaignStatistic.bids)}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title={t('CampaignStatsContainer.Clicks in 14 days', { count: statisticDays })}
            value={campaignStatistic.clicks}
            suffix={calcCtr(campaignStatistic.clicks, campaignStatistic.wonBids)}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title={t('CampaignStatsContainer.Cost in 14 days', { count: statisticDays })}
            value={campaignStatistic.cost}
            // suffix={calcCtr(campaignStatistic.cost, campaignStatistic.shown)}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 32 }}>
        <BarChart
          labels={campaignStatistic.chart.map(v => v.date)}
          datasets={[
            {
              label: 'Bids',
              data: campaignStatistic.chart.map(v => v.bids),
              backgroundColor: 'rgb(250, 155, 154)',
            },
            {
              label: 'Won Bids',
              data: campaignStatistic.chart.map(v => v.wonBids),
              backgroundColor: 'rgb(148, 167, 255)',
            },
            {
              label: 'Clicks',
              data: campaignStatistic.chart.map(v => v.clicks),
              backgroundColor: '#b2df8a',
            },
            {
              label: 'Cost',
              data: campaignStatistic.chart.map(v => v.cost),
              backgroundColor: 'rgb(47, 118, 255)',
            },
          ]}
          axesStacked={false}
          animations={false}
        />
      </Row>
    </>
  )
}

export default CampaignStatsContainer
