import { useTranslation } from 'react-i18next'
import showNotification from '../../../helpers/showNotification'
import { Button, Col, Form, Row } from 'antd'
import { defaultFormLayout, defaultFormLayoutOffset } from '../../../helpers/formLayouts'
import FormSubHeader from '../../../components/forms/FormSubHeader/FormSubHeader'
import Input from '../../../components/forms/Input/Input'
import { useAppState } from '../../../context/AppContext'

const Options = ({ values }) => {
  const { apiClient } = useAppState()
  const { mutateAsync } = apiClient.user.updateSettings.useMutation()
  const { t } = useTranslation()

  const transformedValues = {
    ...values,
  }

  async function onFinish(values) {
    try {
      await mutateAsync({ body: { email: values.email, name: values.name } })
      showNotification({ type: 'success', message: 'Saved' })
    } catch (err) {
      showNotification({ type: 'error', message: 'Error: ' + err.body.message })
    }
  }

  return (
    <>
      <Row gutter={48}>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form onFinish={onFinish} initialValues={{ ...transformedValues }} {...defaultFormLayout}>
            <FormSubHeader title={t('UserSettings.Settings')} layout={defaultFormLayoutOffset} />

            <Input name="email" label={t('UserSettings.Email')} type="email" isRequired="true" />
            <Input name="name" label={t('UserSettings.Name')} />

            <Form.Item {...defaultFormLayoutOffset}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export { Options }
