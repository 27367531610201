import React from 'react'
import PageHeader from '../components/layout/PageHeader/PageHeader'
import { InventoryPage } from '../containers/inventory/inventory'

export const InventoryPageOld = () => {
  return (
    <>
      <PageHeader title="Inventory"></PageHeader>

      <InventoryPage />
    </>
  )
}
