import { gql } from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { campaignPreviewFragment } from '../Campaign/operations.js'

export const GET_CAMPAIGNS_QUERY = gql`
  query GetCampaigns($input: GetCampaignsInput!) {
    getCampaigns(input: $input) {
      campaigns {
        ...campaignPreviewFragment
      }
      allCampaigns {
        id
        title
        maxDailyBudget
        enabledNotificationType
      }
      totalCount
    }
  }
  ${campaignPreviewFragment}
`

export const useGetCampaignsQuery = (input, options = {}) => {
  return useQuery(GET_CAMPAIGNS_QUERY, {
    variables: { input },
    ...options,
  })
}
