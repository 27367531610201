import { BarChartOutlined, LineChartOutlined, SendOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PageHeader from '../components/layout/PageHeader/PageHeader'
import CampaignsReport from '../containers/report/CampaignsReport'
import StreamsReport from '../containers/report/StreamsReport'
import AdvertisersReport from '../containers/report/AdvertisersReport'

import DiscrepancyReport from '../containers/report/CampaignDiscrepancyReport'
import { UserRole } from '@pushflow/backend-http-contract'
import { IUser } from '../context/AppContext'

const CAMPAIGNS_TAB = 'CAMPAIGNS_TAB'
const STREAMS_TAB = 'STREAMS_TAB'
const ADVERTISERS_TAB = 'ADVERTISERS_TAB'
const DESC = 'DESC'

const ReportPage: FC<{ user: IUser }> = ({ user }) => {
  const { t } = useTranslation()
  const [currentTab, setTab] = useState(user.roles.includes(UserRole.PUBLISHER) ? STREAMS_TAB : CAMPAIGNS_TAB)
  const handleTabClick = (e: { key: string }) => setTab(e.key)

  return (
    <>
      <PageHeader title={t('Report.Title')}></PageHeader>
      <Menu
        onClick={handleTabClick}
        selectedKeys={[currentTab]}
        mode="horizontal"
        style={{ marginBottom: 24, marginTop: 24 }}
      >
        <Menu.Item
          key={CAMPAIGNS_TAB}
          icon={<SendOutlined />}
          style={{ fontSize: 18 }}
          hidden={
            !(user.roles.includes(UserRole.EXTERNAL_ADVERTISER) || user.roles.includes(UserRole.INTERNAL_ADVERTISER))
          }
        >
          {t('Report.Tabs.CampaignsTab')}
        </Menu.Item>
        <Menu.Item
          key={STREAMS_TAB}
          icon={<BarChartOutlined />}
          style={{ fontSize: 18 }}
          hidden={!user.roles.includes(UserRole.PUBLISHER)}
        >
          {t('Report.Tabs.StreamsTab')}
        </Menu.Item>
        <Menu.Item
          key={ADVERTISERS_TAB}
          icon={<LineChartOutlined />}
          style={{ fontSize: 18 }}
          hidden={!user.roles.includes(UserRole.ADMIN)}
        >
          {t('Report.Tabs.AdvertisersReportTab')}
        </Menu.Item>
        <Menu.Item
          key={DESC}
          icon={<BarChartOutlined />}
          style={{ fontSize: 18 }}
          hidden={!user.roles.includes(UserRole.INTERNAL_ADVERTISER)}
        >
          {t('Report.Tabs.CampaignDiscrepancyTab')}
        </Menu.Item>
      </Menu>

      {currentTab === CAMPAIGNS_TAB && <CampaignsReport />}
      {currentTab === DESC && <DiscrepancyReport />}
      {currentTab === STREAMS_TAB && <StreamsReport />}
      {currentTab === ADVERTISERS_TAB && <AdvertisersReport />}
    </>
  )
}

export default ReportPage
