import React, { FC, useState } from 'react'
// @ts-ignore
import { useBulkChangeCampaignStatusesMutation } from '@pushflow/server/src/graphql/new/campaigns/bulkChangeCampaignStatuses/operations'
import { useTranslation } from 'react-i18next'
import showNotification from '../../../helpers/showNotification'
import * as Sentry from '@sentry/react'
import { Button } from 'antd'
import { PauseOutlined } from '@ant-design/icons'

export const BulkPauseButton: FC<{
  selectedCampaignIds: number[]
  queryClient: any
}> = ({ selectedCampaignIds, queryClient }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { mutate: bulkChangeCampaignStatuses } = useBulkChangeCampaignStatusesMutation()
  const handle = () => {
    setLoading(true)
    bulkChangeCampaignStatuses({
      variables: {
        input: { ids: selectedCampaignIds, newStatus: 'PAUSED' },
      },
    })
      // @ts-ignore
      .then(result => {
        const errorMessages = result.data?.bulkChangeCampaignStatuses.errorMessages || []
        const updatedCampaigns = result.data?.bulkChangeCampaignStatuses.campaigns || []
        queryClient.invalidateQueries({ queryKey: ['campaigns'] })
        if (updatedCampaigns.length) {
          showNotification({
            type: 'success',
            message: `${t('CampaignsListPage.Success Paused Count')} (${updatedCampaigns.length})`,
          })
        }
        for (const message of errorMessages) {
          showNotification({ type: 'error', message })
        }
      })
      // @ts-ignore
      .catch(err => {
        showNotification({ type: 'error', message: err.message })
        if (process.env.NODE_ENV !== 'development') {
          Sentry.captureException(err)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <Button
      disabled={!selectedCampaignIds.length || loading}
      loading={loading}
      icon={<PauseOutlined />}
      onClick={() => handle()}
    />
  )
}
