import React from 'react'
import PageListHeader from '../../../../components/layout/PageListHeader/PageListHeader'
import { BlackWhiteListForm } from '../../../../containers/blackWhiteList/BlackWhiteListForm'

const BlackWhiteListCreatePage = () => {
  return (
    <div className="BlackWhiteListCreatePage">
      <PageListHeader title="New list" />
      <BlackWhiteListForm blackWhiteList={undefined} />
    </div>
  )
}

export default BlackWhiteListCreatePage
