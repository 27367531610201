import { ControlOutlined, RollbackOutlined } from '@ant-design/icons'
import PageListHeader from '../../../components/layout/PageListHeader/PageListHeader'
import { CampaignRule } from '../../../containers/campaign/components/CampaignRules/CampaignRulesForm'
import { useCampaignQuery } from '@pushflow/server/src/graphql/new/campaigns/getCampaign/operations'
import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'

export const CampaignRuleCreatePage: FC<{ params: { campaignId: number } }> = ({ params }) => {
  const { campaignId } = params
  const { t } = useTranslation()

  const { loading, error, data } = useCampaignQuery(params.campaignId)

  if (loading && !data) return <div>{t('Common.Loading')}</div>
  if (error)
    return (
      <>
        `${t('Common.Error')}!: ${error}`
      </>
    )

  const { campaign } = data

  return (
    <>
      <PageListHeader
        title={`Create rule for campaign ${campaignId}`}
        buttonSize="small"
        buttons={[
          {
            link: `/campaign/${campaignId}`,
            type: 'primary',
            icon: <RollbackOutlined />,
            title: 'Go To Campaign',
          },
          {
            link: `/rules/${campaignId}`,
            type: 'primary',
            icon: <ControlOutlined />,
            title: `Go To Campaign Rules List`,
          },
        ]}
      />
      <CampaignRule campaign={{ ...campaign, hasTargetCpa: campaign.targetCpa !== null }} />
    </>
  )
}
