import { CheckOutlined, CloseOutlined, DeleteTwoTone, QuestionOutlined } from '@ant-design/icons'
import { Alert, Space, Table, Tag } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import DeleteButton from '../../../../components/buttons/DeleteButton/DeleteButton'
import showNotification from '../../../../helpers/showNotification'
import moment from 'moment'
import {
  DSP_DOMAINS_TRAFFIC_TYPE_LABELS,
  DSP_DOMAINS_TRAFFIC_TYPE_LABEL_COLORS,
} from '../../../../common/types/dspDomainsTrafficType'
import { DSP_REGIONS } from '../../../../common/static/dspRegions'
import { useDeleteDspDomainMutation, useDspDomains } from '../hooks'

const CheckResult = ({ checkedAt, isHealthy }) => {
  const checkStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '115px',
  }

  if (!checkedAt) {
    return (
      <div
        style={{
          ...checkStyles,
          color: 'blue',
        }}
      >
        <div>
          <QuestionOutlined />
        </div>
        <div>
          <p>Awaiting check</p>
        </div>
      </div>
    )
  }

  if (!isHealthy) {
    return (
      <div
        style={{
          ...checkStyles,
          color: 'red',
        }}
      >
        <div>
          <CloseOutlined />
        </div>
        <div>Check failed</div>
        <div>{moment(checkedAt).format('YYYY-MM-DD hh:mm')}</div>
      </div>
    )
  }

  return (
    <div
      style={{
        ...checkStyles,
        color: 'green',
      }}
    >
      <div>
        <CheckOutlined />
      </div>
      <div>Check passed</div>
      <div>{moment(checkedAt).format('YYYY-MM-DD hh:mm')}</div>
    </div>
  )
}

const DeleteStreamIcon = ({ id, refetch }) => {
  const { mutateAsync: deleteDomain } = useDeleteDspDomainMutation()
  return (
    <DeleteButton
      key="3"
      isOnlyIcon
      onClick={() => {
        deleteDomain({
          id,
        }).then(() => {
          showNotification({
            type: 'success',
            message: 'Domain successfully deleted',
          })
          refetch()
        })
      }}
    >
      <DeleteTwoTone />
    </DeleteButton>
  )
}

const DomainsList = () => {
  /** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
  const [limit, setLimit] = useState(10)
  /** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
  const [offset, setOffset] = useState(0)

  const { t } = useTranslation()

  const { loading, error, data, refetch } = useDspDomains({
    limit,
    offset,
  })
  const showLoading = loading && !data

  if (error) return <Alert type="error" message={error.message} />

  let columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text, record) => (
        <Link to={`/admin/domains/${record.id}`}>
          <b>{text}</b>
        </Link>
      ),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      render: (text, record) => (
        <Link to={`/admin/domains/${record.id}`}>
          <b>{text}</b>
        </Link>
      ),
    },
    {
      title: 'Region',
      dataIndex: 'region',
      render: text => <Tag color="green">{DSP_REGIONS[text] ?? text}</Tag>,
    },
    {
      title: 'Traffic Type',
      dataIndex: 'trafficType',
      render: text => (
        <div style={{ maxWidth: '300px' }}>
          <Space size={[5, 5]} wrap>
            {text.map((item, index) => (
              <Tag color={DSP_DOMAINS_TRAFFIC_TYPE_LABEL_COLORS[item]} key={index}>
                {DSP_DOMAINS_TRAFFIC_TYPE_LABELS[item]}
              </Tag>
            ))}
          </Space>
        </div>
      ),
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      render: text => (text ? <CheckOutlined /> : <CloseOutlined />),
    },
    {
      title: 'Virus Total Check',
      dataIndex: 'virusTotalCheckAt',
      render: (text, record) => (
        <CheckResult checkedAt={record.virusTotalCheckAt} isHealthy={record.isVirusTotalCheckHealthy} />
      ),
    },
    {
      title: 'AdBlock Check',
      dataIndex: 'adBlockCheckAt',
      render: (text, record) => (
        <CheckResult checkedAt={record.adBlockCheckAt} isHealthy={record.isAdBlockCheckHealthy} />
      ),
    },
    {
      title: 'Ping Check',
      dataIndex: 'pingCheckAt',
      render: (text, record) => <CheckResult checkedAt={record.pingCheckAt} isHealthy={record.isPingCheckHealthy} />,
    },
    {
      title: 'SmartScreen check',
      dataIndex: 'smartScreenCheckAt',
      render: (text, record) => (
        <CheckResult checkedAt={record.smartScreenCheckAt} isHealthy={record.isSmartScreenCheckHealthy} />
      ),
    },
    {
      title: 'Google Safe Browsing Check',
      dataIndex: 'googleSafeBrowsingCheckAt',
      render: (text, record) => (
        <CheckResult checkedAt={record.googleSafeBrowsingCheckAt} isHealthy={record.isGoogleSafeBrowsingCheckHealthy} />
      ),
    },
    {
      title: t('Table.Actions'),
      key: 'action',
      render: (_, record) => {
        return (
          <Space size="large">
            <DeleteStreamIcon id={record.id} refetch={refetch} />
          </Space>
        )
      },
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={data?.items || []}
      loading={showLoading}
      rowKey="id"
      size="middle"
      scroll={{ x: true }}
      pagination={{
        pageSize: limit,
        total: data?.total || 0,
        pageSizeOptions: [10, 20, 50, 100, 400],
        showSizeChanger: true,
        current: offset / limit + 1,
        onChange(page, newLimit) {
          if (newLimit !== limit) {
            const firstItemIndexInCurrentPage = offset * limit
            const pageWhereItemWillBeAfterNewLimit = Math.floor(firstItemIndexInCurrentPage / newLimit)
            setOffset(pageWhereItemWillBeAfterNewLimit * newLimit)
            setLimit(newLimit)
          } else if (page - 1 !== offset) {
            setOffset((page - 1) * limit)
          }
        },
      }}
    />
  )
}

export default DomainsList
