const defaultFormLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}

const defaultFormLayoutOffset = {
  wrapperCol: { sm: { offset: 6, span: 18 }, xs: { span: 24 } },
}

export { defaultFormLayout, defaultFormLayoutOffset }
