import { Button, Form, Modal, Select } from 'antd'
import React, { FC, useState } from 'react'
import { CampaignRuleWithData } from '../../../../types/campaign-rule'
import { useAppState } from '../../../../context/AppContext'
import { useCopyRuleMutation } from '../../hooks'
import showNotification from '../../../../helpers/showNotification'

export const CopyRuleModal: FC<{ ruleInfo: CampaignRuleWithData }> = ({ ruleInfo }) => {
  const { apiClient } = useAppState()
  const { mutateAsync: copyRule } = useCopyRuleMutation()
  const [showCopyModal, setShowCopyModal] = useState(false)
  const { data } = apiClient.campaign.getCampaignListPaginated.useQuery(['campaigns'], {
    query: {
      pagination: {
        pageSize: 1000,
        pageNumber: 0,
      },
    },
  })
  const [form] = Form.useForm()
  const initialValues = {
    campaignId: ruleInfo.campaignId,
  }

  const onSubmit = (e: any) => {
    e.preventDefault()
    const campaignId = form.getFieldValue('campaignId')

    copyRule({
      campaignId: ruleInfo.campaignId,
      ruleId: ruleInfo.id,
      toCampaignId: campaignId,
    })
      .then(() => {
        showNotification({
          message: 'Rule copied',
          type: 'success',
          description: 'Rule copied successfully',
        })
        setShowCopyModal(false)
      })
      .catch(e => {
        console.error(e)
        showNotification({
          message: 'Error',
          type: 'error',
          description: 'Error copying rule',
        })
        setShowCopyModal(false)
      })
  }

  return (
    <>
      <Button
        onClick={() => {
          setShowCopyModal(true)
        }}
      >
        Copy rule
      </Button>
      <Modal
        open={showCopyModal}
        onOk={() => {
          setShowCopyModal(false)
        }}
        onCancel={() => {
          setShowCopyModal(false)
        }}
        footer={[]}
        title="Create rule from template"
      >
        <Form form={form} initialValues={initialValues} onSubmitCapture={e => onSubmit(e)}>
          <Form.Item name="campaignId" label={'Copy to campaign:'}>
            <Select
              showSearch
              style={{ width: '100%' }}
              options={((data?.body.items as Array<{ id: number; title: string }>) || []).map(c => ({
                value: c.id,
                label: `${c.id} | ${c.title}`,
              }))}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Copy rule
          </Button>
        </Form>
      </Modal>
    </>
  )
}
