export const SSP_PUSH = 'SSP_PUSH'
export const SSP_LINK = 'SSP_LINK'
export const SSP_VIDEO = 'SSP_VIDEO'

export const IMPRESSION_PUSH = 'IMPRESSION_PUSH'
export const IMPRESSION_LINK = 'IMPRESSION_LINK'
export const IMPRESSION_VIDEO = 'IMPRESSION_VIDEO'

export const CLICK_PUSH = 'CLICK_PUSH'
export const CLICK_LINK = 'CLICK_LINK'
export const CLICK_VIDEO = 'CLICK_VIDEO'

export const DSP_DOMAINS_TRAFFIC_TYPES = [
  SSP_VIDEO,
  IMPRESSION_VIDEO,
  CLICK_VIDEO,
  SSP_PUSH,
  SSP_LINK,
  IMPRESSION_PUSH,
  IMPRESSION_LINK,
  CLICK_PUSH,
  CLICK_LINK,
]

export const DSP_DOMAINS_TRAFFIC_TYPE_LABELS = {
  [SSP_PUSH]: 'SSP Push',
  [SSP_LINK]: 'SSP Link',
  [IMPRESSION_PUSH]: 'Impressions Push',
  [IMPRESSION_LINK]: 'Impressions Link',
  [CLICK_PUSH]: 'Clicks Push',
  [CLICK_LINK]: 'Clicks Link',
  [SSP_VIDEO]: 'SSP Video',
  [IMPRESSION_VIDEO]: 'Impressions Video',
  [CLICK_VIDEO]: 'Clicks Video',
}

export const DSP_DOMAINS_TRAFFIC_TYPE_LABEL_COLORS = {
  [SSP_PUSH]: 'red',
  [SSP_LINK]: 'volcano',
  [IMPRESSION_PUSH]: 'orange',
  [IMPRESSION_LINK]: 'orange',
  [CLICK_PUSH]: 'blue',
  [CLICK_LINK]: 'purple',
  [SSP_VIDEO]: 'red',
  [IMPRESSION_VIDEO]: 'orange',
  [CLICK_VIDEO]: 'blue',
}
