import { EditOutlined, EditTwoTone } from '@ant-design/icons'
import { useChangeCampaignUrl } from '@pushflow/server/src/graphql/new/campaigns/changeCampaignUrl/operations'
import * as Sentry from '@sentry/react'
import { Form, Input, Modal } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/buttons/Button/Button'
import { InputWithMacroses } from '../../components/forms/InputWithMacroses/InputWithMacroses'
import showNotification from '../../helpers/showNotification'

export const EditCampaignUrlButton = ({ campaignId, type }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleCancel = () => {
    setOpen(false)
  }
  const [form] = Form.useForm()
  const url = Form.useWatch('url', form)
  const [isFormValid, setIsFormValid] = useState(false)
  const validateForm = () =>
    form
      .validateFields()
      .then(() => {
        setIsFormValid(true)
        return true
      })
      .catch(() => {
        setIsFormValid(false)
        return false
      })
  useEffect(() => {
    setIsFormValid(true)
  }, [url])
  useEffect(() => {
    if (!open) {
      form.resetFields()
    }
  }, [open])
  const { mutate: changeUrlCampaign } = useChangeCampaignUrl()
  const handle = () => {
    validateForm().then(isValid => {
      if (!isValid) {
        return
      }
      setLoading(true)
      changeUrlCampaign({
        variables: {
          id: campaignId,
          url,
        },
      })
        .then(result => {
          const updatedCampaign = result.data?.changeCampaignUrl || []
          if (updatedCampaign) {
            showNotification({
              type: 'success',
              message: `${t('Campaign.Success Edit URL')}`,
            })
          }
        })
        .catch(err => {
          showNotification({ type: 'error', message: err.message })
          if (process.env.NODE_ENV !== 'development') {
            Sentry.captureException(err)
          }
        })
        .finally(() => {
          setLoading(false)
          setOpen(false)
        })
    })
  }
  return (
    <>
      {type === 'button' ? (
        <Button disabled={loading} loading={loading} icon={<EditOutlined />} onClick={() => setOpen(true)}>
          {t('CampaignsListPage.Edit URL')}
        </Button>
      ) : type === 'icon' ? (
        <EditTwoTone onClick={() => setOpen(true)} />
      ) : null}

      <Modal
        open={open}
        title={t('CampaignsListPage.Edit Campaign URL Title')}
        onOk={() => handle()}
        onCancel={() => handleCancel()}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            {t('CampaignsListPage.Cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            disabled={loading || !isFormValid}
            onClick={() => handle()}
          >
            {t('CampaignsListPage.Edit Campaign URL Button')}
          </Button>,
        ]}
      >
        <Form form={form} key="form" initialValues={{ url: '' }}>
          <InputWithMacroses
            key="url"
            form={form}
            style={{ marginBottom: 0 }}
            type="url"
            isRequired
            name="url"
            label={t('CampaignsListPage.Edit URL Label')}
          >
            <Input />
          </InputWithMacroses>
        </Form>
      </Modal>
    </>
  )
}
