// import i18n from 'i18next'
// import { initReactI18next } from 'react-i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
// import config from '../config'
// import en from './translations/en.json'
// import ru from './translations/ru.json'
//
// const detection = {
//   // order and from where user language should be detected
//   order: ['cookie', 'localStorage'],
//   lookupCookie: 'lang',
//   lookupLocalStorage: 'lang',
//
//   // cache user language on
//   caches: ['localStorage', 'cookie'],
//   // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
//
//   // optional expire and domain for set cookie
//   cookieMinutes: 60 * 24 * 360,
//   // cookieDomain: 'myDomain',
//
//   // only detect languages that are in the whitelist
//   // checkWhitelist: true
//   // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
// }
//
// i18n
//   // detect user language
//   // learn more: https://github.com/i18next/i18next-browser-languageDetector
//   .use(LanguageDetector)
//   // pass the i18n instance to react-i18next.
//   .use(initReactI18next)
//   // init i18next
//   // for all options read: https://www.i18next.com/overview/configuration-options
//   .init({
//     fallbackLng: 'en',
//     debug: config.isDev,
//
//     resources: {
//       en: {
//         translation: en,
//       },
//       ru: {
//         translation: ru,
//       },
//     },
//     detection,
//     interpolation: {
//       escapeValue: false, // not needed for react as it escapes by default
//     },
//     react: {
//       transSupportBasicHtmlNodes: true,
//       transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
//     },
//   })
//
// export default i18n
