import { useGetUserApiLogsQuery } from '../../../../../../server/src/graphql/new/admin/users/getUserApiLogs/operations'
import { Alert, Table, Tag } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'

export const UserApiLogs = (props: { user: { id: number } }) => {
  const { user } = props

  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)

  const { loading, error, data } = useGetUserApiLogsQuery(
    {
      limit,
      offset,
      userId: user.id.toString(),
    },
    {
      fetchPolicy: 'cache-and-network',
    }
  )

  if (error) return <Alert type="error" message={error.message} />

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => <>{moment(createdAt).format('DD.MM.YYYY HH:mm:ss')}</>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      // @ts-ignore
      render: type => <Tag>{type}</Tag>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      // @ts-ignore
      render: status => <Tag>{status}</Tag>,
    },
  ]

  return (
    <>
      <Table
        columns={columns}
        dataSource={data?.getUserApiLogs.apiLogs || []}
        loading={loading}
        rowKey="id"
        size="middle"
        scroll={{ x: true }}
        expandable={{
          expandedRowRender: apiLog => (
            <pre>
              {JSON.stringify(
                {
                  userId: apiLog.userId,
                  referenceId: +apiLog.referenceId,
                  diff: apiLog.diff,
                  payload: apiLog.payload,
                  previousEntityState: apiLog.previousEntityState,
                },
                null,
                2
              )}
            </pre>
          ),
        }}
        pagination={{
          pageSize: limit,
          total: data?.getUserApiLogs.total || 0,
          pageSizeOptions: [10, 20, 50, 100, 400],
          showSizeChanger: true,
          current: offset + 1,
          onChange(page, newLimit) {
            if (newLimit !== limit) {
              const firstItemIndexInCurrentPage = offset * limit
              const pageWhereItemWillBeAfterNewLimit = Math.floor(firstItemIndexInCurrentPage / newLimit)
              setOffset(pageWhereItemWillBeAfterNewLimit)
              setLimit(newLimit)
            } else if (page - 1 !== offset) {
              setOffset(page - 1)
            }
          },
        }}
      />
    </>
  )
}
