import { gql } from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { CAMPAIGN_STREAM_INFO_DATA } from '../CampaignStreamInfo/operations.js'

export const CHANGE_CAMPAIGN_STREAM_STATUS = gql`
  mutation ChangeCampaignStreamStatus($campaignId: Int!, $streamUid: String!, $status: String!) {
    changeCampaignStreamStatus(campaignId: $campaignId, streamUid: $streamUid, status: $status) {
      ...campaignStreamInfoFullFragment
    }
  }
  ${CAMPAIGN_STREAM_INFO_DATA}
`

export function useChangeCampaignStreamStatus() {
  const [mutate, { data, error }] = useMutation(CHANGE_CAMPAIGN_STREAM_STATUS, {})
  return { mutate, data, error }
}
