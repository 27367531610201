import { Layout, Alert } from 'antd'
import React, { FC, ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'

// @ts-ignore
import logoSVG from './img/logo.svg'

import './AppLayout.sass'
import { useTranslation } from 'react-i18next'
import { useAppState } from '../../../context/AppContext'
import SidebarMenu from '../../SidebarMenu'
import './../../../i18/config'
const { Content } = Layout

const SLIDER_STATE_KEY = 'slider_state'

const AppLayout: FC<{ children: ReactNode }> = ({ children }) => {
  // @ts-ignore
  const { t } = useTranslation()

  const { user } = useAppState()
  const [isSliderCollapsed, setSliderCollapsed] = useState(Boolean(localStorage.getItem(SLIDER_STATE_KEY)))

  const handleSliderCollapsedChange = (val: boolean): void => {
    setSliderCollapsed(val)
    if (val) {
      localStorage.setItem(SLIDER_STATE_KEY, 'hide')
    } else {
      localStorage.removeItem(SLIDER_STATE_KEY)
    }
  }

  return (
    <Layout>
      <Layout.Sider
        className="AppLayout__sider"
        width={250}
        collapsible
        collapsed={isSliderCollapsed}
        onCollapse={val => handleSliderCollapsedChange(val)}
        theme="light"
        style={{
          height: '100vh',
          position: 'fixed',
          left: 0,
          zIndex: 100,
        }}
      >
        <div className="AppLayout__sider-logo__container">
          <Link to="/">
            <img src={logoSVG} alt="Pushflow logo" />
          </Link>
        </div>
        <SidebarMenu />
      </Layout.Sider>

      <Layout className={isSliderCollapsed ? 'AppLayout__container-full' : 'AppLayout__container'}>
        <Content className="AppLayout__content">
          {user && !user.isEmailConfirmed && (
            <Alert message={t('AppLayout.Email confirmation')} type="warning" style={{ marginBottom: 12 }} />
          )}
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

export default AppLayout
