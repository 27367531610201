import { ClearOutlined, CloudDownloadOutlined, FileTextOutlined, LoadingOutlined } from '@ant-design/icons'
import { useReportAdvertisersQuery } from '@pushflow/server/src/graphql/new/stats/reportAdvertisers/operations'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { Button, Col, DatePicker, Form, Row, Select } from 'antd'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { useAppState } from '../../context/AppContext'
import config from '../../config'
import { currencyFormatter, floatFormatter, numberFormatter } from '../../helpers/agGridTable'

const { Option } = Select
const { RangePicker } = DatePicker

LicenseManager.setLicenseKey(config.agGridLicense)

const toGroupHeaderName = (t, groupBy) => {
  return t(`Report.AdvertisersReport.${groupBy}`)
}

const defaultState = {
  reportParams: {
    groupBy: '', //'MONTH',
    groupBy1: '',
    filterByUserId: [],
    dateRange: [moment().startOf('month'), moment().endOf('month')],
    dateFrom: moment().startOf('month').format('YYYY-MM-DD'),
    dateTo: moment().endOf('month').format('YYYY-MM-DD'),
  },
  filteredInfo: {},
  sortedInfo: {},
  pagination: {
    pageSizeOptions: [10, 20, 50, 100, 400],
    showSizeChanger: true,
    pageSize: 50,
  },
  showFilters: false,
}

const AdvertisersReport = () => {
  const { user } = useAppState()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const { refetch, data } = useReportAdvertisersQuery(defaultState.reportParams)
  const [reloadReport, setReloadReport] = useState(false)
  const [reportParams, setReportParams] = useState(defaultState.reportParams)
  const [users, setUsers] = useState([])
  const [reportCSVData, setReportCSVData] = useState([])
  const [cache, setCache] = useState({})
  const { apiClient } = useAppState()

  const reportFilterValues = apiClient.report.getAdvertiserReportFilterValues.useQuery(
    'advertiser-report-filter-values',
    {}
  )

  useEffect(() => {
    if (reloadReport) {
      refetch(reportParams).then(() => {
        setReloadReport(false)
      })
    }

    if (data && data.reportAdvertisers) {
      setReportCSVData(
        data.reportAdvertisers.map(obj => {
          const item = { ...obj }

          delete item.__typename

          if (reportParams.groupBy === 'WEEKDAY') {
            const day = moment().day(item.group).format('dddd')

            item.weekDayName = t('Report.Weekdays.' + day)
          } else if (reportParams.groupBy === 'MONTH') {
            const day = moment()
              .month(item.group - 1)
              .format('YYYY-MM')

            item.monthName = t('Report.Months.' + day)
          }

          return item
        })
      )
    }
    if (reportFilterValues.data?.body) {
      const { users = [] } = reportFilterValues.data?.body

      const usersList = users.map(e => {
        return {
          ...e,
          value: e.id,
          key: 'user-' + e.id,
          label: `${e.id} : ${e.email}`,
        }
      })

      !cache.users && setUsers(usersList)

      setCache({ users: usersList })
    }
  }, [reportFilterValues.data?.body, reloadReport, data])

  const onFinish = event => {
    event.preventDefault()
    const values = form.getFieldsValue()
    setReportParams({
      ...reportParams,
      ...values,
      dateFrom: values.dateRange ? values.dateRange[0].format('YYYY-MM-DD') : null,
      dateTo: values.dateRange ? values.dateRange[1].format('YYYY-MM-DD') : null,
      filterByUserId: values.filterByUserId ? values.filterByUserId.map(Number) : null,
    })

    setReloadReport(true)
  }

  const handleChangeUserId = value => {
    if (value.length) {
      const users = cache.users.filter(e => value.includes(e.id))
      setUsers(users)
    } else {
      setUsers(cache.users)
    }

    setReportParams({ ...reportParams, userId: value })
  }

  const clearAll = () => {
    form.resetFields()
    form.setFieldsValue(defaultState.reportParams)
    setReportParams(defaultState.reportParams)
    setReportParams(defaultState.reportParams)
    setReloadReport(true)
  }

  const gridRef = useRef()
  const [rowData, setRowData] = useState([])

  const columnDefs = useMemo(
    () => [
      { field: 'user.email', width: 130, pinned: 'left', headerName: 'Email' },
      ...(reportParams.groupBy
        ? [
            {
              field: 'group',
              width: 130,
              pinned: 'left',
              headerName: toGroupHeaderName(t, reportParams.groupBy),
              cellRenderer: val => {
                return val.data.group
              },
            },
          ]
        : []),
      // ...(reportParams.groupBy1
      //   ? [
      //       {
      //         field: 'group1',
      //         width: 130,
      //         pinned: 'left',
      //         headerName: toGroupHeaderName(t, reportParams.groupBy1),
      //         valueGetter: val => {
      //           return val.data.group1
      //         },
      //       },
      //     ]
      //   : []),
      {
        field: 'cost',
        width: 100,
        valueFormatter: params => currencyFormatter(Math.ceil((params.data.cost || 0) * 1000) / 1000),
        type: 'rightAligned',
      },
      { field: 'revenue', width: 100, type: 'rightAligned', valueFormatter: numberFormatter },
      {
        field: 'profit',
        width: 100,
        cellStyle: params => {
          if (params.data.profit <= 0) {
            return { color: 'red' }
          } else {
            return { color: 'green' }
          }
        },
        type: 'rightAligned',
        valueFormatter: params => currencyFormatter(params.value),
      },
      {
        field: 'roi',
        width: 120,
        headerName: 'ROI',
        cellStyle: params => {
          if (params.data.roi <= 0) {
            return { color: 'red' }
          } else {
            return { color: 'green' }
          }
        },
        valueFormatter: params => params.data.roi.toFixed(2) + '%',
        type: 'rightAligned',
      },
      { field: 'bids', width: 100, type: 'rightAligned', valueFormatter: numberFormatter },
      { field: 'wonBids', width: 120, type: 'rightAligned', valueFormatter: numberFormatter },
      { field: 'clicks', width: 100, type: 'rightAligned', valueFormatter: numberFormatter },
      { field: 'winRate', width: 120, valueFormatter: floatFormatter, type: 'rightAligned' },
    ],
    [reportParams.groupBy, reportParams.groupBy1]
  )
  const defaultColDef = useMemo(() => ({
    filter: true,
    sortable: true,
    resizable: true,
  }))
  const getFooterData = () => {
    const gridApi = gridRef.current?.api
    if (!gridApi) {
      return
    }
    const result = []
    const model = gridApi.getModel()
    const visibleRows = model.rowsToDisplay
    function sum(values, col) {
      let sum = 0
      values.forEach(function (value) {
        sum += value.data[col]
      })
      return sum
    }
    function avg(values, col) {
      if (!values.length) {
        return 0
      }
      let sum = 0
      values.forEach(function (value) {
        sum += value.data[col]
      })
      return sum / values.length
    }
    result.push({
      group: '',
      group1: '',
      cost: sum(visibleRows, 'cost'),
      revenue: sum(visibleRows, 'revenue'),
      profit: sum(visibleRows, 'profit'),
      roi: avg(visibleRows, 'roi'),
      // roi: parseFloat(((sum(visibleRows, 'profit') / sum(visibleRows, 'cost')) * 100).toFixed(2)),
      bids: sum(visibleRows, 'bids'),
      wonBids: sum(visibleRows, 'wonBids'),
      clicks: sum(visibleRows, 'clicks'),
      winRate: avg(visibleRows, 'winRate'),
      cpc: avg(visibleRows, 'cpc'),
    })
    return result
  }
  const [pinnedBottomRowData, setPinnedBottomRowData] = useState(getFooterData())
  useEffect(() => {
    if (data) {
      setRowData(data.reportAdvertisers)
      setTimeout(() => {
        setPinnedBottomRowData(getFooterData())
      }, 100)
    }
  }, [data])

  return (
    <>
      <Form
        form={form}
        id="reportForm"
        name="reportForm"
        onSubmit={onFinish}
        initialValues={reportParams}
        style={{ marginBottom: 24 }}
      >
        <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
          <Col span={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="dateRange"
              label={<>{t('Report.AdvertisersReport.Controls.DateRangeLabel')} </>}
              key="dateRange"
              rules={[
                {
                  required: false,
                  message: t('Report.AdvertisersReport.Controls.DateRangeMsg'),
                },
              ]}
            >
              <RangePicker
                style={{ width: '100%' }}
                ranges={{
                  'This Year': [moment().startOf('year'), moment().endOf('year')],
                  'Last 7 days': [moment().subtract(7, 'days').startOf(), moment().subtract(1, 'days').endOf()],
                  'Last 30 days': [moment().subtract(30, 'days').startOf(), moment().subtract(1, 'days').endOf()],
                  'This Week': [moment().startOf('week').startOf(), moment().endOf('week').endOf()],
                  'Prevous Week': [
                    moment().subtract(1, 'week').startOf('week').startOf(),
                    moment().subtract(1, 'week').endOf('week').endOf(),
                  ],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'Previous Month': [
                    moment().subtract(1, 'month').startOf('month'),
                    moment().subtract(1, 'month').endOf('month'),
                  ],
                }}
              />
            </Form.Item>
          </Col>
          <Col span={{ xs: 12, lg: 3 }} className="gutter-row">
            {['' /*'1'*/].map(suffix => (
              <Col span={{ xs: 8, sm: 8, md: 8, lg: 8 }} key={'group' + suffix}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name={`groupBy${suffix}`}
                  key={`groupBy${suffix}`}
                  label={t(`Report.AdvertisersReport.Controls.GroupBy${suffix}`)}
                  // rules={
                  //   suffix === '1'
                  //     ? []
                  //     : [
                  //         {
                  //           required: true,
                  //           message: t('Report.AdvertisersReport.Controls.GroupByErrorMsg'),
                  //         },
                  //       ]
                  // }
                >
                  <Select
                    placeholder={t('Report.AdvertisersReport.Controls.GroupByPlaceholder')}
                    allowClear
                    // ={suffix === '1'}
                  >
                    <Option value="DATE">{t('Report.AdvertisersReport.DATE')}</Option>
                    <Option value="YEAR">{t('Report.AdvertisersReport.YEAR')}</Option>
                    {/* <Option value="WEEKDAY">{t('Report.AdvertisersReport.WEEKDAY')}</Option> */}
                    <Option value="MONTH">{t('Report.AdvertisersReport.MONTH')}</Option>
                  </Select>
                </Form.Item>
              </Col>
            ))}
          </Col>

          {/* <Col span={{ xs: 12, lg: 3 }} className="gutter-row">
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="&nbsp;">
              <Button
                type="text"
                form="reportForm"
                onClick={() => setShowFilters(!showFilters)}
                icon={!showFilters ? <DownOutlined /> : <UpOutlined />}
              >
                {!showFilters ? t('Report.Controls.Filters') : t('Report.Controls.Filters')}
              </Button>
            </Form.Item>
          </Col> */}
        </Row>
        {/* <Row style={!showFilters && { display: 'none' }} gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
        </Row> */}
        <Col span={8}>
          {user ? (
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              key="filterByUserId"
              name="filterByUserId"
              label={t('Report.CampaignReport.Controls.UsersFieldLabel')}
            >
              <Select
                mode="multiple"
                placeholder={t('Report.CampaignReport.Controls.UsersFieldPlaceholder')}
                onChange={handleChangeUserId}
                value={reportParams.filterByUserId}
                options={users}
              />
            </Form.Item>
          ) : (
            <></>
          )}
        </Col>
        <Row></Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={{ xs: 12, lg: 3 }} className="gutter-row">
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} key="submitBtn">
              <Button
                type="primary"
                form="reportForm"
                key="submit"
                htmlType="submit"
                onClick={onFinish}
                icon={reloadReport ? <LoadingOutlined /> : <FileTextOutlined />}
              >
                {reloadReport ? t('Report.Loading') : t('Report.Controls.CreateReport')}
              </Button>
            </Form.Item>
          </Col>
          <Col span={{ xs: 12, lg: 3 }} className="gutter-row">
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} key="submitBtn">
              <Button type="" form="reportForm" onClick={clearAll} icon={<ClearOutlined />}>
                {t('Report.Controls.ClearAll')}
              </Button>
            </Form.Item>
          </Col>
          <Col span={{ xs: 12, lg: 3 }} className="gutter-row">
            {reportCSVData && (
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ float: 'right' }}
                key="dowloadReportBtn"
              >
                <Button type="dashed" icon={<CloudDownloadOutlined />}>
                  <CSVLink
                    key="csvData"
                    data={reportCSVData}
                    filename={`pushflow_report_${moment().format('YYYY.MM.DD-hh.mm')}.csv`}
                    target="_blank"
                  >
                    {' '}
                    {t('Report.Controls.DownloadReport')}
                  </CSVLink>
                </Button>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
          domLayout="autoHeight"
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
              },
            ],
          }}
          pinnedBottomRowData={pinnedBottomRowData}
          suppressColumnFilter={false}
        />
      </div>
    </>
  )
}

export default AdvertisersReport
