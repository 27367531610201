import { z } from 'zod';
import { initContract } from '@ts-rest/core';
import { CampaignType, CampaignTypeEnum } from './common/campaign-type';

export interface InventoryData {
  country: string;
  requests: number;
  impressions: number;
  clicks?: number;
  minCpc: number;
  avgCpc: number;
  maxCpc: number;
  avgCtr: number;
}

const c = initContract();

export const inventory = c.router({
  getInventory: {
    method: 'GET',
    path: '/inventory/getInventory',
    query: z.object({
      trafficType: CampaignTypeEnum.default(CampaignType.PUSH),
      period: z.enum(['DAY', 'WEEK']).default('DAY'),
      streams: z.string().array().optional(),
      device: z.enum(['DESKTOP', 'MOBILE']).optional(),
      countries: z.string().array().optional(),
    }),
    responses: {
      200: z.array(
        z.object({
          country: z.string(),
          requests: z.number(),
          impressions: z.number(),
          clicks: z.number().optional(),
          minCpc: z.number(),
          avgCpc: z.number(),
          maxCpc: z.number(),
          avgCtr: z.number(),
        }),
      ),
      500: z.object({
        message: z.string(),
      }),
    },
  },

  getCountryCpcStat: {
    method: 'GET',
    path: '/inventory/getCountryCpcStat',
    query: z.object({
      country: z.string().optional(),
      period: z.enum(['DAY', 'WEEK']).default('DAY'),
      streams: z.string().array().optional(),
      device: z.enum(['DESKTOP', 'MOBILE']).optional(),
      trafficType: CampaignTypeEnum.default(CampaignType.PUSH),
    }),
    responses: {
      200: z.object({
        minCpc: z.number().array(),
        avgCpc: z.number().array(),
        maxCpc: z.number().array(),
        datehour: z.string().array(),
      }),
      500: z.object({
        message: z.string(),
      }),
    },
  },
});
