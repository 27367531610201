import { gql } from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const CREATE_NEW_PASSWORD_MUTATION = gql`
  mutation CreateNewPassword($password: String!, $token: String!) {
    createNewPassword(password: $password, token: $token) {
      isSuccess
    }
  }
`

export function useCreateNewPassword() {
  const [mutate, { data, error }] = useMutation(CREATE_NEW_PASSWORD_MUTATION, {})
  return { mutate, data, error }
}
