import { gql } from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { CAMPAIGN_DATA } from '../Campaign/operations.js'

export const CHANGE_CAMPAIGN_STATUS = gql`
  mutation ChangeCampaignStatus($id: ID!, $status: String!) {
    changeCampaignStatus(id: $id, status: $status) {
      ...CampaignData
    }
  }
  ${CAMPAIGN_DATA}
`

export function useChangeCampaignStatus() {
  const [mutate, { data, error }] = useMutation(CHANGE_CAMPAIGN_STATUS, {})
  return { mutate, data, error }
}
