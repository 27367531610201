import React, { FC, useState } from 'react'
import { Spin } from 'antd'
import { CopyTwoTone } from '@ant-design/icons'
import showNotification from '../../../helpers/showNotification'
import { useDuplicateCampaignMutation } from '../../../containers/campaign/hooks'

export const CopyCampaignIcon: FC<{
  id: number
  refetch: () => void
}> = ({ id, refetch }) => {
  const { mutateAsync: duplicateCampaign } = useDuplicateCampaignMutation()

  const [loading, setLoading] = useState(false)

  return (
    <>
      {loading ? (
        <Spin />
      ) : (
        <CopyTwoTone
          onClick={() => {
            setLoading(true)
            duplicateCampaign(id)
              .then(() => {
                showNotification({
                  type: 'success',
                  message: 'Campaign successfully duplicated',
                })
                refetch()
              })
              .finally(() => setLoading(false))
          }}
        />
      )}
    </>
  )
}
