import { gql } from 'graphql-tag'

export const CAMPAIGN_SUBSTREAM_INFO_DATA = gql`
  fragment campaignSubstreamInfoFullFragment on CampaignSubstreamInfo {
    __typename
    streamUid
    substream
    campaign {
      id
      title
    }
    campaignId
    bid
    status
  }
`
