import { gql } from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { CAMPAIGN_STREAM_INFO_DATA } from '../CampaignStreamInfo/operations.js'

export const CHANGE_CAMPAIGN_STREAM_BID = gql`
  mutation ChangeCampaignStreamBid($campaignId: Int!, $streamUid: String!, $bid: Float!) {
    changeCampaignStreamBid(campaignId: $campaignId, streamUid: $streamUid, bid: $bid) {
      ...campaignStreamInfoFullFragment
    }
  }
  ${CAMPAIGN_STREAM_INFO_DATA}
`

export function useChangeCampaignStreamBid() {
  const [mutate, { data, error }] = useMutation(CHANGE_CAMPAIGN_STREAM_BID, {})
  return { mutate, data, error }
}
