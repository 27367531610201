import { Form, Input, Modal } from 'antd'
import { useCreateInventorySettingsMutation } from '../hooks'
import { useState } from 'react'

export const CreateTemplateModal = ({ gridRef, isOpen, closeModal, setSelected }) => {
  const [confirmLoading, setConfirmLoading] = useState(false)

  const [form] = Form.useForm()

  const createInventorySettings = useCreateInventorySettingsMutation()

  const onFinish = data => {
    form.resetFields()
    setConfirmLoading(true)

    const colState = gridRef.current.columnApi.getColumnState()
    const filterState = gridRef.current.api.getFilterModel()

    createInventorySettings.mutate(
      {
        title: data.title,
        data: JSON.stringify({ colState, filterState }),
      },
      {
        onSettled: data => {
          if (data) {
            setSelected(data.id)
          }

          setConfirmLoading(false)
          closeModal()
        },
      }
    )
  }

  const handleCancel = () => {
    form.resetFields()
    closeModal()
  }

  return (
    <Modal
      title="Save template"
      open={isOpen}
      confirmLoading={confirmLoading}
      onOk={() => form.submit()}
      onCancel={handleCancel}
    >
      <Form onFinish={onFinish} form={form}>
        <Form.Item label="Name" name="title">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}
