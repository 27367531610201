import { gql } from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const RESET_STREAM_SETTINGS = gql`
  mutation ResetStreamSettings($input: ResetStreamSettingsInput!) {
    resetStreamSettings(input: $input) {
      isSuccess
    }
  }
`

export const useResetStreamSettings = (options = {}) => {
  const [mutate, { data, error }] = useMutation(RESET_STREAM_SETTINGS, {
    ...options,
  })

  return { mutate, data, error }
}
