import React, { FC, useEffect, useState } from 'react'
// @ts-ignore
import { useBulkEditUrlsCampaignsMutation } from '@pushflow/server/src/graphql/new/campaigns/bulkEditUrlsCampaigns/operations'
import { Button, Form, Input, Modal, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { InputWithMacroses } from '../../../components/forms/InputWithMacroses/InputWithMacroses'
import showNotification from '../../../helpers/showNotification'
import * as Sentry from '@sentry/react'
import { EditOutlined } from '@ant-design/icons'

export const BulkEditUrlButton: FC<{
  selectedCampaignIds: number[]
  allCampaigns: { id: number; title: string }[]
}> = ({ selectedCampaignIds, allCampaigns }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleCancel = () => {
    setOpen(false)
  }
  const [form] = Form.useForm()
  const url = Form.useWatch('url', form)
  const [isFormValid, setIsFormValid] = useState(false)
  const validateForm = () =>
    form
      .validateFields()
      .then(() => {
        setIsFormValid(true)
        return true
      })
      .catch(() => {
        setIsFormValid(false)
        return false
      })
  useEffect(() => {
    setIsFormValid(true)
  }, [url])
  useEffect(() => {
    if (!open) {
      form.resetFields()
    }
  }, [open])
  const { mutate: bulkEditUrlsCampaigns } = useBulkEditUrlsCampaignsMutation()
  const handle = () => {
    validateForm().then(isValid => {
      if (!isValid) {
        return
      }
      setLoading(true)
      bulkEditUrlsCampaigns({
        variables: {
          input: { ids: selectedCampaignIds, url },
        },
      })
        // @ts-ignore
        .then(result => {
          const updatedCampaigns = result.data?.bulkEditUrlsCampaigns.campaigns || []
          if (updatedCampaigns.length) {
            showNotification({
              type: 'success',
              message: `${t('CampaignsListPage.Success Edit URL Count')} (${updatedCampaigns.length})`,
            })
          }
        })
        // @ts-ignore
        .catch(err => {
          showNotification({ type: 'error', message: err.message })
          if (process.env.NODE_ENV !== 'development') {
            Sentry.captureException(err)
          }
        })
        .finally(() => {
          setLoading(false)
          setOpen(false)
        })
    })
  }
  return (
    <>
      <Button
        disabled={!selectedCampaignIds.length || loading}
        loading={loading}
        icon={<EditOutlined />}
        onClick={() => setOpen(true)}
      >
        {t('CampaignsListPage.Edit URL')}
      </Button>

      <Modal
        open={open}
        title={t('CampaignsListPage.Bulk Edit URL Title')}
        onOk={() => handle()}
        onCancel={() => handleCancel()}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            {t('CampaignsListPage.Cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            disabled={!selectedCampaignIds.length || loading || !isFormValid}
            onClick={() => handle()}
          >
            {t('CampaignsListPage.Bulk Edit Campaign URL Button')}
          </Button>,
        ]}
      >
        <Table
          rowKey="id"
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
            },
            {
              title: 'Title',
              dataIndex: 'title',
              key: 'title',
            },
          ]}
          // @ts-ignore
          dataSource={allCampaigns.filter(campaign => selectedCampaignIds.includes(campaign.id))}
          pagination={false}
          footer={() => (
            <Form form={form} key="form" initialValues={{ url: '' }}>
              <InputWithMacroses
                key="url"
                style={{ marginBottom: 0 }}
                name="url"
                type="url"
                isRequired
                form={form}
                label={t('CampaignsListPage.Bulk Edit Campaign URL Label')}
                rules={[{ required: true }, { type: 'url' }]}
              >
                <Input />
              </InputWithMacroses>
            </Form>
          )}
        />
      </Modal>
    </>
  )
}
