import config from '../../config'
import { getToken } from '../../helpers/jwtHelper'

export const fetchServiceStatus = async () => {
  const res = await fetch(`${config.uiBackendUrl}/rtbConfig/status`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  })

  if (res.status !== 200) {
    throw new Error('Error while fetching service status')
  }

  return res.json()
}

export const stopService = async () => {
  const res = await fetch(`${config.uiBackendUrl}/rtbConfig/stop`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    method: 'POST',
  })

  if (res.status !== 200) {
    throw new Error('Error while stopping service')
  }

  return res.json()
}

export const startService = async () => {
  const res = await fetch(`${config.uiBackendUrl}/rtbConfig/start`, {
    headers: { Authorization: `Bearer ${getToken()}` },
    method: 'POST',
  })

  if (res.status !== 200) {
    throw new Error('Error while starting service')
  }

  return res.json()
}
