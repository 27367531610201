import { Popconfirm, Tooltip } from 'antd'
import React, { FC } from 'react'
import Button from '../Button/Button'
import './PopConfirmButton.sass'
import { useTranslation } from 'react-i18next'

const PopConfirmButton: FC<{
  onClick: () => void
  isOnlyIcon?: boolean
  prompt: string
  placement?: 'top' | 'left' | 'right' | 'bottom'
  okText: string
  icon: React.ReactNode
  cancelText?: string
  tooltip?: string
  children?: React.ReactNode
}> = ({
  children,
  onClick,
  isOnlyIcon,
  prompt,
  placement,
  okText,
  icon,
  cancelText = 'Cancel',
  tooltip = okText,
  ...props
}) => {
  const { t } = useTranslation()

  function renderButton() {
    return (
      <Popconfirm
        title={prompt}
        onConfirm={() => onClick && onClick()}
        okText={okText}
        cancelText={cancelText || 'Cancel'}
        placement={placement || 'bottom'}
      >
        {' '}
        {children || (
          <Button icon={icon} {...props}>
            {!isOnlyIcon && t('Actions.Archive')}
          </Button>
        )}
      </Popconfirm>
    )
  }

  if (isOnlyIcon) {
    return (
      <Tooltip placement="bottom" title={tooltip}>
        {renderButton()}
      </Tooltip>
    )
  }

  return renderButton()
}

export default PopConfirmButton
