import { Alert, Space, Table, Tag } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import StreamFAQAccordion from '../../containers/streams/StreamFAQAccordion'
import { useAppState } from '../../context/AppContext'
import { getProfessionalColumnSearchProps } from '../../helpers/getProfessionalColumnSearchProps'

import { ColumnType } from 'antd/lib/table/interface'
import { DeleteStreamButton } from './components/delete-stream-button'
import { UserRole } from '@pushflow/backend-http-contract'

export const StreamsList = () => {
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)
  const [titleSearch, setTitleSearch] = useState()
  const [idsSearch, setIdsSearch] = useState<number[]>()
  const [userIdsSearch, setUserIdsSearch] = useState()
  const searchInputEl = useRef(null)
  const selectInputEl = useRef(null)
  const { apiClient } = useAppState()
  const { t } = useTranslation()
  const { user } = useAppState()

  const { error, isLoading, data, refetch } = apiClient.stream.getStreamListPaginated.useQuery(
    ['stream-list', pageSize, pageNumber],
    {
      query: {
        filter: { title: titleSearch, userIds: userIdsSearch, ids: idsSearch },
        pagination: { pageNumber, pageSize },
      },
    }
  )

  useEffect(() => {
    refetch()
  }, [pageNumber, pageSize, titleSearch, userIdsSearch, idsSearch])

  if (error?.status === 500) {
    return <Alert type="error" message={error.body.message} />
  }

  let columns: ColumnType<{ id: number; userId: number; title: string; streamType: string }>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      ...getProfessionalColumnSearchProps(setIdsSearch, {
        type: 'multiselect',
        searchInputEl,
        selectInputEl,
        placeholder: 'ID',
        options: [...(data?.body.filterValues.ids || [])].map(id => ({
          label: id,
          value: id,
        })),
      }),
      width: 100,
      render: (text, record) => (
        <Link to={`/streams/${record.id}`}>
          <b>{text}</b>
        </Link>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      // @ts-ignore
      ...getProfessionalColumnSearchProps(setTitleSearch, {
        type: 'text',
        searchInputEl,
        selectInputEl,
      }),
      render: (text, record) => (
        <Link to={`/streams/${record.id}`}>
          <b>{text}</b>
        </Link>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'streamType',
      render: (text, record) => {
        if (record.streamType === 'PUSH') {
          return <Tag color={'purple'}>{text}</Tag>
        } else if (record.streamType === 'LINK') {
          return <Tag color={'blue'}>{text}</Tag>
        } else if (record.streamType === 'VIDEO_PRE_ROLL') {
          return <Tag color={'blue'}>{text}</Tag>
        }

        return <Tag color={'yellow'}>{text}</Tag>
      },
    },
    {
      title: t('Table.Actions'),
      key: 'action',
      render: (_, record) => {
        return (
          <Space size="large">
            <DeleteStreamButton id={record.id} onDeleted={refetch} />
          </Space>
        )
      },
    },
  ]

  if (user && user.roles.includes(UserRole.ADMIN)) {
    columns = [
      columns[0],
      {
        title: 'User ID',
        dataIndex: 'userId',
        render: text => (
          <Link to={`/admin/users/${text}`}>
            <b>{text}</b>
          </Link>
        ),
        // @ts-ignore
        ...getProfessionalColumnSearchProps(setUserIdsSearch, {
          type: 'multiselect',
          options: data?.body.filterValues.userIds.map(id => ({
            label: id,
            value: id,
          })),
          searchInputEl,
          selectInputEl,
        }),
        width: 100,
      },
      ...columns.slice(1),
    ]
  }

  return (
    <>
      <Table<{ id: number; userId: number; title: string; streamType: string }>
        columns={columns}
        dataSource={data?.body.items}
        loading={isLoading}
        rowKey="id"
        size="middle"
        scroll={{ x: true }}
        pagination={{
          pageSize: pageSize,
          total: data?.body.pagination.total,
          pageSizeOptions: [3, 10, 50, 100],
          showSizeChanger: true,
          current: pageNumber + 1,
          onChange(pageNumber, pageSize) {
            setPageNumber(pageNumber - 1)
            setPageSize(pageSize)
          },
        }}
      />

      <StreamFAQAccordion />
    </>
  )
}
