import React, { FC, useState } from 'react'
import { Button, Col, Modal, Spin, Table, TablePaginationConfig } from 'antd'
import { useAppState } from '../../../context/AppContext'
import PageHeader from '../../../components/layout/PageHeader/PageHeader'
import { ColumnsType } from 'antd/lib/table'
import { Player } from 'video-react'
import { Image, Row, Space, Typography } from 'antd'
const { Text } = Typography

interface DataType {
  campaignId: number
  id: number
  title: string | null
}

export const CreativeModerationPage: FC = () => {
  const { apiClient } = useAppState()

  const [open, setOpen] = useState(false)
  const [tableData, setTableData] = useState<DataType[] | null>(null)
  const [modalData, setModalData] = useState<{
    id: number
    bodyImgUrl: string | null
    campaignId: number
    title: string | null
    previewImgUrl: string | null
    videoFileUrl: string | null
    streamIds: string[]
  } | null>(null)
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    pageSize: 20,
    current: 1,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['20', '50'],
  })

  const fetchData = async (pageNumber: number, pageSize: number) => {
    const response = await apiClient.creative.getCreativeForModerationListPaginated.query({
      query: {
        pagination: {
          pageNumber: pageNumber - 1,
          pageSize: pageSize,
        },
      },
    })

    if (response.status === 200) {
      setTableData(response.body.items) // Установка данных
      setPaginationConfig(prev => ({
        ...prev,
        total: response.body.pagination.total, // Общее количество элементов
      }))
    }
  }

  React.useEffect(() => {
    fetchData(paginationConfig.current!, paginationConfig.pageSize!)
  }, [])

  const openModal = (data: {
    id: number
    bodyImgUrl: string | null
    campaignId: number
    title: string | null
    previewImgUrl: string | null
    videoFileUrl: string | null
    streamIds: string[]
  }) => {
    setOpen(true)
    setModalData(data)
  }

  const approveCreative = async (creativeId: number) => {
    await apiClient.creative.approveCreative.mutation({ body: { creativeId } })
    setOpen(false)
    setModalData(null)
    fetchData(paginationConfig.current!, paginationConfig.pageSize!)
  }

  const declineCreative = (creativeId: number) => {
    apiClient.creative.declineCreative.mutation({ body: { creativeId } })
    setOpen(false)
    setModalData(null)
    fetchData(paginationConfig.current!, paginationConfig.pageSize!)
  }

  const handleTableChange = (val: TablePaginationConfig) => {
    setPaginationConfig(val)
    fetchData(val.current!, val.pageSize!)
  }

  if (!tableData) {
    return <Spin size="large" />
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Creative ID',
      dataIndex: 'id',
      key: 'id',
      render: text => <a>{text}</a>,
    },
    {
      title: 'Campaign ID',
      dataIndex: 'campaignId',
      key: 'campaignId',
    },
    {
      title: 'Action',
      render: value => (
        <Button type="primary" onClick={() => openModal(value)}>
          VIEW
        </Button>
      ),
    },
  ]

  return (
    <PageHeader title="Creative Moderation" extra={[]}>
      <Table columns={columns} dataSource={tableData} pagination={paginationConfig} onChange={handleTableChange} />
      <Modal
        title={modalData?.title}
        centered
        open={open}
        onCancel={() => setOpen(false)}
        width={1000}
        footer={[
          <Button key="approve" type="primary" onClick={() => approveCreative(modalData!.id!)}>
            Approve
          </Button>,
          <Button key="decline" danger onClick={() => declineCreative(modalData!.id)}>
            Decline
          </Button>,
        ]}
      >
        <Row gutter={[16, 16]}>
          {modalData?.videoFileUrl && (
            <Col xs={24} sm={12}>
              <Player playsInline width={100} autoPlay muted src={modalData.videoFileUrl} />
            </Col>
          )}

          {modalData?.previewImgUrl && (
            <Col xs={24} sm={12}>
              <Image.PreviewGroup>
                <Image width="50%" src={modalData.previewImgUrl || undefined} style={{ marginBottom: 16 }} />
                {modalData.bodyImgUrl && <Image width="50%" src={modalData.bodyImgUrl} />}
              </Image.PreviewGroup>
            </Col>
          )}
        </Row>

        <Row style={{ marginTop: 24 }}>
          <Col span={24}>
            <Typography.Title level={4}>Stream IDs</Typography.Title>
            <Space direction="vertical">
              {(modalData?.streamIds || []).map((item, index) => (
                <Text key={index} mark>
                  {item}
                </Text>
              ))}
            </Space>
          </Col>
        </Row>
      </Modal>
    </PageHeader>
  )
}
