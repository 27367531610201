export const OS_TYPES = [
  ...new Set([
    'Windows',
    'macOS',
    'macOS X',
    'Mac OS X',
    'Mac OS',
    'iOS',
    'Linux',
    'Android',
    'Xbox',
    'Tizen',
    'android',
    'Ubuntu',
    'Symbian',
    'Fedora',
    'Windows Phone',
    'Chrome OS',
    'Chromecast',
    'Windows Mobile',
    'HarmonyOS',
    'Nintendo',
    'KAIOS',
    'webOS',
    'Raspbian',
    'ChromeOS',
    'Mint',
    'SUSE',
    'Maemo',
    'FreeBSD',
    'OpenBSD',
    'iPhone',
    'Slackware',
    'BlackBerry',
    'Chromium OS',
    'PlayStation',
    'Firefox OS',
  ]),
]
