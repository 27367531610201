import { z } from 'zod';
import { initContract } from '@ts-rest/core';

const c = initContract();

export const scheduleTaskManager = c.router({
  executeTask: {
    method: 'POST',
    path: '/schedule-task-manger.executeTask',
    responses: {
      201: z.object({}),
    },
    body: z.object({
      taskName: z.string(),
    }),
  },

  getTasks: {
    method: 'GET',
    path: '/schedule-task-manger.getTaskNames',
    responses: {
      200: z.object({
        tasks: z
          .object({
            name: z.string(),

            activeCount: z.number(),
            waitingCount: z.number(),
          })
          .array(),
      }),
    },
  },
});
