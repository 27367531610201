// @ts-ignore
import { useCreateOrUpdateCampaign } from '@pushflow/server/src/graphql/new/campaigns/createOrUpdateCampaign/operations'
import * as Sentry from '@sentry/react'
import { Alert, Form, Select, Input as Input2, Radio, Switch, Row, Col, Tooltip, Tag, RadioChangeEvent } from 'antd'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getCountryArray } from '../../common/static/countryCodes'
import { getShortLangArray } from '../../common/static/langCodes'
import { CONNECTION_TYPES } from '../../common/types/connectionTypes'
import { ISP_TYPES } from '../../common/types/ispTypes'
import HelpPopover from '../../components/HelpPopover/HelpPopover'
import Button from '../../components/buttons/Button/Button'
import FormSubHeader from '../../components/forms/FormSubHeader/FormSubHeader'
import Input from '../../components/forms/Input/Input'
import '../../components/forms/Input/Input.sass'
import MultiSelect from '../../components/forms/MultiSelect/MultiSelect'
import { defaultFormLayout, defaultFormLayoutOffset } from '../../helpers/formLayouts'
import removeObjKeys from '../../helpers/removeObjKeys'
import showNotification from '../../helpers/showNotification'
import { TargetDaysAndHoursInput } from './components/TargetDaysAndHoursInput/TargetDaysAndHoursInput'
import { IncludeOrExclude } from '../../components/forms/IncludeOrExclude/IncludeOrExclude'
import { LINK, PUSH, VIDEO_PRE_ROLL } from '../../common/types/campaignTypes'
import { CPC, CPM } from '../../common/types/campaignBidModels.js'
import TimezoneSelect from '../../components/forms/TimezoneSelect/TimezoneSelect'
import { InputWithMacroses } from '../../components/forms/InputWithMacroses/InputWithMacroses'
import SubstreamsInput, { isSubstreamsValid } from './components/SubstreamsInput/SubstreamsInput'
import CampaignEstimateModal from './CampaignEstimate'
import { useAppState } from '../../context/AppContext'
import LabelWithTooltip from '../../components/forms/LabelWithTooltip/LabelWithTooltip'
import { UserSwitchOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { useCampaignTags, useFetchTargets } from './hooks'
import {
  buildCampaignFromFormValues,
  getStringsFormatted,
  getSubscriptionAgeHoursString,
  getSubscriptionAgeParamsFromProps,
  getSubstreamsFormatted,
} from './util'
import { CampaignType, UserRole } from '@pushflow/backend-http-contract'

const { Option } = Select

const OldFieldMessage = (props: { selected: string[] }) => {
  return (
    <div style={{ marginLeft: 7 }}>
      <p style={{ fontSize: 16, margin: 0 }}>
        <b>If you want to update this field, you need to reselect the targets</b>
      </p>
      <p style={{ margin: 0 }}>Currently active targets:</p>
      {props.selected.map(val => (
        <Tag>{val}</Tag>
      ))}
    </div>
  )
}

const OldFieldAlert = (props: { selected: string[] }) => {
  return (
    <Row style={{ marginBottom: 10 }}>
      <Col xs={defaultFormLayout.labelCol.xs} sm={defaultFormLayout.labelCol.sm}></Col>
      <Col xs={defaultFormLayout.wrapperCol.xs} sm={defaultFormLayout.wrapperCol.sm}>
        <Alert message={<OldFieldMessage selected={props.selected} />} type="warning" showIcon />
      </Col>
    </Row>
  )
}

const countries = getCountryArray()

interface ICampaign {
  id?: number
  campaignType: CampaignType
  subscriptionAgeFrom: number
  subscriptionAgeTo: number
  subscriptionAgeUnitsChoice: 'hours' | 'days'
  countryCode: string[]
  contryCodeInclude: boolean
  scheduleTimezone: string
  device: string[]
  deviceOld: string[]
  os: string[]
  osOld: string[]
  osVersion: string[]
  osVersionOld: string[]
  browser: string[]
  browserOld: string[]
  browserVersion: string[]
  browserVersionOld: string[]
  userAuditoryConditions: { conditionType: string; threshold: number; auditoryId: string }[]
  streams: string[]
  substreams: string[]
  domains: string[]
  useragents: string[]
  price: number
  targetCpa: number | string
  ip: string[]
  bidType: string
  targetDaysAndHours: { day: number; hour: number }[]
  auditories: { id: string }[]
  blacklists: { id: string }[]
  whitelists: { id: string }[]
}

const CampaignSettingsForm: FC<{
  values: ICampaign
  // type: string
  refetch: () => void
}> = props => {
  const queryClient = useQueryClient()

  const { user, apiClient } = useAppState()
  const initialValues = useMemo(() => {
    if (props.values) {
      const countryCodeInitialValue = {
        countryCode: props.values.countryCode
          ? props.values.countryCode.map(code => {
              const country = countries.find(e => code === e.value)
              return country ? country.value + ' ' + country.name : code
            })
          : [],
      }

      const validTargetDaysAndHours = (props.values.targetDaysAndHours || []).map(({ day, hour }) => ({
        day: day,
        hour: hour,
      }))

      const { subscriptionAgeFrom, subscriptionAgeTo } = getSubscriptionAgeParamsFromProps(props.values)

      return {
        ...removeObjKeys(props.values),
        ...countryCodeInitialValue,
        id: props.values.id || undefined,
        scheduleTimezone: props.values.scheduleTimezone,
        targetDaysAndHours: validTargetDaysAndHours,
        subscriptionAgeFrom,
        subscriptionAgeTo,
        os: props.values.os ?? [],
        browser: props.values.browser ?? [],
        // type: props.type || props.values,
        campaignType: props.values.campaignType,
        bidType: props.values.bidType,
        auditories: props.values.auditories ? props.values.auditories.map(a => +a.id) : [],
        blacklists: props.values.blacklists ? props.values.blacklists.map(l => +l.id) : [],
        whitelists: props.values.whitelists ? props.values.whitelists.map(l => +l.id) : [],
        streams: props.values.streams,
        substreams: props.values.substreams ? props.values.substreams.join('\n') : '',
        domains: props.values.domains ? props.values.domains.join('\n') : '',
        useragents: props.values.useragents ? props.values.useragents.join('\n') : '',
        price: props.values.price ? props.values.price.toString() : '',
        targetCpa: props.values.targetCpa && props.values.targetCpa !== '' ? props.values.targetCpa.toString() : null,
        ip: props.values.ip ? props.values.ip.join('\n') : [],
        // TODO: refactor to handle many condition or only one
        maxClickedThreshold: props.values.userAuditoryConditions.find(c => c.conditionType === 'CLICKS')
          ? props.values.userAuditoryConditions.find(c => c.conditionType === 'CLICKS')?.threshold
          : 0,
        maxClickedAuditory: props.values.userAuditoryConditions.find(c => c.conditionType === 'CLICKS')
          ? props.values.userAuditoryConditions.find(c => c.conditionType === 'CLICKS')?.auditoryId
          : '',
        maxShownThreshold: props.values.userAuditoryConditions.find(c => c.conditionType === 'SHOWS')
          ? props.values.userAuditoryConditions.find(c => c.conditionType === 'SHOWS')?.threshold
          : 0,
        maxShownAuditory: props.values.userAuditoryConditions.find(c => c.conditionType === 'SHOWS')
          ? props.values.userAuditoryConditions.find(c => c.conditionType === 'SHOWS')?.auditoryId
          : '',
      }
    }

    return {
      id: undefined,
      userId: user ? +user.id : null,
      subscriptionAgeUnitsChoice: 'hours',
      subscriptionAgeFrom: 0,
      subscriptionAgeTo: 0,
      campaignType: PUSH,
      bidType: CPC,
      targetDaysAndHours: [],
      scheduleTimezone: '+0',
      langInclude: true,
      countryCodeInclude: true,
      deviceInclude: true,
      osInclude: true,
      osVersionInclude: true,
      browserInclude: true,
      browserVersionInclude: true,
      browserVersionOp: 'EQUAL',
      connectionTypeInclude: true,
      ispInclude: true,
      streamsInclude: true,
      substreamsInclude: true,
      domainsInclude: true,
      useragentsInclude: true,
      auditoriesInclude: true,
    }
  }, [props.values, user])

  const { data: getCampaignsTagsResult } = useCampaignTags()
  const campaignsTags = getCampaignsTagsResult || []
  const [form] = Form.useForm()
  const campaignType = Form.useWatch('campaignType', form)

  const { data: streams, refetch: refetchStreams } = apiClient.stream.getStreamAutocomplete.useQuery(
    ['streamsAutocomplete'],
    {
      query: {
        streamType: campaignType,
      },
    }
  )
  const { data: blackWhiteLists } = apiClient.blackWhiteList.getListListPaginated.useQuery(['black-white-lists'], {
    query: {
      limit: 100,
      offset: 0,
    },
  })

  const usersQuery =
    user && user.roles.includes(UserRole.ADMIN)
      ? apiClient.user.getUserListPaginated.useQuery(['user-autocomplete'], {
          query: {
            pagination: { limit: 100, offset: 0 },
            filter: {
              roles: [UserRole.EXTERNAL_ADVERTISER, UserRole.INTERNAL_ADVERTISER],
            },
          },
        })
      : { data: { body: { items: [] } } }
  const { body: usersAutoComplete } = usersQuery.data || {}

  const campaignSettingsValues = apiClient.campaign.getCampaignSettingValues.useQuery(['settings-values'])

  // const selectedStreams = Form.useWatch('streams', form)

  // const allStreamsWithClientHints = (selectedStreams || []).every(stream => {
  //   const streamFromList = streams?.getStreamAutoComplete?.streams.find(s => s.uid === stream)
  //   return streamFromList?.hasClientHints
  // })

  const targets = useFetchTargets()

  const selectedOs = Form.useWatch('os', form) ?? []
  const selectedBrowser = Form.useWatch('browser', form) ?? []

  // const osVersion = Form.useWatch('osVersion', form)

  const maxDailyBudget = Form.useWatch('maxDailyBudget', form)
  const maxTotalBudget = Form.useWatch('maxTotalBudget', form)
  const subscriptionAgeEnabled = Form.useWatch('subscriptionAgeEnabled', form)
  const subscriptionAgeFrom = Form.useWatch('subscriptionAgeFrom', form)
  const subscriptionAgeTo = Form.useWatch('subscriptionAgeTo', form)

  const subAgeUnits = Form.useWatch('subscriptionAgeUnitsChoice', form)

  const isMaxDailyBudgetSet = !!maxDailyBudget && !!parseFloat(maxDailyBudget)
  const isMaxTotalBudgetSet = !!maxTotalBudget && !!parseFloat(maxTotalBudget)
  const isBudgetsLimitValid = isMaxDailyBudgetSet || isMaxTotalBudgetSet

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { mutate: saveCampaign } = useCreateOrUpdateCampaign({
    onCompleted: () => {
      if (initialValues.id !== null) {
        queryClient.invalidateQueries({ queryKey: ['campaign', String(initialValues.id), 'debugLink'] })
      }
    },
  })

  const [isFormValid, setIsFormValid] = useState(false)
  const [isFormTouched, setIsFormTouched] = useState(false)
  const [formInvalidReason, setFormInvalidReason] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const validateSubage = (
    subscriptionAgeEnabled: boolean,
    subscriptionAgeFrom: number,
    subscriptionAgeTo: number
  ): {
    reason: string
    status: boolean
  } => {
    if (subscriptionAgeEnabled && subscriptionAgeFrom > subscriptionAgeTo) {
      return { reason: 'Subscription period "from" should be less than "to"', status: false }
    }
    if (subscriptionAgeEnabled && subscriptionAgeFrom === 0 && subscriptionAgeTo === 0) {
      return { reason: 'Subscription period enabled but not set any values', status: false }
    }
    if (subscriptionAgeEnabled && subscriptionAgeFrom === subscriptionAgeTo) {
      return { reason: 'Subscription period "from" and "to" should not be equal', status: false }
    }
    return { reason: '', status: true }
  }

  const validateForm = () => {
    const isFieldsValid = form.getFieldsError().every(item => {
      return item.errors.length === 0
    })
    let substreamsValid = true
    if (typeof form.getFieldValue('substreams') === 'string') {
      substreamsValid = isSubstreamsValid(getStringsFormatted(form.getFieldValue('substreams')))
    }
    const subageCheck = validateSubage(subscriptionAgeEnabled, subscriptionAgeFrom, subscriptionAgeTo)
    const isFormValid = isBudgetsLimitValid && isFieldsValid && substreamsValid && subageCheck.status
    if (!isFormValid) {
      if (!subageCheck.status) {
        setFormInvalidReason(subageCheck.reason)
      } else {
        setFormInvalidReason('Some fields are invalid')
      }
    }
    setIsFormValid(isFormValid)
    setIsFormTouched(true)
  }

  function submit(formValues: Omit<ICampaign, 'substreams'> & { substreams: string }) {
    setSubmitting(true)

    const campaignData = buildCampaignFromFormValues({ formValues, initialValues })

    saveCampaign({
      variables: campaignData,
    })
      .then((result: any) => {
        if (result.errors) return

        showNotification({ type: 'success', message: 'Saved' })

        if (!initialValues.id) {
          const id = result.data.createOrUpdateCampaign.id
          navigate(`/campaign/${id}`)
        }
        if (props.refetch) props.refetch()
      })
      .catch((err: any) => {
        showNotification({ type: 'error', message: 'Error: ' + err.message })

        if (process.env.NODE_ENV !== 'development') Sentry.captureException(err)
      })
      .finally(() => {
        setSubmitting(false)

        form.setFields([{ name: 'substreams', value: getSubstreamsFormatted(formValues.substreams).join('\n') }])
      })
  }

  const handleCampaignTypeChange = (e: RadioChangeEvent) => {
    if (e.target.value === PUSH) {
      form.setFields([{ name: 'bidType', value: CPC }])
    } else if (e.target.value === LINK) {
      form.setFields([{ name: 'bidType', value: CPM }])
    }
  }

  useEffect(() => {
    refetchStreams()
  }, [campaignType])

  // TODO: do something later with this
  // useEffect(() => {
  //   if (
  //     osVersion &&
  //     osVersion.length > 0 &&
  //     selectedStreams &&
  //     selectedStreams.length > 0 &&
  //     streams?.getStreamAutoComplete?.streams.length > 0 &&
  //     !allStreamsWithClientHints
  //   ) {
  //     showNotification({
  //       type: 'warning',
  //       message: t('Campaign.Os Version Warning'),
  //       description: t('Campaign.Os Version Warning Description'),
  //     })
  //   }
  // }, [osVersion, selectedStreams, streams, allStreamsWithClientHints])

  return (
    <>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={submit}
        onFinishFailed={validateForm}
        onFieldsChange={validateForm}
        {...defaultFormLayout}
      >
        {user && user.roles.includes(UserRole.ADMIN) ? (
          <Form.Item {...defaultFormLayout} label="Owner" name="userId">
            <Select
              options={usersAutoComplete?.items.map(u => ({
                value: +u.id,
                label: user && user.id === u.id ? 'Me' : `ID: ${u.id} | ${u.email}`,
              }))}
            />
          </Form.Item>
        ) : (
          <></>
        )}
        <Form.Item {...defaultFormLayout} label="Campaign type" name="campaignType" required>
          <Radio.Group onChange={handleCampaignTypeChange} disabled={!!initialValues.id}>
            <Radio.Button value={PUSH}>Push</Radio.Button>
            <Radio.Button value={LINK}>Link</Radio.Button>
            <Radio.Button value={VIDEO_PRE_ROLL}>Video</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Input label={t('Campaign.Title')} name="title" isRequired />

        <InputWithMacroses label={t('Campaign.Url')} isRequired type="url" name="url" form={form} />

        <Form.Item
          name="tags"
          label={<LabelWithTooltip label={t('Campaign.Tags')} labelTooltip={t('Campaign.Tags Help')} />}
        >
          <Select
            mode="tags"
            allowClear
            options={campaignsTags.map(tag => ({
              label: tag,
              value: tag,
            }))}
            // span={3}
          />
        </Form.Item>

        <MultiSelect
          title={t('Campaign.Streams')}
          decoratorTitle="streams"
          type="array"
          extra={<IncludeOrExclude name="streamsInclude" form={form} />}
          required
        >
          {streams?.body.items
            ? streams.body.items
                .filter(s => s.streamType === campaignType)
                .map(stream => (
                  <Option value={stream.uid} key={stream.id}>
                    {stream.title}{' '}
                    <span style={{ opacity: 0.6 }}>
                      ID: {stream.id}{' '}
                      {stream.hasClientHints ? (
                        <Tooltip title="Client Hits Enabled">
                          <UserSwitchOutlined
                            color="#00ff00"
                            style={{
                              color: 'green',
                              fontWeight: 'bold',
                            }}
                          />
                        </Tooltip>
                      ) : null}
                    </span>
                  </Option>
                ))
            : null}
        </MultiSelect>

        <SubstreamsInput form={form} name="substreams" autoSize={{ minRows: 4, maxRows: 12 }} />

        <MultiSelect decoratorTitle={'whitelists'} title={t('CampaignSettingsForm.Whitelists')} type={'array'}>
          {blackWhiteLists?.body.items
            ? blackWhiteLists.body.items.map(list => (
                <Option value={list.id} key={list.id}>
                  {list.title}{' '}
                  <span style={{ opacity: 0.6 }}>
                    Count: {list.items.length}, ID:{list.id}
                  </span>
                </Option>
              ))
            : null}
        </MultiSelect>

        <MultiSelect decoratorTitle={'blacklists'} title={t('CampaignSettingsForm.Blacklists')} type={'array'}>
          {blackWhiteLists?.body.items
            ? blackWhiteLists.body.items.map(list => (
                <Option value={list.id} key={list.id}>
                  {list.title}{' '}
                  <span style={{ opacity: 0.6 }}>
                    Count: {list.items.length}, ID:{list.id}
                  </span>
                </Option>
              ))
            : null}
        </MultiSelect>

        <MultiSelect
          title={t('Campaign.UserAuditories')}
          decoratorTitle="auditories"
          type="array"
          extra={<IncludeOrExclude name="auditoriesInclude" form={form} />}
        >
          {campaignSettingsValues.data?.body?.userAuditories.map(userAuditory => (
            <Option value={userAuditory.id} key={userAuditory.id}>
              {userAuditory.title} <span style={{ opacity: 0.6 }}>ID: {userAuditory.id}</span>
            </Option>
          ))}
        </MultiSelect>

        <Form.Item
          name="domains"
          label={t('Campaign.Domains')}
          hidden={campaignType === LINK}
          extra={<IncludeOrExclude name="domainsInclude" form={form} />}
        >
          <Input2.TextArea wrap="off" name="domains" autoSize={{ minRows: 4, maxRows: 12 }} />
        </Form.Item>

        <Form.Item
          name="useragents"
          label={t('Campaign.Useragents')}
          extra={<IncludeOrExclude name="useragentsInclude" form={form} />}
        >
          <Input2.TextArea wrap="off" name="domains" autoSize={{ minRows: 4, maxRows: 12 }} />
        </Form.Item>

        <MultiSelect
          decoratorTitle={'countryCode'}
          title={t('Campaign.Countries')}
          type={'array'}
          extra={<IncludeOrExclude name="countryCodeInclude" form={form} />}
        >
          {countries.map(country => (
            <Option value={country.value + ' ' + country.name} key={country.value}>
              {country.name} / {country.value}
            </Option>
          ))}
        </MultiSelect>

        <MultiSelect
          decoratorTitle={'lang'}
          title={t('Campaign.Languages')}
          type={'array'}
          extra={<IncludeOrExclude name="langInclude" form={form} />}
          filterFn={(input, option) => {
            const value = option.key.split(':')[0]
            const name = option.key.split(':')[1]
            return value.toLowerCase().includes(input.toLowerCase()) || name.toLowerCase().includes(input.toLowerCase())
          }}
        >
          {getShortLangArray().map(lang => (
            <Option value={lang.value} key={`${lang.value}:${lang.name}`}>
              {lang.name} / {lang.value}
            </Option>
          ))}
        </MultiSelect>

        {props.values && props.values.deviceOld.length > 0 ? <OldFieldAlert selected={props.values.deviceOld} /> : null}

        <MultiSelect
          decoratorTitle={'device'}
          title={t('Campaign.Devices')}
          type={'array'}
          extra={<IncludeOrExclude name="deviceInclude" form={form} />}
          options={targets.data?.device.map(({ id, label }) => ({ value: id, title: label, label }))}
        />

        {props.values && props.values.osOld.length > 0 ? <OldFieldAlert selected={props.values.osOld} /> : null}

        <MultiSelect
          decoratorTitle={'os'}
          title={t('Campaign.OS')}
          type={'array'}
          extra={<IncludeOrExclude name="osInclude" form={form} />}
          options={targets.data?.os.map(({ id, label }) => ({ value: id, title: label, label }))}
        />

        {props.values && props.values.osVersionOld.length > 0 ? (
          <OldFieldAlert selected={props.values.osVersionOld} />
        ) : null}

        <MultiSelect
          decoratorTitle={'osVersion'}
          title={t('Campaign.OS Version')}
          type={'array'}
          extra={<IncludeOrExclude name="osVersionInclude" form={form} />}
          options={targets.data?.os
            .filter(({ id }) => selectedOs.includes(id))
            .flatMap(os =>
              os.versions.map(version => ({
                value: version.id,
                title: `${os.label} ${version.label}`,
                label: `${os.label} ${version.label}`,
              }))
            )}
        />

        {props.values && props.values.browserOld.length > 0 ? (
          <OldFieldAlert selected={props.values.browserOld} />
        ) : null}

        <MultiSelect
          decoratorTitle={'browser'}
          title={t('Campaign.Browsers')}
          type={'array'}
          extra={<IncludeOrExclude name="browserInclude" form={form} />}
          options={targets.data?.browser.map(({ id, label }) => ({ value: id, title: label, label }))}
        />

        {props.values && props.values.browserVersionOld.length > 0 ? (
          <OldFieldAlert selected={props.values.browserVersionOld} />
        ) : null}

        <MultiSelect
          decoratorTitle={'browserVersion'}
          title={t('Campaign.Browser Version')}
          type={'array'}
          extra={<IncludeOrExclude name="browserVersionInclude" form={form} />}
          options={targets.data?.browser
            .filter(({ id }) => selectedBrowser.includes(id))
            .flatMap(browser =>
              browser.versions.map(version => ({
                value: version.id,
                title: `${browser.label} ${version.label}`,
                label: `${browser.label} ${version.label}`,
              }))
            )}
        />

        <MultiSelect
          decoratorTitle={'connectionType'}
          title={t('Campaign.connectionType')}
          type={'array'}
          extra={<IncludeOrExclude name="connectionTypeInclude" form={form} />}
        >
          {CONNECTION_TYPES.map((type, i) => (
            <Option value={type} key={i}>
              {type}
            </Option>
          ))}
        </MultiSelect>

        <MultiSelect
          decoratorTitle={'isp'}
          title={t('Campaign.isp')}
          type={'array'}
          extra={<IncludeOrExclude name="ispInclude" form={form} />}
        >
          {ISP_TYPES.map((ispType, i) => (
            <Option value={ispType} key={i}>
              {ispType}
            </Option>
          ))}
        </MultiSelect>

        <Form.Item
          label={<>{t('CampaignSettingsForm.Subscription Period.label')} </>}
          hidden={campaignType !== CampaignType.PUSH}
          tooltip={'Enter integer values for subscription period. Floats will be rounded to nearest integer.'}
          help={
            subAgeUnits === 'days'
              ? `Will be converted as "From ${getSubscriptionAgeHoursString({
                  subscriptionAgeFrom,
                  subscriptionAgeTo,
                  subscriptionAgeUnitsChoice: subAgeUnits,
                })} hours"`
              : undefined
          }
          style={{ marginBottom: 0 }}
        >
          <span
            style={{
              display: 'inline-block',
              margin: '0 8px',
              lineHeight: '32px',
            }}
          >
            {t('CampaignSettingsForm.Subscription Period.inputLabel').split('%')[0]}
          </span>
          <Input
            name="subscriptionAgeFrom"
            isNumber
            style={{ display: 'inline-block' }}
            min={0}
            step={1}
            precision={0}
            disabled={!subscriptionAgeEnabled}
          />
          <span>&nbsp;{subAgeUnits}</span>
          <span
            style={{
              display: 'inline-block',
              margin: '0 8px',
              lineHeight: '32px',
            }}
          >
            {t('CampaignSettingsForm.Subscription Period.inputLabel').split('%')[1]}
          </span>
          <Input
            name="subscriptionAgeTo"
            isNumber
            style={{ display: 'inline-block' }}
            min={0}
            step={1}
            precision={0}
            disabled={!subscriptionAgeEnabled}
          />
          <span>&nbsp;{subAgeUnits}</span>

          <Row gutter={24} style={{ marginBottom: '10px' }}>
            <Col span={12}>
              <Form.Item name="subscriptionAgeUnitsChoice" noStyle>
                <Radio.Group disabled={!subscriptionAgeEnabled}>
                  <Radio.Button value="days">Days</Radio.Button>
                  <Radio.Button value="hours">Hours</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="subscriptionAgeEnabled" valuePropName="checked" noStyle>
                <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <FormSubHeader title={<>{t('CampaignSettingsForm.Campaign limits')} </>} layout={defaultFormLayoutOffset} />
        <Form.Item
          label={'Max shown per day'}
          tooltip={t('CampaignSettingsForm.MaxShownDescription')}
          style={{ marginBottom: 0 }}
        >
          <Input style={{ display: 'inline-block', marginRight: 8 }} name="maxShown" isNumber />
        </Form.Item>

        <Form.Item
          label={'Max clicked per day'}
          tooltip={t('CampaignSettingsForm.MaxClickedDescription')}
          style={{ marginBottom: 0 }}
        >
          <Input style={{ display: 'inline-block', marginRight: 8 }} name="maxClicked" isNumber />
        </Form.Item>

        <FormSubHeader title={<>User Overall Limits</>} layout={defaultFormLayoutOffset} />

        <Form.Item label={'Max shown per user'} style={{ marginBottom: 0 }}>
          <Input style={{ display: 'inline-block', marginRight: 8 }} name="maxShownUser" isNumber />
        </Form.Item>

        <Form.Item label={'Max clicked per user'} style={{ marginBottom: 0 }}>
          <Input style={{ display: 'inline-block', marginRight: 8 }} name="maxClickedUser" isNumber />
        </Form.Item>

        <FormSubHeader title={<>User Periodic Limits</>} layout={defaultFormLayoutOffset} />
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label={'Max shown per user'} style={{ marginBottom: 0 }} labelCol={{ span: 12 }}>
              <Input style={{ display: 'inline-block' }} name="maxShownUserAmtPeriod" isNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'maxShownUserPeriod'} label={'for last N days'} labelCol={{ span: 12 }}>
              <Select>
                {[0, 1, 2, 7].map(days => {
                  const hours = days * 24
                  return (
                    <Option value={hours} key={hours}>
                      {days === 0 ? '-' : `${days} days (${hours} hours)`}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label={'Max clicked per user'} style={{ marginBottom: 0 }} labelCol={{ span: 12 }}>
              <Input style={{ display: 'inline-block' }} name="maxClickedUserAmtPeriod" isNumber />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'maxClickedUserPeriod'} label={'for last N days'} labelCol={{ span: 12 }}>
              <Select>
                {[0, 1, 2, 7].map(days => {
                  const hours = days * 24
                  return (
                    <Option value={hours} key={hours}>
                      {days === 0 ? '-' : `${days} days (${hours} hours)`}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <FormSubHeader title={'Budget limits'} layout={defaultFormLayoutOffset} />

        <Form.Item
          label={t('CampaignSettingsForm.maxDailyBudget')}
          tooltip={t('CampaignSettingsForm.MaxDailyBudgetDescription')}
          style={{ marginBottom: 0 }}
        >
          <Input style={{ display: 'inline-block', marginRight: 8 }} name="maxDailyBudget" isNumber />
        </Form.Item>

        <Form.Item
          label={t('CampaignSettingsForm.maxTotalBudget')}
          tooltip={t('CampaignSettingsForm.MaxTotalBudgetDescription')}
          style={{ marginBottom: 0 }}
        >
          <Input style={{ display: 'inline-block', marginRight: 8 }} name="maxTotalBudget" isNumber />
        </Form.Item>

        {!isBudgetsLimitValid && (
          <Form.Item {...defaultFormLayoutOffset}>
            <Alert message="One of the budgets limits should be set" type="error" />
          </Form.Item>
        )}
        <FormSubHeader title={'Auditories conditions'} layout={defaultFormLayoutOffset} />

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={t('CampaignSettingsForm.AuditoriesConditions.MaxClickedThreshold')}
              tooltip={t('CampaignSettingsForm.AuditoriesConditions.MaxClickedThresholdDescription')}
              labelCol={{ span: 12 }}
              name={'maxClickedThreshold'}
            >
              <Input className="" name="maxClickedThreshold" isNumber />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="maxClickedAuditory">
              <Select style={{ width: '100%' }}>
                {campaignSettingsValues.data?.body.userAuditories.map(auditory => (
                  <Option value={auditory.id} key={auditory.id}>
                    {auditory.title}
                  </Option>
                ))}
                <Option value={''}>None</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {campaignType === PUSH && (
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label={t('CampaignSettingsForm.AuditoriesConditions.MaxShownThreshold')}
                tooltip={t('CampaignSettingsForm.AuditoriesConditions.MaxShownThresholdDescription')}
                labelCol={{ span: 12 }}
              >
                <Input name="maxShownThreshold" isNumber />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="maxShownAuditory">
                <Select style={{ width: '100%' }}>
                  {campaignSettingsValues.data?.body.userAuditories.map(auditory => (
                    <Option value={auditory.id} key={auditory.id}>
                      {auditory.title}
                    </Option>
                  ))}
                  <Option value={''}>None</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <FormSubHeader
          title={
            <>
              {t('CampaignSettingsForm.Time schedule')}{' '}
              <HelpPopover content={t('CampaignSettingsForm.Time schedule description')} />
            </>
          }
          layout={defaultFormLayoutOffset}
        />

        <TimezoneSelect title={t('CampaignSettingsForm.Timezone')} name="scheduleTimezone" isVertical={false} />

        <Form.Item label={t('Campaign.Target Days And Hours')} name="targetDaysAndHours">
          <TargetDaysAndHoursInput form={form} />
        </Form.Item>

        <FormSubHeader title={<>{t('CampaignSettingsForm.Price')} </>} layout={defaultFormLayoutOffset} />

        <Form.Item {...defaultFormLayout} name="bidType" label={t('Campaign.BidModel')}>
          <Radio.Group>
            <Radio.Button value={CPC}>{CPC}</Radio.Button>
            <Radio.Button value={CPM} disabled={campaignType === PUSH}>
              {CPM}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item label={t('CampaignSettingsForm.Price')} style={{ marginBottom: 0 }}>
          <Input isRequired style={{ display: 'inline-block', marginRight: 8 }} name="price" />
        </Form.Item>

        <Form.Item label={`Target CPA`} style={{ marginBottom: 0 }}>
          <Input style={{ display: 'inline-block', marginRight: 8 }} name="targetCpa" />
        </Form.Item>

        {!isFormValid && isFormTouched && (
          <Form.Item {...defaultFormLayoutOffset}>
            <Alert message={formInvalidReason} type="error" />
          </Form.Item>
        )}
        <Form.Item {...defaultFormLayoutOffset}>
          <Button type="primary" htmlType="submit" loading={submitting} disabled={!isFormValid && isFormTouched}>
            {t('Actions.Save')}
          </Button>
        </Form.Item>
      </Form>
      <CampaignEstimateModal form={form} initialValues={initialValues} />
    </>
  )
}

export default CampaignSettingsForm
