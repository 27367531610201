import { CampaignStatus } from '@pushflow/backend-http-contract'
// @ts-ignore
import { useChangeCampaignStatus } from '@pushflow/server/src/graphql/new/campaigns/changeCampaignStatus/operations'
import { QueryClient } from '@tanstack/react-query'
import React, { FC } from 'react'
import showNotification from '../../../helpers/showNotification'
import { PauseCircleTwoTone, PlayCircleTwoTone } from '@ant-design/icons'

export const ChangeStatusIcon: FC<{
  id: number
  status: CampaignStatus
  queryClient: QueryClient
}> = ({ id, status, queryClient }) => {
  const { mutate: changeCampaignStatus } = useChangeCampaignStatus()

  const updateStatus = () => {
    changeCampaignStatus({
      variables: {
        id,
        status: status === CampaignStatus.WORKING ? CampaignStatus.PAUSED : CampaignStatus.WORKING,
      },
    }).then(() => {
      queryClient.invalidateQueries({ queryKey: ['campaigns'] })
      showNotification({
        type: 'success',
        message: `Campaign ${
          status === CampaignStatus.WORKING ? CampaignStatus.PAUSED.toLowerCase() : CampaignStatus.WORKING.toLowerCase()
        }`,
      })
    })
  }
  return status === CampaignStatus.WORKING ? (
    <PauseCircleTwoTone onClick={() => updateStatus()} twoToneColor="orange" />
  ) : (
    <PlayCircleTwoTone onClick={() => updateStatus()} />
  )
}
