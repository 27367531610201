import { Button, Space, Spin, Table, TableProps } from 'antd'
import React, { FC, useState, useMemo } from 'react'
import { useAppState } from '../../../context/AppContext'
import { HistoryOutlined } from '@ant-design/icons'
import PageListHeader from '../../../components/layout/PageListHeader/PageListHeader'
import config from '../../../config'
import showNotification from '../../../helpers/showNotification'

interface DataType {
  key: string
  name: string
  activeCount: number
  waitingCount: number
}

export const ScheduleTaskPage: FC = () => {
  const { apiClient } = useAppState()
  const [isTaskAdding, setIsTaskAdding] = useState<{ [key: string]: boolean }>({})

  const { isLoading, data } = apiClient.scheduleTaskManager.getTasks.useQuery(['tasks'], undefined, {
    queryKey: ['tasks'],
    refetchInterval: 3000,
  })

  const startTask = async (taskName: string) => {
    setIsTaskAdding(prev => ({ ...prev, [taskName]: true }))
    try {
      await apiClient.scheduleTaskManager.executeTask.mutation({
        body: { taskName },
      })
      showNotification({ type: 'success', message: `Task '${taskName}' added` })
    } finally {
      setIsTaskAdding(prev => ({ ...prev, [taskName]: false }))
    }
  }

  const columns: TableProps<DataType>['columns'] = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Active tasks',
        dataIndex: 'activeCount',
        key: 'activeCount',
      },
      {
        title: 'Waiting tasks',
        dataIndex: 'waitingCount',
        key: 'waitingCount',
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <Button onClick={() => startTask(record.name)} loading={isTaskAdding[record.name]}>
              Add task to queue
            </Button>
          </Space>
        ),
      },
    ],
    [isTaskAdding]
  )

  const dataSource: DataType[] = useMemo(
    () =>
      data?.body?.tasks.map(i => ({
        key: i.name,
        name: i.name,
        activeCount: i.activeCount,
        waitingCount: i.waitingCount,
      })) || [],
    [data]
  )

  if (isLoading) {
    return <Spin size="large" />
  }

  return (
    <>
      <PageListHeader
        title="Schedule task"
        buttons={[
          {
            link: `${config.uiBackendUrl}/queues`,
            type: 'primary',
            icon: <HistoryOutlined />,
            title: 'Bull dashboard',
          },
        ]}
      />
      <Table<DataType> columns={columns} dataSource={dataSource} pagination={false} />
    </>
  )
}
