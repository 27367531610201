import { Col, Form, Row } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Input from '../components/forms/Input/Input'
import Submit from '../components/forms/Submit/Submit'
import Title from '../components/layout/Title/Title'
import { useCreateNewPassword } from '@pushflow/server/src/graphql/new/auth/createNewPassword/operations'
import { useResetPassword } from '@pushflow/server/src/graphql/new/auth/resetPassword/operations'
import showNotification from '../helpers/showNotification'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
}

export const ResetPasswordPage = () => {
  const { mutate: ResetPasswordMutation } = useResetPassword()
  const [email, setEmail] = useState()
  let { token } = useParams()
  const { t } = useTranslation()

  const onFinish = async values => {
    const { errors, data } = await ResetPasswordMutation({ variables: values })
    if (errors || !data.resetPassword || !data.resetPassword.isSuccess) return
    setEmail(values.email)
  }

  if (token) return <CheckToken token={token} />

  if (email)
    return (
      <Row justify="center">
        <Col span={10}>
          <Title level={3} style={{ marginBottom: 0 }}>
            {t('ResetPasswordPage.The password recovery link has been sent to')} {email}.
          </Title>
          <p style={{ marginTop: 16 }}>{t("ResetPasswordPage.If you don't receive the email")}</p>
        </Col>
      </Row>
    )

  return (
    <Row justify="center">
      <Col span={8}>
        <Form {...layout} onFinish={onFinish}>
          <Form.Item {...tailLayout}>
            <Title level={3} style={{ marginBottom: 0 }}>
              {t('ResetPasswordPage.Reset title')}
            </Title>
          </Form.Item>
          <Input name="email" label={t('Common.Email')} type="email" isRequired />
          <Submit label={t('Actions.Submit')} {...tailLayout} />
          <Form.Item {...tailLayout}>
            <Link to="/login">{t('ResetPasswordPage.Back to login')}</Link>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

const CheckToken = ({ token }) => {
  const { t } = useTranslation()
  let navigate = useNavigate()

  const { mutate: createNewPassword } = useCreateNewPassword()

  const onFinish = async values => {
    if (values.password !== values.passwordRepeat) {
      showNotification({ type: 'error', message: t("ResetPasswordPage.Passwords don't match") })
      return
    }
    const { errors, data } = await createNewPassword({
      variables: { password: values.password, token },
    })

    if (errors || !data.createNewPassword || !data.createNewPassword.isSuccess) {
      showNotification({ type: 'error', message: 'Failed to update your password' })
      return
    }
    showNotification({ type: 'success', message: 'Password has been updated' })

    navigate('/login')
  }

  return (
    <Row justify="center">
      <Col span={12}>
        <Form {...layout} onFinish={onFinish}>
          <Form.Item {...tailLayout}>
            <Title level={3} style={{ marginBottom: 0 }}>
              {t('ResetPasswordPage.Create new password')}
            </Title>
          </Form.Item>
          <Input name="password" label={t('Common.Password')} isRequired />
          <Input name="passwordRepeat" label={t('Common.Password again')} isRequired />
          <Submit label={t('Actions.Submit')} {...tailLayout} />
          <Form.Item {...tailLayout}>
            <Link to="/login">{t('ResetPasswordPage.Back to login')}</Link>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}
