import React, { lazy } from 'react'

import CampaignSettingsForm from '../../containers/campaign/CampaignSettingsForm'
import { useTranslation } from 'react-i18next'
import componentVariationsFabric from '../../helpers/components-fabric.helper'

const CampaignCreatePage = () => {
  const { t } = useTranslation()
  return (
    <>
      <h2>{t('CampaignCreatePage.Create campaign')}</h2>
      <CampaignSettingsForm />
    </>
  )
}

CampaignCreatePage.Variants = componentVariationsFabric(
  CampaignCreatePage,
  lazy(() => import('./CampaignCreatePage'))
)

export default CampaignCreatePage
