import PageListHeader from '../../components/layout/PageListHeader/PageListHeader'
import React from 'react'
import { StreamForm } from './components/stream-form'
import { useAppState } from '../../context/AppContext'
import showNotification from '../../helpers/showNotification'
import { useNavigate } from 'react-router-dom'
import Loading from '../../components/Loading'

export const StreamsCreatePage = () => {
  const { apiClient, user } = useAppState()
  const navigate = useNavigate()
  const { mutation } = apiClient.stream.createStream

  // @ts-ignore
  const onSubmit = async val => {
    const { status, body } = await mutation({ body: val })

    if (status === 200) {
      showNotification({
        type: 'success',
        message: 'Stream successfully created!',
      })
      navigate(`/streams/${body.id}`)
    }
    if (status === 500) {
      showNotification({ type: 'error', message: body.message })
    }
  }

  if (!user) return <Loading />
  return (
    <div className="StreamsCreatePage">
      <PageListHeader title="New Stream" />
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      <StreamForm onSubmit={onSubmit} user={user} />
    </div>
  )
}
