import { Alert } from 'antd'
import Loading from '../../../../components/Loading'
import PageListHeader from '../../../../components/layout/PageListHeader/PageListHeader'
import { DomainForm } from '../../../../containers/domains/DomainForm'
import { useDspDomain } from '../hooks'

const DomainsEditPage = ({ params }) => {
  const { loading, error, data } = useDspDomain({
    id: params.id,
  })

  if (loading || !data || !data.id) return <Loading />
  if (error) return <Alert type="error" message={error.message} />

  return (
    <div className="StreamsEditPage">
      <PageListHeader title={`Domain ${data.address}`} />
      <DomainForm domain={data} />
    </div>
  )
}

export default DomainsEditPage
