import Title from '../../components/layout/Title/Title'
import { AdvertiserStats } from './components/advertiser-stats'
import { PublisherStats } from './components/publisher-stats'
import React, { FC } from 'react'
import { UserRole } from '@pushflow/backend-http-contract'
import { IUser } from '../../context/AppContext'

export const DashboardPage: FC<{ user: IUser }> = ({ user }) => {
  return (
    <>
      <div style={{ position: 'relative' }}>
        <Title level={2} style={{ marginBottom: 28 }} isSeparator={true}>
          Dashboard
        </Title>

        <div style={{ display: 'flex', flexFlow: 'column', gap: 40 }}>
          {user.roles.includes(UserRole.PUBLISHER) && <PublisherStats />}
          {(user.roles.includes(UserRole.EXTERNAL_ADVERTISER) || user.roles.includes(UserRole.INTERNAL_ADVERTISER)) && (
            <AdvertiserStats />
          )}
        </div>
      </div>
    </>
  )
}
