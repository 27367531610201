import { gql } from '@apollo/client'

export const API_LOG_DATA = gql`
  fragment ApiLogData on ApiLog {
    id
    type
    referenceId
    diff
    payload
    status
    previousEntityState
    createdAt
  }
`
