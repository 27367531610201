/* eslint-disable no-console */
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import config from '../config'
import { AUTH_TOKEN } from '../constant'
import showNotification from '../helpers/showNotification'

const httpLink = new createUploadLink({
  uri: config.apiUrl + '/graphql',
  credentials: 'include',
})

const middlewareLink = new ApolloLink((operation, forward) => {
  // get the authentication token from local storage if it exists
  const tokenValue = localStorage.getItem(AUTH_TOKEN)
  // return the headers to the context so httpLink can read them

  operation.setContext(({ headers }) => ({
    headers: {
      Authorization: tokenValue ? `Bearer ${tokenValue}` : '',
      ...headers,
      'Apollo-Require-Preflight': 'true',
    },
  }))
  return forward(operation)
})

// authenticated httplink
const httpLinkAuth = middlewareLink.concat(httpLink)

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      console.log('GraphQL error', message)
      showNotification({
        type: 'error',
        message: 'Error: ' + message,
      })

      if (message === 'UNAUTHENTICATED' || message === 'Must authenticate') {
        localStorage.removeItem(AUTH_TOKEN)
        window.location = config.baseUrl + config.path + '/login'
      }
    })
  }

  if (networkError) {
    console.log('Network error', networkError)
    console.log('Api url:', config.apiUrl)
    showNotification({
      type: 'error',
      message: 'Our service is unavailable, please try again later',
      // message: 'Network error: ' + networkError,
    })

    if (networkError.statusCode === 401) {
      console.log('make signout')
      // signOut(client);
    }
  }
})

// apollo client setup
const client = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLinkAuth]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          campaigns: {
            merge: (_, next) => next,
            keyArgs: false,
          },
        },
      },
    },
  }),
  connectToDevTools: true,

  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})

export default client
