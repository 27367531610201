import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import UserAuditoriesList from './components/user-auditory-list'
import PageListHeader from '../../components/layout/PageListHeader/PageListHeader'

const UserAuditoriesListPage = () => {
  const { t } = useTranslation()
  return (
    <div className="UserAuditoriesListPage">
      <PageListHeader
        title="UserAuditories"
        buttons={[
          {
            link: `/userauditory/create`,
            type: 'primary',
            icon: <PlusOutlined />,
            title: t('Actions.Create'),
          },
        ]}
      />
      <UserAuditoriesList />
    </div>
  )
}

export default UserAuditoriesListPage
