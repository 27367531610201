import { DeleteTwoTone, SearchOutlined } from '@ant-design/icons'
import { Alert, Button, Input, InputRef, Modal, Space, Table } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import DeleteButton from '../../../components/buttons/DeleteButton/DeleteButton'
import showNotification from '../../../helpers/showNotification'
import { useAppState } from '../../../context/AppContext'
import Loading from '../../../components/Loading'
import { useQueryClient } from '@tanstack/react-query'
import { UserRole } from '@pushflow/backend-http-contract'

const CampaignsWithAuditoryModal: FC<{
  campaigns?: Array<{
    id: number
    title: string
  }>
  auditoryId: number
  show: boolean
  onOk: () => void
  onCancel: () => void
}> = ({ campaigns, auditoryId, show, onOk, onCancel }) => {
  return (
    <Modal
      open={show}
      title={
        <>
          <b>Warning❗</b>
          <>this will remove auditory #{auditoryId} from targets of campaigns:</>
        </>
      }
      onOk={() => {
        onOk()
      }}
      onCancel={() => {
        onCancel()
      }}
    >
      {campaigns?.length === 0 ? (
        <p>This auditory is not linked with any campaigns. You can safely delete it by pressing OK</p>
      ) : (
        <Table
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              render: (text: string, record: { id: number }) => (
                <Link to={`/campaign/${record.id}`}>
                  <b>{text}</b>
                </Link>
              ),
            },
            {
              title: 'Title',
              dataIndex: 'title',
              render: (text: string, record: { id: number }) => (
                <Link to={`/campaign/${record.id}`}>
                  <b>{text}</b>
                </Link>
              ),
            },
          ]}
          dataSource={campaigns}
          rowKey="id"
          size="middle"
        />
      )}
    </Modal>
  )
}

const DeleteUserAuditoryIcon: FC<{
  id: number
  refetch: () => void
}> = ({ id, refetch }) => {
  const { apiClient } = useAppState()
  const queryClient = useQueryClient()
  const [showModal, setShowModal] = useState(false)

  const { mutateAsync: deleteUserAuditory } = apiClient.userAuditory.deleteUserAuditory.useMutation({
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['userAuditories'],
      })
    },
  })

  const qk = ['campaignsWithAuditory', id]
  const { data: campaignsWithAuditoryQuery, refetch: refetchCampaigns } =
    apiClient.userAuditory.getCampaignsWithAuditory.useQuery(
      qk,
      {
        query: {
          auditoryId: id,
        },
      },
      {
        queryKey: qk,
        enabled: false,
      }
    )

  return (
    <>
      <CampaignsWithAuditoryModal
        auditoryId={id}
        show={showModal}
        campaigns={campaignsWithAuditoryQuery?.body.items}
        onOk={() => {
          deleteUserAuditory({
            body: { id },
          })
            .then(() => {
              showNotification({
                type: 'success',
                message: 'User auditory successfully deleted',
              })
              refetch()
            })
            .catch(() => {
              showNotification({
                type: 'error',
                message: 'User auditory not deleted',
              })
            })
        }}
        onCancel={() => {
          setShowModal(false)
        }}
      />
      <DeleteButton
        key="3"
        isOnlyIcon
        onClick={() => {
          refetchCampaigns()
          setShowModal(true)
        }}
      >
        <DeleteTwoTone />
      </DeleteButton>
    </>
  )
}

const UserAuditoriesList = () => {
  const [pageSize, setPageSize] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)
  const [title, setTitle] = useState<string>()

  const { t } = useTranslation()
  const { apiClient, user } = useAppState()

  const {
    isLoading: loading,
    error,
    data,
    refetch,
  } = apiClient.userAuditory.getUserAuditoryListPaginated.useQuery(['userAuditories'], {
    query: {
      filter: {
        title,
      },
      pagination: {
        pageSize,
        pageNumber,
      },
    },
  })
  const filtersData = data?.body.filters

  useEffect(() => {
    refetch()
  }, [pageSize, pageNumber, title])

  const showLoading = (loading && !data) || !filtersData
  if (showLoading) return <Loading />

  if (error) {
    console.error(error)
    return <Alert type="error" message={"Can't load user auditories"} />
  }

  let searchInput: InputRef | null = null

  const getColumnSearchProps = (dataIndex: string, setter: (...p: any[]) => void) => {
    const _handler = (values: any, callback: any) => {
      setter(values)

      callback()
    }

    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: (arg0: any) => void
        selectedKeys: any[]
        confirm: (...p: any[]) => void
        clearFilters: () => void
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              searchInput = node
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={({ target }) => setSelectedKeys(target.value ? [target.value] : [])}
            onPressEnter={() => _handler(selectedKeys[0], confirm)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => _handler(selectedKeys[0], confirm)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                _handler(undefined, clearFilters)
                confirm()
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value: any, record: any) =>
        record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : undefined,
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => searchInput && searchInput.select(), 100)
        }
      },
      render: (text: string, record: { id: number }) => (
        <Link to={`/campaign/${record.id}`}>
          <b>{text}</b>
        </Link>
      ),
    }
  }

  let columns: any[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: string, record: { id: number }) => (
        <Link to={`/userauditory/${record.id}`}>
          <b>{text}</b>
        </Link>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      ...getColumnSearchProps('title', (v: string) => setTitle((v || '').toLowerCase())),
      render: (text: string, record: { id: number }) => (
        <Link to={`/userauditory/${record.id}`}>
          <b>{text}</b>
        </Link>
      ),
    },
    {
      title: t('Table.Actions'),
      key: 'action',
      render: (_: any, record: { id: number }) => {
        return (
          <Space size="large">
            <DeleteUserAuditoryIcon id={record.id} refetch={refetch} />
          </Space>
        )
      },
    },
  ]

  if (user && user.roles.includes(UserRole.ADMIN)) {
    columns = [
      columns[0],
      {
        title: 'User ID',
        dataIndex: 'userId',
        filters: [...(filtersData?.userIds || [])].sort().map(value => ({ text: value, value })),
        filterSearch: true,
        onFilter: (value: string, record: { userId: number }) => {
          return +record.userId === +value
        },
        render: (text: string) => <Link to={`/admin/users/${text}`}>{text}</Link>,
        width: 100,
      },
      ...columns.slice(1),
    ]
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={data?.body.items || []}
        loading={showLoading}
        rowKey="id"
        size="middle"
        scroll={{ x: true }}
        pagination={{
          pageSize,
          total: data?.body.pagination.total || 0,
          pageSizeOptions: [10, 20, 50, 100, 400],
          showSizeChanger: true,
          current: pageNumber,
          onChange(newPage, newPageSize) {
            if (newPageSize !== pageSize) {
              setPageNumber(newPage)
              setPageSize(newPageSize)
            } else if (newPage !== pageNumber) {
              setPageNumber(newPage)
            }
          },
        }}
      />
    </>
  )
}

export default UserAuditoriesList
