import { useGetStreamAutocompleteQuery } from '@pushflow/server/src/graphql/new/streams/getStreamAutoComplete/operations'

export const StreamColRenderer = params => {
  const streams = useGetStreamAutocompleteQuery(
    {},
    {
      fetchPolicy: 'cache-first',
    }
  )

  if (streams.loading) {
    return params.value
  }

  const title = streams.data?.getStreamAutoComplete?.streams?.find(stream => stream.uid === params.value)?.title

  return title ?? params.value
}
