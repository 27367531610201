import { useTranslation } from 'react-i18next'
import * as css from './TargetDaysAndHoursInput.module.sass'
import cn from 'classnames'
import times from 'lodash/times'
import { Form } from 'antd'
import { useEffect, useRef } from 'react'

const DayAndHourCheckbox = ({ day, hour, targetDaysAndHours = [] }) => {
  const checked = targetDaysAndHours.find(item => item.day === day && item.hour === hour)
  return (
    <div
      id={`${day}-${hour}`}
      className={cn({
        [css.checkbox]: true,
        [css.checked]: checked,
      })}
    />
  )
}

export const TargetDaysAndHoursInput = ({ form }) => {
  const targetDaysAndHours = Form.useWatch('targetDaysAndHours', form)
  const { t } = useTranslation()
  const days = times(7, i => {
    const dayName = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][i]
    return {
      value: i + 1,
      label: t(`Date.Short Days.${dayName}`),
    }
  })
  const hours = times(24, i => {
    return {
      value: i,
      label: `${i < 10 ? '0' : ''}${i}`,
    }
  })
  const isMousePressed = useRef(false)
  const mousePressedOnCheckboxWithId = useRef(null)
  const lastTriggeredCheckboxWithId = useRef(null)
  useEffect(() => {
    const trigger = e => {
      const [day, hour] = e.target.id.split('-').map(Number)
      if (!day || !hour) return
      const checked = targetDaysAndHours.find(item => item.day === day && item.hour === hour)
      if (checked) {
        const newTargetDaysAndHours = targetDaysAndHours.filter(item => item.day !== day || item.hour !== hour)
        form.setFieldValue('targetDaysAndHours', newTargetDaysAndHours)
      } else {
        const newTargetDaysAndHours = [...targetDaysAndHours, { day, hour }]
        form.setFieldValue('targetDaysAndHours', newTargetDaysAndHours)
      }
      lastTriggeredCheckboxWithId.current = e.target.id
    }
    const onMouseDown = e => {
      isMousePressed.current = true
      mousePressedOnCheckboxWithId.current = e.target.id
      trigger(e)
    }
    const onMouseUp = () => {
      isMousePressed.current = false
      mousePressedOnCheckboxWithId.current = null
    }
    const onMouseOver = e => {
      if (!isMousePressed.current) return
      if (e.target.id === mousePressedOnCheckboxWithId) return
      if (e.target.id === lastTriggeredCheckboxWithId) return
      trigger(e)
    }
    document.addEventListener('mousedown', onMouseDown)
    document.addEventListener('mouseup', onMouseUp)
    document.addEventListener('mouseover', onMouseOver)
    // document.addEventListener('mouseout', onMouseOut)
    return () => {
      document.removeEventListener('mousedown', onMouseDown)
      document.removeEventListener('mouseup', onMouseUp)
      document.removeEventListener('mouseover', onMouseOver)
      // document.removeEventListener('mouseout', onMouseOut)
    }
  }, [form, targetDaysAndHours])
  return (
    <div className={css.table}>
      <div className={css.row}>
        <div className={css.cell}>
          <div
            onClick={() => {
              const checkedCount = targetDaysAndHours.length
              if (!checkedCount) {
                const newTargetDaysAndHours = [
                  ...days.map(day => hours.map(hour => ({ day: day.value, hour: hour.value }))),
                ].flat()
                form.setFieldValue('targetDaysAndHours', newTargetDaysAndHours)
              } else {
                const newTargetDaysAndHours = []
                form.setFieldValue('targetDaysAndHours', newTargetDaysAndHours)
              }
            }}
            className={cn(css.label, css.hour)}
          >
            X
          </div>
        </div>
        {hours.map(hour => (
          <div key={hour.value} className={css.cell}>
            <div
              onClick={() => {
                const checkedDays = targetDaysAndHours.filter(item => item.hour === hour.value)
                const isEachDayChecked = checkedDays.length === 7
                if (isEachDayChecked) {
                  const newTargetDaysAndHours = targetDaysAndHours.filter(item => item.hour !== hour.value)
                  form.setFieldValue('targetDaysAndHours', newTargetDaysAndHours)
                  return
                }
                const newTargetDaysAndHours = [
                  ...targetDaysAndHours.filter(item => item.hour !== hour.value),
                  ...days.map(day => ({ day: day.value, hour: hour.value })),
                ]
                form.setFieldValue('targetDaysAndHours', newTargetDaysAndHours)
              }}
              className={cn(css.label, css.hour)}
            >
              {hour.label}
            </div>
          </div>
        ))}
      </div>
      {days.map(day => (
        <div key={day.value} className={css.row}>
          <div className={css.cell}>
            <div
              onClick={() => {
                const checkedHours = targetDaysAndHours.filter(item => item.day === day.value)
                const isEachHourChecked = checkedHours.length === 24
                if (isEachHourChecked) {
                  const newTargetDaysAndHours = targetDaysAndHours.filter(item => item.day !== day.value)
                  form.setFieldValue('targetDaysAndHours', newTargetDaysAndHours)
                  return
                }
                const newTargetDaysAndHours = [
                  ...targetDaysAndHours.filter(item => item.day !== day.value),
                  ...hours.map(hour => ({ hour: hour.value, day: day.value })),
                ]
                form.setFieldValue('targetDaysAndHours', newTargetDaysAndHours)
              }}
              className={cn(css.label, css.day)}
            >
              {day.label}
            </div>
          </div>
          {hours.map(hour => (
            <div key={hour.value} className={css.cell}>
              <DayAndHourCheckbox
                day={day.value}
                hour={hour.value}
                targetDaysAndHours={targetDaysAndHours}
                form={form}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
