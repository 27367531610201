import { useQuery } from '@tanstack/react-query'
import { fetchDomains } from './fetchers'
import config from '../../../config'

export const useDspDomains = (region, trafficType) => {
  return useQuery({
    queryKey: ['dsp', 'domains', region, trafficType],
    queryFn: () => fetchDomains(region, trafficType),
    placeholderData: [config.dspUrl],
  })
}
