import { gql } from 'graphql-tag'

export const BLACK_WHITE_LIST_DATA = gql`
  fragment BlackWhiteListData on BlackWhiteList {
    __typename
    id
    title
    listType
    userId
    items
  }
`
