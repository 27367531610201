import { IncludeOrExclude } from '../../../../components/forms/IncludeOrExclude/IncludeOrExclude'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { getStringsFormatted, getSubstreamsFormatted } from '../../util'

const { Input, Tag, Form } = require('antd')

export const substreamRegexp = /\b\w{1,}:\w{1,}\b/
export const isSubstreamsValid = (s = []) => {
  if (Array.isArray(s) === false) return false
  if (s.length === 0) return true
  return s.every(s => s.match(substreamRegexp))
}

const SubstreamsInput = ({ form, showExcludeInclude = true, ...props }) => {
  const { t } = useTranslation()

  const stringsValues = Form.useWatch(props.name, form)
  const [errText, setErrText] = useState('')

  useEffect(() => {
    if (stringsValues) {
      const strings = getStringsFormatted(stringsValues)
      if (isSubstreamsValid(strings) === false) {
        const s = strings.find(s => !s.match(substreamRegexp))
        setErrText(`Substream ${s} must have format "X:XXX..." or "XX:XXX..." or "XXX:XXX..."`)
      } else {
        setErrText('')
      }
    }
  }, [stringsValues])

  return (
    <Form.Item
      label={t('Campaign.Substreams')}
      name={props.name}
      extra={
        <div>
          {showExcludeInclude ? <IncludeOrExclude name="substreamsInclude" form={form} /> : <></>}
          <Tag>Items count: {getSubstreamsFormatted(stringsValues).length || 0}</Tag>
          {errText !== '' && <Tag color="error">{errText}</Tag>}
        </div>
      }
    >
      <Input.TextArea wrap="off" autoSize={{ minRows: 4, maxRows: 12 }} {...props} />
    </Form.Item>
  )
}

export default SubstreamsInput
