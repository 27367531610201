import React from 'react'
const { Spin } = require('antd')

const Loading = () => (
  <div className="Loading">
    <Spin
      size="large"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: 8999,
      }}
    />
  </div>
)

export default Loading
