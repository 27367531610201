import { gql } from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { CAMPAIGN_SUBSTREAM_INFO_DATA } from '../CampaignSubstreamInfo/operations.js'

export const CHANGE_CAMPAIGN_SUBSTREAM_STATUS = gql`
  mutation ChangeCampaignSubstreamStatus(
    $campaignId: Int!
    $streamUid: String!
    $substream: String!
    $status: String!
  ) {
    changeCampaignSubstreamStatus(
      campaignId: $campaignId
      streamUid: $streamUid
      substream: $substream
      status: $status
    ) {
      ...campaignSubstreamInfoFullFragment
    }
  }
  ${CAMPAIGN_SUBSTREAM_INFO_DATA}
`

export function useChangeCampaignSubstreamStatus() {
  const [mutate, { data, error }] = useMutation(CHANGE_CAMPAIGN_SUBSTREAM_STATUS, {})
  return { mutate, data, error }
}
