import { gql } from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email) {
      isSuccess
    }
  }
`

export function useResetPassword() {
  const [mutate, { data, error }] = useMutation(RESET_PASSWORD_MUTATION, {})
  return { mutate, data, error }
}
