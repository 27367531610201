export const BROWSER_TYPES = [
  ...new Set([
    'Chrome',
    'Edge',
    'Safari',
    'Firefox',
    'Opera',
    'Mobile Chrome',
    'Mobile Safari',
    'Mobile Firefox',
    'Android Browser',
    'UCBrowser',
    'baidubrowser',
    'Samsung Browser',
    'Chrome Headless',
    'Firefox Reality',
    '360 Browser',
    'Oculus Browser',
    'Instagram',
    'KAKAOSTORY',
    'Opera Touch',
    'LBBROWSER',
    'Huawei Browser',
    'SeaMonkey',
    'Opera Mini',
    'QQBrowser',
    'Avast Secure Browser',
    'AVG Secure Browser',
    'TikTok',
    'Slim',
    'IE',
    'MetaSr',
    'Whale',
    'IEMobile',
    'Coc Coc',
    'WeChat',
    'DuckDuckGo',
    'MIUI Browser',
    'baiduboxapp',
    'KAKAOTALK',
    'Chrome WebView',
    'Firefox Focus',
    'WebKit',
    'weibo',
    'GSA',
    'HeyTap',
    'Vivaldi',
    'Silk',
    'Maxthon',
    'Brave',
    'Puffin',
    'Iridium',
    'NAVER',
    'Line',
    'Mozilla',
    'Waterfox',
    'Midori',
    'Quark',
    'Electron',
    'Facebook',
    'Yandex',
    'Snapchat',
    'Chromium',
    'RockMelt',
    'Opera GX',
    'Iron',
    'jasmine',
    'Baidu',
    'Wavebox',
    'slim',
    'Camino',
    'Fennec',
    'Atomic',
    'PaleMoon',
    'LinkedIn',
    'Netscape',
    'obiGo',
    'Falkon',
    'SLIM',
    'QQ',
    'Tenta',
    'Skyfire',
    'Hola',
    'Klarna',
    'maxthon',
    'Kindle',
    'Android WebView',
    'Opera Crypto',
    '2345Explorer',
    'TizenBrowser',
    'CCleaner Browser',
    'Microsoft Edge',
    'IceDragon',
    'Sogou Mobile',
    'Sogou Explorer',
    'OperaMobile',
    'Samsung Internet',
    'Brave Browser',
    'YaBrowser',
    'BIDUBrowser',
    'Vivo Browser',
    'Konqueror',
    'Viasat Browser',
    'NokiaBrowser',
    'HeadlessChrome',
    'WaveBrowser',
    'Norton Secure Browser',
    'AvastSecureBrowser',
    'Avira Secure Browser',
    'Smart Lenovo Browser',
  ]),
]
