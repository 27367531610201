import React, { FC } from 'react'
import './HelpPopover.sass'
import { Popover } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

interface HelpPopoverProps {
  content?: string
  maxWidth?: number
  fontSize?: number
  children?: React.ReactNode
}

const HelpPopover: FC<HelpPopoverProps> = ({ content, children, maxWidth = 350, fontSize, ...props }) => {
  return (
    <Popover content={content || children} overlayStyle={{ maxWidth }}>
      <QuestionCircleOutlined style={{ marginLeft: 12, opacity: 0.5, fontSize }} {...props} />
    </Popover>
  )
}

export default HelpPopover
