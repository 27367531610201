import { gql } from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { CAMPAIGN_SUBSTREAM_INFO_DATA } from '../CampaignSubstreamInfo/operations.js'

export const CHANGE_CAMPAIGN_SUBSTREAM_BID = gql`
  mutation ChangeCampaignSubstreamBid(
    $campaignId: Int!
    $streamUid: String!
    $substream: String!
    $bid: Float!
  ) {
    changeCampaignSubstreamBid(
      campaignId: $campaignId
      streamUid: $streamUid
      substream: $substream
      bid: $bid
    ) {
      ...campaignSubstreamInfoFullFragment
    }
  }
  ${CAMPAIGN_SUBSTREAM_INFO_DATA}
`

export function useChangeCampaignSubstreamBid() {
  const [mutate, { data, error }] = useMutation(CHANGE_CAMPAIGN_SUBSTREAM_BID, {})
  return { mutate, data, error }
}
