import { PlusOutlined } from '@ant-design/icons'
import PageListHeader from '../../../../components/layout/PageListHeader/PageListHeader'
import BlackWhiteLists from './BlackWhiteLists'
import { useTranslation } from 'react-i18next'
import React from 'react'

const BlackWhiteListsPage = () => {
  const { t } = useTranslation()
  return (
    <div className="BlackWhiteListsPage">
      <PageListHeader
        title="Black & White Lists"
        buttons={[
          {
            link: `/subscriptionlist/create`,
            type: 'primary',
            icon: <PlusOutlined {...({} as any)} />,
            title: t('Actions.Create'),
          },
        ]}
      />
      <BlackWhiteLists />
    </div>
  )
}

export default BlackWhiteListsPage
