import React, { FC } from 'react'
import './PageHeader.sass'
import { Breadcrumb } from 'antd'

import { PageHeader as AntHeader } from 'antd'
import { Link } from 'react-router-dom'
import Title from '../Title/Title'

const PageHeader: FC<{
  title: string
  breadcrumbs?: { title: string; link?: string }[]
  [key: string]: any
}> = ({ children, breadcrumbs, title, ...props }) => {
  function renderBreadcrumbs() {
    return (
      <Breadcrumb>
        {breadcrumbs &&
          breadcrumbs.map(crumb => {
            const content = crumb.link ? <Link to={crumb.link}>{crumb.title}</Link> : crumb.title
            return <Breadcrumb.Item key="index">{content}</Breadcrumb.Item>
          })}
      </Breadcrumb>
    )
  }
  return (
    <div className="PageHeader">
      {breadcrumbs && renderBreadcrumbs()}
      <AntHeader
        title={
          <Title isSeparator={true} level={2} style={{ margin: 0 }}>
            {title}
          </Title>
        }
        className="PageHeader__ant"
        {...props}
      >
        <div className="PageHeader__content">{children}</div>
      </AntHeader>
    </div>
  )
}

export default PageHeader
