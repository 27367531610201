import { useQuery } from '@apollo/react-hooks'
import { gql } from 'graphql-tag'

export const ESTIMATE_CAMPAIGN_QUERY = gql`
  query EstimateCampaignQuery(
    $campaignBody: CampaignBodyInput!
    $estimationParams: EstimationParamsInput
  ) {
    estimateCampaign(campaignBody: $campaignBody, estimationParams: $estimationParams) {
      totalCampaignsCounted
      wonBids
      bids
      clicks
      impressions
      costForDay
      costForWeek
      costForMonth
    }
  }
`

export function useEstimateCampaign(input) {
  const { loading, error, data, refetch } = useQuery(ESTIMATE_CAMPAIGN_QUERY, {
    variables: { ...input },
  })
  return { loading, error, data, refetch }
}
