import { gql } from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

export const REPORT_ADVERTISERS_DATA = gql`
  fragment ReportAdvertisersData on ReportAdvertisers {
    __typename
    date
    group
    group1
    bids
    wonBids
    clicks
    winRate
    cost
    revenue
    profit
    roi
    advertiserId
    user {
      id
      email
    }
  }
`
export const REPORT_ADVERTISERS_QUERY = gql`
  query ReportAdvertisersQuery(
    $dateFrom: String
    $dateTo: String
    $filterByUserId: [Int]
    $groupBy: String
    $groupBy1: String
  ) {
    reportAdvertisers(
      dateFrom: $dateFrom
      dateTo: $dateTo
      filterByUserId: $filterByUserId
      groupBy: $groupBy
      groupBy1: $groupBy1
    ) {
      ...ReportAdvertisersData
    }
  }
  ${REPORT_ADVERTISERS_DATA}
`

export function useReportAdvertisersQuery(variables) {
  const { loading, error, data, refetch } = useQuery(REPORT_ADVERTISERS_QUERY, {
    variables,
  })
  return { loading, error, data, refetch }
}
