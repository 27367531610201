const isDev = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'beta'

const config = {
  isDev,
  docsUrl: process.env.REACT_APP_DOCS_URL,
  workerUrl: process.env.REACT_APP_WORKER_URL,
  scriptUrl: process.env.REACT_APP_SCRIPT_URL,
  baseUrl: process.env.REACT_APP_BASE_URL,
  safariWebserviceApi: process.env.SAFARI_WEBSERVICE_API,
  path: '/app',
  // apiUrl: '/api',
  apiUrl: process.env.REACT_APP_API_URL + '/api',
  stripeKey: process.env.REACT_APP_STRIPE_KEY,
  // Sentry fields
  sentryDsn: 'https://f944b5e4cf4a4a0bb4ccef59eba5453e@sentry.pushflow.dev//9',
  sourceVersion: process.env.REACT_APP_SOURCE_VERSION,
  dspUrl: process.env.REACT_APP_DSP_URL,
  agGridLicense: process.env.REACT_APP_AG_GRID_LICENSE,
  telegramBotUrl: process.env.REACT_APP_TELEGRAM_BOT_URL,
  uiBackendUrl: process.env.REACT_APP_UI_BACKEND_URL,
}

export default config
