import React from 'react'
import { Statistic as AntStat } from 'antd'

const Suffix = ({ suffix }) =>
  suffix ? <span style={{ opacity: 0.4, fontSize: 16, marginLeft: 4 }}>{`${suffix}`}</span> : <></>

const Statistic = ({ suffix, isBorder, ...props }) => {
  return (
    <AntStat
      style={
        isBorder
          ? {
              padding: 20,
              border: '1px solid rgba(0, 0, 0, 0.08)',
            }
          : {}
      }
      suffix={<Suffix suffix={suffix} />}
      {...props}
    />
  )
}

export default Statistic
