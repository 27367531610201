import { FileTextOutlined, ReloadOutlined } from '@ant-design/icons'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { Button, Col, DatePicker, Form, Result, Row, Tag } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import TimezoneSelect from '../../components/forms/TimezoneSelect/TimezoneSelect'
import config from '../../config'
import { numberFormatter } from '../../helpers/agGridTable'
import { useQuery } from '@tanstack/react-query'
import { fetchDiscrepancyReport } from './hooks/fetchers'

const { RangePicker } = DatePicker

LicenseManager.setLicenseKey(config.agGridLicense)

// const defaultState = {
//   reportParams: {
//     groupBy: 'DATE',
//     filtersByTargets: [],
//     timezone: '+0',
//     dateRange: [moment().subtract(14, 'd'), moment().endOf()],
//     dateFrom: moment().subtract(14, 'd').format('YYYY-MM-DD'),
//     dateTo: moment().add('1d').format('YYYY-MM-DD'),
//   },
//   filteredInfo: {},
//   sortedInfo: {},
//   pagination: {
//     pageSizeOptions: [10, 20, 50, 100, 400],
//     showSizeChanger: true,
//     pageSize: 50,
//   },
//   showFilters: false,
// }

const DiscrepancyReport = () => {
  const [filter, setFilter] = useState()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const { data, isError, refetch, error } = useQuery({
    enabled: Boolean(filter),
    queryKey: ['report', 'campaign-discrepancy', `${filter?.fromDate}${filter?.toDate}`],
    queryFn: () => fetchDiscrepancyReport(filter?.fromDate, filter?.toDate),
  })

  useEffect(() => {
    if (filter?.fromDate && filter?.toDate) {
      refetch()
    }
  }, [filter, refetch])

  const timezone = Form.useWatch('timezone', form)

  if (isError) {
    return (
      <Result
        status="error"
        title="Request failed"
        subTitle={error.message}
        extra={[
          <Button key="retry" onClick={refetch} icon={<ReloadOutlined />}>
            Retry
          </Button>,
        ]}
      />
    )
  }

  const onSubmit = async event => {
    event.preventDefault()
    const { dateRange } = form.getFieldsValue()
    setFilter({ fromDate: dateRange[0], toDate: dateRange[1] })
  }

  let rowData = data?.items || []

  const columnDefs = [
    {
      field: 'date',
      width: 140,
      pinned: 'left',
      headerName: 'Date',
      valueFormatter: data => moment(data.value).format('YYYY-MM-DD'),
    },
    {
      field: 'internalStat.clicks',
      width: 120,
      headerName: 'DSP clicks',
      type: 'rightAligned',
      valueFormatter: numberFormatter,
    },
    {
      field: 'externalStat.clicks',
      width: 120,
      headerName: 'TP clicks',
      type: 'rightAligned',
      valueFormatter: numberFormatter,
    },
    {
      field: 'clicksDiscrepancy',
      width: 120,
      headerName: 'clicksDiscrepancy',
      type: 'rightAligned',
      valueFormatter: data => `${data.value} %`,
    },
    {
      field: 'internalStat.cost',
      width: 120,
      headerName: 'DSP cost',
      type: 'rightAligned',
      valueFormatter: numberFormatter,
    },
    {
      field: 'externalStat.cost',
      width: 120,
      headerName: 'TP cost',
      type: 'rightAligned',
      valueFormatter: numberFormatter,
    },
    {
      field: 'costDiscrepancy',
      width: 120,
      headerName: 'costDiscrepancy',
      type: 'rightAligned',
      valueFormatter: data => `${data.value} %`,
    },
  ]

  const defaultColDef = {
    filter: true,
    sortable: true,
    resizable: true,
  }

  return (
    <>
      <Form
        form={form}
        id="reportForm"
        name="reportForm"
        onSubmit={onSubmit}
        // initialValues={reportParams}
        style={{ marginBottom: 24 }}
      >
        <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
          <Col span={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={
                <>
                  {t('Report.CampaignReport.Controls.DateRangeLabel')}{' '}
                  <Tag style={{ verticalAlign: 2, marginLeft: 4 }}>UTC{timezone}</Tag>
                </>
              }
              name="dateRange"
              key="dateRange"
              rules={[
                {
                  required: true,
                  message: t('Report.CampaignReport.Controls.DateRangeMsg'),
                },
              ]}
            >
              <RangePicker
                style={{ width: '100%' }}
                ranges={{
                  Today: [moment().startOf(), moment().endOf()],
                  Yesterday: [moment().subtract(1, 'days').startOf(), moment().subtract(1, 'days').endOf()],
                  'This Week': [moment().startOf('week').startOf(), moment().endOf('week').endOf()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'Last 7 days': [moment().subtract(7, 'days').startOf(), moment().subtract(1, 'days').endOf()],
                  'Last 30 days': [moment().subtract(30, 'days').startOf(), moment().subtract(1, 'days').endOf()],
                }}
              />
            </Form.Item>
          </Col>
          <Col span={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            <TimezoneSelect title={t('Report.Controls.Timezone')} name={'timezone'} />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={{ xs: 12, lg: 3 }} className="gutter-row">
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} key="submitBtn">
              <Button
                type="primary"
                form="reportForm"
                key="submit"
                htmlType="submit"
                onClick={onSubmit}
                icon={<FileTextOutlined />}
              >
                {t('Report.Controls.CreateReport')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          // ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
          domLayout="autoHeight"
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
              },
            ],
          }}
          // pinnedBottomRowData={pinnedBottomRowData}
          suppressColumnFilter={false}
        />
      </div>
    </>
  )
}
export default DiscrepancyReport
