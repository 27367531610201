import * as Sentry from '@sentry/react'
import { Alert, Button, Checkbox, Form, Select } from 'antd'
import pick from 'lodash/pick'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Input from '../../components/forms/Input/Input'
import { defaultFormLayout } from '../../helpers/formLayouts'
import showNotification from '../../helpers/showNotification'
import { DSP_DOMAINS_TRAFFIC_TYPES, DSP_DOMAINS_TRAFFIC_TYPE_LABELS } from '../../common/types/dspDomainsTrafficType'
import { useCreateDspDomainMutation, useUpdateDspDomainMutation } from '../../pages/admin/domains/hooks'

export const DomainForm = ({ domain = {} }) => {
  const navigate = useNavigate()
  const { mutateAsync: createOrUpdateDomain } = domain.id ? useUpdateDspDomainMutation() : useCreateDspDomainMutation()
  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()

  const [isFormValid, setIsFormValid] = useState(false)
  const [isFormTouched, setIsFormTouched] = useState(false)

  const validateForm = () => {
    setIsFormValid(
      form.getFieldsError().every(item => {
        return item.errors.length === 0
      })
    )
    setIsFormTouched(true)
  }

  const neededDomainFeilds = pick(domain, ['address', 'trafficType', 'isActive', 'region'])

  const initialValues = {
    address: '',
    region: 'eu',
    trafficType: [],
    isActive: true,
    ...neededDomainFeilds,
  }

  const submit = values => {
    createOrUpdateDomain({
      id: domain.id,
      ...values,
    })
      .then(result => {
        if (!result.errors) {
          showNotification({
            type: 'success',
            message: domain.id ? 'Domain succesfully updated!' : 'Domain succesfully created!',
          })
          navigate(`/admin/domains`)
        }
      })
      .catch(err => {
        showNotification({ type: 'error', message: err.message })
        if (process.env.NODE_ENV !== 'development') Sentry.captureException(err)
        setSubmitting(false)
      })
  }

  return (
    <Form
      form={form}
      onFinish={submit}
      onFinishFailed={validateForm}
      onFieldsChange={validateForm}
      initialValues={initialValues}
      layout="horizontal"
      disabled={submitting}
      {...defaultFormLayout}
    >
      <Input isRequired name="address" label="Address" {...defaultFormLayout} />

      <Form.Item {...defaultFormLayout} label="Region" name="region">
        <Select
          options={[
            { value: 'us', label: 'US (East Cost)' },
            { value: 'eu', label: 'EU (Germany)' },
          ]}
        />
      </Form.Item>

      <Form.Item
        {...defaultFormLayout}
        label="Traffic Type"
        name="trafficType"
        rules={[
          {
            validator: (rule, value, callback) =>
              value.length > 0 ? callback() : callback('Please select traffic type'),
          },
        ]}
      >
        <Select
          mode="multiple"
          style={{ width: '400px' }}
          placeholder="Please select"
          options={DSP_DOMAINS_TRAFFIC_TYPES.map(text => ({
            value: text,
            label: DSP_DOMAINS_TRAFFIC_TYPE_LABELS[text],
          }))}
        />
      </Form.Item>

      <Form.Item {...defaultFormLayout} label="Active" name="isActive" valuePropName="checked">
        <Checkbox>Enabled</Checkbox>
      </Form.Item>

      {!isFormValid && isFormTouched && (
        <Form.Item {...defaultFormLayout}>
          <Alert message="Some fields are invalid" type="error" />
        </Form.Item>
      )}

      <Form.Item {...defaultFormLayout}>
        <Button type="primary" htmlType="submit" loading={submitting} disabled={!isFormValid && isFormTouched}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}
