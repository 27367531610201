import { z } from 'zod';
import { initContract } from '@ts-rest/core';
const c = initContract();

export const blackWhiteList = c.router({
  getListListPaginated: {
    method: 'GET',
    path: '/blackWhiteList',
    query: z.object({
      filter: z
        .object({
          title: z.string().optional(),
          ids: z.array(z.coerce.number()).optional(),
          userIds: z.array(z.coerce.number()).optional(),
          listType: z.enum(['STREAM', 'SUBSTREAM']).optional(),
        })
        .optional(),
      limit: z.coerce.number().default(100),
      offset: z.coerce.number().default(0),
    }),
    responses: {
      200: z.object({
        items: z
          .object({
            id: z.number(),
            title: z.string(),
            items: z.array(z.string()),
            listType: z.enum(['STREAM', 'SUBSTREAM']),
            userId: z.number(),
            blcampaigns: z.array(
              z.object({
                campaignId: z.number(),
                title: z.string(),
              }),
            ),
            wlcampaigns: z.array(
              z.object({
                campaignId: z.number(),
                title: z.string(),
              }),
            ),
          })
          .array(),
        filterValues: z.object({
          ids: z.array(z.number()),
          listTypes: z.array(z.enum(['STREAM', 'SUBSTREAM'])),
          userIds: z.array(z.number()),
        }),
        total: z.number(),
      }),
    },
  },
  getList: {
    method: 'GET',
    path: '/blackWhiteList/:id',
    pathParams: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        title: z.string(),
        items: z.array(z.string()),
        listType: z.enum(['STREAM', 'SUBSTREAM']),
        userId: z.number(),
        blcampaigns: z.array(
          z.object({
            campaignId: z.number(),
            title: z.string(),
          }),
        ),
        wlcampaigns: z.array(
          z.object({
            campaignId: z.number(),
            title: z.string(),
          }),
        ),
      }),
    },
  },
  createList: {
    method: 'POST',
    path: '/blackWhiteList',
    body: z.object({
      title: z.string(),
      listType: z.enum(['STREAM', 'SUBSTREAM']),
      items: z.array(z.string()),
      blcampaigns: z.array(z.coerce.number()).default([]),
      wlcampaigns: z.array(z.coerce.number()).default([]),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        title: z.string(),
        listType: z.enum(['STREAM', 'SUBSTREAM']),
        items: z.array(z.string()),
        userId: z.number(),
        blcampaigns: z.array(
          z.object({
            campaignId: z.number(),
            title: z.string(),
          }),
        ),
        wlcampaigns: z.array(
          z.object({
            campaignId: z.number(),
            title: z.string(),
          }),
        ),
      }),
    },
  },
  updateList: {
    method: 'PATCH',
    path: '/blackWhiteList',
    body: z.object({
      id: z.coerce.number(),
      title: z.string(),
      listType: z.enum(['STREAM', 'SUBSTREAM']),
      items: z.array(z.string()),
      blcampaigns: z.array(z.coerce.number()).default([]),
      wlcampaigns: z.array(z.coerce.number()).default([]),
    }),
    responses: {
      200: z.object({
        id: z.number(),
        title: z.string(),
        listType: z.enum(['STREAM', 'SUBSTREAM']),
        items: z.array(z.string()),
        userId: z.number(),
        blcampaigns: z.array(
          z.object({
            campaignId: z.number(),
            title: z.string(),
          }),
        ),
        wlcampaigns: z.array(
          z.object({
            campaignId: z.number(),
            title: z.string(),
          }),
        ),
      }),
    },
  },
  deleteList: {
    method: 'DELETE',
    path: '/blackWhiteList',
    body: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      200: z.object({
        ok: z.boolean(),
      }),
    },
  },
});
