export const langCodes = {
  ab: 'Abkhazian',
  ace: 'Achinese',
  ach: 'Acoli',
  ada: 'Adangme',
  ady: 'Adyghe',
  aa: 'Afar',
  afh: 'Afrihili',
  af: 'Afrikaans',
  agq: 'Aghem',
  ain: 'Ainu',
  ak: 'Akan',
  akk: 'Akkadian',
  bss: 'Akoose',
  akz: 'Alabama',
  sq: 'Albanian',
  ale: 'Aleut',
  arq: 'Algerian Arabic',
  en_us: 'American English',
  ase: 'American Sign Language',
  am: 'Amharic',
  egy: 'Ancient Egyptian',
  grc: 'Ancient Greek',
  anp: 'Angika',
  njo: 'Ao Naga',
  ar: 'Arabic',
  an: 'Aragonese',
  arc: 'Aramaic',
  aro: 'Araona',
  arp: 'Arapaho',
  arw: 'Arawak',
  hy: 'Armenian',
  rup: 'Aromanian',
  frp: 'Arpitan',
  as: 'Assamese',
  ast: 'Asturian',
  asa: 'Asu',
  cch: 'Atsam',
  en_au: 'Australian English',
  de_at: 'Austrian German',
  av: 'Avaric',
  ae: 'Avestan',
  awa: 'Awadhi',
  ay: 'Aymara',
  az: 'Azerbaijani',
  bfq: 'Badaga',
  ksf: 'Bafia',
  bfd: 'Bafut',
  bqi: 'Bakhtiari',
  ban: 'Balinese',
  bal: 'Baluchi',
  bm: 'Bambara',
  bax: 'Bamun',
  bjn: 'Banjar',
  bas: 'Basaa',
  ba: 'Bashkir',
  eu: 'Basque',
  bbc: 'Batak Toba',
  bar: 'Bavarian',
  bej: 'Beja',
  be: 'Belarusian',
  bem: 'Bemba',
  bez: 'Bena',
  bn: 'Bengali',
  bew: 'Betawi',
  bho: 'Bhojpuri',
  bik: 'Bikol',
  bin: 'Bini',
  bpy: 'Bishnupriya',
  bi: 'Bislama',
  byn: 'Blin',
  zbl: 'Blissymbols',
  brx: 'Bodo',
  bs: 'Bosnian',
  brh: 'Brahui',
  bra: 'Braj',
  pt_br: 'Brazilian Portuguese',
  br: 'Breton',
  en_gb: 'British English',
  bug: 'Buginese',
  bg: 'Bulgarian',
  bum: 'Bulu',
  bua: 'Buriat',
  my: 'Burmese',
  cad: 'Caddo',
  frc: 'Cajun French',
  en_ca: 'Canadian English',
  fr_ca: 'Canadian French',
  yue: 'Cantonese',
  cps: 'Capiznon',
  car: 'Carib',
  ca: 'Catalan',
  cay: 'Cayuga',
  ceb: 'Cebuano',
  tzm: 'Central Atlas Tamazight',
  dtp: 'Central Dusun',
  ckb: 'Central Kurdish',
  esu: 'Central Yupik',
  shu: 'Chadian Arabic',
  chg: 'Chagatai',
  ch: 'Chamorro',
  ce: 'Chechen',
  chr: 'Cherokee',
  chy: 'Cheyenne',
  chb: 'Chibcha',
  cgg: 'Chiga',
  qug: 'Chimborazo Highland Quichua',
  zh: 'Chinese',
  chn: 'Chinook Jargon',
  chp: 'Chipewyan',
  cho: 'Choctaw',
  cu: 'Church Slavic',
  chk: 'Chuukese',
  cv: 'Chuvash',
  nwc: 'Classical Newari',
  syc: 'Classical Syriac',
  ksh: 'Colognian',
  swb: 'Comorian',
  swc: 'Congo Swahili',
  cop: 'Coptic',
  kw: 'Cornish',
  co: 'Corsican',
  cr: 'Cree',
  mus: 'Creek',
  crh: 'Crimean Turkish',
  hr: 'Croatian',
  cs: 'Czech',
  dak: 'Dakota',
  da: 'Danish',
  dar: 'Dargwa',
  dzg: 'Dazaga',
  del: 'Delaware',
  din: 'Dinka',
  dv: 'Divehi',
  doi: 'Dogri',
  dgr: 'Dogrib',
  dua: 'Duala',
  nl: 'Dutch',
  dyu: 'Dyula',
  dz: 'Dzongkha',
  frs: 'Eastern Frisian',
  efi: 'Efik',
  arz: 'Egyptian Arabic',
  eka: 'Ekajuk',
  elx: 'Elamite',
  ebu: 'Embu',
  egl: 'Emilian',
  en: 'English',
  myv: 'Erzya',
  eo: 'Esperanto',
  et: 'Estonian',
  pt_pt: 'European Portuguese',
  es_es: 'European Spanish',
  ee: 'Ewe',
  ewo: 'Ewondo',
  ext: 'Extremaduran',
  fan: 'Fang',
  fat: 'Fanti',
  fo: 'Faroese',
  hif: 'Fiji Hindi',
  fj: 'Fijian',
  fil: 'Filipino',
  fi: 'Finnish',
  nl_be: 'Flemish',
  fon: 'Fon',
  gur: 'Frafra',
  fr: 'French',
  fur: 'Friulian',
  ff: 'Fulah',
  gaa: 'Ga',
  gag: 'Gagauz',
  gl: 'Galician',
  gan: 'Gan Chinese',
  lg: 'Ganda',
  gay: 'Gayo',
  gba: 'Gbaya',
  gez: 'Geez',
  ka: 'Georgian',
  de: 'German',
  aln: 'Gheg Albanian',
  bbj: 'Ghomala',
  glk: 'Gilaki',
  gil: 'Gilbertese',
  gom: 'Goan Konkani',
  gon: 'Gondi',
  gor: 'Gorontalo',
  got: 'Gothic',
  grb: 'Grebo',
  el: 'Greek',
  gn: 'Guarani',
  gu: 'Gujarati',
  guz: 'Gusii',
  gwi: 'Gwich\u02bcin',
  hai: 'Haida',
  ht: 'Haitian',
  hak: 'Hakka Chinese',
  ha: 'Hausa',
  haw: 'Hawaiian',
  he: 'Hebrew',
  hz: 'Herero',
  hil: 'Hiligaynon',
  hi: 'Hindi',
  ho: 'Hiri Motu',
  hit: 'Hittite',
  hmn: 'Hmong',
  hu: 'Hungarian',
  hup: 'Hupa',
  iba: 'Iban',
  ibb: 'Ibibio',
  is: 'Icelandic',
  io: 'Ido',
  ig: 'Igbo',
  ilo: 'Iloko',
  smn: 'Inari Sami',
  id: 'Indonesian',
  izh: 'Ingrian',
  inh: 'Ingush',
  ia: 'Interlingua',
  ie: 'Interlingue',
  iu: 'Inuktitut',
  ik: 'Inupiaq',
  ga: 'Irish',
  it: 'Italian',
  jam: 'Jamaican Creole English',
  ja: 'Japanese',
  jv: 'Javanese',
  kaj: 'Jju',
  dyo: 'Jola-Fonyi',
  jrb: 'Judeo-Arabic',
  jpr: 'Judeo-Persian',
  jut: 'Jutish',
  kbd: 'Kabardian',
  kea: 'Kabuverdianu',
  kab: 'Kabyle',
  kac: 'Kachin',
  kgp: 'Kaingang',
  kkj: 'Kako',
  kl: 'Kalaallisut',
  kln: 'Kalenjin',
  xal: 'Kalmyk',
  kam: 'Kamba',
  kbl: 'Kanembu',
  kn: 'Kannada',
  kr: 'Kanuri',
  kaa: 'Kara-Kalpak',
  krc: 'Karachay-Balkar',
  krl: 'Karelian',
  ks: 'Kashmiri',
  csb: 'Kashubian',
  kaw: 'Kawi',
  kk: 'Kazakh',
  ken: 'Kenyang',
  kha: 'Khasi',
  km: 'Khmer',
  kho: 'Khotanese',
  khw: 'Khowar',
  ki: 'Kikuyu',
  kmb: 'Kimbundu',
  krj: 'Kinaray-a',
  rw: 'Kinyarwanda',
  kiu: 'Kirmanjki',
  tlh: 'Klingon',
  bkm: 'Kom',
  kv: 'Komi',
  koi: 'Komi-Permyak',
  kg: 'Kongo',
  kok: 'Konkani',
  ko: 'Korean',
  kfo: 'Koro',
  kos: 'Kosraean',
  avk: 'Kotava',
  khq: 'Koyra Chiini',
  ses: 'Koyraboro Senni',
  kpe: 'Kpelle',
  kri: 'Krio',
  kj: 'Kuanyama',
  kum: 'Kumyk',
  ku: 'Kurdish',
  kru: 'Kurukh',
  kut: 'Kutenai',
  nmg: 'Kwasio',
  ky: 'Kyrgyz',
  quc: 'K\u02bciche\u02bc',
  lad: 'Ladino',
  lah: 'Lahnda',
  lkt: 'Lakota',
  lam: 'Lamba',
  lag: 'Langi',
  lo: 'Lao',
  ltg: 'Latgalian',
  la: 'Latin',
  es_419: 'Latin American Spanish',
  lv: 'Latvian',
  lzz: 'Laz',
  lez: 'Lezghian',
  lij: 'Ligurian',
  li: 'Limburgish',
  ln: 'Lingala',
  lfn: 'Lingua Franca Nova',
  lzh: 'Literary Chinese',
  lt: 'Lithuanian',
  liv: 'Livonian',
  jbo: 'Lojban',
  lmo: 'Lombard',
  nds: 'Low German',
  sli: 'Lower Silesian',
  dsb: 'Lower Sorbian',
  loz: 'Lozi',
  lu: 'Luba-Katanga',
  lua: 'Luba-Lulua',
  lui: 'Luiseno',
  smj: 'Lule Sami',
  lun: 'Lunda',
  luo: 'Luo',
  lb: 'Luxembourgish',
  luy: 'Luyia',
  mde: 'Maba',
  mk: 'Macedonian',
  jmc: 'Machame',
  mad: 'Madurese',
  maf: 'Mafa',
  mag: 'Magahi',
  vmf: 'Main-Franconian',
  mai: 'Maithili',
  mak: 'Makasar',
  mgh: 'Makhuwa-Meetto',
  kde: 'Makonde',
  mg: 'Malagasy',
  ms: 'Malay',
  ml: 'Malayalam',
  mt: 'Maltese',
  mnc: 'Manchu',
  mdr: 'Mandar',
  man: 'Mandingo',
  mni: 'Manipuri',
  gv: 'Manx',
  mi: 'Maori',
  arn: 'Mapuche',
  mr: 'Marathi',
  chm: 'Mari',
  mh: 'Marshallese',
  mwr: 'Marwari',
  mas: 'Masai',
  mzn: 'Mazanderani',
  byv: 'Medumba',
  men: 'Mende',
  mwv: 'Mentawai',
  mer: 'Meru',
  mgo: 'Meta\u02bc',
  es_mx: 'Mexican Spanish',
  mic: 'Micmac',
  dum: 'Middle Dutch',
  enm: 'Middle English',
  frm: 'Middle French',
  gmh: 'Middle High German',
  mga: 'Middle Irish',
  nan: 'Min Nan Chinese',
  min: 'Minangkabau',
  xmf: 'Mingrelian',
  mwl: 'Mirandese',
  lus: 'Mizo',
  ar_001: 'Modern Standard Arabic',
  moh: 'Mohawk',
  mdf: 'Moksha',
  ro_md: 'Moldavian',
  lol: 'Mongo',
  mn: 'Mongolian',
  mfe: 'Morisyen',
  ary: 'Moroccan Arabic',
  mos: 'Mossi',
  mul: 'Multiple Languages',
  mua: 'Mundang',
  ttt: 'Muslim Tat',
  mye: 'Myene',
  naq: 'Nama',
  na: 'Nauru',
  nv: 'Navajo',
  ng: 'Ndonga',
  nap: 'Neapolitan',
  ne: 'Nepali',
  new: 'Newari',
  sba: 'Ngambay',
  nnh: 'Ngiemboon',
  jgo: 'Ngomba',
  yrl: 'Nheengatu',
  nia: 'Nias',
  niu: 'Niuean',
  zxx: 'No linguistic content',
  nog: 'Nogai',
  nd: 'North Ndebele',
  frr: 'Northern Frisian',
  se: 'Northern Sami',
  nso: 'Northern Sotho',
  no: 'Norwegian',
  nb: 'Norwegian Bokm\u00e5l',
  nn: 'Norwegian Nynorsk',
  nov: 'Novial',
  nus: 'Nuer',
  nym: 'Nyamwezi',
  ny: 'Nyanja',
  nyn: 'Nyankole',
  tog: 'Nyasa Tonga',
  nyo: 'Nyoro',
  nzi: 'Nzima',
  nqo: 'N\u02bcKo',
  oc: 'Occitan',
  oj: 'Ojibwa',
  ang: 'Old English',
  fro: 'Old French',
  goh: 'Old High German',
  sga: 'Old Irish',
  non: 'Old Norse',
  peo: 'Old Persian',
  pro: 'Old Proven\u00e7al',
  or: 'Oriya',
  om: 'Oromo',
  osa: 'Osage',
  os: 'Ossetic',
  ota: 'Ottoman Turkish',
  pal: 'Pahlavi',
  pfl: 'Palatine German',
  pau: 'Palauan',
  pi: 'Pali',
  pam: 'Pampanga',
  pag: 'Pangasinan',
  pap: 'Papiamento',
  ps: 'Pashto',
  pdc: 'Pennsylvania German',
  fa: 'Persian',
  phn: 'Phoenician',
  pcd: 'Picard',
  pms: 'Piedmontese',
  pdt: 'Plautdietsch',
  pon: 'Pohnpeian',
  pl: 'Polish',
  pnt: 'Pontic',
  pt: 'Portuguese',
  prg: 'Prussian',
  pa: 'Punjabi',
  qu: 'Quechua',
  raj: 'Rajasthani',
  rap: 'Rapanui',
  rar: 'Rarotongan',
  rif: 'Riffian',
  rgn: 'Romagnol',
  ro: 'Romanian',
  rm: 'Romansh',
  rom: 'Romany',
  rof: 'Rombo',
  root: 'Root',
  rtm: 'Rotuman',
  rug: 'Roviana',
  rn: 'Rundi',
  ru: 'Russian',
  rue: 'Rusyn',
  rwk: 'Rwa',
  ssy: 'Saho',
  sah: 'Sakha',
  sam: 'Samaritan Aramaic',
  saq: 'Samburu',
  sm: 'Samoan',
  sgs: 'Samogitian',
  sad: 'Sandawe',
  sg: 'Sango',
  sbp: 'Sangu',
  sa: 'Sanskrit',
  sat: 'Santali',
  sc: 'Sardinian',
  sas: 'Sasak',
  sdc: 'Sassarese Sardinian',
  stq: 'Saterland Frisian',
  saz: 'Saurashtra',
  sco: 'Scots',
  gd: 'Scottish Gaelic',
  sly: 'Selayar',
  sel: 'Selkup',
  seh: 'Sena',
  see: 'Seneca',
  sr: 'Serbian',
  sh: 'Serbo-Croatian',
  srr: 'Serer',
  sei: 'Seri',
  ksb: 'Shambala',
  shn: 'Shan',
  sn: 'Shona',
  ii: 'Sichuan Yi',
  scn: 'Sicilian',
  sid: 'Sidamo',
  bla: 'Siksika',
  szl: 'Silesian',
  zh_hans: 'Simplified Chinese',
  sd: 'Sindhi',
  si: 'Sinhala',
  sms: 'Skolt Sami',
  den: 'Slave',
  sk: 'Slovak',
  sl: 'Slovenian',
  xog: 'Soga',
  sog: 'Sogdien',
  so: 'Somali',
  snk: 'Soninke',
  azb: 'South Azerbaijani',
  nr: 'South Ndebele',
  alt: 'Southern Altai',
  sma: 'Southern Sami',
  st: 'Southern Sotho',
  es: 'Spanish',
  srn: 'Sranan Tongo',
  zgh: 'Standard Moroccan Tamazight',
  suk: 'Sukuma',
  sux: 'Sumerian',
  su: 'Sundanese',
  sus: 'Susu',
  sw: 'Swahili',
  ss: 'Swati',
  sv: 'Swedish',
  fr_ch: 'Swiss French',
  gsw: 'Swiss German',
  de_ch: 'Swiss High German',
  syr: 'Syriac',
  shi: 'Tachelhit',
  tl: 'Tagalog',
  ty: 'Tahitian',
  dav: 'Taita',
  tg: 'Tajik',
  tly: 'Talysh',
  tmh: 'Tamashek',
  ta: 'Tamil',
  trv: 'Taroko',
  twq: 'Tasawaq',
  tt: 'Tatar',
  te: 'Telugu',
  ter: 'Tereno',
  teo: 'Teso',
  tet: 'Tetum',
  th: 'Thai',
  bo: 'Tibetan',
  tig: 'Tigre',
  ti: 'Tigrinya',
  tem: 'Timne',
  tiv: 'Tiv',
  tli: 'Tlingit',
  tpi: 'Tok Pisin',
  tkl: 'Tokelau',
  to: 'Tongan',
  fit: 'Tornedalen Finnish',
  zh_hant: 'Traditional Chinese',
  tkr: 'Tsakhur',
  tsd: 'Tsakonian',
  tsi: 'Tsimshian',
  ts: 'Tsonga',
  tn: 'Tswana',
  tcy: 'Tulu',
  tum: 'Tumbuka',
  aeb: 'Tunisian Arabic',
  tr: 'Turkish',
  tk: 'Turkmen',
  tru: 'Turoyo',
  tvl: 'Tuvalu',
  tyv: 'Tuvinian',
  tw: 'Twi',
  kcg: 'Tyap',
  udm: 'Udmurt',
  uga: 'Ugaritic',
  uk: 'Ukrainian',
  umb: 'Umbundu',
  und: 'Unknown Language',
  hsb: 'Upper Sorbian',
  ur: 'Urdu',
  ug: 'Uyghur',
  uz: 'Uzbek',
  vai: 'Vai',
  ve: 'Venda',
  vec: 'Venetian',
  vep: 'Veps',
  vi: 'Vietnamese',
  vo: 'Volap\u00fck',
  vro: 'V\u00f5ro',
  vot: 'Votic',
  vun: 'Vunjo',
  wa: 'Walloon',
  wae: 'Walser',
  war: 'Waray',
  wbp: 'Warlpiri',
  was: 'Washo',
  guc: 'Wayuu',
  cy: 'Welsh',
  vls: 'West Flemish',
  fy: 'Western Frisian',
  mrj: 'Western Mari',
  wal: 'Wolaytta',
  wo: 'Wolof',
  wuu: 'Wu Chinese',
  xh: 'Xhosa',
  hsn: 'Xiang Chinese',
  yav: 'Yangben',
  yao: 'Yao',
  yap: 'Yapese',
  ybb: 'Yemba',
  yi: 'Yiddish',
  yo: 'Yoruba',
  zap: 'Zapotec',
  dje: 'Zarma',
  zza: 'Zaza',
  zea: 'Zeelandic',
  zen: 'Zenaga',
  za: 'Zhuang',
  gbz: 'Zoroastrian Dari',
  zu: 'Zulu',
  zun: 'Zuni',
}
