import { gql } from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

export const REPORT_CAMPAIGN_STREAM_AUTOCOMPLETE_QUERY = gql`
  query CampaignStreamReportAutoCompleteQuery {
    campaignStreamReportAutoComplete {
      streams {
        id
        uid
        title
        user {
          id
        }
      }
    }
  }
`

export function useCampaignStreamReportAutocompleteQuery() {
  const { loading, error, data, refetch } = useQuery(REPORT_CAMPAIGN_STREAM_AUTOCOMPLETE_QUERY, {
    variables: {},
  })
  return { loading, error, data, refetch }
}
