import { z } from 'zod';

export const CreativeModerationStatus = {
  REVIEW: 'REVIEW',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
} as const;

export type CreativeModerationStatus =
  (typeof CreativeModerationStatus)[keyof typeof CreativeModerationStatus];

export const CreativeModerationStatusEnum = z.enum([
  CreativeModerationStatus.REVIEW,
  CreativeModerationStatus.APPROVED,
  CreativeModerationStatus.DECLINED,
]);
