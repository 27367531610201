import * as Sentry from '@sentry/react'
import { Form, Modal, Select, Spin } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PUSH_CREATIVE_BODY_IMG_TYPE, PUSH_CREATIVE_PREVIEW_IMG_TYPE } from '../../common/types/creativeImageTypes'
import ImageCropper, { useImageCropperController } from '../../components/ImageCropper/ImageCropper'
import Input from '../../components/forms/Input/Input'
import LabelWithTooltip from '../../components/forms/LabelWithTooltip/LabelWithTooltip'

import showNotification from '../../helpers/showNotification'
import { useAppState } from '../../context/AppContext'
import React from 'react'
import { ICreative } from '../../pages/creative/creative-list.page'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

const CreativeForm: FC<{
  creative?: ICreative
  refetch: () => void
  campaignId: number
  visible: boolean
  setVisible: any
}> = ({ creative = {} as ICreative, refetch, campaignId, visible, setVisible }) => {
  const { t } = useTranslation()
  const { apiClient } = useAppState()
  const [isLoading, setLoading] = useState(false)
  const initialValues = {
    ...creative,
    tags: creative.tags || [],
  }
  const creativeId = creative && creative.id
  const getCreativesTagsResult = apiClient.creative.getCreativesTags.useQuery(['creatives-tags'])

  const creativesTags = getCreativesTagsResult.data?.body.tags || []
  useEffect(() => {
    if (visible) {
      getCreativesTagsResult.refetch()
    }
  }, [visible])

  const { mutateAsync: saveCreative } = creativeId
    ? apiClient.creative.updateCreative.useMutation({
        onSettled: () => {
          refetch()
        },
      })
    : apiClient.creative.createCreative.useMutation({
        onSettled: () => {
          refetch()
        },
      })

  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [JSON.stringify(initialValues)])

  const previewImgController = useImageCropperController()
  const bodyImgController = useImageCropperController()

  async function onFinish(formValues: any) {
    setLoading(true)

    if (creative && !creative.previewImgUrl && !previewImgController.image.src) {
      setLoading(false)
      showNotification({ type: 'error', message: 'Preview image is required' })
      return
    }

    if (creative && !creative.bodyImgUrl && !bodyImgController.image.src) {
      setLoading(false)
      showNotification({ type: 'error', message: 'Body image is required' })
      return
    }

    const previewImg = await previewImgController.getImageUpload()
    const bodyImg = await bodyImgController.getImageUpload()

    const fd = new FormData()

    if (creativeId) {
      fd.append('id', `${creativeId}`)
    }
    fd.append('campaignId', `${campaignId}`)
    fd.append('title', formValues.title)
    fd.append('body', formValues.body)
    fd.append('tags', formValues.tags)
    fd.append('previewImg', previewImg as any)
    fd.append('bodyImg', bodyImg as any)

    saveCreative({
      body: fd,
      extraHeaders: {
        'Content-Type': undefined,
      },
    })
      .then(() => {
        showNotification({ type: 'success', message: t('Messages.Saved') })
        setVisible(false)
        setLoading(false)
        form.resetFields()
        previewImgController.clearImage()
        bodyImgController.clearImage()
      })
      .catch((err: any) => {
        showNotification({
          type: 'error',
          message: `${t('Common.Error')}: ${err.message ? err.message : 'Can not save creative'}`,
        })
        setLoading(false)

        if (process.env.NODE_ENV !== 'development') Sentry.captureException(err)
      })
  }

  if (!visible) return <></>

  return (
    <Modal
      destroyOnClose={true}
      title={creative.id ? t('CreativeForm.Edit creative') : t('CreativeForm.Add creative')}
      open={visible}
      onOk={() => form.submit()}
      onCancel={() => setVisible(false)}
      width={920}
      confirmLoading={isLoading}
    >
      <Spin spinning={isLoading}>
        <Form form={form} onFinish={onFinish} initialValues={initialValues} {...formItemLayout}>
          <>
            <Form.Item required label={t('CreativeForm.Preview Image')} tooltip="Size: 196x196px">
              <ImageCropper
                type={PUSH_CREATIVE_PREVIEW_IMG_TYPE}
                title={t('CreativeForm.Preview Image')}
                imageCropperController={previewImgController}
                description={'Size: 196x196px'}
                width={196}
                height={196}
                creative={creative}
              />
            </Form.Item>
            <Form.Item required label={t('CreativeForm.Body Image')} tooltip="Size: 492x328px">
              <ImageCropper
                type={PUSH_CREATIVE_BODY_IMG_TYPE}
                imageCropperController={bodyImgController}
                title={t('CreativeForm.Body Image')}
                description={'Size: 492x328px'}
                width={492}
                height={328}
                creative={creative}
              />
            </Form.Item>
          </>
          <Input label={t('Creative.Title')} name="title" isRequired validators={[{ max: 80 }]} />
          <Input label={t('Creative.Body')} name="body" isRequired validators={[{ max: 150 }]} />
          <Form.Item
            name="tags"
            label={<LabelWithTooltip label={t('Creative.Tags')} labelTooltip={t('Creative.Tags Help')} />}
          >
            <Select
              mode="tags"
              allowClear
              options={creativesTags.map((tag: string) => ({
                label: tag,
                value: tag,
              }))}
              // span={3}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

export default CreativeForm
