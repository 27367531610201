import { createApiRequest } from '../../../../api/http-client'

/**
 * @param {Object} params
 * @param {string} params.regions
 * @param {Array<string>} params.trafficTypes
 * @param {Array<number>} params.ids
 * @param {boolean} param.isActive
 * @param {number} params.limit
 * @param {number} params.offset
 */
export const fetchDomains = async params => {
  const data = await createApiRequest('POST', `/domain/get`, params)

  return data
}

/**
 * @param {Object} params
 * @param {string} params.id
 */
export const fetchDomain = async params => {
  const data = await createApiRequest('POST', `/domain/get/${params.id}`)

  return data
}

/**
 * @param {Object} params
 * @param {string} params.region
 * @param {Array<string>} params.trafficType
 * @param {boolean} param.isActive
 */
export const createDomain = async params => {
  const data = await createApiRequest('POST', `/domain/create`, params)

  return data
}

/**
 * @param {Object} params
 * @param {string} params.id
 * @param {string} params.region
 * @param {Array<string>} params.trafficType
 * @param {boolean} param.isActive
 */
export const updateDomain = async params => {
  const data = await createApiRequest('POST', `/domain/update/${params.id}`, params)

  return data
}

/**
 * @param {Object} params
 * @param {string} params.id
 */
export const deleteDomain = async params => {
  const data = await createApiRequest('POST', `/domain/delete/${params.id}`)

  return data
}
