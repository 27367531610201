import { DeleteOutlined, DeleteTwoTone, RedoOutlined } from '@ant-design/icons'
// @ts-ignore
import { useBulkDeleteCampaignsMutation } from '@pushflow/server/src/graphql/new/campaigns/bulkDeleteCampaigns/operations'
// @ts-ignore
import { useDeleteCampaign } from '@pushflow/server/src/graphql/new/campaigns/deleteCampaign/operations'
import * as Sentry from '@sentry/react'
import { Button, Checkbox, Modal, Space, Table, Tag } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PUSH } from '../../../common/types/campaignTypes'
import { FixedTableFooter } from '../../../components/FixedTableFooter/FixedTableFooter'
import DeleteButton from '../../../components/buttons/DeleteButton/DeleteButton'
import { useAppState } from '../../../context/AppContext'
import showNotification from '../../../helpers/showNotification'
import { useArchivedCampaigns, useRestoreCampaignMutation } from './hooks'
import PopConfirmButton from '../../../components/buttons/PopConfirmButton/PopConfirmButton'
import { UserRole } from '@pushflow/backend-http-contract'

const RestoreCampaignIcon: FC<{
  id: number
  refetch: () => void
}> = ({ id, refetch }) => {
  const { mutate: restoreCampagin } = useRestoreCampaignMutation()
  return (
    <PopConfirmButton
      isOnlyIcon
      prompt={'Are you sure you want to restore campaign?'}
      okText={'Restore'}
      icon={<RedoOutlined />}
      placement={'top'}
      onClick={() =>
        restoreCampagin(id, {
          onSuccess: () => {
            showNotification({
              type: 'success',
              message: 'Campaign successfully restored',
            })
            refetch()
          },
          onError: () => {
            showNotification({
              type: 'error',
              message: 'Failed to restore campaign',
            })
          },
        })
      }
    >
      <RedoOutlined />
    </PopConfirmButton>
  )
}
const DeleteCampaignIcon: FC<{
  id: number
  refetch: () => void
}> = ({ id, refetch }) => {
  const { mutate: deleteCampagin } = useDeleteCampaign()
  return (
    <DeleteButton
      key="3"
      isOnlyIcon
      onClick={() =>
        deleteCampagin({
          variables: { id },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteCampaign: {
              id,
              isSuccess: true,
              __typename: 'isSuccess',
            },
          },
        }).then(() => {
          showNotification({
            type: 'success',
            message: 'Campaign successfully deleted',
          })
          refetch()
        })
      }
    >
      <DeleteTwoTone />
    </DeleteButton>
  )
}

const BulkDeleteButton: FC<{
  selectedCampaignIds: number[]
  allCampaigns: any[]
  setSelectedCampaignIds: (ids: number[]) => void
  refetch: () => void
}> = ({ selectedCampaignIds, allCampaigns, setSelectedCampaignIds, refetch }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleCancel = () => {
    setOpen(false)
  }
  const { mutate: bulkDeleteCampaigns } = useBulkDeleteCampaignsMutation()
  const handle = () => {
    setLoading(true)
    bulkDeleteCampaigns({
      variables: {
        input: { ids: selectedCampaignIds },
      },
    })
      .then((result: any) => {
        const errorMessages = result.data?.bulkDeleteCampaigns.errorMessages || []
        const deletedCampaignsIds = result.data?.bulkDeleteCampaigns.deletedCampaignsIds || []
        const newSelectedCampaignIds = selectedCampaignIds.filter(id => !deletedCampaignsIds.includes(id))
        setSelectedCampaignIds(newSelectedCampaignIds)
        if (deletedCampaignsIds.length) {
          showNotification({
            type: 'success',
            message: `${t('CampaignsListPage.Success Delete Count')} (${deletedCampaignsIds.length})`,
          })
        }
        for (const message of errorMessages) {
          showNotification({ type: 'error', message })
        }
      })
      .catch((err: any) => {
        showNotification({ type: 'error', message: err.message })
        if (process.env.NODE_ENV !== 'development') {
          Sentry.captureException(err)
        }
      })
      .finally(() => {
        refetch()
        setLoading(false)
        setOpen(false)
      })
  }
  return (
    <>
      <Button disabled={!selectedCampaignIds.length} icon={<DeleteOutlined />} onClick={() => setOpen(true)} />
      <Modal
        open={open}
        title={t('CampaignsListPage.Bulk Delete Title')}
        onOk={() => handle()}
        onCancel={() => handleCancel()}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={loading}>
            {t('CampaignsListPage.Cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            disabled={!selectedCampaignIds.length || loading}
            onClick={() => handle()}
          >
            {t('CampaignsListPage.Bulk Delete Button')}
          </Button>,
        ]}
      >
        <Table
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
            },
            {
              title: 'Title',
              dataIndex: 'title',
              key: 'title',
            },
          ]}
          dataSource={allCampaigns.filter(campaign => selectedCampaignIds.includes(campaign.id))}
          pagination={false}
        />
      </Modal>
    </>
  )
}

export const ArchivedCampaignsList: FC = () => {
  const { user } = useAppState()

  /** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
  const [limit, setLimit] = useState(100)
  /** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
  const [offset, setOffset] = useState(0)

  const { t } = useTranslation()
  const { isLoading, data, refetch } = useArchivedCampaigns({ offset, limit })

  const totalCount = data?.campaigns.length || 0
  const campaigns: { id: number }[] = data?.campaigns || []
  const allCampaignsIds = campaigns.map(({ id }) => id)
  const [selectedCampaignIds, setSelectedCampaignIds] = useState<number[]>([])

  useEffect(() => {
    refetch()
  }, [offset])

  const showLoading = isLoading && !data

  let columns = [
    {
      title: () => {
        return (
          <Checkbox
            indeterminate={selectedCampaignIds.length > 0 && selectedCampaignIds.length < totalCount}
            checked={selectedCampaignIds.length === totalCount}
            onChange={() => {
              if (selectedCampaignIds.length === totalCount) {
                setSelectedCampaignIds([])
              } else {
                setSelectedCampaignIds(allCampaignsIds)
              }
            }}
          />
        )
      },
      render: (_: any, record: any) => {
        return (
          <Checkbox
            checked={selectedCampaignIds.includes(record.id)}
            onChange={() => {
              if (selectedCampaignIds.includes(record.id)) {
                setSelectedCampaignIds(selectedCampaignIds.filter(id => id !== record.id))
              } else {
                setSelectedCampaignIds([...selectedCampaignIds, record.id])
              }
            }}
          />
        )
      },
      width: 30,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      width: 70,
      render: (text: string, record: any) => (
        <Link to={`/campaign/${record.id}`}>
          <b>{text}</b>
        </Link>
      ),
    },
    {
      title: t('Campaign.Title'),
      dataIndex: 'title',
      width: 200,
      render: (text: string, record: any) => <Link to={`/campaign/${record.id}`}>{<b>{text}</b>}</Link>,
    },
    {
      title: t('Campaign.CampaignTypeShort'),
      dataIndex: 'campaignType',
      render: (_: any, { campaignType }: any) =>
        campaignType === PUSH ? <Tag color="purple">{campaignType}</Tag> : <Tag color="blue">{campaignType}</Tag>,
      width: 70,
    },
    {
      title: t('Table.Actions'),
      key: 'action',
      render: (_: any, record: any) => {
        return (
          <Space size="large">
            <DeleteCampaignIcon id={record.id} refetch={refetch} />
            <RestoreCampaignIcon id={record.id} refetch={refetch} />
          </Space>
        )
      },
    },
  ]

  if (user && user.roles.includes(UserRole.ADMIN)) {
    columns = [
      columns[0],
      columns[1],
      {
        title: 'User ID',
        dataIndex: 'userId',
        width: 70,
        render: (text, record) => (
          <Link to={`/admin/users/${record.user_id}`}>
            <b>{text}</b>
          </Link>
        ),
      },
      ...columns.slice(2),
    ]
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={campaigns}
        loading={showLoading}
        rowKey="id"
        size="middle"
        scroll={{ x: true }}
        sticky={true}
        pagination={{
          showSizeChanger: true,
          pageSize: limit,
          current: offset + 1,
          total: totalCount,
          pageSizeOptions: [10, 20, 50, 100, 400],
          onChange(page, newLimit) {
            if (newLimit !== limit) {
              const firstItemIndexInCurrentPage = offset * limit
              const pageWhereItemWillBeAfterNewLimit = Math.floor(firstItemIndexInCurrentPage / newLimit)
              setOffset(pageWhereItemWillBeAfterNewLimit)
              setLimit(newLimit)
            } else if (page - 1 !== offset) {
              setOffset(page - 1)
            }
          },
        }}
        footer={() => {
          return (
            <FixedTableFooter>
              <Space>
                <BulkDeleteButton
                  selectedCampaignIds={selectedCampaignIds}
                  allCampaigns={campaigns}
                  setSelectedCampaignIds={setSelectedCampaignIds}
                  refetch={refetch}
                />
              </Space>
            </FixedTableFooter>
          )
        }}
      />
    </>
  )
}
