import { AUTH_TOKEN } from '../../../constant'

export const fetchInventory = async data => {
  const token = localStorage.getItem(AUTH_TOKEN)

  const res = await fetch('/api/inventory', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  if (res.status !== 200) {
    throw new Error('Error fetching inventory')
  }

  return res.json()
}

export const fetchInventorySettings = async () => {
  const token = localStorage.getItem(AUTH_TOKEN)

  const res = await fetch('/api/inventory/settings', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.status !== 200) {
    throw new Error('Error fetching inventory settings')
  }

  return res.json()
}

export const createInventorySettings = async data => {
  const token = localStorage.getItem(AUTH_TOKEN)

  const res = await fetch('/api/inventory/settings', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  if (res.status !== 200) {
    throw new Error('Error creating inventory settings')
  }

  return res.json()
}

export const updateInventorySettings = async data => {
  const token = localStorage.getItem(AUTH_TOKEN)

  const res = await fetch('/api/inventory/settings', {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  if (res.status !== 200) {
    throw new Error('Error updating inventory settings')
  }

  return res.json()
}

export const deleteInventorySettings = async id => {
  const token = localStorage.getItem(AUTH_TOKEN)

  const res = await fetch(`/api/inventory/settings/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (res.status !== 200) {
    throw new Error('Error removing inventory settings')
  }

  return res.json()
}
