import { Button as AntButton } from 'antd'
import React, { FC, ReactNode } from 'react'

const Button: FC<{
  [key: string]: any
  children?: ReactNode
}> = ({ children, ...props }) => {
  return (
    <AntButton className="Button" {...props}>
      {children}
    </AntButton>
  )
}

export default Button
