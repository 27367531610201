import PageHeader from '../../../components/layout/PageHeader/PageHeader'
import React from 'antd'
import { UserCreateForm } from './components/user-create-form'

export const AdminUserNewPage = () => {
  return (
    <>
      <PageHeader breadcrumbs={[{ title: 'Users', link: '/admin/users' }, { title: 'New User' }]} title="New User" />
      <UserCreateForm />
    </>
  )
}
