import { Button, Popconfirm, Spin, Table, Tag, Tooltip } from 'antd'
import {
  useCampaignRules,
  useDeleteCampaignRuleMutation,
  useRunRuleAgainMutation,
} from '../../../containers/campaign/hooks'
import { Link, useNavigate } from 'react-router-dom'
import PageListHeader from '../../../components/layout/PageListHeader/PageListHeader'
import {
  OrderedListOutlined,
  PlusOutlined,
  QuestionCircleFilled,
  ReloadOutlined,
  RollbackOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import {
  RuleActionView,
  RuleConditionView,
} from '../../../containers/campaign/components/CampaignRules/CampaignRulesForm'
import moment from 'moment'
import { useCampaignPrices } from './hooks/index'
import React, { FC } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { CampaignRuleWithData } from '../../../types/campaign-rule'
import { CreateRuleFromTemplateModal } from '../../../containers/campaign/components/CampaignRules/RuleTemplateModal'

export const CampaignRulesList: FC<{ params: { campaignId: number } }> = ({ params }) => {
  const { data: rules, isLoading, isError, error } = useCampaignRules(params.campaignId)
  const navigate = useNavigate()

  const { data: campaignPrices } = useCampaignPrices()

  if (isLoading && !rules) return <Spin />
  if (isError) return <>`${error}`</>

  const columns: ColumnsType<CampaignRuleWithData> = [
    {
      title: 'Rule ID',
      dataIndex: 'id',
      width: 50,
      render: (text, rec) => (
        <Link to={`/rules/${params.campaignId}/${rec.id}`}>
          <b>{text}</b>
        </Link>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      width: 100,
      render: (text, rec) => (
        <Link to={`/rules/${params.campaignId}/${rec.id}`}>
          <b>{text ?? '-'}</b>
        </Link>
      ),
    },
    {
      title: (
        <>
          Check/trigger status&nbsp;
          <Tooltip title="Check - last rule conditions check time, Trigger - last time when rule actions were applied">
            <QuestionCircleFilled />
          </Tooltip>
        </>
      ),
      width: 60,
      render: (text, rec) => {
        return (
          <>
            {rec.lastCheckedAt ? (
              <Tooltip title="When rule was checked last time">
                <Tag color="blue">{moment(rec.lastCheckedAt).format('YYYY-MM-DD HH:mm')}</Tag>
              </Tooltip>
            ) : null}
            {rec.lastTriggeredAt ? (
              <Tooltip title="When rule was triggered last time">
                <Tag color="red">{moment(rec.lastTriggeredAt).format('YYYY-MM-DD HH:mm')}</Tag>
              </Tooltip>
            ) : null}
          </>
        )
      },
    },
    {
      title: (
        <>
          Status&nbsp;
          <Tooltip title="Active - rule will be checked next time, Inactive - rule check will be skipped">
            <QuestionCircleFilled />
          </Tooltip>
        </>
      ),
      width: 80,
      render: (text, rec) => {
        const isBidAction =
          rec.data.ruleAction.action === 'increase_bid' || rec.data.ruleAction.action === 'decrease_bid'

        const target = rec.data.ruleAction.rulesTarget
        const targetId = rec.data.ruleAction.targetId
        let price = 0

        if (!campaignPrices) {
          return <></>
        }
        if (target === 'campaign') {
          price = campaignPrices[targetId].price
        }
        if (target === 'stream') {
          price = campaignPrices[params.campaignId].streamBids[targetId]?.bid || campaignPrices[params.campaignId].price
        }
        if (target === 'substream') {
          const [s] = targetId.split(':')
          price =
            campaignPrices[params.campaignId].substreamBids[targetId]?.bid ||
            campaignPrices[params.campaignId].streamBids[s]?.bid ||
            campaignPrices[params.campaignId].price
        }

        if (rec.data.ruleAction.action === 'increase_bid') {
          if (price + rec.data.ruleAction.value <= rec.data.ruleAction.limit) {
            return <Tag color="green">Active</Tag>
          } else {
            return <Tag color="red">Inactive</Tag>
          }
        }

        if (rec.data.ruleAction.action === 'decrease_bid') {
          if (price - rec.data.ruleAction.value >= rec.data.ruleAction.limit) {
            return <Tag color="green">Active</Tag>
          } else {
            return <Tag color="red">Inactive</Tag>
          }
        }

        const isActive = isBidAction ? true : !rec.lastTriggeredAt

        return <>{isActive ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>}</>
      },
    },
    {
      title: 'Rule condition',
      dataIndex: 'data',
      width: 200,
      render: (text, rec) => <RuleConditionView rules={text.rules} rule={rec} />,
    },
    {
      title: 'Rule action',
      dataIndex: 'data',
      width: 200,
      render: (text, rec) => {
        let price = 0
        if (!campaignPrices) {
          return <></>
        }
        if (rec.data.ruleAction.rulesTarget === 'campaign') {
          price = campaignPrices[rec.data.ruleAction.targetId].price
        }
        if (rec.data.ruleAction.rulesTarget === 'stream') {
          price =
            campaignPrices[params.campaignId].streamBids[rec.data.ruleAction.targetId]?.bid ||
            campaignPrices[params.campaignId].price
        }
        if (rec.data.ruleAction.rulesTarget === 'substream') {
          const [s] = rec.data.ruleAction.targetId.split(':')
          price =
            campaignPrices[params.campaignId].substreamBids[rec.data.ruleAction.targetId]?.bid ||
            campaignPrices[params.campaignId].streamBids[s]?.bid ||
            campaignPrices[params.campaignId].price
        }

        return <RuleActionView rule={text} currentPrice={price} />
      },
    },
    {
      title: 'Actions',
      width: 200,
      render: (_, rec) => (
        <>
          <Button
            onClick={() => {
              navigate(`/rules/${params.campaignId}/${rec.id}`)
            }}
          >
            Edit
          </Button>
          <DeleteRuleButton ruleId={rec.id} campaignId={params.campaignId} />
          {rec.data.ruleAction.action !== 'increase_bid' &&
          rec.data.ruleAction.action !== 'decrease_bid' &&
          rec.lastTriggeredAt ? (
            <RunRuleAgainButton ruleId={rec.id} campaignId={params.campaignId} />
          ) : (
            <></>
          )}
        </>
      ),
    },
  ]
  return (
    <>
      <PageListHeader
        title={`Rules for campaign ${params.campaignId}`}
        buttonSize="small"
        buttons={[
          {
            link: `/rules/${params.campaignId}/create`,
            type: 'primary',
            icon: <PlusOutlined />,
            title: 'Create',
          },
          {
            link: `/campaign/${params.campaignId}`,
            type: 'primary',
            icon: <RollbackOutlined />,
            title: `Go To ${params.campaignId} Campaign`,
          },
          {
            link: `/campaign`,
            type: 'primary',
            icon: <OrderedListOutlined />,
            title: 'Go To Campaigns',
          },
        ]}
      />
      <CreateRuleFromTemplateModal campaignId={params.campaignId} />
      <Table rowKey={'id'} dataSource={rules} columns={columns as ColumnsType<object>} />
    </>
  )
}

const DeleteRuleButton = (params: { ruleId: number; campaignId: number }) => {
  const { ruleId, campaignId } = params
  const { t } = useTranslation()
  const { mutate: deleteRule } = useDeleteCampaignRuleMutation({ campaignId, ruleId })
  return (
    <Popconfirm title={'Are you sure to delete this rule?'} onConfirm={() => deleteRule({ campaignId, ruleId })}>
      <Button type="primary" danger>
        {t('Actions.Delete')}
      </Button>
    </Popconfirm>
  )
}

const RunRuleAgainButton = (params: { ruleId: number; campaignId: number }) => {
  const { ruleId, campaignId } = params
  const { mutate: runRuleAgain } = useRunRuleAgainMutation({ campaignId, ruleId })
  return (
    <Popconfirm title={'Are you sure to run this rule again?'} onConfirm={() => runRuleAgain({ campaignId, ruleId })}>
      <Button type="dashed" icon={<ReloadOutlined />}>
        Run Again
      </Button>
    </Popconfirm>
  )
}
