import { getCountryCodes } from '../../../common/static/countryCodes'
import { getLangName } from '../../../common/static/langCodes'

const numberFormatter = Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 2 })

export const formatNumber = params => {
  return numberFormatter.format(params.value)
}

const percentFormatter = Intl.NumberFormat('en', { style: 'percent', maximumFractionDigits: 2 })

export const formatPercent = params => {
  return percentFormatter.format(params.value)
}

const currencyFormatter = Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 4,
})

export const formatCurrency = params => {
  return currencyFormatter.format(params.value)
}

export const formatCountry = params => {
  const country = getCountryCodes(params.value)

  if (!country) {
    return 'Unknown'
  }

  return country
}

export const formatLang = params => {
  const lang = getLangName(params.value)

  if (!lang) {
    return 'Unknown'
  }

  return lang
}
