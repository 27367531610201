import { Button, Col, Form, Row, Switch } from 'antd'

import config from '../../../config'
import React, { FC, useState } from 'react'
import { defaultFormLayout, defaultFormLayoutOffset } from '../../../helpers/formLayouts'
import FormSubHeader from '../../../components/forms/FormSubHeader/FormSubHeader'

import { useAppState } from '../../../context/AppContext'
import { useQueryClient } from '@tanstack/react-query'

export const NotificationSettings: FC<{
  data: {
    userId: number
    isEnableTelegram: boolean
  }
}> = ({ data }) => {
  const { apiClient } = useAppState()
  const [isChecked, setIsChecked] = useState(data.isEnableTelegram)
  const queryClient = useQueryClient()

  const { mutateAsync: switchTelegram } = apiClient.user.updateTelegramNotificationSettings.useMutation({
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['me'],
      })
      await queryClient.invalidateQueries({
        queryKey: ['notificationSettings'],
      })
    },
  })

  const onChange = async (checked: boolean) => {
    setIsChecked(checked)
    await switchTelegram({ body: { enabled: checked } })
  }

  return (
    <>
      <Row gutter={48}>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <Form {...defaultFormLayout}>
            <FormSubHeader
              description={'Telegram notification setting'}
              title="Notification setting"
              layout={defaultFormLayoutOffset}
            />

            <Form.Item label="Telegram notification" valuePropName="checked" {...defaultFormLayoutOffset}>
              <Switch checked={isChecked} onChange={onChange} />
            </Form.Item>
            <Form.Item {...defaultFormLayoutOffset}>
              <Button type="link" target="_blank" href={`${config.telegramBotUrl}?start=${data.userId}`}>
                Connect telegram notification bot
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}
