import { Input, Popconfirm } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { useState } from 'react'
import { useChangeCampaignPrice } from '@pushflow/server/src/graphql/new/campaigns/changeCampaignPrice/operations'
import showNotification from '../../../helpers/showNotification'

export const EditCampaignPriceButton = ({ campaignId, price, queryClient }) => {
  const { mutate: changeCampaignPrice } = useChangeCampaignPrice()

  const [priceValue, setPriceValue] = useState(price)
  return (
    <Popconfirm
      title={
        <div>
          <span>Change price for campaign {campaignId}</span>
          <Input
            defaultValue={priceValue}
            onChange={e => {
              setPriceValue(e.target.value)
            }}
          />
        </div>
      }
      onConfirm={() =>
        changeCampaignPrice({
          variables: {
            id: campaignId,
            price: parseFloat(priceValue),
          },
        }).then(r => {
          if (r.errors && r.errors.length > 0) {
            showNotification({
              type: 'error',
              message: `Error changing price for campaign ${campaignId}`,
            })
            return
          }
          queryClient.invalidateQueries({ queryKey: ['campaigns'] })
          showNotification({
            type: 'success',
            message: `Price changed for campaign ${campaignId}`,
          })
        })
      }
      okText="Change Price"
      cancelText="Cancel"
      placement="bottom"
    >
      <EditTwoTone />
    </Popconfirm>
  )
}
