import { z } from 'zod';

export const CampaignStatus = {
  WORKING: 'WORKING',
  PAUSED: 'PAUSED',
  ARCHIVED: 'ARCHIVED',
} as const;

export type CampaignStatus =
  (typeof CampaignStatus)[keyof typeof CampaignStatus];

export const CampaignStatusEnum = z.enum([
  CampaignStatus.WORKING,
  CampaignStatus.PAUSED,
  CampaignStatus.ARCHIVED,
]);
