import { useEffect, useRef, useState } from 'react'

function getScrollableParentEl(element, includeHidden) {
  var style = getComputedStyle(element)
  var excludeStaticParent = style.position === 'absolute'
  var overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/

  if (style.position === 'fixed') return document.body
  for (var parent = element; (parent = parent.parentElement); ) {
    style = getComputedStyle(parent)
    if (excludeStaticParent && style.position === 'static') {
      continue
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return parent
  }

  return document.body
}

export const FixedTableFooter = ({ children }) => {
  const elRef = useRef(null)
  const [style, setStyle] = useState({
    zIndex: 100,
  })
  const [scrollableEl, setScrollableEl] = useState(null)
  const [originalFooterEl, setOriginalFooterEl] = useState(null)
  const [isFixed, setIsFixed] = useState(false)
  const [notFixedMargin, setNotFixedMargin] = useState(null)
  const [fixedLeft, setFixedLeft] = useState(null)

  useEffect(() => {
    if (elRef.current) {
      setOriginalFooterEl(elRef.current.closest('.ant-table-footer'))
    }
  }, [elRef.current])

  useEffect(() => {
    if (elRef.current) {
      setScrollableEl(getScrollableParentEl(elRef.current))
    }
  }, [elRef.current])

  useEffect(() => {
    if (originalFooterEl) {
      const originalFooterStyle = window.getComputedStyle(originalFooterEl, null)
      const originalFooterPadding = originalFooterStyle.getPropertyValue('padding')
      const originalFooterRealHeight = originalFooterEl.offsetHeight
      const originalFooterRealWidth = originalFooterEl.offsetWidth
      const originalFooterRealLeft = originalFooterEl.getBoundingClientRect().left
      const originalFooterPaddingBackgroundColor = originalFooterStyle.getPropertyValue('background-color')

      const elPadding = originalFooterPadding
      const elBackgroundColor = originalFooterPaddingBackgroundColor

      setStyle(prevStyle => ({
        ...prevStyle,
        padding: elPadding,
        backgroundColor: elBackgroundColor,
        width: originalFooterRealWidth,
        height: originalFooterRealHeight,
      }))

      originalFooterEl.style.width = `${originalFooterRealWidth}px`
      originalFooterEl.style.height = `${originalFooterRealHeight}px`

      const negativeMargin = originalFooterPadding
        .split(' ')
        .map(item => `-${item}`)
        .join(' ')
      setNotFixedMargin(negativeMargin)
      setFixedLeft(originalFooterRealLeft)
    }
  }, [originalFooterEl, setStyle])

  useEffect(() => {
    if (!scrollableEl || !originalFooterEl) {
      return
    }
    const handler = () => {
      const isOriginalFooterBottomLowerThenScreenBottom = (() => {
        const originalFooterRect = originalFooterEl.getBoundingClientRect()
        const scrollableRect = scrollableEl.getBoundingClientRect()
        return originalFooterRect.bottom < scrollableRect.bottom
      })()
      if (isOriginalFooterBottomLowerThenScreenBottom && isFixed) {
        setIsFixed(false)
      } else if (!isOriginalFooterBottomLowerThenScreenBottom && !isFixed) {
        setIsFixed(true)
      }
    }
    handler()
    scrollableEl.addEventListener('scroll', handler)
    const interval = setInterval(handler, 300)
    return () => {
      scrollableEl.removeEventListener('scroll', handler)
      clearInterval(interval)
    }
  }, [scrollableEl, originalFooterEl, isFixed, setIsFixed])

  useEffect(() => {
    if (notFixedMargin === null || fixedLeft === null) {
      return
    }
    if (isFixed) {
      setStyle(prevStyle => ({
        ...prevStyle,
        position: 'fixed',
        left: fixedLeft,
        bottom: 0,
        margin: 0,
        boxShadow: '0px -1px 0px 0px #f0f0f0',
      }))
    } else {
      setStyle(prevStyle => ({
        ...prevStyle,
        position: 'relative',
        left: 'auto',
        bottom: 'auto',
        margin: notFixedMargin,
        boxShadow: 'none',
      }))
    }
  }, [isFixed, setStyle, notFixedMargin, fixedLeft])

  return (
    <div ref={elRef} style={style}>
      {children}
    </div>
  )
}
