import { langCodes } from './langs'

function getLangName(code) {
  if (Object.prototype.hasOwnProperty.call(langCodes, code)) {
    return langCodes[code]
  }
  return false
}

function getShortLangCodes() {
  const newObj = {}
  for (const key in langCodes) {
    if (!key.includes('-')) {
      newObj[key] = langCodes[key]
    }
  }
  return newObj
}

function getShortLangArray() {
  const arr = []
  const current = getShortLangCodes()
  for (const key in current) {
    arr.push({ value: key, name: current[key] })
  }
  return arr
}

function getLangCodesArray() {
  const arr = []
  const current = getShortLangCodes()
  for (const key in current) {
    arr.push(key)
  }
  return arr
}
const shortLangCodes = getLangCodesArray()

export { langCodes, getShortLangCodes, getLangName, getShortLangArray, getLangCodesArray, shortLangCodes }
