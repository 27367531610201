import { Typography } from 'antd'

const { Text, Title } = Typography
export const PostbackUrl = ({ dspUrl }) => {
  const postbackUrl = `https://dsp.${dspUrl}/dsp/postback?click_id={hit_id}&type={conversion_type}&payout={payout_amount}`
  return (
    <>
      <Title level={2}>Postback URL</Title>
      <Text code copyable text={postbackUrl} format={'text/plain'}>
        {postbackUrl}
      </Text>
    </>
  )
}
