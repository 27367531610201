import React, { FC } from 'react'
import PageListHeader from '../../components/layout/PageListHeader/PageListHeader'
import { UserAuditoryForm } from './components/user-auditory-form'

const UserAuditoryCreatePage: FC = () => {
  return (
    <div className="UserAuditoryCreatePage">
      <PageListHeader title="New User Auditory" />
      <UserAuditoryForm />
    </div>
  )
}

export default UserAuditoryCreatePage
