import { createApiRequest } from '../../../api/http-client'
import { CampaignRule, CampaignRuleWithData } from '../../../types/campaign-rule'
export interface CampaignListFilters {
  offset?: number
  limit?: number
  streamIds?: number[]
  usersIds?: number[]
  campaignTypes?: Array<'PUSH' | 'LINK'>
  ids?: number[]
  statuses?: Array<'PAUSED' | 'WORKING'>
  countries?: string[]
  title?: string
  tags?: string[]
  spent?: boolean
}
// export const fetchCampaignList = async (params: CampaignListFilters) => {
//   const { offset, limit, ...rest } = params
//   const filter = { ...rest }

//   const data = await createApiRequest('POST', '/campaign', { offset, limit, filter }, 201)

//   return data
// }

export const fetchTargets = async (): Promise<{
  device: Array<{ id: number; label: string; targets: string[] }>
  browser: Array<{ id: number; label: string; targets: string[]; versions: { id: number; label: string }[] }>
  os: Array<{ id: number; label: string; targets: string[]; versions: { id: number; label: string }[] }>
}> => {
  return createApiRequest('POST', '/targets/getTargets')
}

/**
 * @param {number} campaignId
 * @returns {string | null}
 */
export const fetchCampaignDebugLink = async (campaignId: number) => {
  const res = await createApiRequest('GET', `/campaign/debugLink?campaignId=${campaignId}`)

  if (res.debugLink === '') {
    return null
  }

  return res.debugLink
}

export const fetchCampaignRules = async (campaignId: number) => {
  const res = await createApiRequest('GET', `/campaignRule/${campaignId}`)

  if (!res.rules) {
    return []
  }

  return res.rules
}

export const fetchRule = async (params: { campaignId: number; ruleId: number | null }) => {
  const { campaignId, ruleId } = params
  if (!ruleId) {
    return null
  }
  const res = await createApiRequest('GET', `/campaignRule/${campaignId}/${ruleId}`)

  if (!res.rule) {
    return []
  }

  return res.rule
}

export const createCampaignRule = async (params: {
  campaignId: number
  rule: Pick<CampaignRuleWithData, 'title' | 'data' | 'frequency' | 'frequencyType'>
}) => {
  const { campaignId, rule } = params
  if (!rule) {
    return null
  }
  const res = await createApiRequest('POST', `/campaignRule/${campaignId}`, rule, 201)

  return res
}

export const updateCampaignRule = async (params: {
  campaignId: number
  rule: Pick<CampaignRuleWithData, 'id' | 'title' | 'data' | 'frequency' | 'frequencyType'>
}) => {
  const { campaignId, rule } = params
  if (!rule.id) {
    return null
  }
  const res = await createApiRequest('PUT', `/campaignRule/${campaignId}/${rule.id}`, rule)

  return res
}

export const deleteCampaignRule = async (params: { campaignId: number; ruleId: number | null }) => {
  const { campaignId, ruleId } = params
  if (!ruleId) {
    return null
  }
  const res = await createApiRequest('DELETE', `/campaignRule/${campaignId}/${ruleId}`)

  return res
}

export const runRuleAgain = async (params: { campaignId: number; ruleId: number | null }) => {
  const { campaignId, ruleId } = params
  if (!ruleId) {
    return null
  }
  const res = await createApiRequest('PATCH', `/campaignRule/runAgain/${campaignId}/${ruleId}`)

  return res
}

export const createTemplateRule = async (params: { title: string; data: CampaignRuleWithData['data'] }) => {
  const { title, data } = params
  const res = await createApiRequest('POST', '/templateCampaignRules/create', { title, data })

  return res
}

export const fetchRuleTemplateList = async (): Promise<
  Array<Pick<CampaignRule, 'data'> & { title: string; id: number }>
> => {
  const res = await createApiRequest('POST', '/templateCampaignRules/get')

  if (!res.templates) {
    return []
  }

  return res.templates
}

export const createRuleFromTemplate = async (params: { campaignIds: number[]; templateId: number; title?: string }) => {
  const { campaignIds, templateId, title } = params
  const res = await createApiRequest('POST', `/templateCampaignRules/createRule/${templateId}`, { title, campaignIds })

  return res
}

export const copyRule = async (params: { campaignId: number; ruleId: number; toCampaignId: number }) => {
  const { campaignId, ruleId, toCampaignId } = params
  const res = await createApiRequest('POST', `/campaignRule/${campaignId}/${ruleId}/copy`, { toCampaignId })

  return res
}

export const fetchStreamInfo = async (params: { campaignId: number; streamId: number | null }) => {
  const { campaignId, streamId } = params
  if (!streamId) {
    return null
  }
  const res = await createApiRequest('GET', `/campaign/streamInfo/${campaignId}/${streamId}`)

  if (!res.streamInfo) {
    return null
  }

  return res.streamInfo
}

export const fetchSubstreamInfo = async (params: {
  campaignId: number
  streamId: number | null
  substreamId: string | null
}): Promise<{ status: string; bid: number } | null> => {
  const { campaignId, streamId, substreamId } = params
  if (!streamId || !substreamId) {
    return null
  }
  const res = await createApiRequest('GET', `/campaign/substreamInfo/${campaignId}/${streamId}/${substreamId}`)

  if (!res.substreamInfo) {
    return null
  }

  return res.substreamInfo
}

export const duplicateCampaign = async (campaignId: number) => {
  const res = await createApiRequest('POST', `/campaign/duplicate/${campaignId}`)

  return res
}

export const fetchCampaignTags = async (): Promise<string[]> => {
  const res = await createApiRequest('POST', '/campaign/tags')

  return res
}
