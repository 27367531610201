import { gql } from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { CAMPAIGN_DATA } from '../Campaign/operations.js'

export const CHANGE_CAMPAIGN_URL = gql`
  mutation ChangeCampaignUrl($id: ID!, $url: String!) {
    changeCampaignUrl(id: $id, url: $url) {
      ...CampaignData
    }
  }
  ${CAMPAIGN_DATA}
`

export function useChangeCampaignUrl() {
  const [mutate, { data, error }] = useMutation(CHANGE_CAMPAIGN_URL, {})
  return { mutate, data, error }
}
