import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

export default function useLang(init) {
  const { i18n } = useTranslation()
  const [lang, setLang] = useState(init)

  function updateLang(newLang) {
    if (newLang !== 'en' && newLang !== 'ru') {
      // eslint-disable-next-line no-console
      return console.log('Something went wrong on Update Lang: ', newLang)
    }
    i18n.changeLanguage(newLang)
    Cookies.set('lang', newLang)
    setLang(newLang)
  }

  return { lang, updateLang }
}
