import React, { FC, useCallback, useState } from 'react'
import { BatchImageCropper, useBatchImageCropperController } from '../../components/BatchImageCropper/BatchImageCropper'
import { Avatar, Button, Card, Checkbox, Col, Form, Input, Modal, Row, Spin } from 'antd'
import { useAppState } from '../../context/AppContext'
import showNotification from '../../helpers/showNotification'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

export interface ResultImage {
  bodyImg: any
  previewImg: any
  title: string
  body?: string
}

export const GenerateCreativesForm: FC<{
  // values: any
  campaignId: number
  refetch: () => void
  visible: boolean
  setVisible: any
}> = ({ visible, setVisible, campaignId, refetch }) => {
  const { apiClient } = useAppState()
  const [form] = Form.useForm()
  const [resultsForm] = Form.useForm()
  const previewImgsController = useBatchImageCropperController({})
  const bodyImgsController = useBatchImageCropperController({})
  const [isGenerating, setIsGenerating] = useState(false)
  const [resultImages, setResultImages] = useState<ResultImage[]>([])
  const [showResultModal, setShowResultModal] = useState(false)

  const { mutateAsync: bulkCreate } = apiClient.creative.bulkCreateCreative.useMutation()

  const generateCombinations = useCallback(async () => {
    const previewImagesCount = previewImgsController.getImagesCount()
    const bodyImagesCount = bodyImgsController.getImagesCount()
    const titles = (form.getFieldValue('titles') || '')
      .split('\n')
      .map((str: string) => str.trim())
      .filter(Boolean)
    const bodies = (form.getFieldValue('bodies') || '')
      .split('\n')
      .map((str: string) => str.trim())
      .filter(Boolean)

    const totalImages = previewImagesCount * bodyImagesCount * titles.length * (bodies.length || 1)

    if (totalImages > 100) {
      showNotification({ type: 'error', message: 'Too many creative combinations. Max 100' })
      return
    }
    setShowResultModal(true)
    setIsGenerating(true)
    const previewImages = await previewImgsController.getNewImagesUploads()
    const bodyImages = await bodyImgsController.getNewImagesUploads()

    const result: ResultImage[] = []
    for (const title of titles) {
      for (const previewImg of previewImages) {
        for (const bodyImg of bodyImages) {
          const bodiesFiltered = bodies || []
          if (bodiesFiltered.length === 0) {
            result.push({ bodyImg, previewImg, title })
          }
          for (const body of bodiesFiltered) {
            result.push({ bodyImg, previewImg, title, body })
          }
        }
      }
    }

    setResultImages(result)
    setIsGenerating(false)
  }, [previewImgsController, bodyImgsController, form])

  const calculateCombinations = useCallback(() => {
    const previewImagesCount = previewImgsController.getImagesCount()
    const bodyImagesCount = bodyImgsController.getImagesCount()
    const titles = (form.getFieldValue('titles') || '')
      .split('\n')
      .map((str: string) => str.trim())
      .filter(Boolean)
    const bodies = (form.getFieldValue('bodies') || '')
      .split('\n')
      .map((str: string) => str.trim())
      .filter(Boolean)

    const totalImages = previewImagesCount * bodyImagesCount * titles.length * (bodies.length || 1)

    showNotification({ type: 'info', message: `Total combinations: ${totalImages}` })
  }, [previewImgsController, bodyImgsController, form])

  const resultFormInitialValues = resultImages.reduce((acc, _, index) => {
    acc[`creative_${index}`] = true
    return acc
  }, {} as Record<string, boolean>)

  return (
    <Col>
      <Modal
        destroyOnClose={true}
        title={'Generate Creatives'}
        open={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="calculate"
            type="dashed"
            onClick={async () => {
              calculateCombinations()
            }}
            disabled={isGenerating}
          >
            Calculate combinations
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={async () => {
              await generateCombinations()
            }}
            disabled={isGenerating}
          >
            Create combinations
          </Button>,
        ]}
        width="full"
      >
        <BatchImageCropper
          title={'Preview Images'}
          description={'Size: 196x196px'}
          width={196}
          height={196}
          resizeRatio={0.5}
          batchImageCropperController={previewImgsController}
        />
        <BatchImageCropper
          title={'Body Images'}
          description={'Size: 492x328px'}
          width={492 / 2}
          height={328 / 2}
          resizeRatio={0.3}
          batchImageCropperController={bodyImgsController}
        />
        <Form form={form} {...formItemLayout}>
          <Form.Item
            name="titles"
            label={'Titles'}
            help={'One title per line'}
            rules={[
              {
                required: true,
                validator(_, value) {
                  if (
                    !value ||
                    !value.length ||
                    value
                      .split('\n')
                      .map((str: string) => str.trim())
                      .filter(Boolean).length === 0
                  ) {
                    return Promise.reject('Required')
                  }
                  return Promise.resolve()
                },
              },
              {
                validator(_, value) {
                  if (!value || !value.length) {
                    return Promise.resolve()
                  }
                  const vals = value
                    .split('\n')
                    .map((str: string) => str.trim())
                    .entries()
                  for (const [i, item] of vals) {
                    if (item.length && item.length > 80) {
                      return Promise.reject(`Max length is 80. Error in line ${i + 1}`)
                    }
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <Input.TextArea wrap="off" name="titles" autoSize={{ minRows: 4, maxRows: 12 }} />
          </Form.Item>
          <Form.Item
            name="bodies"
            label={'Bodies'}
            help={"One body per line. Leave empty if you don't want to use bodies"}
            rules={[
              {
                validator(_, value) {
                  if (
                    !value ||
                    !value.length ||
                    value
                      .split('\n')
                      .map((str: string) => str.trim())
                      .filter(Boolean).length === 0
                  ) {
                    return Promise.reject('Required')
                  }
                  return Promise.resolve()
                },
              },
              {
                validator(_, value) {
                  if (!value || !value.length) {
                    return Promise.resolve()
                  }
                  const vals = value
                    .split('\n')
                    .map((str: string) => str.trim())
                    .entries()
                  for (const [i, item] of vals) {
                    if (item.length && item.length > 150) {
                      return Promise.reject(`Max length is 150. Error in line ${i + 1}`)
                    }
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <Input.TextArea wrap="off" name="bodies" autoSize={{ minRows: 4, maxRows: 12 }} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        destroyOnClose={true}
        title={'Result'}
        open={showResultModal}
        onOk={async () => {
          const values = await resultsForm.validateFields()
          const selectedCreatives = resultImages.filter((_, index) => values[`creative_${index}`])
          setIsGenerating(true)
          const data = new FormData()
          data.append('campaignId', campaignId.toString())
          const t = []
          const b = []
          for (const { bodyImg, previewImg, title, body } of selectedCreatives) {
            t.push(title)
            b.push(body || '')
            data.append('previewImg', previewImg)
            data.append('bodyImg', bodyImg)
          }
          data.append('titles', t.toString())
          data.append('bodies', b.toString())

          bulkCreate({
            body: data,
            extraHeaders: {
              'Content-Type': undefined,
            },
          })
            .then(() => {
              refetch()
              bodyImgsController.clearImages()
              previewImgsController.clearImages()
              form.resetFields()
              showNotification({ type: 'success', message: 'Creatives created' })
            })
            .catch(e => {
              console.error(e)
              showNotification({ type: 'error', message: 'Error creating creatives' })
            })
            .finally(() => {
              setIsGenerating(false)
              setVisible(false)
              setShowResultModal(false)
            })
        }}
        okText="Upload creatives"
        onCancel={() => setShowResultModal(false)}
        width="full"
      >
        {isGenerating ? (
          <Spin />
        ) : (
          <Form form={resultsForm} initialValues={resultFormInitialValues}>
            <Row gutter={16}>
              {resultImages.map((resultImage, index) => (
                <Col span={6} key={index}>
                  <Row gutter={2}>
                    <Col>
                      <Form.Item name={`creative_${index}`} valuePropName="checked" tooltip="Enable creative">
                        <Checkbox />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Card
                        style={{ minWidth: '250px', marginBottom: 24 }}
                        cover={
                          resultImage && (
                            <div
                              style={{
                                backgroundPosition: '50%',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                margin: '0 auto',
                                paddingTop: '67%',
                                backgroundImage: `url(${URL.createObjectURL(resultImage.bodyImg)})`,
                              }}
                            >
                              {' '}
                            </div>
                          )
                        }
                      >
                        <Card.Meta
                          avatar={<Avatar shape="square" src={URL.createObjectURL(resultImage.previewImg)} />}
                          title={resultImage.title}
                          description={resultImage.body}
                        />
                      </Card>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Form>
        )}
      </Modal>
    </Col>
  )
}
