import { PlusOutlined } from '@ant-design/icons'
import PageListHeader from '../../components/layout/PageListHeader/PageListHeader'
import { StreamsList } from './stream-list.page'
import { useTranslation } from 'react-i18next'

const StreamsListPage = () => {
  const { t } = useTranslation()
  return (
    <div className="StreamsListPage">
      <PageListHeader
        title="Streams"
        buttons={[
          {
            link: `/streams/create`,
            type: 'primary',
            icon: <PlusOutlined />,
            title: t('Actions.Create'),
          },
        ]}
      />
      <StreamsList />
    </div>
  )
}

export default StreamsListPage
