import { Form, Input, Row, Select, Tag } from 'antd'
import React, { FC, useEffect } from 'react'
import { useStreamInfo, useSubstreamInfo } from '../../hooks'
import { RulesAction } from '../../../../types/campaign-rule'

export const CampaignRuleAction: FC<{
  campaignId: number
  campaignPrice: number
  action?: RulesAction
  availableStreams: Array<{ id: number; title: string }>
  availableCampaigns: Array<{ id: number; title: string }>
  availableStreamBlacklists: Array<{ id: number; title: string }>
  availableSubstreamBlacklists: Array<{ id: number; title: string }>
  onRuleActionChange?: (values: RulesAction) => void
  campaignHasTargetCpa: boolean
}> = ({
  campaignId,
  campaignPrice,
  action,
  availableStreams,
  availableCampaigns,
  availableStreamBlacklists,
  availableSubstreamBlacklists,
  onRuleActionChange,
  campaignHasTargetCpa,
}) => {
  const [form] = Form.useForm<{
    action: string
    rulesTarget: string
    targetId: string
    value: number
    limit: number
    listId: string
  }>()

  const selectedAction = Form.useWatch('action', form)
  const selectedTarget = Form.useWatch('rulesTarget', form)
  const selectedTargetId = Form.useWatch('targetId', form)

  const streamInfo = useStreamInfo({
    campaignId,
    streamId: +selectedTargetId || availableStreams[0]?.id,
  })

  const substreamInfo = useSubstreamInfo({
    campaignId,
    streamId: selectedTargetId ? Number(`${selectedTargetId}`.split(':')[0]) : null,
    substreamId: selectedTargetId ? `${selectedTargetId}`.split(':')[1] : '',
  })

  useEffect(() => {
    if (selectedTarget === 'stream') {
      if (!selectedTargetId && availableStreams[0]) {
        form.setFieldValue('targetId', availableStreams[0]?.id)
      }
      if (selectedAction === 'add_to_list' && availableStreamBlacklists[0]) {
        form.setFieldValue('listId', availableStreamBlacklists[0]?.id)
      }
    }
    if (selectedTarget === 'substream' && selectedAction === 'add_to_list' && availableSubstreamBlacklists[0]) {
      form.setFieldValue('listId', availableSubstreamBlacklists[0]?.id)
    }
  }, [selectedTarget, selectedAction])

  const bid = streamInfo.data?.bid || substreamInfo.data?.bid || campaignPrice

  const initialValues = {
    // @ts-ignore
    action: 'stop',
    // @ts-ignore
    rulesTarget: 'campaign',
    // @ts-ignore
    targetId: action && action.targetId ? action.targetId : campaignId,
    value: parseFloat(((bid / 100) * 10).toFixed(3)),
    limit: bid,
    listId: availableStreamBlacklists[0]?.id,
    ...(action || {}),
  }

  useEffect(() => {
    if (selectedTarget === 'stream' && selectedTargetId) {
      streamInfo.refetch()
    }
    if (selectedTarget === 'substream') {
      substreamInfo.refetch()
    }
  }, [selectedTargetId])

  return (
    <>
      {(selectedAction === 'increase_bid' || selectedAction === 'decrease_bid') && campaignHasTargetCpa && (
        <Row>
          <Tag color="warning">This campaign has target CPA. Rule that change bid will be skipped.</Tag>
        </Row>
      )}
      <Form
        form={form}
        initialValues={initialValues}
        onValuesChange={(_, values) => {
          onRuleActionChange && onRuleActionChange(values as RulesAction)
        }}
      >
        <Form.Item name="action" label="Action" required>
          <Select
            options={[
              { label: 'Stop', value: 'stop' },
              { label: 'Increase bid', value: 'increase_bid' },
              { label: 'Decrease bid', value: 'decrease_bid' },
              ...(selectedTarget === 'stream' || selectedTarget === 'substream'
                ? [{ label: 'Add to list', value: 'add_to_list' }]
                : []),
            ]}
          />
        </Form.Item>
        <Form.Item
          name="rulesTarget"
          label="Action target"
          tooltip="To which entity the action will be applied"
          required
        >
          <Select
            options={[
              { label: 'Campaign', value: 'campaign' },
              { label: 'Stream', value: 'stream' },
              { label: 'Substream', value: 'substream' },
            ]}
          />
        </Form.Item>
        <Form.Item name="targetId" label="Target ID" tooltip="Campaign ID/Stream ID/Substream ID" required>
          {selectedTarget !== 'substream' ? (
            <Select
              options={
                selectedTarget === 'stream'
                  ? availableStreams.map(s => ({
                      label: `ID: ${s.id} | ${s.title}`,
                      value: `${s.id}`,
                    }))
                  : availableCampaigns.map(c => ({
                      label: `ID: ${c.id} | ${c.title}`,
                      value: `${c.id}`,
                    }))
              }
            />
          ) : (
            <Input />
          )}
        </Form.Item>
        <Form.Item
          name="listId"
          label="List ID"
          tooltip="Add stream or substrem to blacklist or whitelist."
          required
          hidden={selectedAction !== 'add_to_list'}
        >
          <Select
            options={
              selectedTarget === 'stream'
                ? availableStreamBlacklists.map(l => ({
                    label: `ID: ${l.id} | ${l.title}`,
                    value: l.id,
                  }))
                : availableSubstreamBlacklists.map(l => ({
                    label: `ID: ${l.id} | ${l.title}`,
                    value: l.id,
                  }))
            }
          />
        </Form.Item>

        {(selectedAction === 'increase_bid' || selectedAction === 'decrease_bid') && (
          <Row>
            Current {selectedTarget} bid: {bid}$
          </Row>
        )}

        <Form.Item
          name="value"
          label="Bid change amount $"
          tooltip="Amount to change bid of target entity"
          required
          hidden={selectedAction !== 'increase_bid' && selectedAction !== 'decrease_bid'}
          extra={
            <div>
              {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(v => (
                <Tag
                  key={v}
                  onClick={() => {
                    const amt = parseFloat(((bid / 100) * v).toFixed(3))
                    form.setFieldValue('value', amt)
                    onRuleActionChange && onRuleActionChange(form.getFieldsValue() as RulesAction)
                  }}
                >
                  {v}%
                </Tag>
              ))}
            </div>
          }
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="limit"
          label={`${selectedAction === 'increase_bid' ? 'Increase' : 'Decrease'} limit`}
          tooltip="Bid change will be limited to this value"
          required
          hidden={selectedAction !== 'increase_bid' && selectedAction !== 'decrease_bid'}
        >
          <Input />
        </Form.Item>
      </Form>
    </>
  )
}
