import { arrayMove } from '@dnd-kit/sortable'
import { useState } from 'react'

export interface TargetsData {
  id: number
  label: string
  targets: string[]
  versions?: TargetsData[]
  regexp: string | null
}

export const useTargets = (initialData: TargetsData[]) => {
  const [values, setValues] = useState<TargetsData[]>(initialData)

  const add = (value: TargetsData) => {
    setValues(values => [...values, value])
  }

  // eslint-disable-next-line no-unused-vars
  const update = (id: number, updater: (d: TargetsData) => TargetsData) => {
    setValues(values =>
      values.map(el => {
        if (el.id === id) {
          return updater(el)
        }

        return el
      })
    )
  }

  const remove = (id: number) => {
    setValues(values => values.filter(el => el.id != id))
  }

  const swap = (active: number | string, over: number | string) => {
    setValues(values => {
      const activeIndex = values.findIndex(val => val.id === active)
      const overIndex = values.findIndex(val => val.id === over)

      return arrayMove(values, activeIndex, overIndex)
    })
  }

  return {
    values,

    add,
    set: setValues,
    update,
    swap,
    remove,
  }
}
