import { Collapse, Table } from 'antd'

const { Panel } = Collapse

const pushParams = [
  {
    field: 'ssp*',
    type: 'string',
    description: 'Stream ID provided from our service',
  },
  {
    field: 'type*',
    type: 'string',
    description: 'Response type: xml, json',
  },
  {
    field: 'id*',
    type: 'string',
    description: 'Unique request id',
  },
  {
    field: 'ip*',
    type: 'string',
    description: 'IPv4 user IP',
  },
  {
    field: 's2*',
    type: 'string',
    description: 'Substream id',
  },
  {
    field: 'lang*',
    type: 'string',
    description: '2 or 3 letter designation of the language from the HTTP Accept-Language header',
  },
  {
    field: 'ua*',
    type: 'string',
    description: 'User-agent',
  },
  {
    field: 'user_id',
    type: 'string',
    description: 'User id in partner system',
  },
  {
    field: 'subage_ts',
    type: 'integer',
    description: 'Unix Timestamp when the Subscription was created (preferred method)',
  },
  {
    field: 'subage_day',
    type: 'integer',
    description: 'Days since the Subscription was created. Starting from 1',
  },
  {
    field: 'subage_day0',
    type: 'integer',
    description: 'Days since the Subscription was created. Starting from 0',
  },
  {
    field: 'subage_dt',
    type: 'string',
    description: 'Subscription date in ISO 8601 format',
  },
  {
    field: 'ch-ua',
    type: 'string',
    description: 'Value from Sec-CH-UA header',
  },
  {
    field: 'ch-ua-full-version',
    type: 'string',
    description: 'Value from Sec-CH-UA-Full-Version header',
  },
  {
    field: 'ch-ua-full-version-list',
    type: 'string',
    description: 'Value from Sec-CH-UA-Full-Version-List header',
  },
  {
    field: 'ch-ua-arch',
    type: 'string',
    description: 'Value from Sec-CH-UA-Arch header',
  },
  {
    field: 'ch-ua-bitness',
    type: 'string',
    description: 'Value from Sec-CH-UA-Bitness header',
  },
  {
    field: 'ch-ua-platform',
    type: 'string',
    description: 'Value from Sec-CH-UA-Platform header',
  },
  {
    field: 'ch-ua-platform-version',
    type: 'string',
    description: 'Value from Sec-CH-UA-Platform-Version header',
  },
  {
    field: 'ch-ua-model',
    type: 'string',
    description: 'Value from Sec-CH-UA-Model header',
  },
  {
    field: 'ch-ua-mobile',
    type: 'string',
    description: 'Value from Sec-CH-UA-Mobile header',
  },
  {
    field: 'ch-ua-wow64',
    type: 'string',
    description: 'Value from Sec-CH-UA-Wow64 header',
  },
]

const linkParams = [
  {
    field: 'ssp*',
    type: 'string',
    description: 'Stream ID provided from our service',
  },
  {
    field: 'type*',
    type: 'string',
    description: 'Response type: xml, json',
  },
  {
    field: 'id*',
    type: 'string',
    description: 'Unique request id',
  },
  {
    field: 'ip*',
    type: 'string',
    description: 'IPv4 user IP',
  },
  {
    field: 's2*',
    type: 'string',
    description: 'Substream id',
  },
  {
    field: 'lang*',
    type: 'string',
    description: '2 or 3 letter designation of the language from the HTTP Accept-Language header',
  },
  {
    field: 'ua*',
    type: 'string',
    description: 'User-agent',
  },
  {
    field: 'user_id',
    type: 'string',
    description: 'User id in partner system',
  },
  {
    field: 'ref',
    type: 'string',
    description: 'Referrer URL that caused navigation to the current page.',
  },
  {
    field: 'keyword',
    type: 'string',
    description: 'Site keywords / user search query.',
  },
  {
    field: 'ch-ua',
    type: 'string',
    description: 'Value from Sec-CH-UA header',
  },
  {
    field: 'ch-ua-full-version',
    type: 'string',
    description: 'Value from Sec-CH-UA-Full-Version header',
  },
  {
    field: 'ch-ua-full-version-list',
    type: 'string',
    description: 'Value from Sec-CH-UA-Full-Version-List header',
  },
  {
    field: 'ch-ua-arch',
    type: 'string',
    description: 'Value from Sec-CH-UA-Arch header',
  },
  {
    field: 'ch-ua-bitness',
    type: 'string',
    description: 'Value from Sec-CH-UA-Bitness header',
  },
  {
    field: 'ch-ua-platform',
    type: 'string',
    description: 'Value from Sec-CH-UA-Platform header',
  },
  {
    field: 'ch-ua-platform-version',
    type: 'string',
    description: 'Value from Sec-CH-UA-Platform-Version header',
  },
  {
    field: 'ch-ua-model',
    type: 'string',
    description: 'Value from Sec-CH-UA-Model header',
  },
  {
    field: 'ch-ua-mobile',
    type: 'string',
    description: 'Value from Sec-CH-UA-Mobile header',
  },
  {
    field: 'ch-ua-wow64',
    type: 'string',
    description: 'Value from Sec-CH-UA-WoW64 header',
  },
]

const StreamFAQTable = ({ dataSource }) => {
  const columns = [
    {
      title: 'Field',
      dataIndex: 'field',
      render: text => <>{text}</>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: text => <>{text}</>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: text => <>{text}</>,
    },
  ]
  return <Table pagination={false} columns={columns} dataSource={dataSource} rowKey="field" />
}

const StreamFAQAccordion = () => {
  const items = [
    {
      key: '1',
      label: 'Push parameters',
      children: <StreamFAQTable dataSource={pushParams} />,
    },
    {
      key: '2',
      label: 'Link parameters',
      children: <StreamFAQTable dataSource={linkParams} />,
    },
  ]
  return (
    <Collapse
      style={{
        width: '50%',
        height: '100%',
      }}
      accordion
    >
      {items.map(item => {
        return (
          <Panel key={item.key} header={item.label}>
            {item.children}
          </Panel>
        )
      })}
    </Collapse>
  )
}

export default StreamFAQAccordion
