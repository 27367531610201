import React from 'react'
import 'chart.js/auto'
import { Bar } from 'react-chartjs-2'
import 'chartjs-adapter-moment'

const BarChart = ({ labels = [], datasets = [], axesStacked = true, animations = true }) => {
  const barSettings = {
    borderWidth: 0,
    borderSkipped: 'bottom',
    categoryPercentage: 0.5,
    barPercentage: 0.9,
  }
  const chartData = {
    labels,
    datasets: datasets.length > 0 ? datasets.map(s => ({ ...barSettings, ...s })) : [],
  }

  const options = {
    interaction: {
      mode: 'index',
    },
    plugins: {
      legend: {
        labels: {
          color: '#959595',
        },
        position: 'bottom',
      },
      tooltip: {
        mode: 'index',
        position: 'average',
      },
    },
    scales: {
      xAxes: {
        stacked: axesStacked,
        display: true,
        grid: {
          color: '#f2f2f2',
        },
        type: 'time',
        time: {
          fromFormat: 'yyyy-MM-dd',
          tooltipFormat: 'DD.MM',
          unit: 'day',
          unitStepSize: 1,
          displayFormats: { day: 'DD.MM' },
        },
      },
      yAxes: [
        {
          stacked: axesStacked,
          display: true,
          grid: {
            display: true,
            color: '#f2f2f2',
            borderDash: [4, 3],
          },
        },
        {
          stacked: axesStacked,
          display: true,
          grid: {
            display: true,
            color: '#f2f2f2',
            borderDash: [4, 3],
          },
        },
      ],
    },
    animations,
  }
  return <Bar data={chartData} options={options} height={80} />
}

export default BarChart

export const HistChart = ({ labels, counts }) => {
  const chartData = {
    labels,
    datasets: [
      {
        label: 'Count',
        data: counts,
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderWidth: 3,
      },
    ],
  }

  const options = {
    scales: {
      x: {
        stacked: true,
        hideLabels: true,
        display: false,
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
  }

  return <Bar data={chartData} options={options} />
}
