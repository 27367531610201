import React, { Form, Select } from 'antd'
import './TimezoneSelect.sass'
import { FC } from 'react'

const { Option } = Select

const TWELVE_HOURS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const TWELVE_HOURS_REV = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].reverse()

const TimezoneSelect: FC<{
  title: string
  name: string
  extra?: string
  formLayout?: any
  isVertical?: boolean
}> = ({ title, name, extra, formLayout = {}, isVertical = true }) => {
  const formProps = isVertical ? { labelCol: { span: 24 }, wrapperCol: { span: 24 } } : {}
  return (
    <Form.Item {...formProps} label={title} name={name} extra={extra} {...formLayout}>
      <Select style={{ width: '100px' }}>
        {TWELVE_HOURS_REV.map(v => {
          const tz = `+${v}`
          return <Option value={tz} key={`key_p_${v}`}>{`UTC+${v}`}</Option>
        })}
        <Option value="+0" key="key_z_0">
          UTC+0
        </Option>
        {TWELVE_HOURS.map(v => {
          const tz = `-${v}`
          return <Option value={tz} key={`key_m_${v}`}>{`UTC-${v}`}</Option>
        })}
      </Select>
    </Form.Item>
  )
}

export default TimezoneSelect
