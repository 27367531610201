import * as Sentry from '@sentry/react'
import { Alert, Button, Form, Radio, Tag, Input as Input2, Select, Spin } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { defaultFormLayout } from '../../helpers/formLayouts'
import showNotification from '../../helpers/showNotification'
import { STREAM_LIST, SUBSTREAM_LIST } from '../../common/types/blackWhiteListTypes'
import Input from '../../components/forms/Input/Input'
import { getStringsFormatted } from '../campaign/util'
import MultiSelect from '../../components/forms/MultiSelect/MultiSelect'
import { useAppState } from '../../context/AppContext'
import SubstreamsInput from '../campaign/components/SubstreamsInput/SubstreamsInput'
import React from 'react'

export const BlackWhiteListForm: FC<{
  blackWhiteList:
    | {
        items: string[]
        id: number
        title: string
        listType: 'STREAM' | 'SUBSTREAM'
        wlcampaigns: { campaignId: number; title: string }[]
        blcampaigns: { campaignId: number; title: string }[]
      }
    | undefined
  refetch?: () => void
}> = ({ blackWhiteList, refetch: refetchList }) => {
  const { user, apiClient } = useAppState()
  const navigate = useNavigate()

  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()

  const [isFormValid, setIsFormValid] = useState(false)
  const [isFormTouched, setIsFormTouched] = useState(false)

  const listType = Form.useWatch('listType', form)
  const items = Form.useWatch('items', form)
  const wlcampaigns = Form.useWatch('wlcampaigns', form)
  const blcampaigns = Form.useWatch('blcampaigns', form)

  const { mutateAsync: createOrUpdateBlackWhiteList } =
    blackWhiteList && blackWhiteList.id
      ? apiClient.blackWhiteList.updateList.useMutation()
      : apiClient.blackWhiteList.createList.useMutation()

  const campaignFilters = user
    ? {
        query: {
          usersIds: [user.id],
        },
        pagination: { pageNumber: 0, pageSize: 10000 },
      }
    : {
        pagination: {
          pageNumber: 0,
          pageSize: 10000,
        },
      }

  const { isLoading, data, refetch } = apiClient.campaign.getCampaignListPaginated.useQuery(['campaigns'], {
    query: campaignFilters,
  })

  useEffect(() => {
    if (user) {
      refetch()
    }
  }, [user])

  if (isLoading || !data || !data.body?.items) {
    return <Spin />
  }

  const validateForm = () => {
    setIsFormValid(
      form.getFieldsError().every(item => {
        return item.errors.length === 0
      })
    )
    setIsFormTouched(true)
  }

  const neededBlackWhiteListFeilds = blackWhiteList
    ? blackWhiteList
    : { id: null, items: [], blcampaigns: [], wlcampaigns: [] }
  const initialValues = {
    listType: STREAM_LIST,
    ...neededBlackWhiteListFeilds,
    items: neededBlackWhiteListFeilds.items ? neededBlackWhiteListFeilds.items.join('\n') : '',
    blcampaigns: neededBlackWhiteListFeilds.blcampaigns
      ? neededBlackWhiteListFeilds.blcampaigns.map(item => item.campaignId)
      : [],
    wlcampaigns: neededBlackWhiteListFeilds.wlcampaigns
      ? neededBlackWhiteListFeilds.wlcampaigns.map(item => item.campaignId)
      : [],
  }

  const submit = (values: any) => {
    values.items = Array.isArray(values.items)
      ? values.items
      : (values.items ? getStringsFormatted(values.items) : []).map(substream => substream.trim()).filter(Boolean)
    const listData = {
      ...(blackWhiteList ? { id: blackWhiteList?.id } : {}),
      ...values,
    }

    createOrUpdateBlackWhiteList({
      body: listData,
    })
      .then(result => {
        showNotification({
          type: 'success',
          message: blackWhiteList?.id ? 'List succesfully updated!' : 'List succesfully created!',
        })
        refetchList && refetchList()
        navigate(`/subscriptionlist/${result?.body?.id}`)
      })
      .catch(err => {
        showNotification({ type: 'error', message: err.message })
        if (process.env.NODE_ENV !== 'development') Sentry.captureException(err)
        setSubmitting(false)
      })
  }

  return (
    <Form
      form={form}
      onFinish={submit}
      onFinishFailed={validateForm}
      onFieldsChange={validateForm}
      initialValues={initialValues}
      layout="horizontal"
      disabled={submitting}
      {...defaultFormLayout}
    >
      {/* @ts-ignore */}
      <Input isRequired name="title" label="Title" {...defaultFormLayout} />

      <Form.Item {...defaultFormLayout} label="List Type" name="listType">
        <Radio.Group name="listType">
          <Radio.Button value={STREAM_LIST}>Streams list</Radio.Button>
          <Radio.Button value={SUBSTREAM_LIST}>Substreams list</Radio.Button>
        </Radio.Group>
      </Form.Item>

      {!isFormValid && isFormTouched && (
        <Form.Item {...defaultFormLayout}>
          <Alert message="Some fields are invalid" type="error" />
        </Form.Item>
      )}

      {listType === STREAM_LIST ? (
        <Form.Item
          label={'Streams'}
          name="items"
          extra={
            <div>
              <Tag>Items count: {getStringsFormatted(items).length || 0}</Tag>
            </div>
          }
        >
          <Input2.TextArea wrap="off" autoSize={{ minRows: 4, maxRows: 12 }} />
        </Form.Item>
      ) : (
        <SubstreamsInput showExcludeInclude={false} form={form} name="items" autoSize={{ minRows: 4, maxRows: 12 }} />
      )}

      <MultiSelect
        required={false}
        extra={null}
        title={'Blacklisted in'}
        decoratorTitle={'blcampaigns'}
        type="array"
        filterFn={(input: string, option: any) => {
          return option.children
            ? option.children.some(
                (v: string) =>
                  v && v.toString().toLowerCase() && v.toString().toLowerCase().includes(input.toLowerCase())
              )
            : true
        }}
      >
        {!isLoading &&
          data &&
          data.body?.items &&
          data.body?.items.length > 0 &&
          wlcampaigns &&
          data.body?.items
            .filter((item: { id: number; title: string }) => !wlcampaigns.includes(item.id))
            .map((item: { id: number; title: string }) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.title}| ID: {item.id}
                </Select.Option>
              )
            })}
      </MultiSelect>

      <MultiSelect
        required={false}
        extra={null}
        title={'Whitelisted in'}
        decoratorTitle={'wlcampaigns'}
        type="array"
        filterFn={(input: string, option: any) => {
          return option.children
            ? option.children.some(
                (v: string) =>
                  v && v.toString().toLowerCase() && v.toString().toLowerCase().includes(input.toLowerCase())
              )
            : true
        }}
      >
        {!isLoading &&
          data &&
          data.body &&
          data.body.items.length > 0 &&
          blcampaigns &&
          data.body.items
            .filter((item: { id: number; title: string }) => !blcampaigns.includes(item.id))
            .map((item: { id: number; title: string }) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.title}| ID: {item.id}
                </Select.Option>
              )
            })}
      </MultiSelect>

      <Form.Item {...defaultFormLayout}>
        <Button type="primary" htmlType="submit" loading={submitting} disabled={!isFormValid && isFormTouched}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}
