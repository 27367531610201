import { gql } from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

export const GET_CAMPAIGN_AUTOCOMPLETE_QUERY = gql`
  query getStreamAutoCompleteQuery($input: GetStreamAutoCompleteInput!) {
    getStreamAutoComplete(input: $input) {
      streams {
        id
        uid
        title
        hasClientHints
      }
    }
  }
`

export function useGetStreamAutocompleteQuery(input = {}, options = {}) {
  const { loading, error, data, refetch } = useQuery(GET_CAMPAIGN_AUTOCOMPLETE_QUERY, {
    variables: { input },
    ...options,
  })
  return { loading, error, data, refetch }
}
