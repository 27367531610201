import { gql } from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { CAMPAIGN_DATA } from '../Campaign/operations.js'

export const CAMPAIGN_QUERY = gql`
  query CampaignQuery($id: String!) {
    campaign(id: $id) {
      ...CampaignData
    }
  }
  ${CAMPAIGN_DATA}
`

export function useCampaignQuery(id) {
  const { loading, error, data, refetch } = useQuery(CAMPAIGN_QUERY, {
    variables: { id },
    fetchPolicy: 'network-only',
  })
  return { loading, error, data, refetch }
}
