import { Button, Col, Dropdown, Row, Skeleton, Space, Typography } from 'antd'
import { CreateTemplateModal } from './CreateTemplateModal'
import { useDeleteInventorySettingsMutation, useInventorySettingsQuery } from '../hooks'
import { useMemo, useState } from 'react'

const Layout = ({ children }) => {
  return <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingBottom: '20px' }}>{children}</div>
}

export const Templates = ({ gridRef }) => {
  const { isLoading, data } = useInventorySettingsQuery()
  const deleteInventorySettings = useDeleteInventorySettingsMutation()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selected, setSelected] = useState(null)

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const onSelect = ({ key }) => {
    const id = Number(key)

    setSelected(id)

    const inventorySettings = data.find(inventorySettings => inventorySettings.id === id)

    const { colState, filterState } = JSON.parse(inventorySettings.data)

    gridRef.current.columnApi.applyColumnState({ state: colState })
    gridRef.current.api.setFilterModel(filterState)
  }

  const onReset = () => {
    setSelected(null)

    gridRef.current.columnApi.resetColumnState()
    gridRef.current.api.setFilterModel({})
  }

  const onDelete = () => {
    if (selected === null) {
      return
    }

    deleteInventorySettings.mutate(selected, {
      onSettled: () => {
        onReset()
      },
    })
  }

  const currentSettings = useMemo(() => {
    if (!selected) {
      return null
    }

    return data.find(inventorySettings => inventorySettings.id === selected)
  }, [selected, data])

  if (isLoading) {
    return (
      <Layout>
        <Skeleton.Input style={{ width: 240 }} active />

        <Row gutter={10}>
          <Col>
            <Skeleton.Button active />
          </Col>
          <Col>
            <Skeleton.Button active />
          </Col>
          <Col>
            <Skeleton.Button active />
          </Col>
          <Col>
            <Skeleton.Button active />
          </Col>
        </Row>
      </Layout>
    )
  }

  return (
    <>
      <Layout>
        <div>
          <Typography.Title level={4}>Template</Typography.Title>
          <Dropdown
            menu={{
              items: data ? data.map(({ id, title }) => ({ key: id, label: title })) : [],
              onClick: onSelect,
            }}
            trigger={['click']}
          >
            <Button onClick={e => e.preventDefault()}>
              {currentSettings ? currentSettings.title : 'Not selected'}
            </Button>
          </Dropdown>
        </div>

        <Space>
          <Button style={{ borderColor: '#52c41a', color: '#52c41a' }} onClick={() => setIsModalOpen(true)}>
            Save
          </Button>
          <Button onClick={() => onDelete()} danger>
            Delete
          </Button>
          <Button style={{ borderColor: '#1677ff', color: '#1677ff' }} onClick={() => onReset()}>
            Reset
          </Button>
        </Space>
      </Layout>

      <CreateTemplateModal gridRef={gridRef} isOpen={isModalOpen} closeModal={closeModal} setSelected={setSelected} />
    </>
  )
}
