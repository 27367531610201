import { Button } from 'antd'
import Cookies from 'js-cookie'
import React, { FC } from 'react'

export const ImpersonateButton: FC = () => {
  return (
    <>
      {Cookies.get('uid') && (
        <Button
          danger
          type="primary"
          onClick={() => {
            Cookies.remove('uid')
            window.location.replace('/app')
          }}
        >
          STOP IMPERSONATING
        </Button>
      )}
    </>
  )
}
