import { Button, Checkbox, Form, Input, Radio } from 'antd'
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { defaultFormLayout, defaultFormLayoutOffset } from '../../../../helpers/formLayouts'
import showNotification from '../../../../helpers/showNotification'
import { UserRole, UserStatus } from '@pushflow/backend-http-contract'
import { useAppState } from '../../../../context/AppContext'
import { useQueryClient } from '@tanstack/react-query'

interface FormValues {
  status: UserStatus
  isEmailConfirmed: boolean
  isPublisher: boolean
  isAdvertiser: boolean
  advertiserType: 'team' | 'external'
  name?: string
  preferredContact?: string
  fee?: number
  password?: string
}

const percentageValidator = (_: any, value: number): Promise<void> => {
  if (value === undefined) {
    return Promise.resolve()
  }

  if (value < 0 || value > 100) {
    return Promise.reject(new Error('Please enter an integer between 0 and 100'))
  }

  return Promise.resolve()
}

export const UserEditForm: FC<{
  user: {
    roles: UserRole[]
    status: UserStatus
    preferredContact: string | null
    email: string
    id: number
    name: string | null
    fee: number | null
    isEmailConfirmed: boolean
    updatedAt: string | null
    postback: string | null
    createdAt: string
  }
}> = ({ user }) => {
  const navigate = useNavigate()
  const { apiClient } = useAppState()
  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()
  const queryClient = useQueryClient()

  const isAdvertiser = Form.useWatch('isAdvertiser', form)
  const advertiserType = Form.useWatch('advertiserType', form)
  const isExternalAdvertiser = isAdvertiser === true && advertiserType === 'external'

  const isAdver = user.roles.includes(UserRole.INTERNAL_ADVERTISER) || user.roles.includes(UserRole.EXTERNAL_ADVERTISER)
  const adverType = isAdver && user.roles.includes(UserRole.INTERNAL_ADVERTISER) ? 'team' : 'external'
  const initialValues: FormValues = {
    status: user.status,
    isEmailConfirmed: user.isEmailConfirmed,
    isPublisher: user.roles.includes(UserRole.PUBLISHER),
    name: user.name || '',
    fee: user.fee || undefined,
    preferredContact: user.preferredContact || '',
    isAdvertiser: isAdver,
    advertiserType: adverType,
  }

  const submit = async (values: FormValues) => {
    const roles: UserRole[] = user.roles.includes(UserRole.ADMIN) ? [UserRole.ADMIN] : []

    if (values.isAdvertiser && values.advertiserType === 'team') {
      roles.push(UserRole.INTERNAL_ADVERTISER)
    }

    if (values.isAdvertiser && values.advertiserType === 'external') {
      roles.push(UserRole.EXTERNAL_ADVERTISER)
    }

    if (values.isPublisher) {
      roles.push(UserRole.PUBLISHER)
    }

    const fee = values.isAdvertiser && values.advertiserType === 'external' ? values.fee : 0

    const { status, body } = await apiClient.user.updateUser.mutation({
      body: {
        id: Number(user.id),
        roles,
        ...values,
        fee,
      },
    })

    if (status === 200) {
      showNotification({ type: 'success', message: 'User successfully updated!' })
      await queryClient.invalidateQueries({ queryKey: ['user-list'] })
      await queryClient.invalidateQueries({ queryKey: ['hasNewUsers'] })
      navigate(`/admin/users/${body.id}`)
    } else {
      setSubmitting(false)
    }
  }

  return (
    <Form<FormValues>
      form={form}
      onFinish={submit}
      initialValues={initialValues}
      layout="horizontal"
      disabled={submitting}
      {...defaultFormLayout}
    >
      <Form.Item name="password" label="Password">
        <Input />
      </Form.Item>
      <Form.Item name="name" label="Name">
        <Input />
      </Form.Item>
      <Form.Item name="preferredContact" label="Preferred contact">
        <Input />
      </Form.Item>
      <Form.Item label="User status" name="status">
        <Radio.Group>
          {/* We are not allowing to change status to NEW from any other state, but we must show it if user is new */}
          <Radio.Button disabled={user.status !== UserStatus.NEW} value={UserStatus.NEW}>
            🆕 New
          </Radio.Button>
          <Radio.Button value={UserStatus.ACTIVE}>✅ Active</Radio.Button>
          <Radio.Button value={UserStatus.INACTIVE}>❌ Inactive</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Is email confirmed" name="isEmailConfirmed" valuePropName="checked">
        <Checkbox>Enabled</Checkbox>
      </Form.Item>
      <Form.Item label="Publisher" name="isPublisher" valuePropName="checked">
        <Checkbox>Enabled</Checkbox>
      </Form.Item>
      <Form.Item label="Advertiser" name="isAdvertiser" valuePropName="checked">
        <Checkbox>Enabled</Checkbox>
      </Form.Item>
      <Form.Item label="Advertiser type" name="advertiserType" hidden={isAdvertiser !== true}>
        <Radio.Group buttonStyle="solid">
          <Radio.Button value="team">Team</Radio.Button>
          <Radio.Button value="external">External</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="fee"
        label="Fee"
        rules={[{ required: true, message: 'Please enter a percentage value' }, { validator: percentageValidator }]}
        hidden={isExternalAdvertiser !== true}
        initialValue={user.fee || 0}
      >
        <Input type={'number'} required={isExternalAdvertiser} />
      </Form.Item>

      <Form.Item {...defaultFormLayoutOffset}>
        <Button type="primary" htmlType="submit" loading={submitting}>
          Update
        </Button>
      </Form.Item>
    </Form>
  )
}
