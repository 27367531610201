import React, { useEffect, useRef, useState, FC } from 'react'
import { Button, Col, Row } from 'antd'
// @ts-ignore
import AvatarEditor from 'react-avatar-editor'
import { useTranslation } from 'react-i18next'
import './BatchImageCropper.sass'

export const useBatchImageCropperController = ({
  initialOldImages = [],
}): {
  oldImages: any[]
  setOldImages: any
  newImages: any[]
  setNewImages: any
  getNewImagesUploads: any
  getImagesCount: () => number
  clearImages: () => void
} => {
  const [oldImages, setOldImages] = useState([...initialOldImages])
  const [newImages, setNewImages] = useState([])
  const getNewImagesUploads = async () => {
    if (!newImages.length) return null
    const uploads = await Promise.all(
      newImages.map(async (newImage: any) => {
        const canvas = newImage.ref.current.getImage().toDataURL()
        const blob = await fetch(canvas).then(res => res.blob())
        return new File([blob], newImage.name, { type: newImage.type })
      })
    )
    return uploads
  }
  const getImagesCount = () => newImages.length
  return {
    oldImages,
    setOldImages,
    newImages,
    setNewImages,
    getNewImagesUploads,
    getImagesCount,
    clearImages: () => {
      setOldImages([])
      setNewImages([])
    },
  }
}

const NewImage: FC<{
  newImage: { src: string; name: string; type: string; id: string; ref: any }
  width: number
  height: number
  index: number
  setNewImages: (newImages: any) => void
}> = ({ newImage, width, height, index, setNewImages }) => {
  const { t } = useTranslation()
  const imgRef = useRef(null)
  useEffect(() => {
    if (imgRef.current && !newImage.ref) {
      const newNewImage = {
        ...newImage,
        ref: imgRef,
      }
      setNewImages((newImages: any[]) => [...newImages.slice(0, index), newNewImage, ...newImages.slice(index + 1)])
    }
  }, [imgRef, newImage])

  return (
    <div className="BatchImageCropper__image">
      <div>
        <div className="BatchImageCropper__image-name" style={{ maxWidth: width }}>
          {newImage.name}
        </div>
        <AvatarEditor ref={imgRef} image={newImage.src} width={width} height={height} rotate={0} />
      </div>
      <div style={{ marginBottom: 18 }}>
        <Button
          type="dashed"
          onClick={() => {
            setNewImages((newImages: any[]) => [...newImages.slice(0, index), ...newImages.slice(index + 1)])
          }}
        >
          {t('CreativeForm.Batch Cropper.Delete')}
        </Button>
      </div>
    </div>
  )
}

export const BatchImageCropper: FC<{
  title: string
  description: string
  width: number
  height: number
  batchImageCropperController: ReturnType<typeof useBatchImageCropperController>
  resizeRatio?: number
}> = ({ title, description, width, height, batchImageCropperController }) => {
  const { t } = useTranslation()
  const { newImages, setNewImages, oldImages } = batchImageCropperController
  const imagesExists = !!oldImages?.length || !!newImages?.length
  const uploadInputRef = useRef(null)

  return (
    <div className="BatchImageCropper">
      <Row>
        <Col>
          <h5 className="BatchImageCropper__title">{title}</h5>
          <p className="BatchImageCropper__description">{description}</p>
        </Col>
      </Row>
      {!!newImages?.length && (
        <div className="BatchImageCropper__images">
          {newImages.map((newImage, index) => (
            <NewImage
              newImage={newImage}
              key={newImage.id}
              width={width}
              height={height}
              index={index}
              setNewImages={setNewImages}
            />
          ))}
        </div>
      )}

      <Row>
        <Col>
          <input
            ref={uploadInputRef}
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            multiple={true}
            onChange={e => {
              if (e.target.files && e.target.files.length > 0) {
                for (const file of e.target.files) {
                  const reader = new FileReader()
                  reader.addEventListener('load', () => {
                    setNewImages((newImages: any) => [
                      ...newImages,
                      {
                        src: reader.result,
                        name: file.name,
                        type: file.type,
                        id: `${Math.random()}`,
                      },
                    ])
                  })
                  reader.readAsDataURL(file)
                }
              }
            }}
            className="ImageCropper__input"
          />
          <Button
            type="dashed"
            onClick={() => {
              ;(uploadInputRef.current as any).click()
            }}
          >
            {imagesExists ? t('CreativeForm.Batch Cropper.Upload More') : t('CreativeForm.Batch Cropper.Upload')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}
