import { useRef } from 'react'
import config from '../../config'
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { countryCodes } from '../../common/static/countryCodes'
import { shortLangCodes } from '../../common/static/langCodes'
import { OS_TYPES } from '../../common/types/osTypes'
import { BROWSER_TYPES } from '../../common/types/browserTypes'
import { DEVICE_TYPES } from '../../common/types/deviceTypes'
import { CONNECTION_TYPES } from '../../common/types/connectionTypes'
import { fetchInventory } from './hooks/fetchers'
import { Templates } from './components/Templates'
import { formatCountry, formatCurrency, formatLang, formatNumber, formatPercent } from './utils/formatters'
import { streamListValuesLoader } from './utils/loaders'
import { StreamColRenderer } from './utils/renderers'

LicenseManager.setLicenseKey(config.agGridLicense)

const datasource = {
  getRows: params => {
    const hiddenFields = params.columnApi
      .getColumnState()
      .filter(col => col.hide)
      .map(col => col.colId)

    fetchInventory({
      ...params.request,
      hiddenFields,
    })
      .then(data => {
        params.success({
          rowData: data,
        })
      })
      .catch(() => {
        params.fail()
      })
  },
}

const getRowId = params => {
  const keys = [
    'day',
    'hour',
    'campaign_type',
    'stream',
    'substream',
    'country',
    'language_short',
    'language_full',
    'os_name',
    'browser_name',
    'subage_day',
    'subage',
    'device',
    'connection_type',
  ]

  return keys.map(key => params.data[key]).join('-')
}

const columns = [
  {
    headerName: 'Date',
    children: [
      {
        field: 'day',
        width: 115,
        sortable: true,
        enableRowGroup: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          filterOptions: ['equals', 'lessThanOrEqual', 'greaterThanOrEqual', 'inRange'],
          maxNumConditions: 1,
        },
      },
      {
        field: 'hour',
        width: 85,
        sortable: true,
        enableRowGroup: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: ['equals', 'lessThanOrEqual', 'greaterThanOrEqual', 'inRange'],
          maxNumConditions: 1,
        },
      },
    ],
  },
  {
    headerName: 'Stream Info',
    children: [
      {
        field: 'campaign_type',
        width: 85,
        headerName: 'Type',
        enableRowGroup: true,
      },
      {
        field: 'stream',
        width: 150,
        cellRenderer: StreamColRenderer,
        resizable: true,
        enableRowGroup: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: streamListValuesLoader,
          cellRenderer: StreamColRenderer,
        },
      },
      {
        field: 'substream',
        width: 150,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['equals'],
          maxNumConditions: 1,
        },
        enableRowGroup: true,
      },
    ],
  },
  {
    headerName: 'Geo',
    children: [
      {
        field: 'country',
        tooltipValueGetter: formatCountry,
        width: 105,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: countryCodes,
        },
        enableRowGroup: true,
      },
      {
        field: 'language_short',
        tooltipValueGetter: formatLang,
        width: 85,
        headerName: 'Lang',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: shortLangCodes,
        },
        enableRowGroup: true,
      },
      {
        field: 'language_full',
        tooltipValueGetter: formatLang,
        width: 100,
        headerName: 'Dialect',
        enableRowGroup: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: shortLangCodes,
        },
        columnGroupShow: 'open',
      },
    ],
  },
  {
    headerName: 'Browser',
    children: [
      {
        field: 'os_name',
        width: 95,
        filter: 'agSetColumnFilter',
        resizable: true,
        filterParams: {
          values: OS_TYPES,
        },
        headerName: 'OS',
        enableRowGroup: true,
      },
      {
        field: 'browser_name',
        width: 105,
        resizable: true,
        headerName: 'Browser',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: BROWSER_TYPES,
        },
        enableRowGroup: true,
      },
      {
        field: 'subage_day',
        width: 105,
        valueFormatter: params => (params.value === -1 ? '-' : params.value),
        headerName: 'Sub Day',
        sortable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: ['equals', 'lessThanOrEqual', 'greaterThanOrEqual', 'inRange'],
          maxNumConditions: 1,
        },
        enableRowGroup: true,
      },
      {
        field: 'subage',
        width: 105,
        valueFormatter: params => (params.value === -1 ? '-' : params.value),
        headerName: 'Sub Age',
        sortable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: ['equals', 'lessThanOrEqual', 'greaterThanOrEqual', 'inRange'],
          maxNumConditions: 1,
        },
        enableRowGroup: true,
      },
      {
        field: 'device',
        width: 95,
        enableRowGroup: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: DEVICE_TYPES,
        },
        columnGroupShow: 'open',
      },
      {
        field: 'connection_type',
        width: 130,
        headerName: 'Connection',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: CONNECTION_TYPES,
        },
        enableRowGroup: true,
        columnGroupShow: 'open',
      },
    ],
  },
  {
    headerName: 'Rates',
    children: [
      {
        field: 'fill',
        width: 90,
        valueFormatter: formatPercent,
        headerName: 'Fill %',
        sortable: true,
      },
      {
        field: 'won_rate',
        width: 100,
        valueFormatter: formatPercent,
        headerName: 'Won %',
        sortable: true,
      },
      {
        field: 'click_rate',
        width: 100,
        valueFormatter: formatPercent,
        headerName: 'Click %',
        sortable: true,
      },
    ],
  },
  {
    headerName: 'Cost',
    children: [
      {
        field: 'avg_bid',
        valueFormatter: formatCurrency,
        width: 105,
        sortable: true,
        headerName: 'Avg Bid',
        aggFunc: 'sum',
      },
      {
        field: 'max_bid',
        valueFormatter: formatCurrency,
        width: 105,
        sortable: true,
        headerName: 'Max Bid',
        aggFunc: 'sum',
      },
      {
        field: 'total_norm',
        valueFormatter: formatCurrency,
        width: 105,
        resizable: true,
        sortable: true,
        headerName: 'Total',
        columnGroupShow: 'open',
        aggFunc: 'sum',
      },
    ],
  },
  {
    headerName: 'Stats',
    children: [
      {
        field: 'stream_requests',
        width: 115,
        valueFormatter: formatNumber,
        sortable: true,
        headerName: 'Requests',
        aggFunc: 'sum',
      },
      {
        field: 'bids',
        width: 80,
        valueFormatter: formatNumber,
        sortable: true,
        columnGroupShow: 'open',
        aggFunc: 'sum',
      },
      {
        field: 'won_bids',
        width: 115,
        valueFormatter: formatNumber,
        sortable: true,
        headerName: 'Won Bids',
        columnGroupShow: 'open',
        aggFunc: 'sum',
      },
      {
        field: 'impressions',
        width: 130,
        valueFormatter: formatNumber,
        sortable: true,
        headerName: 'Impressions',
        columnGroupShow: 'open',
        aggFunc: 'sum',
      },
      {
        field: 'clicks',
        width: 90,
        sortable: true,
        valueFormatter: formatNumber,
        aggFunc: 'sum',
      },
      {
        field: 'payouts',
        width: 105,
        valueFormatter: formatNumber,
        sortable: true,
        aggFunc: 'sum',
      },
      {
        field: 'no_bids',
        width: 105,
        valueFormatter: formatNumber,
        headerName: 'No Bids',
        sortable: true,
        aggFunc: 'sum',
      },
    ],
  },
]

export const InventoryOld = () => {
  const gridRef = useRef()

  const onColumnVisible = params => {
    params.api.refreshServerSide({ purge: true })
  }

  return (
    <>
      <Templates gridRef={gridRef} />

      <div className="ag-theme-alpine" style={{ width: '100%', height: '700px' }}>
        <AgGridReact
          ref={gridRef}
          columnDefs={columns}
          rowGroupPanelShow="always"
          rowModelType="serverSide"
          serverSideDatasource={datasource}
          suppressDragLeaveHidesColumns={true}
          cacheBlockSize={100}
          maxBlocksInCache={10}
          getRowId={getRowId}
          enableBrowserTooltips={true}
          tooltipShowDelay={1000}
          rowSelection="multiple"
          suppressAggFuncInHeader={true}
          groupDisplayType="multipleColumns"
          autoGroupColumnDef={{
            width: 150,
          }}
          onColumnVisible={onColumnVisible}
        ></AgGridReact>
      </div>
    </>
  )
}
