import { Button } from 'antd'

import { useState } from 'react'
import { PauseOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { createApiRequest } from '../../../api/http-client'

export const NotificationButton = ({ notificationEnabled, campaignId }) => {
  const [isEnabled, setIsChecked] = useState(notificationEnabled)

  const switchNotification = useMutation({
    mutationFn: isEnabled => {
      return createApiRequest('PATCH', `/campaign/notification/`, {
        enabled: isEnabled,
        campaignId: Number(campaignId),
      })
    },
  })

  const onChange = async () => {
    setIsChecked(!isEnabled)
    await switchNotification.mutate(!isEnabled)
  }

  return (
    <>
      <Button icon={isEnabled ? <PauseOutlined /> : <PlayCircleOutlined />} onClick={onChange}>
        Notification
      </Button>
    </>
  )
}
