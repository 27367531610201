import { Alert } from 'antd'
import Loading from '../../../../components/Loading'
import PageListHeader from '../../../../components/layout/PageListHeader/PageListHeader'
import { BlackWhiteListForm } from '../../../../containers/blackWhiteList/BlackWhiteListForm'
import { useAppState } from '../../../../context/AppContext'
import React, { FC } from 'react'

const BlackWhiteListEditPage: FC<{ params: { id: number } }> = ({ params }) => {
  const { apiClient } = useAppState()
  const { isLoading, data, error, refetch } = apiClient.blackWhiteList.getList.useQuery(['blackWhiteList', params.id], {
    params: { id: params.id },
  })

  if (isLoading || !data) return <Loading />
  if (error) return <Alert type="error" message={error.body?.toString()} />

  return (
    <div>
      <PageListHeader level={4} title={`List ${data?.body.id}`} />
      <BlackWhiteListForm blackWhiteList={data.body} refetch={refetch} />
    </div>
  )
}

export default BlackWhiteListEditPage
