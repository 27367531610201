import { useServiceStatusQuery, useStartServiceMutation, useStopServiceMutation } from '../../hooks'
import { Button, Col, Form, Row, Tag } from 'antd'
import { defaultFormLayout, defaultFormLayoutOffset } from '../../../helpers/formLayouts'
import FormSubHeader from '../../../components/forms/FormSubHeader/FormSubHeader'

const ServiceStatus = () => {
  const { data, isError, isLoading } = useServiceStatusQuery()
  const { mutate: stopService } = useStopServiceMutation()
  const { mutate: startService } = useStartServiceMutation()

  if (isLoading) return null
  if (isError) return <div>Can not fetch service status</div>
  return (
    <Row gutter={48}>
      <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <Form {...defaultFormLayout}>
          <FormSubHeader
            title={
              <>
                Service status{' '}
                <Tag color={data.serviceStopped ? 'red' : 'green'}>
                  {data.serviceStopped ? 'Service stopped' : 'Service working'}
                </Tag>
              </>
            }
            layout={defaultFormLayoutOffset}
          />

          <Form.Item {...defaultFormLayoutOffset}>
            <Button
              type="primary"
              danger
              onClick={() => {
                if (data.serviceStopped === false) {
                  stopService()
                } else {
                  startService()
                }
              }}
            >{`${data.serviceStopped === false ? 'STOP' : 'START'} SERVICE`}</Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export { ServiceStatus }
