import React, { FC } from 'react'
// @ts-ignore
import { Alert, Col, Divider, Row, Typography } from 'antd'
import Loading from '../../components/Loading'
import PageListHeader from '../../components/layout/PageListHeader/PageListHeader'
import { UserAuditoryActions } from './components/user-auditory-actions'
import { UserAuditoryForm } from './components/user-auditory-form'
import { useAppState } from '../../context/AppContext'

const UserAuditoryEditPage: FC<{
  params: { id: number }
}> = ({ params }) => {
  const { apiClient } = useAppState()
  const {
    isLoading: loading,
    error,
    refetch,
    data,
  } = apiClient.userAuditory.getUserAuditory.useQuery(['userAuditory', params.id], { query: { id: params.id } })

  if (loading && !data) return <Loading />
  if (error) {
    console.error(error)
    return <Alert type="error" message={"Can't load user auditory"} />
  }

  return (
    <div className="UserAuditoryEditPage">
      <PageListHeader title={`Users Auditory ${data?.body.title}`} />
      <Row>
        <Col span={6}>
          <Typography.Title
            style={{
              fontSize: '20px',
            }}
          >{`Auditory ID: ${data?.body.id}`}</Typography.Title>
        </Col>
        <Col span={6}>
          <Typography.Title
            style={{
              fontSize: '20px',
            }}
          >{`Users Count: ${data?.body.usersCount}`}</Typography.Title>
        </Col>
      </Row>
      <UserAuditoryActions
        auditoryId={params.id}
        onChange={() => {
          refetch()
        }}
      />
      <Divider
        style={{
          margin: '20px 0',
        }}
      />
      <UserAuditoryForm userAuditory={data?.body} />
    </div>
  )
}

export default UserAuditoryEditPage
