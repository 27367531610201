import { z } from 'zod';

export const UserStatus = {
  NEW: 'NEW',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const;

export type UserStatus = (typeof UserStatus)[keyof typeof UserStatus];

export const UserStatusEnum = z.enum([
  UserStatus.NEW,
  UserStatus.ACTIVE,
  UserStatus.INACTIVE,
]);
