import {
  ClearOutlined,
  CloudDownloadOutlined,
  DownOutlined,
  FileTextOutlined,
  LoadingOutlined,
  UpOutlined,
} from '@ant-design/icons'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { Button, Checkbox, Col, DatePicker, Form, Row, Select, Tag } from 'antd'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import TimezoneSelect from '../../components/forms/TimezoneSelect/TimezoneSelect'
import config from '../../config'
import { useAppState } from '../../context/AppContext'
import { createLabel } from '../../helpers/createAutocompleteLabel'
import { currencyFormatter, floatFormatter, numberFormatter } from '../../helpers/agGridTable'
import { useGetStreamReport } from './hooks'
const { Option } = Select
const { RangePicker } = DatePicker

LicenseManager.setLicenseKey(config.agGridLicense)

const targetNames = [
  'country',
  'language_short',
  'device',
  'os_name',
  // 'os_version',
  'browser_name',
  'browser_version',
  'connection_type',
  'domain',
  'isp',
]

const toGroupHeaderName = (t, groupBy) => {
  if (targetNames.includes(groupBy)) {
    return t(`Report.StreamReport.Controls.FieldLabel_${groupBy}`)
  }
  return t(`Report.StreamReport.${groupBy}`)
}

const defaultState = {
  reportParams: {
    groupBy: 'DATE',
    groupBy1: '',
    showDeleted: false,
    filterByUserId: [],
    filterByStreamId: [],
    filtersByTargets: targetNames.map(targetName => ({ type: targetName, values: [] })),
    timezone: '+0',
    dateRange: [moment().subtract(14, 'd'), moment().endOf()],
    dateFrom: moment().subtract(14, 'd').format('YYYY-MM-DD'),
    dateTo: moment().add('1d').format('YYYY-MM-DD'),
  },
  filteredInfo: {},
  sortedInfo: {},
  pagination: {
    pageSizeOptions: [10, 20, 50, 100, 400],
    showSizeChanger: true,
    pageSize: 50,
  },
  showFilters: false,
}

const StreamsReport = () => {
  const { user } = useAppState()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [reloadReport, setReloadReport] = useState(false)
  const [streams, setStreams] = useState([])
  const [users, setUsers] = useState([])
  const [reportCSVData, setReportCSVData] = useState([])
  const [reportParams, setReportParams] = useState(defaultState.reportParams)
  const { refetch, data } = useGetStreamReport(reportParams)
  const [cache, setCache] = useState({})
  const [showFilters, setShowFilters] = useState(defaultState.showFilters)
  const { apiClient } = useAppState()

  const reportFilterValues = apiClient.report.getStreamReportFilterValues.useQuery('stream-filter-values', {})

  const timezone = Form.useWatch('timezone', form)

  useEffect(() => {
    if (reloadReport) {
      refetch(reportParams).then(() => {
        setReloadReport(false)
      })
    }

    if (data && data.reportStream) {
      setReportCSVData(
        data.reportStream.map(obj => {
          const item = { ...obj }

          delete item.__typename

          if (reportParams.groupBy === 'WEEKDAY') {
            const day = moment().day(item.group).format('dddd')

            item.weekDayName = t('Report.Weekdays.' + day)
          } else if (reportParams.groupBy === 'MONTH') {
            const day = moment()
              .month(item.group - 1)
              .format('YYYY-MM')

            item.monthName = t('Report.Months.' + day)
          }

          if (item.stream) {
            item.streamTitle = item.stream.title
            item.streamId = item.stream.uid
          }

          delete item.stream

          return item
        })
      )
    }

    if (reportFilterValues.data?.body) {
      const { streams, users } = reportFilterValues.data?.body

      const streamsList = streams.map(e => ({
        ...e,
        value: e.uid,
        key: 'stream-' + e.uid,
        label: createLabel(user, e),
      }))

      const usersList = users.map(e => {
        return {
          ...e,
          value: e.id,
          key: 'user-' + e.id,
          label: `${e.id} : ${e.email}`,
        }
      })

      !cache.streams && setStreams(streamsList)
      !cache.users && setUsers(usersList)

      setCache({ streams: streamsList, users: usersList })
    }
  }, [reportFilterValues.data?.body, reloadReport, data])

  const onFinish = event => {
    event.preventDefault()

    const values = form.getFieldsValue()

    setReportParams({
      ...reportParams,
      ...values,
      dateFrom: values.dateRange ? values.dateRange[0].format('YYYY-MM-DD') : null,
      dateTo: values.dateRange ? values.dateRange[1].format('YYYY-MM-DD') : null,
      filterByStreamId: values.filterByStreamId ? values.filterByStreamId : null,
      filterByUserId: values.filterByUserId ? values.filterByUserId.map(Number) : null,
      filtersByTargets: reportParams.filtersByTargets,
    })

    setReloadReport(true)
  }

  const handleChangeStreamId = value => {
    setReportParams({ ...reportParams, streamId: value })
  }

  const handleChangeUserId = value => {
    if (value.length) {
      const users = cache.users.filter(e => value.includes(e.id))
      setUsers(users)
    } else {
      setUsers(cache.users)
    }

    setReportParams({ ...reportParams, userId: value })
  }

  const handleChangeShowDeletedStreams = value => {
    setReportParams({ ...reportParams, showDeleted: value })
  }

  const filtersByTargets = Form.useWatch('filtersByTargets', form)

  useEffect(() => {
    if (filtersByTargets) {
      setReportParams(reportParams => {
        return {
          ...reportParams,
          filtersByTargets: reportParams.filtersByTargets.map(({ type }, index) => {
            return { type, values: filtersByTargets[index].values }
          }),
        }
      })
    }
  }, [filtersByTargets])

  const clearAll = () => {
    form.resetFields()
    form.setFieldsValue(defaultState.reportParams)
    setReportParams(defaultState.reportParams)
    setReportParams(defaultState.reportParams)
    setStreams(cache.streams)
    setReloadReport(true)
  }

  const gridRef = useRef()
  const [rowData, setRowData] = useState([])

  const columnDefs = useMemo(
    () => [
      {
        field: 'group',
        width: 130,
        pinned: 'left',
        headerName: toGroupHeaderName(t, reportParams.groupBy),
        valueGetter: val => {
          if (reportParams.groupBy === 'STREAM_ID') {
            return val.data.stream ? `(${val.data.stream.id}) ${val.data.stream.title}` : ''
          }
          return val.data.group
        },
        ...(reportParams.groupBy === 'HOUR' ? { comparator: (a, b) => a - b } : {}),
      },
      ...(reportParams.groupBy1
        ? [
            {
              field: 'group1',
              width: 130,
              pinned: 'left',
              headerName: toGroupHeaderName(t, reportParams.groupBy1),
              valueGetter: val => {
                if (reportParams.groupBy1 === 'STREAM_ID') {
                  return val.data.stream ? `(${val.data.stream.id}) ${val.data.stream.title}` : ''
                }
                return val.data.group1
              },
              ...(reportParams.groupBy1 === 'HOUR' ? { comparator: (a, b) => a - b } : {}),
            },
          ]
        : []),
      { field: 'requests', width: 100, type: 'rightAligned', valueFormatter: numberFormatter },
      { field: 'bids', width: 100, type: 'rightAligned', valueFormatter: numberFormatter },
      { field: 'wonBids', width: 120, type: 'rightAligned', valueFormatter: numberFormatter },
      { field: 'clicks', width: 100, type: 'rightAligned', valueFormatter: numberFormatter },
      { field: 'fill', width: 100, valueFormatter: floatFormatter, type: 'rightAligned' },
      { field: 'winRate', width: 120, valueFormatter: floatFormatter, type: 'rightAligned' },
      {
        field: 'cpc',
        width: 100,
        valueFormatter: params => currencyFormatter(Math.ceil((params.value || 0) * 10000) / 10000, '$', 3),
        type: 'rightAligned',
      },
      {
        field: 'ctr',
        width: 100,
        valueFormatter: params => (params.value * 100).toFixed(2) + '%',
        type: 'rightAligned',
      },
      {
        field: 'cost',
        width: 100,
        valueFormatter: params => currencyFormatter(Math.ceil((params.value || 0) * 1000) / 1000),
        type: 'rightAligned',
      },
    ],
    [reportParams.groupBy, reportParams.groupBy1]
  )
  const defaultColDef = useMemo(() => ({
    filter: true,
    sortable: true,
    resizable: true,
  }))
  const getFooterData = () => {
    const gridApi = gridRef.current?.api
    if (!gridApi) {
      return
    }
    const result = []
    const model = gridApi.getModel()
    const visibleRows = model.rowsToDisplay
    function sum(values, col) {
      let sum = 0
      values.forEach(function (value) {
        sum += value.data[col]
      })
      return sum
    }
    function avg(values, col) {
      if (!values.length) {
        return 0
      }
      let sum = 0
      values.forEach(function (value) {
        sum += value.data[col]
      })
      return sum / values.length
    }
    result.push({
      group: '',
      group1: '',
      stream: '',
      requests: sum(visibleRows, 'requests'),
      bids: sum(visibleRows, 'bids'),
      wonBids: sum(visibleRows, 'wonBids'),
      clicks: sum(visibleRows, 'clicks'),
      fill: avg(visibleRows, 'fill'),
      winRate: avg(visibleRows, 'winRate'),
      cpc: avg(visibleRows, 'cpc'),
      ctr: avg(visibleRows, 'ctr'),
      cost: sum(visibleRows, 'cost'),
    })
    return result
  }
  const [pinnedBottomRowData, setPinnedBottomRowData] = useState(getFooterData())
  useEffect(() => {
    if (data) {
      setRowData(data.reportStream)
      setTimeout(() => {
        setPinnedBottomRowData(getFooterData())
      }, 100)
    }
  }, [data])

  const filterOption = (input, { label }) => (label ?? '').toLowerCase().includes(input.toLowerCase())

  return (
    <>
      <Form
        form={form}
        id="reportForm"
        name="reportForm"
        onSubmit={onFinish}
        initialValues={reportParams}
        style={{ marginBottom: 24 }}
      >
        <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
          {['', '1'].map(suffix => (
            <Col span={{ xs: 8, sm: 8, md: 8, lg: 8 }} key={'group' + suffix}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name={`groupBy${suffix}`}
                key={`groupBy${suffix}`}
                label={t(`Report.StreamReport.Controls.GroupBy${suffix}`)}
                rules={
                  suffix === '1'
                    ? []
                    : [
                        {
                          required: true,
                          message: t('Report.StreamReport.Controls.GroupByErrorMsg'),
                        },
                      ]
                }
              >
                <Select placeholder={t('Report.StreamReport.Controls.GroupByPlaceholder')} allowClear={suffix === '1'}>
                  <Option value="DATE">{t('Report.StreamReport.DATE')}</Option>
                  <Option value="STREAM_ID">{t('Report.StreamReport.STREAM_ID')}</Option>
                  <Option value="SUBSTREAM">{t('Report.StreamReport.SUBSTREAM')}</Option>
                  <Option value="HOUR">{t('Report.StreamReport.HOUR')}</Option>
                  <Option value="WEEKDAY">{t('Report.StreamReport.WEEKDAY')}</Option>
                  <Option value="MONTH">{t('Report.StreamReport.MONTH')}</Option>
                  {reportParams.filtersByTargets.map(({ type }) => (
                    <Option value={type} key={type}>
                      {t(`Report.StreamReport.Controls.FieldLabel_${type}`)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ))}
          <Col span={{ lg: 12 }}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={
                <>
                  {t('Report.StreamReport.Controls.DateRangeLabel')}{' '}
                  <Tag style={{ verticalAlign: 2, marginLeft: 4 }}>UTC{timezone}</Tag>
                </>
              }
              name="dateRange"
              key="dateRange"
              rules={[
                {
                  required: false,
                  message: t('Report.StreamReport.Controls.DateRangeMsg'),
                },
              ]}
            >
              <RangePicker
                style={{ width: '100%' }}
                ranges={{
                  Today: [moment().startOf(), moment().endOf()],
                  Yesterday: [moment().subtract(1, 'days').startOf(), moment().subtract(1, 'days').endOf()],
                  'This Week': [moment().startOf('week').startOf(), moment().endOf('week').endOf()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'Last 7 days': [moment().subtract(7, 'days').startOf(), moment().subtract(1, 'days').endOf()],
                  'Last 30 days': [moment().subtract(30, 'days').startOf(), moment().subtract(1, 'days').endOf()],
                }}
              />
            </Form.Item>
          </Col>
          <Col span={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            <TimezoneSelect title={t('Report.Controls.Timezone')} name="timezone" />
          </Col>
          <Col span={{ xs: 12, lg: 3 }} className="gutter-row">
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label="&nbsp;">
              <Button
                type="text"
                form="reportForm"
                onClick={() => setShowFilters(!showFilters)}
                icon={!showFilters ? <DownOutlined /> : <UpOutlined />}
              >
                {!showFilters ? t('Report.Controls.Filters') : t('Report.Controls.Filters')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row style={!showFilters && { display: 'none' }} gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
          <Col span={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              key="filterByStreamId"
              label={t('Report.StreamReport.Controls.StreamsFieldLabel')}
              name="filterByStreamId"
            >
              <Select
                style={{ minWidth: 150 }}
                mode="multiple"
                placeholder={t('Report.StreamReport.Controls.StreamsFieldPlaceholder')}
                onChange={handleChangeStreamId}
                filterOption={filterOption}
                value={reportParams.filterByStreamId}
                options={streams}
              />
            </Form.Item>
          </Col>
          <Col span={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            {user && user.isAdmin ? (
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                key="filterByUserId"
                name="filterByUserId"
                label={t('Report.StreamReport.Controls.UsersFieldLabel')}
              >
                <Select
                  style={{ minWidth: 150 }}
                  mode="multiple"
                  placeholder={t('Report.StreamReport.Controls.UsersFieldPlaceholder')}
                  onChange={handleChangeUserId}
                  value={reportParams.filterByUserId}
                  options={users}
                />
              </Form.Item>
            ) : (
              <></>
            )}
          </Col>
          <Col span={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            {user && user.isAdmin ? (
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                valuePropName="checked"
                name="showDeleted"
                form={form}
                label={t('Report.StreamReport.Controls.ShowDeletedFlagLabel')}
              >
                <Checkbox
                  name="showDeleted"
                  onChange={e => handleChangeShowDeletedStreams(e.target.checked)}
                  checked={reportParams.showDeleted}
                >
                  {reportParams.showDeleted === true ? 'ENABLED' : 'DISABLED'}
                </Checkbox>
              </Form.Item>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row style={!showFilters && { display: 'none' }} gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
          {reportParams.filtersByTargets.map((target, index) => {
            const values = reportFilterValues.data?.body.targets.find(el => el.type === target.type)?.values

            return (
              <Col span={8} key={index}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  key={target.type}
                  label={t(`Report.StreamReport.Controls.FieldLabel_${target.type}`)}
                  name={['filtersByTargets', index, 'values']}
                >
                  <Select
                    mode="multiple"
                    placeholder={t(`Report.StreamReport.Controls.FieldLabel_${target.type}`)}
                    onChange={handleChangeUserId}
                    value={target.values}
                    options={values?.map(value => ({
                      value,
                      key: value,
                      label: value,
                    }))}
                  />
                </Form.Item>
              </Col>
            )
          })}
        </Row>
        <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
          <Col span={{ xs: 8, sm: 8, md: 8, lg: 8 }}>
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} key="submitBtn">
              <Button
                type="primary"
                form="reportForm"
                key="submit"
                htmlType="submit"
                onClick={onFinish}
                icon={reloadReport ? <LoadingOutlined /> : <FileTextOutlined />}
              >
                {reloadReport ? t('Report.Loading') : t('Report.Controls.CreateReport')}
              </Button>
            </Form.Item>
          </Col>
          <Col span={{ xs: 12, lg: 3 }} className="gutter-row">
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} key="submitBtn">
              <Button type="" form="reportForm" onClick={clearAll} icon={<ClearOutlined />}>
                {t('Report.Controls.ClearAll')}
              </Button>
            </Form.Item>
          </Col>
          <Col span={{ xs: 12, lg: 3 }} className="gutter-row">
            {reportCSVData && (
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ float: 'right' }}
                key="dowloadReportBtn"
              >
                <Button type="dashed" icon={<CloudDownloadOutlined />}>
                  <CSVLink
                    key="csvData"
                    data={reportCSVData}
                    filename={`pushflow_report_${moment().format('YYYY.MM.DD-hh.mm')}.csv`}
                    target="_blank"
                  >
                    {' '}
                    {t('Report.Controls.DownloadReport')}
                  </CSVLink>
                </Button>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
          domLayout="autoHeight"
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
              },
            ],
          }}
          pinnedBottomRowData={pinnedBottomRowData}
        />
      </div>
    </>
  )
}
export default StreamsReport
