import { createApiRequest } from '../../../api/http-client'
import config from '../../../config'
import { getToken } from '../../../helpers/jwtHelper'
import showNotification from '../../../helpers/showNotification'

export const fetchDiscrepancyReport = async (fromDate, toDate) => {
  const res = await fetch(
    `${
      config.uiBackendUrl
    }/report/campaign-discrepancy?fromDate=${fromDate?.toISOString()}&toDate=${toDate?.toISOString()}`,
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    }
  )

  if (res.status !== 200) {
    throw new Error('Error while fetching debug link')
  }

  const data = await res.json()

  return data
}

export const fetchCampaignReport = async params => {
  const body = {}
  if (params.groupBy) {
    body.groupBy = params.groupBy
  }
  if (params.groupBy1) {
    body.groupBy1 = params.groupBy1
  }
  if (params.timezone) {
    body.tz = params.timezone
  }
  if (params.dateFrom) {
    body.fromDate = params.dateFrom
  }
  if (params.dateTo) {
    body.toDate = params.dateTo
  }
  if (params.dateRange) {
    body.fromDate = params.dateRange[0]
    body.toDate = params.dateRange[1]
  }

  const filters = {}
  if (params.filterByUserId) {
    filters.userId = params.filterByUserId
  }
  if (params.filterByCampaignId) {
    filters.campaignIds = params.filterByCampaignId
  }
  if (params.filterByCreativeId) {
    filters.creativeIds = params.filterByCreativeId
  }
  if (params.filterByCampaignTags) {
    filters.campaignTags = params.filterByCampaignTags
  }
  if (params.filterByCreativeTags) {
    filters.creativeTags = params.filterByCreativeTags
  }
  if (params.filtersByTargets) {
    filters.targets = params.filtersByTargets
  }

  body.filters = filters

  try {
    const res = await createApiRequest('POST', '/report/campaignReport', body, 201)
    if (!res.reportCampaign) {
      return []
    }

    return res
  } catch (e) {
    if (e.message) {
      showNotification({ type: 'error', message: `${e.message}` })
    }
    return []
  }
}

export const fetchStreamReport = async params => {
  const body = {}
  if (params.showDeleted) {
    body.showDeleted = params.showDeleted
  }
  if (params.groupBy) {
    body.groupBy = params.groupBy
  }
  if (params.groupBy1) {
    body.groupBy1 = params.groupBy1
  }
  if (params.timezone) {
    body.tz = params.timezone
  }
  if (params.dateFrom) {
    body.fromDate = params.dateFrom
  }
  if (params.dateTo) {
    body.toDate = params.dateTo
  }
  if (params.dateRange) {
    body.fromDate = params.dateRange[0]
    body.toDate = params.dateRange[1]
  }

  const filters = {}
  if (params.filterByUserId) {
    filters.userIds = params.filterByUserId
  }
  if (params.filterByStreamId) {
    filters.streamUids = params.filterByStreamId
  }
  if (params.filtersByTargets) {
    filters.targets = params.filtersByTargets
  }

  body.filters = filters
  try {
    const res = await createApiRequest('POST', '/report/streamReport', body, 201)
    if (!res.reportStream) {
      return []
    }

    return res
  } catch (e) {
    if (e.message) {
      showNotification({ type: 'error', message: `${e.message}` })
    }
    return []
  }
}
