import iso from 'iso-3166-1'

const countriesData = iso.all()
const countries = Object.fromEntries(countriesData.map(x => [x.alpha2.toLowerCase(), x.country]))

function getCountryName(countryCode) {
  if (Object.prototype.hasOwnProperty.call(countries, countryCode)) {
    return countries[countryCode]
  }
  return false
}

// [AR, AL...]
function getCountryCodes() {
  const arr = []
  for (const key in countries) {
    arr.push(key)
  }
  return arr
}

function getCountryArray() {
  const arr = []
  for (const key in countries) {
    arr.push({ value: key, name: countries[key] })
  }
  return arr
}

const countryCodes = getCountryCodes()

export { countries, countryCodes, getCountryArray, getCountryCodes, getCountryName }
