import { gql } from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { CAMPAIGN_DATA } from '../Campaign/operations.js'

export const CHANGE_CAMPAIGN_PRICE = gql`
  mutation ChangeCampaignPrice($id: ID!, $price: Float!) {
    changeCampaignPrice(id: $id, price: $price) {
      ...CampaignData
    }
  }
  ${CAMPAIGN_DATA}
`

export function useChangeCampaignPrice() {
  const [mutate, { data, error }] = useMutation(CHANGE_CAMPAIGN_PRICE, {})
  return { mutate, data, error }
}
