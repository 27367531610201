import PageListHeader from '../../../../components/layout/PageListHeader/PageListHeader'
import { DomainForm } from '../../../../containers/domains/DomainForm'

const DomainsCreatePage = () => {
  return (
    <div className="StreamsCreatePage">
      <PageListHeader title="New Domain" />
      <DomainForm />
    </div>
  )
}

export default DomainsCreatePage
