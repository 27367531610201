import { useTranslation } from 'react-i18next'
import { Button, Col, Form, Row } from 'antd'
import showNotification from '../../../helpers/showNotification'
import { defaultFormLayout, defaultFormLayoutOffset } from '../../../helpers/formLayouts'
import FormSubHeader from '../../../components/forms/FormSubHeader/FormSubHeader'
import Input from '../../../components/forms/Input/Input'
import { useAppState } from '../../../context/AppContext'

const ChangePassword = () => {
  const { t } = useTranslation()
  const { apiClient } = useAppState()
  const { mutateAsync } = apiClient.auth.changePassword.useMutation()
  const [form] = Form.useForm()

  async function onFinish(values) {
    if (values.newPassword !== values.passwordAgain) {
      return showNotification({
        type: 'error',
        message: "Passwords didn't match",
      })
    }

    try {
      await mutateAsync({ body: { currentPassword: values.currentPassword, newPassword: values.newPassword } })

      showNotification({ type: 'success', message: 'Updated' })
    } catch (err) {
      showNotification({ type: 'error', message: err.body.message })
    }
  }

  return (
    <Row gutter={48} style={{ marginTop: 48 }}>
      <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <Form onFinish={onFinish} {...defaultFormLayout} form={form}>
          <FormSubHeader title={t('UserSettings.Change password')} layout={defaultFormLayoutOffset} />

          <Input name="currentPassword" label="Current password" isRequired="true" />
          <Input name="newPassword" label={t('UserSettings.Password')} isRequired="true" />
          <Input name="passwordAgain" label={t('UserSettings.Password again')} isRequired="true" />

          <Form.Item {...defaultFormLayoutOffset}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export { ChangePassword }
