import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { archiveCampaign, bulkArchiveCampaigns, fetchArchivedCampaigns, restoreCampaign } from './fetchers'

/**
 * @param {object} p
 * @param {number} p.limit
 * @param {number} p.offset
 */
export const useArchivedCampaigns = ({ limit, offset }) => {
  return useQuery({
    queryKey: ['archivedCampaigns', limit, offset],
    queryFn: () => fetchArchivedCampaigns({ limit, offset }),
  })
}

/**
 * @param {number} campaignId
 */
export const useArchiveCampaignMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: archiveCampaign,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['archivedCampaigns'] })
    },
  })
}

/**
 * @param {number[]} campaignIds
 */
export const useBulkArchiveCampaignsMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: bulkArchiveCampaigns,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['archivedCampaigns'] })
    },
  })
}

export const useRestoreCampaignMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: restoreCampaign,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['archivedCampaigns'] })
    },
  })
}
