import { z } from 'zod';

export const CreativeStatus = {
  WORKING: 'WORKING',
  PAUSED: 'PAUSED',
} as const;

export type CreativeStatus =
  (typeof CreativeStatus)[keyof typeof CreativeStatus];

export const CreativeStatusEnum = z.enum([
  CreativeStatus.WORKING,
  CreativeStatus.PAUSED,
]);
