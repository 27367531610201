import { gql } from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

export const REPORT_CAMPAIGN_STREAMS_DATA = gql`
  fragment ReportCampaignStreamsData on ReportCampaignStreams {
    __typename
    date
    group
    bids
    wonBids
    clicks
    winRate
    cpc
    conversionRate
    cpm
    cost
    conv1
    conv2
    conv3
    payout
    substream
    streamBid
    substreamBid
    streamStatus
    substreamStatus
    streamUid
    stream {
      id
      title
      uid
    }
    bidChangedByRule
    statusChangedByRule
  }
`

export const REPORT_CAMPAIGN_STREAMS_QUERY = gql`
  query ReportCampaignSteramsQuery(
    $campaignId: Int!
    $dateFrom: String
    $dateTo: String
    $filterByStreamId: [Int]
    $timezone: String
    $groupBy: String
  ) {
    reportCampaignStreams(
      dateFrom: $dateFrom
      dateTo: $dateTo
      campaignId: $campaignId
      filterByStreamId: $filterByStreamId
      timezone: $timezone
      groupBy: $groupBy
    ) {
      ...ReportCampaignStreamsData
    }
  }
  ${REPORT_CAMPAIGN_STREAMS_DATA}
`

export function useReportCampaignStreamsQuery(variables) {
  const { loading, error, data, refetch } = useQuery(REPORT_CAMPAIGN_STREAMS_QUERY, {
    variables,
  })
  return { loading, error, data, refetch }
}
