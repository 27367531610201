import { z } from 'zod';

export const CampaignType = {
  PUSH: 'PUSH',
  LINK: 'LINK',
  VIDEO_PRE_ROLL: 'VIDEO_PRE_ROLL',
} as const;

export type CampaignType = (typeof CampaignType)[keyof typeof CampaignType];

export const CampaignTypeEnum = z.enum([
  CampaignType.PUSH,
  CampaignType.LINK,
  CampaignType.VIDEO_PRE_ROLL,
]);
