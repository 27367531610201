import { gql } from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const DELETE_CAMPAIGN = gql`
  mutation DeleteCampaign($id: ID!) {
    deleteCampaign(id: $id) {
      id
      isSuccess
    }
  }
`

export function useDeleteCampaign() {
  const [mutate, { data, error }] = useMutation(DELETE_CAMPAIGN, {
    update(cache, { data: { deleteCampaign } }) {
      cache.modify({
        fields: {
          campaigns(existing, { readField }) {
            return existing.filter(ref => deleteCampaign.id !== readField('id', ref))
          },
        },
      })
    },
  })
  return { mutate, data, error }
}
