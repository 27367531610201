import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createInventorySettings,
  deleteInventorySettings,
  fetchInventorySettings,
  updateInventorySettings,
} from './fetchers'

export const useInventorySettingsQuery = () => {
  return useQuery({
    queryKey: ['inventory', 'settings'],
    queryFn: fetchInventorySettings,
  })
}

export const useCreateInventorySettingsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createInventorySettings,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['inventory', 'settings'] })
    },
  })
}

export const useUpdateInventorySettingsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateInventorySettings,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['inventory', 'settings'] })
    },
  })
}

export const useDeleteInventorySettingsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteInventorySettings,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['inventory', 'settings'] })
    },
  })
}
