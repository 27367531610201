import { gql } from 'graphql-tag'

export const CAMPAIGN_STREAM_INFO_DATA = gql`
  fragment campaignStreamInfoFullFragment on CampaignStreamInfo {
    __typename
    stream {
      id
      title
      uid
    }
    streamUid
    campaign {
      id
      title
    }
    campaignId
    bid
    status
  }
`
