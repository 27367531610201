import React, { FC } from 'react'
import PopConfirmButton from '../../../components/buttons/PopConfirmButton/PopConfirmButton'
import { DeleteTwoTone } from '@ant-design/icons'
import showNotification from '../../../helpers/showNotification'
import * as Sentry from '@sentry/react'
import { useArchiveCampaignMutation } from '../../../containers/campaign/ArchivedCampaignsList/hooks'

export const ArchiveCampaignIcon: FC<{
  id: number
  refetch: () => void
}> = ({ id, refetch }) => {
  const { mutate: archiveCampagin } = useArchiveCampaignMutation()
  return (
    <PopConfirmButton
      key="archive"
      isOnlyIcon
      icon={<DeleteTwoTone />}
      prompt="Are you sure you want to archive campaign?"
      okText="Archive"
      placement="left"
      onClick={() =>
        archiveCampagin(id, {
          onSuccess: () => {
            showNotification({
              type: 'success',
              message: 'Campaign successfully archived',
            })
            refetch()
          },
          onError: err => {
            showNotification({ type: 'error', message: err.message })
            if (process.env.NODE_ENV !== 'development') {
              Sentry.captureException(err)
            }
          },
        })
      }
    >
      <DeleteTwoTone />
    </PopConfirmButton>
  )
}
