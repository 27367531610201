import { useQuery } from '@apollo/react-hooks'
import { Spin } from 'antd'
import { lazy } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { useAppState } from '../context/AppContext'
import { EMAIL_CONFIRMATION_QUERY } from '@pushflow/server/src/graphql/new/user/emailConfirmation/operations'
import componentVariationsFabric from '../helpers/components-fabric.helper'
import showNotification from '../helpers/showNotification'

const EmailConfirmation = () => {
  let { token } = useParams()
  const { loading, data } = useQuery(EMAIL_CONFIRMATION_QUERY, {
    variables: { token },
  })
  const { refetchUser } = useAppState()

  if (loading) {
    return <Spin />
  }

  if (!data || !data.emailConfirmation || !data.emailConfirmation.isSuccess) {
    showNotification({ type: 'error', message: 'E-mail confirmation failed' })
  }
  refetchUser()
  return <Navigate to="/" />
}

EmailConfirmation.Variants = componentVariationsFabric(
  EmailConfirmation,
  lazy(() => import('./EmailConfirmation'))
)

export default EmailConfirmation
