import { gql } from '@apollo/client'
import client from '../../../graphql/client'

const getStreamAutoCompleteQuery = gql`
  query getStreamAutoCompleteQuery($input: GetStreamAutoCompleteInput!) {
    getStreamAutoComplete(input: $input) {
      streams {
        uid
      }
    }
  }
`

export const streamListValuesLoader = params => {
  client
    .query({
      query: getStreamAutoCompleteQuery,
      variables: {
        input: {},
      },
      fetchPolicy: 'cache-first',
    })
    .then(response => {
      params.success(response.data.getStreamAutoComplete.streams.map(({ uid }) => uid))
    })
    .catch(() => {
      params.fail()
    })
}
