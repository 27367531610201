import { Checkbox, Popover, Progress, Space, Table, Tag, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { FixedTableFooter } from '../../../components/FixedTableFooter/FixedTableFooter'

import { EditCampaignUrlButton } from '../../../containers/campaign/EditCampaignUrl'
import { getProfessionalColumnSearchProps } from '../../../helpers/getProfessionalColumnSearchProps'

import { useQueryClient } from '@tanstack/react-query'

import { EditCampaignPriceButton } from '../../../containers/campaign/components/EditCampaignPriceButton'
import { EditCampaignDailyBudget } from '../../../containers/campaign/components/EditCampaignDailyBudget'
import { CampaignStatus, CampaignType, UserRole } from '@pushflow/backend-http-contract'

import { ICampaign } from '../../../types/campaign'
import { BulkStartButton } from './bulk-start-button'
import { BulkPauseButton } from './bulk-pause-button'
import { BulkArchiveButton } from './bulk-archive-button'
import { BulkEditUrlButton } from './bulk-edit-url-button'
import { ChangeStatusIcon } from './change-status-icon'
import { CopyCampaignIcon } from './copy-campaign-icon'
import { ArchiveCampaignIcon } from './archive-campaign-icon'
import { DailyStatNotificationCheckbox } from './daily-notification-checkbox'
import { useAppState } from '../../../context/AppContext'
import showNotification from '../../../helpers/showNotification'
import { ColumnsType } from 'antd/lib/table'
import { CAMPAIGN_LIST_PAGE_SIZE_KEY } from '../../../constant'
import { TelegramIcon } from '../../../assets/icons'

export const CampaignsList = () => {
  const { user } = useAppState()
  const queryClient = useQueryClient()

  const savedPageSize = localStorage.getItem(CAMPAIGN_LIST_PAGE_SIZE_KEY)
    ? Number(localStorage.getItem(CAMPAIGN_LIST_PAGE_SIZE_KEY))
    : 10
  const [pageSize, setPageSize] = useState(savedPageSize)
  const [pageNumber, setPageNumber] = useState(0)

  const [streamIds, setStreamsIds] = useState<number[]>()
  const [tags, setTags] = useState<string[]>()
  const [ids, setIds] = useState<number[]>()
  const [usersIds, setUsersIds] = useState<number[]>()
  const [statuses, setStatuses] = useState<('WORKING' | 'PAUSED')[]>()
  const [countries, setCountries] = useState<string[]>()
  const [title, setTitle] = useState<string>()
  const [campaignTypes, setCampaignTypes] = useState<CampaignType[]>()

  const { apiClient } = useAppState()

  const searchInputEl = useRef(null)
  const selectInputEl = useRef(null)
  const { t } = useTranslation()

  const { isLoading, isError, data, refetch } = apiClient.campaign.getCampaignListPaginated.useQuery(
    ['campaigns', streamIds, usersIds, campaignTypes, tags, ids, statuses, countries, title],
    {
      query: {
        filter: {
          streamIds,
          userIds: usersIds,
          campaignTypes,
          tags,
          ids,
          statuses,
          countries,
          title,
          spent: true,
        },
        pagination: { pageSize, pageNumber },
      },
    }
  )

  const totalCount = data?.body?.pagination.total || 0
  const campaigns = data?.body?.items || []
  const allCampaigns = data?.body?.items || []

  const allCampaignsIds = allCampaigns.map(({ id }) => id)
  const [selectedCampaignIds, setSelectedCampaignIds] = useState<number[]>([])

  useEffect(() => {
    refetch()
  }, [pageNumber, pageSize])

  useEffect(() => {
    setPageNumber(0)

    refetch()
  }, [ids, streamIds, statuses, countries, title, tags])

  let columns: ColumnsType<ICampaign> = [
    {
      title: () => {
        return (
          <Checkbox
            indeterminate={selectedCampaignIds.length > 0 && selectedCampaignIds.length < totalCount}
            checked={selectedCampaignIds.length === totalCount}
            onChange={() => {
              if (selectedCampaignIds.length === totalCount) {
                setSelectedCampaignIds([])
              } else {
                setSelectedCampaignIds(allCampaignsIds)
              }
            }}
          />
        ) as any
      },
      render: (_, record) => {
        return (
          <Checkbox
            checked={selectedCampaignIds.includes(record.id)}
            onChange={() => {
              if (selectedCampaignIds.includes(record.id)) {
                setSelectedCampaignIds(selectedCampaignIds.filter(id => id !== record.id))
              } else {
                setSelectedCampaignIds([...selectedCampaignIds, record.id])
              }
            }}
          />
        )
      },
      width: 30,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      width: 70,
      render: (text, record) => (
        <Link to={`/campaign/${record.id}`}>
          <b>{text}</b>
        </Link>
      ),
      ...getProfessionalColumnSearchProps(setIds, {
        type: 'multiselect',
        placeholder: 'ID',
        options: [...(data?.body.filterValues?.ids || [])].map(id => ({
          label: id,
          value: id,
        })),
        searchInputEl,
        selectInputEl,
      }),
    },
    {
      title: t('Campaign.Title'),
      dataIndex: 'title',
      width: 200,
      ...getProfessionalColumnSearchProps(setTitle, {
        type: 'text',
        placeholder: t('Campaign.Title'),
        searchInputEl,
        selectInputEl,
      }),
      render: (text, record) => <Link to={`/campaign/${record.id}`}>{<b>{text}</b>}</Link>,
    },
    {
      title: t('Campaign.CampaignTypeShort'),
      dataIndex: 'campaignType',
      render: (_, { campaignType }) =>
        campaignType === CampaignType.PUSH ? (
          <Tag color="purple">{campaignType}</Tag>
        ) : (
          <Tag color="blue">{campaignType}</Tag>
        ),
      ...getProfessionalColumnSearchProps(setCampaignTypes, {
        type: 'multiselect',
        placeholder: t('Campaign.CampaignTypeShort'),
        options: [CampaignType.PUSH, CampaignType.LINK, CampaignType.VIDEO_PRE_ROLL].map(id => ({
          label: id,
          value: id,
        })),
        searchInputEl,
        selectInputEl,
      }),
      width: 70,
    },
    {
      title: t('Campaign.Status.Status'),
      dataIndex: 'status',
      ...getProfessionalColumnSearchProps(setStatuses, {
        type: 'multiselect',
        placeholder: t('Campaign.Status.Status'),
        options: [...(data?.body.filterValues?.statuses || [])].sort().map(id => ({
          label: id,
          value: id,
        })),
        searchInputEl,
        selectInputEl,
      }),
      render: (_, record) =>
        record.status === CampaignStatus.WORKING ? (
          <Tag color="success">Working</Tag>
        ) : (
          <Tag color="warning">Paused</Tag>
        ),
      width: 90,
    },
    {
      title: t('Campaign.Countries'),
      dataIndex: 'countryCode',
      render: (text: string[]) => {
        const isEmptyCountries = !text || text.length === 0
        return !isEmptyCountries ? text.map(r => r + ' ') : 'All'
      },
      width: 100,
      ...getProfessionalColumnSearchProps(setCountries, {
        type: 'multiselect',
        placeholder: t('Campaign.Countries'),
        options: [...(data?.body.filterValues?.countries || [])].map(id => ({
          label: id,
          value: id,
        })),
        searchInputEl,
        selectInputEl,
      }),
    },
    {
      title: t('Campaign.Streams'),
      dataIndex: 'streamsInfo',
      ...getProfessionalColumnSearchProps(setStreamsIds, {
        type: 'multiselect',
        placeholder: t('Campaign.Streams'),
        options: [...(data?.body.filterValues?.streamIds || [])].map(id => ({
          label: id,
          value: id,
        })),
        searchInputEl,
        selectInputEl,
      }),
      render: streams => {
        if (!streams) return null

        let maxTags = 3

        if (streams.length === maxTags + 1) {
          maxTags += 1
        }

        const hiddenStreams = streams.slice(maxTags) as ICampaign['streamsInfo']
        const shownElements = streams.slice(0, maxTags) as ICampaign['streamsInfo']

        const space = shownElements.map(s => (
          <Tooltip key={s.id} title={s.title}>
            <Tag>{s.id}</Tag>
          </Tooltip>
        ))
        const popover = (
          <Popover
            content={hiddenStreams.map(s => (
              <p>
                {s.id} - {s.title}
              </p>
            ))}
          >
            {shownElements.map(({ id }) => id).join(', ')}
            <Tag>...</Tag>
          </Popover>
        )

        return <> {hiddenStreams.length > 1 ? popover : space} </>
      },
      width: 90,
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      width: 100,
      ...getProfessionalColumnSearchProps(setTags, {
        type: 'multiselect',
        placeholder: 'Tags',
        options: [...(data?.body?.filterValues?.tags || [])].map(id => ({
          label: id,
          value: id,
        })),
        searchInputEl,
        selectInputEl,
      }),
      render: (text: string[], r: { id: number }) => {
        if (!text) return null
        return text.map((t: string) => <Tag key={`${r.id}_${t}`}>{t}</Tag>)
      },
    },
    {
      title: t('Campaign.Price'),
      dataIndex: 'price',
      width: 100,
      render: (text, record) => {
        return (
          <div>
            {parseFloat(parseFloat(text).toFixed(4))}$&nbsp;
            <EditCampaignPriceButton price={text} campaignId={record.id} queryClient={queryClient} />
          </div>
        )
      },
    },
    {
      title: 'Daily spent',
      dataIndex: 'maxDailyBudget',
      width: 140,
      render: (val, { id, maxDailyBudget = 0, spent, maxTotalBudget = 0 }) => {
        let percent = 0
        let text = ''

        if (maxDailyBudget) {
          text = `${spent?.toFixed(2) || 0} of ${maxDailyBudget?.toFixed(2)}`
          percent = Math.round(((spent || 0) / maxDailyBudget) * 100)
        }

        if (!maxDailyBudget && maxTotalBudget) {
          text = `${spent?.toFixed(2)}`
        }

        return (
          <>
            {text}$&nbsp;
            <EditCampaignDailyBudget
              dailyBudget={maxDailyBudget}
              campaignId={id}
              refetch={refetch}
              queryClient={queryClient}
            />
            {maxDailyBudget ? (
              <Progress
                percent={percent}
                size="small"
                strokeColor={{ '0%': '#87d068', '50%': '#ffe58f', '100%': '#ffccc7' }}
              />
            ) : (
              ''
            )}
          </>
        )
      },
    },
    {
      title: t('Table.Actions'),
      key: 'action',
      render: (_, record) => {
        return (
          <Space size="large">
            <ChangeStatusIcon id={record.id} status={record.status} queryClient={queryClient} />
            <CopyCampaignIcon id={record.id} refetch={refetch} />
            <ArchiveCampaignIcon id={record.id} refetch={refetch} />
            <EditCampaignUrlButton type="icon" campaignId={record.id} />
          </Space>
        )
      },
    },
    {
      title: (
        <Tooltip title="Telegram notifications settings" placement="left">
          <TelegramIcon
            // @ts-ignore
            height="1rem"
            width="1rem"
          />
        </Tooltip>
      ),
      key: 'action',
      render: (_, record) => {
        return (
          <Space size="large">
            <DailyStatNotificationCheckbox
              campaignId={record.id}
              enabledDailyStatsNotification={record.enabledNotificationType.includes('DAILY_STAT')}
            ></DailyStatNotificationCheckbox>
          </Space>
        )
      },
    },
  ]

  if (user && user.roles.includes(UserRole.ADMIN)) {
    columns = [
      columns[0],
      columns[1],
      {
        title: 'User ID',
        dataIndex: 'userId',
        width: 70,
        render: (text, record) => (
          <Link to={`/admin/users/${record.id}`}>
            <b>{text}</b>
          </Link>
        ),
        ...getProfessionalColumnSearchProps(setUsersIds, {
          type: 'multiselect',
          placeholder: 'User ID',
          options: [...(data?.body.filterValues?.userIds || [])].map(id => ({
            label: id,
            value: id,
          })),
          searchInputEl,
          selectInputEl,
        }),
      },
      ...columns.slice(2),
    ]
  }

  if (isError) {
    showNotification({ type: 'error', message: 'Something went wrong during loading campaign list' })
    return <></>
  }

  return (
    <>
      <Table
        columns={columns as any}
        dataSource={campaigns}
        loading={isLoading}
        rowKey="id"
        size="middle"
        scroll={{ x: true }}
        sticky={true}
        pagination={{
          showSizeChanger: true,
          pageSize,
          current: pageNumber + 1,
          total: totalCount,
          pageSizeOptions: [10, 20, 50, 100, 400],
          onChange(page, newLimit) {
            if (newLimit !== pageSize) {
              setPageSize(newLimit)
              setPageNumber(page - 1)
              localStorage.setItem(CAMPAIGN_LIST_PAGE_SIZE_KEY, newLimit.toString())
            } else if (page - 1 !== pageNumber) {
              setPageNumber(page - 1)
            }
          },
        }}
        footer={() => {
          return (
            <FixedTableFooter>
              <Space>
                <BulkStartButton selectedCampaignIds={selectedCampaignIds} queryClient={queryClient} />
                <BulkPauseButton selectedCampaignIds={selectedCampaignIds} queryClient={queryClient} />
                <BulkArchiveButton
                  selectedCampaignIds={selectedCampaignIds}
                  allCampaigns={allCampaigns}
                  setSelectedCampaignIds={setSelectedCampaignIds}
                  refetch={refetch}
                />
                <BulkEditUrlButton selectedCampaignIds={selectedCampaignIds} allCampaigns={allCampaigns} />
              </Space>
            </FixedTableFooter>
          )
        }}
      />
    </>
  )
}
