import React, { useState } from 'react'
import { Form, Input as AntdInput, Tag } from 'antd'

export const TextAreaWithMacros: React.FC<{ name: string; form: any; macroses: any }> = ({ name, form, macroses }) => {
  const [cursor, setCursor] = useState(0)
  // @ts-ignore
  const getCursorPosition = e => {
    setCursor(e.target.selectionStart)
  }
  // @ts-ignore
  const putMacros = macros => {
    const text = form.getFieldValue(name)

    const newText = text.slice(0, cursor) + `{${macros}}` + text.slice(cursor)

    form.setFieldValue(name, newText)
  }

  return (
    <>
      <Form.Item noStyle name={name}>
        <AntdInput.TextArea autoSize onKeyUp={getCursorPosition} onClick={getCursorPosition} />
      </Form.Item>

      {
        // @ts-ignore
        macroses.map((text, index) => (
          <Tag key={index} onClick={() => putMacros(text)} style={{ cursor: 'pointer' }}>
            {text}
          </Tag>
        ))
      }
    </>
  )
}
