import { gql } from '@apollo/client'
import { useQuery } from '@apollo/react-hooks'
import { API_LOG_DATA } from '../../../entities/ApiLog/operations.js'

export const GET_USER_API_LOGS_QUERY = gql`
  query GetUserApiLogs($input: GetUserApiLogsInput!) {
    getUserApiLogs(input: $input) {
      apiLogs {
        ...ApiLogData
      }
      total
    }
  }
  ${API_LOG_DATA}
`

export const useGetUserApiLogsQuery = (input, options = {}) => {
  return useQuery(GET_USER_API_LOGS_QUERY, {
    variables: { input },
    ...options,
  })
}
