import { notification } from 'antd'

// 'success', 'info', 'warning', 'error'
const showNotification = (params: {
  type: 'success' | 'info' | 'warning' | 'error'
  message: string
  description?: string
  duration?: number
}) => {
  notification[params.type]({
    message: params.message,
    description: params.description,
    duration: params.duration || 5,
  })
}

export default showNotification
