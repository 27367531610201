.table
  display: flex
  flex-flow: column
  position: relative
  z-index: 1000
  user-select: none

  .row
    display: flex
    flex-flow: row nowrap

    .cell
      flex: 0 0 20px
      width: 20px
      height: 20px
      display: flex
      justify-content: center
      align-items: center

.label
  font-size: 13px
  color: rgba(0,0,0,.45)

  &.hour, &.day
    cursor: pointer
    &:hover
      color: #0046e8

.checkbox
  width: 16px
  height: 16px
  border-radius: 3px
  background: #d8d8d8
  cursor: pointer

  &.checked
    background: #0046e8
