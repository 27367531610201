import React, { FC } from 'react'
import { CreativeModerationStatus } from '@pushflow/backend-http-contract/dist/common/creative-moderation-status'
import { Tooltip } from 'antd'

export const CreativeModerationIndicator: FC<{ moderationStatus: CreativeModerationStatus }> = ({
  moderationStatus,
}) => {
  if (moderationStatus === CreativeModerationStatus.REVIEW) {
    return (
      <Tooltip title="Creative reviewing ⏳" color={'yellow'} key={'yellow'}>
        🟡
      </Tooltip>
    )
  }
  if (moderationStatus === CreativeModerationStatus.APPROVED) {
    return (
      <Tooltip title="Creative approved 👍" color={'green'} key={'green'}>
        🟢
      </Tooltip>
    )
  }
  if (moderationStatus === CreativeModerationStatus.DECLINED) {
    return (
      <Tooltip title="Creative delined 😡" color={'red'} key={'red'}>
        🔴
      </Tooltip>
    )
  }

  return (
    <Tooltip title="prompt text" color={'blue'} key={'blue'}>
      🔘
    </Tooltip>
  )
}
