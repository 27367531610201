import { ControlOutlined, PlusOutlined, RollbackOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useRule } from '../../../containers/campaign/hooks'
import { useCampaignQuery } from '@pushflow/server/src/graphql/new/campaigns/getCampaign/operations'
import { CampaignRule } from '../../../containers/campaign/components/CampaignRules/CampaignRulesForm'
import PageListHeader from '../../../components/layout/PageListHeader/PageListHeader'
import { RuleTemplateModal } from '../../../containers/campaign/components/CampaignRules/RuleTemplateModal'
import { CopyRuleModal } from '../../../containers/campaign/components/CampaignRules/CopyRuleModal'
import React, { FC } from 'react'

export const CampaignRulePage: FC<{ params: { campaignId: number; id: number } }> = ({ params }) => {
  const { campaignId, id: ruleId } = params
  const { t } = useTranslation()

  const { loading, error, data } = useCampaignQuery(campaignId)
  const { data: ruleInfo, isLoading, isError, error: ruleErr } = useRule({ campaignId, ruleId })

  if (loading || !data) {
    return <div>{t('Common.Loading')}</div>
  }
  if (error) {
    return (
      <>
        `${t('Common.Error')}!: ${error}`
      </>
    )
  }
  if (isLoading && !ruleInfo) return <div>{t('Common.Loading')}</div>
  if (isError) {
    return (
      <>
        `${t('Common.Error')}!: ${ruleErr}`
      </>
    )
  }
  const { campaign } = data

  return (
    <>
      <PageListHeader
        title={`Campaign ${campaignId} | Rule ID: ${ruleId}`}
        buttonSize="small"
        buttons={[
          {
            link: `/rules/${campaignId}/create`,
            type: 'primary',
            icon: <PlusOutlined />,
            title: t('Actions.Create'),
          },
          {
            link: `/rules/${campaignId}`,
            type: 'primary',
            icon: <ControlOutlined />,
            title: `Campaign Rules List`,
          },
          {
            link: `/campaign`,
            type: 'primary',
            icon: <RollbackOutlined />,
            title: 'Campaigns',
          },
        ]}
      />
      <RuleTemplateModal ruleInfo={ruleInfo} />
      <CopyRuleModal ruleInfo={ruleInfo} />
      <CampaignRule campaign={{ ...campaign, hasTargetCpa: campaign.targetCpa !== null }} rule={ruleInfo} />
    </>
  )
}
