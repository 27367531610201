import { z } from 'zod';

export const paginationRequest = z.object({
  pageNumber: z.coerce.number().default(100),
  pageSize: z.coerce.number().default(0),
});

export const paginationResponse = z.object({
  total: z.coerce.number(),
});
