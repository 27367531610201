import { useTranslation } from 'react-i18next'
import Title from '../../../components/layout/Title/Title'
import { Col, Row, Statistic, Tag } from 'antd'
import { useAppState } from '../../../context/AppContext'
import React from 'react'

export const AdvertiserStats: React.FC = () => {
  const { apiClient } = useAppState()
  const { t } = useTranslation()

  const { data, isLoading } = apiClient.report.getAdvertiserSummaryReport.useQuery(['advertiser-summary-report'])

  if (isLoading || !data?.body) return <div>{t('Common.Loading')}</div>

  const stats = data.body

  return (
    <>
      <div>
        <Title level={4} style={{ marginBottom: 20 }} isSeparator>
          Campaigns Cost <Tag style={{ verticalAlign: 2 }}>UTC+0</Tag>
        </Title>

        <Row gutter={16}>
          <Col span={6}>
            <Statistic title="Today" value={stats.costToday} suffix="$" />
          </Col>
          <Col span={6}>
            <Statistic title="Yesterday" value={stats.costYesterday} suffix="$" />
          </Col>
          <Col span={6}>
            <Statistic title="Last 7 days" value={stats.cost7days} suffix="$" />
          </Col>
          <Col span={6}>
            <Statistic title="This month" value={stats.costThisMonth} suffix="$" />
          </Col>
        </Row>
      </div>
      <div>
        <Title level={4} style={{ marginBottom: 20 }} isSeparator>
          Campaigns Clicks <Tag style={{ verticalAlign: 2 }}>UTC+0</Tag>
        </Title>

        <Row gutter={16}>
          <Col span={6}>
            <Statistic title="Today" value={stats.clicksToday} />
          </Col>
          <Col span={6}>
            <Statistic title="Yesterday" value={stats.clicksYesterday} />
          </Col>
          <Col span={6}>
            <Statistic title="Last 7 days" value={stats.clicks7days} />
          </Col>
          <Col span={6}>
            <Statistic title="This month" value={stats.clicksThisMonth} />
          </Col>
        </Row>
      </div>
    </>
  )
}
