import { z } from 'zod';

export const UserNotificationType = {
  TELEGRAM: 'TELEGRAM',
  EMAIL: 'EMAIL',
} as const;

export type UserNotificationType =
  (typeof UserNotificationType)[keyof typeof UserNotificationType];

export const UserNotificationTypeEnum = z.enum([
  UserNotificationType.TELEGRAM,
  UserNotificationType.EMAIL,
]);
