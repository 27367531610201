import config from '../../../../config'
import { getToken } from '../../../../helpers/jwtHelper'

export const fetchArchivedCampaigns = async ({ limit, offset }) => {
  const query = new URLSearchParams({ limit, offset })
  const res = await fetch(`${config.uiBackendUrl}/campaign/archived?${query.toString()}`, {
    headers: { Authorization: `Bearer ${getToken()}` },
  })

  if (res.status !== 200) {
    throw new Error('Error while fetching archived campaigns')
  }

  return res.json()
}

export const archiveCampaign = async campaignId => {
  const res = await fetch(`${config.uiBackendUrl}/campaign/archive/${campaignId}`, {
    method: 'PATCH',
    headers: { Authorization: `Bearer ${getToken()}` },
  })

  if (res.status !== 200) {
    throw new Error('Error while archiving campaign')
  }
}

export const bulkArchiveCampaigns = async campaignIds => {
  const res = await fetch(`${config.uiBackendUrl}/campaign/archive`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({ campaignIds }),
  })

  if (res.status !== 200) {
    throw new Error('Error while archiving campaigns')
  }

  return res.json()
}

export const restoreCampaign = async campaignId => {
  const res = await fetch(`${config.uiBackendUrl}/campaign/restore/${campaignId}`, {
    method: 'PATCH',
    headers: { Authorization: `Bearer ${getToken()}` },
  })

  if (res.status !== 200) {
    throw new Error('Error while restoring campaign')
  }
}
