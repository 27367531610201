import { PlusOutlined } from '@ant-design/icons'
import PageListHeader from '../../../../components/layout/PageListHeader/PageListHeader'
import DomainsList from './DomainsList'
import { useTranslation } from 'react-i18next'

const DomainsListPage = () => {
  const { t } = useTranslation()
  return (
    <div className="StreamsListPage">
      <PageListHeader
        title="Domains"
        buttons={[
          {
            link: `/admin/domains/create`,
            type: 'primary',
            icon: <PlusOutlined />,
            title: t('Actions.Create'),
          },
        ]}
      />
      <DomainsList />
    </div>
  )
}

export default DomainsListPage
