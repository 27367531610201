import { Tag } from 'antd'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '../Input/Input'

const macroses = [
  'hit_id',
  'external_hit_id',
  'external_uid',
  'days',
  'hours',
  'minutes',
  'campaign_id',
  'creative_id',
  'country',
  'ip',
  'isp',
  'stream_id',
  'sub_stream_id',
  'price_model',
  'cost',
  'os',
  'os_version',
  'browser',
  'browser_version',
  'lang',
  'campaign_tags',
  'creative_tags',
  'click_delay',
]

function doGetCaretPosition(oField) {
  // Initialize
  var iCaretPos = 0

  // IE Support
  if (document.selection) {
    // Set focus on the element
    oField.focus()

    // To get cursor position, get empty selection range
    var oSel = document.selection.createRange()

    // Move selection start to 0 position
    oSel.moveStart('character', -oField.value.length)

    // The caret position is selection length
    iCaretPos = oSel.text.length
  }

  // Firefox support
  else if (oField.selectionStart || oField.selectionStart == '0')
    iCaretPos = oField.selectionDirection == 'backward' ? oField.selectionStart : oField.selectionEnd

  // Return results
  return iCaretPos
}

function insertAtIndex(str, substring, index) {
  return str.slice(0, index) + substring + str.slice(index)
}

export const InputWithMacroses = ({ form, ...props }) => {
  const { t } = useTranslation()
  const inputRef = useRef(null)
  const [cursorPosition, setCursorPosition] = useState(form.getFieldValue(props.name)?.length || 0)
  return (
    <Input
      {...props}
      validators={[
        {
          warningOnly: true,
          validator: (_, value) => {
            const allMacrosesInValue = [...value.matchAll(/{(.*?)}/g)]
            const invalidMacroses = allMacrosesInValue
              .filter(macros => !macroses.includes(macros[1]) && macros[1] !== '')
              .map(macros => macros[1])
            if (invalidMacroses.length) {
              return Promise.reject(new Error(`Invalid macroses: ${invalidMacroses.join(', ')}`))
            } else {
              return Promise.resolve()
            }
          },
        },
      ]}
      onChange={(...args) => {
        setCursorPosition(doGetCaretPosition(inputRef.current.input))
        props.onChange?.(...args)
      }}
      onKeyUp={(...args) => {
        setCursorPosition(doGetCaretPosition(inputRef.current.input))
        props.onKeyUp?.(...args)
      }}
      onClick={(...args) => {
        const e = args[0]
        if (e.target.tagName.toLowerCase() === 'input') {
          setCursorPosition(doGetCaretPosition(inputRef.current.input))
        }
        props.onClick?.(...args)
      }}
      ref={inputRef}
      description={
        <>
          {t('CreativeForm.Help.Macros')}
          <div style={{ marginBottom: -6, cursor: 'pointer' }}>
            {macroses.map(macros => (
              <Tag
                onClick={() => {
                  const oldValue = form.getFieldValue(props.name)
                  const macrosString = `{${macros}}`
                  const newValue = insertAtIndex(oldValue, macrosString, cursorPosition)
                  form.setFieldsValue({ [props.name]: newValue })
                  const newCursorPosition = cursorPosition + macrosString.length
                  setCursorPosition(newCursorPosition)
                  inputRef.current.focus()
                  setTimeout(() => {
                    inputRef.current.input.setSelectionRange(newCursorPosition, newCursorPosition)
                  }, 0)
                  form.validateFields([props.name])
                }}
                key={macros}
                style={{ marginBottom: 6 }}
              >{`{${macros}}`}</Tag>
            ))}
          </div>
        </>
      }
    />
  )
}
