export const floatFormatter = params => {
  if (params.value === null) {
    return params.value
  }
  return Number(params.value.toFixed(2))
}

export const currencyFormatter = (value, sign = '$', symbolsCount = 2) => {
  const formatted = value.toFixed(symbolsCount)
  return `${sign}${formatted}`
}

export const numberFormatter = params => {
  const num = params.value
  if (num < 10000 && num > -10000) return num
  var parts = num.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}
