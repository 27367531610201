import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { useEstimateCampaign } from '@pushflow/server/src/graphql/new/campaigns/estimateCampaign/operations'
import { Button, Typography, Checkbox, Row, Col, Descriptions, Divider } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { buildCampaignFromFormValues } from './util'
const targetingFields = [
  'countryCode',
  'lang',
  'browser',
  'os',
  'device',
  'streams',
  'substreams',
  'domains',
  'useragents',
  'connectionType',
  'isp',
]
const targetingIncludeFields = targetingFields.map(field => `${field}Include`)

const campaignLimitFields = [
  'maxClicked',
  'maxShown',
  'maxClickedUser',
  'maxShownUser',
  'maxDailyBudget',
  'maxTotalBudget',
]

const fieldsWithStatsImpact = [
  ...targetingFields,
  ...targetingIncludeFields,
  ...campaignLimitFields,
  'targetDaysAndHours',
  'userAuditoryConditions',
  'blacklists',
  'whitelists',
  'auditories',
  'auditoriesInclude',
  'campaignType',
  'bidType',
  'subscriptionAgeEnabled',
  'subscriptionAgeFrom',
  'subscriptionAgeTo',
  // 'maxShownAuditory',
  // 'maxClickedAuditory',
  // 'maxShownThreshold',
  // 'maxClickedThreshold',
]

const { Text, Title } = Typography

const CampaignEstimateModal = ({ form, initialValues }) => {
  const { data, refetch } = useEstimateCampaign({
    campaignBody: {},
  })
  const [loading, setLoading] = useState(false)
  const [campaignBody, setCampaignBody] = useState(null)
  const [estimationParams, setEstimateParams] = useState({
    // includeCreatives: false,
    includeBlWl: false,
    includeUserAuditories: false,
    // includeUserAuditoriesConditions: false,
    includeCampaignLimits: false,
    includeUserLimits: false,
    includeBudgetLimits: false,
    includeTimeSchedule: false,
    // includeFields: [],
    // excludeFields: [],
    exactTargetsMatch: true,
  })
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const handleEstimateClick = () => {
    const formValues = form.getFieldsValue()
    const campaignData = buildCampaignFromFormValues({ formValues, initialValues })
    const campaignBody = Object.entries(campaignData).reduce((acc, [key, value]) => {
      const val = campaignLimitFields.includes(key) ? +value : value
      if (fieldsWithStatsImpact.includes(key)) {
        acc[key] = val
      }
      return acc
    }, {})
    campaignBody.id = +campaignData.id
    setCampaignBody(campaignBody)
  }

  const handleParamsChange = (paramName, val) => {
    setEstimateParams({
      ...estimationParams,
      [paramName]: val,
    })
  }

  useEffect(() => {
    if (campaignBody) {
      setLoading(true)
      refetch({ campaignBody, estimationParams }).then(() => {
        setLoading(false)
      })
    }
  }, [campaignBody, refetch])

  useEffect(() => {
    if (open) {
      form.validateFields().then(() => {
        handleEstimateClick()
      })
    }
  }, [open])

  const DescrItem = ({ loading, data, title }) => {
    return (
      <Descriptions title={title} layout="vertical" bordered>
        {!loading ? (
          data.map(([key, value]) => (
            <Descriptions.Item key={key} label={<Text>{t(`Campaign.EstimateData.${key}`)}</Text>}>
              {value}
            </Descriptions.Item>
          ))
        ) : (
          <div>Loading...</div>
        )}
      </Descriptions>
    )
  }
  const statsFieldNames = ['bids', 'wonBids', 'clicks', 'impressions']
  const costFieldNames = ['costForMonth', 'costForDay', 'costForWeek']
  const statsFields = Object.entries(data?.estimateCampaign || {}).filter(
    ([key, val]) => statsFieldNames.includes(key) && val !== null
  )
  const costFields = Object.entries(data?.estimateCampaign || {})
    .filter(([key, val]) => costFieldNames.includes(key) && val !== null)
    .map(([k, v]) => [k, `${v} $`])

  return (
    <div>
      <Title style={{ cursor: 'pointer' }} level={3} onClick={() => setOpen(!open)}>
        Estimate <span style={{ fontSize: 14 }}>{!open ? <DownOutlined /> : <UpOutlined />}</span>
      </Title>
      <Row style={!open && { display: 'none' }}>
        {data && (
          <Col span={24}>
            <Title level={4}>
              Estimates by {data?.estimateCampaign.totalCampaignsCounted}{' '}
              {estimationParams.exactTargetsMatch ? 'exactly matched' : 'similar'} campaigns
            </Title>
            <DescrItem title="Stats Estimate" loading={loading} data={statsFields} />
            <DescrItem title="Cost Estimate" loading={loading} data={costFields} />
            <Divider />
            <Row gutter={24}>
              {Object.entries(estimationParams)
                .filter(([key]) => key !== 'includeFields' && key !== 'excludeFields')
                .map(([paramName, val]) => {
                  return (
                    <Col span={12} key={`check-key-${paramName}`}>
                      <Checkbox onChange={v => handleParamsChange(paramName, v.target.checked)} checked={val}>
                        {t(
                          `Campaign.EstimateParams.${paramName}${
                            estimationParams.exactTargetsMatch && paramName.startsWith('include') ? 'Exact' : ''
                          }`
                        )}
                      </Checkbox>
                    </Col>
                  )
                })}
              <Button onClick={handleEstimateClick} type="primary">
                Estimate
              </Button>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default CampaignEstimateModal
