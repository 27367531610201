import config from '../config'
import qs from 'qs'
import { initQueryClient } from '@ts-rest/react-query'
import { contract } from '@pushflow/backend-http-contract'
import { getToken } from '../helpers/jwtHelper'
import { tsRestFetchApi } from '@ts-rest/core'
import { ZodError } from 'zod'
import { ErrorCodeToMessage, ErrorCode } from '@pushflow/backend-http-contract'

export const createApiClient = (
  token: string | null,
  onError: (title: string, message: string, type?: 'error' | 'warn') => void
) => {
  const baseHeaders: Parameters<typeof initQueryClient>[1]['baseHeaders'] = { 'Content-Type': 'application/json' }
  if (token) {
    baseHeaders['Authorization'] = `Bearer ${token}`
  }

  return initQueryClient(contract, {
    baseUrl: config.uiBackendUrl!,
    validateResponse: true,
    jsonQuery: true,
    baseHeaders,
    credentials: 'include',
    api: async args => {
      try {
        const res = await tsRestFetchApi(args)

        if (res.status === 500) {
          const { message = '', requestId = '' } = res.body as { message: undefined; requestId: undefined }
          onError(message, 'Request id: ' + requestId, 'error')
        }

        // @ts-ignore
        if (res.status === 400 && res.body && 'errorCode' in res.body) {
          const { type: msgType, message } = ErrorCodeToMessage[res.body.errorCode as ErrorCode]
          const title = msgType === 'error' ? 'Error!' : 'Attention!'
          onError(title, message, msgType)
        }

        return res
      } catch (err: ZodError | unknown) {
        if (err instanceof ZodError) {
          onError('Validation error', 'Some fields are invalid')
          console.error('Validation error', err)
        } else if (err instanceof DOMException && err.code === DOMException.ABORT_ERR) {
          if (err.message === 'signal is aborted without reason') {
            // TODO: request was aborted without reason, investigate why this happens and whata to do with that
          } else {
            console.warn('Request was aborted')
          }
        } else {
          onError('Unknown error', 'Something went wrong')
          console.error('Error during request', err)
        }

        throw err
      }
    },
  })
}

export const createApiRequest = async (method: string, path: string, params?: any, successStatus?: number) => {
  const token = getToken()
  let url = `${config.uiBackendUrl}${path}`

  let init: RequestInit = {
    method: method,
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    credentials: 'include',
  }

  if (method === 'POST' || method === 'PATCH' || method === 'DELETE' || method === 'PUT') {
    init = { ...init, body: JSON.stringify(params) }
  }

  if (method === 'GET' && params !== undefined) {
    url = url + '?' + qs.stringify(params)
  }

  const res = await fetch(url, init)

  if (successStatus !== undefined) {
    if (res.status !== successStatus) {
      throw new Error('Error while fetching data')
    }
  } else {
    if (method === 'GET' && res.status !== 200) {
      throw new Error('Error while fetching data')
    }

    if (method === 'POST' && res.status !== 201) {
      throw new Error('Error while fetching data')
    }
  }

  return res.json()
}
