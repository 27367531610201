import { EyeOutlined, SearchOutlined } from '@ant-design/icons'
import { Alert, Button, Input, Radio, Row, Space, Table, Tag } from 'antd'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppState } from '../../../../context/AppContext'
import { UserRole, UserStatus } from '@pushflow/backend-http-contract'
import showNotification from '../../../../helpers/showNotification'

export const UserList: FC<{ status: UserStatus }> = ({ status }) => {
  const { apiClient, user } = useAppState()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const [ftsByEmail, setFtsByEmail] = useState()
  const [isPublisher, setIsPublisher] = useState()
  const [isAdvertiser, setIsAdvertiser] = useState()

  const navigate = useNavigate()

  const searchInputEl = useRef(null)

  const { error, isLoading, data, refetch } = apiClient.user.getUserListPaginated.useQuery(
    ['user-list', pageNumber, pageSize, ftsByEmail, status],
    {
      query: {
        filter: {
          ftsByEmail,
          roles: [
            ...(isPublisher ? [UserRole.PUBLISHER] : []),
            ...(isAdvertiser ? [UserRole.EXTERNAL_ADVERTISER, UserRole.INTERNAL_ADVERTISER] : []),
          ],
          statuses: [status],
        },
        pagination: { offset: (pageNumber - 1) * pageSize, limit: pageSize },
      },
    }
  )
  const { mutateAsync: changeUserStatus } = apiClient.user.changeUserStatus.useMutation()

  useEffect(() => {
    refetch()
  }, [pageNumber, pageSize, ftsByEmail, isPublisher, isAdvertiser, status])

  if (error) {
    console.error(error)
    return <Alert type="error" message={<>error?.body</>} />
  }

  // @ts-ignore
  const getColumnSearchProps = (setValue, { type, placeholder }) => {
    return {
      // @ts-ignore
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        if (type === 'text') {
          return (
            <div style={{ padding: 8 }}>
              <Input
                ref={searchInputEl}
                placeholder={placeholder}
                value={selectedKeys[0]}
                onChange={({ target }) => setSelectedKeys(target.value ? [target.value] : [])}
                onPressEnter={() => {
                  confirm()
                  setValue(selectedKeys[0])
                }}
                style={{ marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    confirm()
                    setValue(selectedKeys[0])
                  }}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button
                  onClick={() => {
                    clearFilters?.()
                    confirm()
                    setValue(undefined)
                  }}
                  size="small"
                  style={{ width: 90 }}
                >
                  Reset
                </Button>
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    confirm({
                      closeDropdown: false,
                    })
                    setValue(selectedKeys[0])
                  }}
                >
                  Filter
                </Button>
              </Space>
            </div>
          )
        }
        if (type === 'boolean') {
          return (
            <div style={{ padding: 8 }}>
              <div style={{ marginBottom: 8 }}>
                <Radio.Group
                  onChange={({ target }) => {
                    setSelectedKeys([target.value])
                  }}
                  value={selectedKeys[0]}
                >
                  <Radio value={true}>true</Radio>
                  <Radio value={false}>false</Radio>
                </Radio.Group>
              </div>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    confirm()
                    setValue(selectedKeys[0])
                  }}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button
                  onClick={() => {
                    clearFilters?.()
                    confirm()
                    setValue(undefined)
                  }}
                  size="small"
                  style={{ width: 90 }}
                >
                  Reset
                </Button>
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    confirm({
                      closeDropdown: false,
                    })
                    setValue(selectedKeys[0])
                  }}
                >
                  Filter
                </Button>
              </Space>
            </div>
          )
        }
      },
      // @ts-ignore
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          // @ts-ignore
          setTimeout(() => searchInputEl.current?.select(), 100)
        }
      },
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ...getColumnSearchProps(setFtsByEmail, { type: 'text', placeholder: 'Search by email' }),
    },
    {
      title: 'Publisher',
      dataIndex: 'roles',
      render: (roles: UserRole[]) => <>{roles.includes(UserRole.PUBLISHER) ? '✅' : '❌'}</>,
      // @ts-ignore
      ...getColumnSearchProps(setIsPublisher, { type: 'boolean' }),
    },
    {
      title: 'Advertiser',
      dataIndex: 'roles',
      render: (roles: UserRole[]) => {
        const isTeam = roles.includes(UserRole.INTERNAL_ADVERTISER)
        const isExternal = roles.includes(UserRole.EXTERNAL_ADVERTISER)
        const notAdvertiser = !isTeam && !isExternal
        return (
          <>
            {isTeam && <Tag color="blue">Team</Tag>}
            {isExternal && <Tag color="green">External</Tag>}
            {notAdvertiser && <>❌</>}
          </>
        )
      },
      // @ts-ignore
      ...getColumnSearchProps(setIsAdvertiser, { type: 'boolean' }),
    },
    ...(status === UserStatus.ACTIVE ? [{ title: 'Fee(%)', dataIndex: 'fee' }] : []),
    {
      title: 'Change status',
      render: (_: any, record: any) => (
        <Row>
          {status === 'NEW' && (
            <Button
              style={{ marginRight: '10px' }}
              size="small"
              onClick={event => {
                event.stopPropagation()
                changeUserStatus({ body: { userId: record.id, status: UserStatus.INACTIVE } }).then(() => {
                  showNotification({ type: 'success', message: `User status changed to INACTIVE` })
                  refetch()
                })
              }}
            >
              ❌&nbsp;Reject
            </Button>
          )}
          <Button
            size="small"
            onClick={event => {
              event.stopPropagation()
              const newStatus =
                status === UserStatus.NEW || status === UserStatus.INACTIVE ? UserStatus.ACTIVE : UserStatus.INACTIVE
              const isDeactivatingAdvertiser =
                newStatus === UserStatus.INACTIVE &&
                (record.roles.includes(UserRole.EXTERNAL_ADVERTISER) ||
                  record.roles.includes(UserRole.INTERNAL_ADVERTISER))
              const msg = isDeactivatingAdvertiser
                ? 'User deactivated. All user campaigns and creatives paused'
                : `User status changed to ${newStatus}`
              changeUserStatus({
                body: { userId: record.id, status: newStatus },
              }).then(() => {
                showNotification({ type: 'success', message: msg })
                refetch()
              })
            }}
          >
            {status === UserStatus.NEW || status === UserStatus.INACTIVE ? '✅ Activate' : 'Deactivate'}
          </Button>
        </Row>
      ),
    },
    ...(status === 'ACTIVE'
      ? [
          {
            title: 'Login as user',
            render: (value: any, record: any) => {
              return (
                <>
                  {user && record.id !== user.id ? (
                    <Button
                      onClick={() => {
                        window.location.href = `/app?uid=${record.id}`
                      }}
                      size="small"
                    >
                      <EyeOutlined />
                    </Button>
                  ) : (
                    <></>
                  )}
                </>
              )
            },
          },
        ]
      : []),
  ]

  return (
    <>
      <Table
        // @ts-ignore
        columns={columns}
        dataSource={data?.body?.items || []}
        loading={isLoading}
        onRow={user => {
          return {
            style: { cursor: 'pointer' },
            onClick: () => navigate(`/admin/users/${user.id}`),
          }
        }}
        rowKey="id"
        size="middle"
        scroll={{ x: true }}
        pagination={{
          pageSize: data?.body.pagination.limit,
          total: data?.body.pagination.total || 0,
          pageSizeOptions: [10, 20, 50, 100, 400],
          showSizeChanger: true,
          current: pageNumber,
          onChange(pageNumber, pageSize) {
            setPageNumber(pageNumber)
            setPageSize(pageSize)
          },
        }}
      />
    </>
  )
}
