import { gql } from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const RESET_SUBSTREAM_SETTINGS = gql`
  mutation ResetSubstreamSettings($input: ResetSubstreamSettingsInput!) {
    resetSubstreamSettings(input: $input) {
      isSuccess
    }
  }
`

export const useResetSubstreamSettings = (options = {}) => {
  const [mutate, { data, error }] = useMutation(RESET_SUBSTREAM_SETTINGS, {
    ...options,
  })

  return { mutate, data, error }
}
